import React, {useEffect, useRef} from 'react';

const WordCloud = (props) => {
    const canvas = useRef();

    useEffect(() => {
        window.$(canvas.current).jQWCloud(props);
    }, [props.words]);

    return (
        <div className="jq-cloud-container">
            <div id="jq-cloud" ref={canvas}></div>
        </div>
    );
}
export default WordCloud;
