import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogCloseButton,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogOverlay,
    Box,
    Heading,
    Image,
    Link,
    Stack
} from "@chakra-ui/react";
import './source-alert.css';
import {ExternalLinkIcon, ViewIcon} from '@chakra-ui/icons';
import googleLogo from './media/google-trends-logo.png';
// import trendsmapLogo from './media/trendsmap-logo.png';
import localDbLogo from './media/local-database-logo.png';
import {configs} from "../../Configs";
import DetailsModal from './DetailsModal';
import {useState} from 'react';
import {useNavigate} from "react-router-dom";

import {FormattedMessage} from 'react-intl';

export default function SourceAlert({target, isOpen, onOpen, onClose, cancelRef, ...rest}) {
    const [localDbTarget, setLocalDbTarget] = useState({});
    const navigate = useNavigate();

    return <>
        <AlertDialog
            motionPreset='slideInBottom'
            leastDestructiveRef={cancelRef}
            onClose={onClose}
            isOpen={isOpen}
            isCentered
        >
            <AlertDialogOverlay/>

            <AlertDialogContent>
                <AlertDialogHeader>
                    <Heading fontSize='2xl' align={'center'}
                             cursor="pointer"
                             _hover={{textDecoration: 'underline'}}
                             onClick={() => {
                                 const terms = [{'logic': 'OR', 'terms': [{'phrase': target.title, 'exclude': false}]}]
                                 return navigate(`/raqib6?tm=${encodeURIComponent(JSON.stringify(terms))}`)
                             }
                             }>
                        {target.title}
                    </Heading>
                    <AlertDialogCloseButton/>
                </AlertDialogHeader>
                <AlertDialogBody pb={6}>
                    <Stack direction={'row'} spacing={8}>
                        <Box p={5} shadow='md' borderWidth='1px' key={'db'}>
                            <Heading fontSize='lg' mb={4}>
                                <FormattedMessage
                                    id="raqib1.localDb"
                                    defaultMessage="Local Database"
                                />
                            </Heading>
                            <div className="source-alert-image-wrapper">
                                <Image
                                    className="source-alert-image"
                                    borderRadius='full'
                                    boxSize='150px'
                                    src={localDbLogo}
                                    alt='Local Data'
                                />
                                <div className="source-alert-image-overlay"
                                     onClick={() => setLocalDbTarget({...target})}>
                                    <ViewIcon/>
                                </div>
                            </div>
                        </Box>

                        {configs.raqib1.mapsSourcesVals.GOOGLE_TRENDS == target.source &&
                            <Box p={5} shadow='md' borderWidth='1px' key={'google-trends'}>
                                <Heading fontSize='lg' mb={4}>
                                    <FormattedMessage
                                        id="raqib1.googleTrends"
                                        defaultMessage="Google Trends"
                                    />
                                </Heading>
                                <div className="source-alert-image-wrapper">
                                    <Image
                                        className="source-alert-image"
                                        borderRadius='full'
                                        boxSize='150px'
                                        src={googleLogo}
                                        alt='Googe Trends'
                                    />

                                    <Link className="source-alert-image-overlay" isExternal
                                          href={`https://trends.google.com/trends/explore?date=${encodeURIComponent('now 1-d')}&geo=${configs.target_location.google_trends_country_code}&q=${encodeURIComponent(target.title)}`}>
                                        <ExternalLinkIcon/>
                                    </Link>
                                </div>
                            </Box>}

                        {/*{[configs.raqib1.mapsSourcesVals.TRENDSMAP, configs.raqib1.mapsSourcesVals.X].includes(target.source) &&*/}
                        {/*    <Box p={5} shadow='md' borderWidth='1px'>*/}
                        {/*        <Heading fontSize='lg' mb={4}>Trendsmap</Heading>*/}
                        {/*        <div className="source-alert-image-wrapper">*/}
                        {/*            <Image*/}
                        {/*                className="source-alert-image"*/}
                        {/*                borderRadius='full'*/}
                        {/*                boxSize='150px'*/}
                        {/*                src={trendsmapLogo}*/}
                        {/*                alt='Trendsmap'*/}
                        {/*            />*/}
                        {/*            <Link className="source-alert-image-overlay" isExternal*/}
                        {/*                  href={`https://www.trendsmap.com/topic/${encodeURIComponent(target.title)}`}>*/}
                        {/*                <ExternalLinkIcon/>*/}
                        {/*            </Link>*/}
                        {/*        </div>*/}
                        {/*    </Box>}*/}

                    </Stack>
                </AlertDialogBody>
            </AlertDialogContent>
        </AlertDialog>
        <DetailsModal timerange={configs.raqib1.mapsFilters.timeranges[3]} entity={localDbTarget}/>
    </>
}
