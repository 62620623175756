import {Box, Flex, FormControl, FormLabel, IconButton, Select, useColorModeValue,} from "@chakra-ui/react";
import {configs} from "../../Configs";
import {FaSortAmountDown, FaSortAmountUp} from "react-icons/fa";
import {FormattedMessage} from 'react-intl';

export const NavBar = ({period, setPeriod, sortBy, setSortBy, ascending, setAscending, ...rest}) => {
    return (
        <Box>
            <Flex
                as="nav"
                align="center"
                justify="center"
                wrap="wrap"
                padding="1rem"
                overflowX={'scroll'}
                className={'transparent-scrollbar'}
                color={useColorModeValue("gray.800", "white")}
            >
                <FormControl mx={{md: 2}} w={{base: '100%', md: 'auto'}}>
                    <FormLabel>
                        <FormattedMessage
                            id="raqib4.timeFilter"
                            defaultMessage="Time Filter"
                        />
                    </FormLabel>
                    <Select
                        variant="filled"
                        size="sm"
                        onChange={e => setPeriod(e.target.value)}
                        value={period}
                    >
                        <option key={"timerange:"} value="0">
                            <FormattedMessage
                                id="raqib4.all"
                                defaultMessage="All"
                            />
                        </option>
                        {configs.raqib4.periods.map((tr) => (
                            <option key={"timerange:" + tr.value} value={tr.value}>
                                <FormattedMessage
                                    id={`raqib4.${tr.label}`}
                                    defaultMessage={tr.label}
                                />
                            </option>
                        ))}
                    </Select>
                </FormControl>

                <FormControl mx={{md: 2}} w={{base: '100%', md: 'auto'}}>
                    <FormLabel>
                        <FormattedMessage
                            id="raqib4.sortBy"
                            defaultMessage="Sort by"
                        />
                    </FormLabel>
                    <Select
                        variant="filled"
                        size="sm"
                        value={sortBy}
                        onChange={e => setSortBy(e.target.value)}
                        color={useColorModeValue("gray.800", "white")}
                    >
                        <option key={"sortby:time"} value="created_at">
                            <FormattedMessage
                                id="raqib4.time"
                                defaultMessage="Time"
                            />
                        </option>
                        <option key={"platfrom:repeat"} value="repeat">
                            <FormattedMessage
                                id="raqib4.repeatedInPosts"
                                defaultMessage="Repeated in posts"
                            />
                        </option>
                    </Select>
                </FormControl>

                <FormControl mx={{md: 2}} w={{base: '100%', md: 'auto'}}>
                    <FormLabel>
                        <FormattedMessage
                            id="raqib4.sort"
                            defaultMessage="Sort"
                        />
                    </FormLabel>
                    <IconButton
                        size={'sm'}
                        w={{base: '100%'}}
                        variant={'outline'}
                        icon={
                            ascending ?
                                <FaSortAmountUp style={{display: 'inline-block'}} cursor={'pointer'}/> :
                                <FaSortAmountDown style={{display: 'inline-block'}} cursor={'pointer'}/>
                        }
                        onClick={() => setAscending(!ascending)}
                    />
                </FormControl>
            </Flex>
        </Box>
    );
};
