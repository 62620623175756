import Post from './Post';
import {NavBar} from "./NavBar";
import {Box, Spinner, Text} from '@chakra-ui/react';
import {configs} from "../../Configs";
import {useCallback, useEffect, useRef, useState} from "react";
import Masonry from 'react-masonry-css';
import "./Masonry.css";
import useAxios from "../../hooks/useAxois";
import axios from "axios";
import {FormattedMessage} from "react-intl";

export const Raqib3 = () => {
    const axiosInstance = useAxios();
    // pagination related stuff
    const [period, setPeriod] = useState(configs.raqib3.periods[5].value);
    const [sortBy, setSortBy] = useState('created_at');
    const [ascending, setAscending] = useState(false);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [lastElmId, setLastElmId] = useState(null);
    const [error, setError] = useState(false);
    const [pageId, setPageId] = useState(null);
    const [nextPageId, setNextPageId] = useState(null);
    const [forceRefresh, setForceRefresh] = useState(false);
    const observer = useRef();

    useEffect(() => {
        setNextPageId(null);
        setPageId(null);
        setData([]);
        setForceRefresh(!forceRefresh);
    }, [period, sortBy, ascending])

    useEffect(() => {
        setLastElmId(null);
        setIsLoading(true);
        setError(false);
        let cancel;
        axiosInstance.get("/raqib3/", {
            headers: {'Client-Timezone': configs.time_locale},
            params: {period: period, after: pageId, sort_by: sortBy, ascending: ascending},
            cancelToken: new axios.CancelToken(c => cancel = c)
        }).then((res) => setData(prev => {
            const theData = [...prev, ...res.data];
            // uniqify data combination
            let itteratedKeys = [];
            let newData = [];
            theData.map(pst => {
                if (!itteratedKeys.includes(pst.id)) {
                    itteratedKeys.push(pst.id);
                    newData.push(pst);
                }
            })

            // the nextPageId is determined by least sort (desc)
            let sortedData = theData;
            sortedData.sort((a, b) => parseFloat(a.sort) - parseFloat(b.sort));
            setLastElmId(sortedData.length ? sortedData[0].id : null);
            setNextPageId(sortedData.length ? sortedData[0].sort : null)
            setIsLoading(false);
            setError(false);

            return newData;
        })).catch((error) => {
            if (axios.isCancel(error)) return;
            setIsLoading(false);
            if (error.response && error.response.status === 404) return;
            setError(true);
            console.error(error);
        });
        return () => cancel();
    }, [pageId, forceRefresh])


    const lastPostElmRef = useCallback(node => {
        if (isLoading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && nextPageId) {
                setPageId(nextPageId)
            }
        })
        if (node) observer.current.observe(node);
    }, [isLoading, nextPageId])

    useEffect(() => {

        if (!configs.raqib3.should_refresh_default) {
            return
        }

        const theInterval = setInterval(() => {
            setData([]);
            setIsLoading(false);
            setLastElmId(null);
            setError(false);
            setNextPageId(null);
            setPageId(null);
            setForceRefresh(prevForceRefresh => !prevForceRefresh);
        }, configs.raqib3.refresh_interval_default * 1000);

        return () => {
            clearInterval(theInterval);
        };
    }, []);

    return (
        <>
            <NavBar period={period} setPeriod={setPeriod} sortBy={sortBy} setSortBy={setSortBy} ascending={ascending}
                    setAscending={setAscending}/>
            <Box p={5} pb={{base: 100, md: 5}}>
                <Masonry
                    breakpointCols={{
                        default: 5,
                        1100: 3,
                        700: 2,
                        500: 1
                    }}
                    className="masonry-grid"
                    columnClassName="masonry-grid_column"
                >
                    {data && data.map(pst => {
                        if (pst.id === lastElmId) {
                            return <Post key={pst.id} refr={lastPostElmRef} {...pst}/>
                        } else {
                            return <Post key={pst.id} {...pst}/>
                        }
                    })}
                </Masonry>

                {/*<Grid {{base: '1fr', md: 'repeat(3, 1fr)', lg: 'repeat(4, 1fr)', xl:'repeat(5, 1fr)'}} gap={6}>*/}
                {/*    {data && data.map(pst => {*/}
                {/*        if (pst.id === lastElmId) {*/}
                {/*            return <Post key={pst.id} refr={lastPostElmRef} {...pst}/>*/}
                {/*        } else {*/}
                {/*            return <Post key={pst.id} {...pst}/>*/}
                {/*        }*/}
                {/*    })}*/}
                {/*</Grid>*/}

                <Box w='100%' textAlign='center'>
                    {!data.length && !isLoading &&
                        <Text>
                            <FormattedMessage
                                id="responses.noData"
                                defaultMessage="No Data"
                            />
                        </Text>}
                    {!isLoading && error &&
                        <Text>
                            <FormattedMessage
                                id="responses.connectionFailed"
                                defaultMessage="Connection Failed"
                            />
                        </Text>}
                    {isLoading && <Spinner mt={5} color="green"/>}
                </Box>
            </Box>
        </>
    );
}
