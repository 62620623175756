import {Post} from './post';
import {Box, Spinner, Text, useColorModeValue} from "@chakra-ui/react";
import {useCallback, useEffect, useRef, useState} from "react";
import useAxios from "../../hooks/useAxois";
import axios from "axios";
import {FormattedMessage, useIntl} from 'react-intl';
import ReactSelect from 'react-select';

export const Raqib2PostsColumn = ({faction, factionsCount, shouldRefresh, refreshInterval}) => {
    const intl = useIntl();
    const colId = faction.value;
    const axiosInstance = useAxios();
    // pagination related stuff
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [lastElmId, setLastElmId] = useState(null);
    const [error, setError] = useState(false);
    const [pageId, setPageId] = useState(null);
    const [nextPageId, setNextPageId] = useState(null);
    const [forceRefresh, setForceRefresh] = useState(false);
    const [factions, setFactions] = useState([]);
    const [selectedColFactions, setSelectedColFactions] = useState([faction]);
    const observer = useRef();

    useEffect(() => {
        axiosInstance.get('/raqib2_tag').then((data) => setFactions(data.data.map(item => (
            {
                value: item.tag_id,
                label: item.title
            }
        ))));
    }, []);

    useEffect(() => {
        if (!faction) return;
        setLastElmId(null);
        setIsLoading(true);
        setError(false);
        let cancel;
        // Construct the query string
        const queryString = selectedColFactions.map(fct => `faction_ids=${fct.value}`).join('&');
        axiosInstance.get(`/raqib2/?${queryString}`, {
            // params: {faction_ids: faction.value, after: pageId},
            params: {after: pageId},
            cancelToken: new axios.CancelToken(c => cancel = c)
        }).then((res) => setData(prev => {
            const theData = [...prev, ...res.data];

            // uniqify data combination
            let itteratedKeys = [];
            let newData = [];
            theData.map(pst => {
                if (!itteratedKeys.includes(`${colId}:${pst.source}:${pst.id}`)) {
                    itteratedKeys.push(`${colId}:${pst.source}:${pst.id}`);
                    newData.push(pst);
                }
            })
            // the nextPageId is determined by least sort (desc)
            let sortedData = theData;
            sortedData.sort((a, b) => parseFloat(a.sort) - parseFloat(b.sort));
            setLastElmId(sortedData.length ? `${colId}:${sortedData[0].source}:${sortedData[0].id}` : null);
            setNextPageId(sortedData.length ? sortedData[0].sort : null)
            setIsLoading(false);
            setError(false);
            return newData;
        })).catch((error) => {
            if (axios.isCancel(error)) return;
            setIsLoading(false);
            setError(true);
            console.error(error);
        });
        return () => cancel();
    }, [pageId, forceRefresh])

    const lastPostElmRef = useCallback(node => {
        if (isLoading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && nextPageId) {
                setPageId(nextPageId)
            }
        })
        if (node) observer.current.observe(node);
    }, [isLoading, nextPageId])

    const refresh_procedure = () => {
        setData([]);
        setIsLoading(false);
        setLastElmId(null);
        setError(false);
        setNextPageId(null);
        setPageId(null);
        setForceRefresh(prevForceRefresh => !prevForceRefresh);
    }
    useEffect(() => refresh_procedure(), [selectedColFactions]);
    useEffect(() => {

        if (!shouldRefresh) {
            return
        }

        const theInterval = setInterval(() => refresh_procedure(), refreshInterval * 1000);

        return () => {
            clearInterval(theInterval);
        };
    }, []);

    const cstmMutliSelect = {
        width: '100%',
        minWidth: '0px',
        outline: '2px solid transparent',
        outlineOffset: '2px',
        position: 'relative',
        appearance: 'none',
        transitionProperty: 'var(--chakra-transition-property-common)',
        transitionDuration: 'var(--chakra-transition-duration-normal)',
        fontSize: 'var(--chakra-fontSizes-lg)',
        paddingInlineStart: 'var(--chakra-space-4)',
        paddingInlineEnd: 'var(--chakra-space-4)',
        minHeight: 'var(--chakra-sizes-12)',
        borderRadius: 'var(--chakra-radii-md)',
        border: '0px solid',
        borderColor: 'var(--chakra-colors-transparent)',
        background: useColorModeValue("var(--chakra-colors-gray-300)", "var(--chakra-colors-gray-600)"),
        marginTop: 'var(--chakra-space-6)',
        "&:hover": {
            background: useColorModeValue('var(--chakra-colors-gray-200)', 'var(--chakra-colors-whiteAlpha-100)'),
        },
        "&:focus-visible": {
            background: 'var(--chakra-colors-transparent)',
            borderColor: '#63b3ed'
        }
    }

    const cstmMutliSelectMenu = {
        background: useColorModeValue("var(--chakra-colors-gray-300)", "var(--chakra-colors-gray-600)"),
        color: useColorModeValue("var(--chakra-colors-gray-800)", "var(--chakra-colors-gray-300)"),
    }

    const cstmMutliSelectOptions = {
        background: useColorModeValue("var(--chakra-colors-gray-300)", "var(--chakra-colors-gray-600)"),

        "&:hover": {
            background: useColorModeValue('var(--chakra-colors-gray-200)', 'var(--chakra-colors-whiteAlpha-100)'),
        },
        "&:focus-visible": {
            background: 'var(--chakra-colors-transparent)',
            borderColor: '#63b3ed'
        }
    }

    let postCnt = 0;
    return (
        <Box
            maxW={{md: '25%'}}
            w={{base: '95vw', md: `calc((100% - 26px) / ${factionsCount} );`}}
            mx={{base: '15px', md: 'auto'}}
            align="center"
            wrap="wrap"
            justify="space-between"
        >
            <Box
                w={'100%'}
                mx={"auto"}
                mb={5}
                textAlign={'center'}>
                <ReactSelect
                    value={selectedColFactions}
                    onChange={o => setSelectedColFactions(o)}
                    placeholder={intl.formatMessage({
                        id: 'raqib2.factions',
                        defaultMessage: 'Topic Tags, Factions, ...'
                    })}
                    isMulti
                    options={factions}
                    closeMenuOnSelect={false}
                    classNames={
                        {
                            control: (state) => state.isFocused ? 'border-red-600' : 'border-grey-300'
                        }
                    }
                    styles={{
                        control: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelect}),
                        menu: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelectMenu}),
                        option: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelectOptions})
                    }}
                />
                {/*<Text fontFamily={'Cairo'} fontSize={18} fontWeight="bold">{faction.label}</Text>*/}
            </Box>

            {data && data.map(pst => {
                postCnt++;
                if (`${colId}:${pst.source}:${pst.id}` === lastElmId) {
                    return <Post
                        key={`${colId}:${pst.source}:${pst.id}`}
                        {...pst}
                        refr={lastPostElmRef}
                    />
                } else {
                    return <Post key={`${colId}:${pst.source}:${pst.id}`} {...pst}/>
                }
            })}
            {data && !postCnt && !isLoading &&
                <Text>
                    <FormattedMessage
                        id="responses.noData"
                        defaultMessage="No Data"
                    />
                </Text>}
            {!isLoading && error && <Text>
                <FormattedMessage
                    id="responses.connectionFailed"
                    defaultMessage="Connection Failed"
                />
            </Text>}
            {isLoading && <Spinner mt={5} color="green"/>}
        </Box>
    );
}
