import {Box, Button, Flex, Stack, useColorModeValue,} from "@chakra-ui/react";
import {configs} from "../../Configs";
import {FormattedMessage} from "react-intl";

export const NavBar = ({period, setPeriod, ...rest}) => {
    return (
        <Box>
            <Flex
                as="nav"
                align="center"
                justifyContent={{md: 'center'}}
                wrap="wrap"
                padding="1rem"
                overflowX={'scroll'}
                className={'transparent-scrollbar'}
                color={useColorModeValue("gray.800", "white")}
            >
                <Stack direction="row" spacing={8}>
                    {configs.articles.periods.map(tp => {
                        return <Button
                            key={`period:${tp.value}`}
                            colorScheme={tp.value === period ? 'green' : 'gray'}
                            onClick={() => setPeriod(tp.value)}
                            size="sm" variant='outline'
                        >
                            <FormattedMessage
                                id={`articles.${tp.label}`}
                                defaultMessage={tp.label}
                            />
                        </Button>
                    })}
                </Stack>
            </Flex>
        </Box>
    );
};
