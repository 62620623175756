import {Box, Spinner, Text} from "@chakra-ui/react";
import {FormattedMessage, useIntl} from "react-intl";
import {useContext, useEffect, useLayoutEffect, useState} from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5wc from "@amcharts/amcharts5/wc";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {LocaleContext} from "../Theme/LocaleProvider";
import useAxios from "../../hooks/useAxois";
import {useAuth} from "../../hooks/useAuth";
import {configs} from "../../Configs";
import {parseDashboardChartsFilters, rgbToHex} from "../../utils/helpers";
import {useNavigate} from "react-router-dom";

export const PostsTopWordsChart = ({
                                       parentIsLoading,
                                       since,
                                       until,
                                       resource_ids,
                                       tag_ids,
                                       faction_ids,
                                       ...rest
                                   }) => {
    const navigate = useNavigate();
    const axiosInstance = useAxios();
    const context = useContext(LocaleContext);
    const {hasPermission} = useAuth();
    const hasPermit = hasPermission('charts:posts:posts-top-words', 'fe_posts_top_words');
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(hasPermit ? 0 : 0);
    const [error, setError] = useState(false);

    const convertCountsToPercentage = (items) => {
        // Calculate the total count
        const totalCount = items.reduce((sum, item) => sum + item.count, 0);

        // Update each item's count to its percentage
        items.forEach(item => {
            item.count = (item.count / totalCount) * 100;
        });

        return items;
    }

    useEffect(() => {
        if (!hasPermit || parentIsLoading) return;

        // clear previous filtered data
        setData([]);

        const sourceList = Object.values(configs.raqib1.mapsSourcesVals)

        let apiCalls = [];
        let payload = {
            since: since,
            until: until
        }

        const url = parseDashboardChartsFilters("/charts/posts/posts-top-words?", tag_ids, faction_ids, resource_ids);
        sourceList.map(source => {
            if (!source.length) return;
            setIsLoading((old) => old + 1);
            payload.source = source;
            apiCalls.push(
                axiosInstance.get(url, {
                    headers: {'Client-Timezone': configs.time_locale},
                    params: payload
                }).catch((error) => console.error(error)).finally(() => {
                    setIsLoading(old => old - 1);
                })
            );
        });

        Promise.all(apiCalls).then(responses => {
            const items = [].concat(...responses.map(res => res?.data).filter(res => res !== undefined));
            let newData = items.filter((item) => sourceList.includes(item.source)).map((item, index) => {
                return {
                    ...item,
                    labelSettings: {
                        fill: rgbToHex(...configs.raqib1.mapsOsmProvider.colors[item.source])
                    }
                }
            });
            setData((oldData) => {
                let item_cnts = {};
                return [...oldData, ...newData].filter(item => {
                    item_cnts[item.source] = item_cnts[item.source] ? item_cnts[item.source] + 1 : 1;
                    return item_cnts[item.source] <= 10
                })
            });
        });
    }, [since, until, resource_ids, tag_ids, faction_ids]);

    useLayoutEffect(() => {
        if (isLoading || !hasPermit || parentIsLoading || !data.length) return;

        // define root chart with custom theme based on rtl/ltr
        let root = am5.Root.new("most-used-words");
        const customTheme = am5.Theme.new(root);
        if (context.isRTL) {
            customTheme.rule("Label").setAll({
                fontFamily: "IRANSans",
                direction: 'rtl'
            });
        }
        root.setThemes([am5themes_Animated.new(root), customTheme]);

        // Create wrapper container
        let container = root.container.children.push(am5.Container.new(root, {
            width: am5.percent(100),
            height: am5.percent(100),
            layout: root.verticalLayout
        }));

        // container.children.unshift(am5.Label.new(root, {
        //     text: intl.formatMessage({id: 'charts.posts.postsByTopicSunburstTitle'}),
        //     fontSize: 25,
        //     fontWeight: "500",
        //     textAlign: "center",
        //     x: am5.percent(50),
        //     centerX: am5.percent(50),
        //     paddingTop: 0,
        //     paddingBottom: 10
        // }));

        // Create series
        let series = container.children.push(am5wc.WordCloud.new(root, {
            categoryField: "word",
            valueField: "count",
            calculateAggregates: true // this is needed for heat rules to work
        }));

        series.labels.template.setAll({
            templateField: "labelSettings",
            padding: 10
        });

        // Add click event on words
        series.labels.template.events.on("click", (ev) => {
            const terms = [{
                'logic': 'OR',
                'terms': [{'phrase': ev.target.dataItem.dataContext.word, 'exclude': false}]
            }]
            return navigate(`/raqib6?tm=${encodeURIComponent(JSON.stringify(terms))}`)
        });

        // series.nodes.template.set("tooltipText", "{category}: {sum}");
        series.data.setAll(convertCountsToPercentage(data));
        series.set("selectedDataItem", series.dataItems[0]);

        // Make stuff animate on load - Beware that using this line in tag cloud cause word overlying
        // series.appear(1000, 100);

        return () => {
            root.dispose();
        };
    }, [isLoading, context.isRTL]);

    if (!hasPermit) return <></>

    return (<Box
        bg={"gray.100"}
        minHeight="3rem"
        my={2}
        p={3}
        py={5}
        rounded="lg"
        dir='ltr'
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        {...rest}
    >
        <Text mb={5} fontSize={25} fontWeight={500} dir={context.isRTL ? 'rtl' : 'ltr'}>
            <FormattedMessage id="charts.posts.postsTopWordsTitle"/>
        </Text>

        {(isLoading || parentIsLoading) && <Spinner color="green"/>}

        {!data.length && !isLoading && !parentIsLoading &&
            <Text><FormattedMessage id="responses.noData"/></Text>}

        {!data.length && !isLoading && !parentIsLoading && error &&
            <Text><FormattedMessage id="responses.connectionFailed"/></Text>}

        {!isLoading && !parentIsLoading && <Box id="most-used-words" w="100%" h="400px"></Box>}
    </Box>);
};
