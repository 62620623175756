import {Post} from './Post';
import {NavBar} from "./NavBar";
import {Box, Spinner, Text} from '@chakra-ui/react';
import {useCallback, useEffect, useRef, useState} from "react";
import {configs} from "../../Configs";
import useAxios from "../../hooks/useAxois";
import axios from "axios";
import Masonry from 'react-masonry-css';
import {FormattedMessage} from "react-intl";


export const Raqib6 = () => {
    const axiosInstance = useAxios();
    const searchParams = new URLSearchParams(window.location.search);

    // pagination related stuff
    const [socialplatform, setSocialPlatform] = useState(searchParams.get('sp') || '');
    const [hashtags, setHashtags] = useState([]);
    const [hashtag, setHashtag] = useState(searchParams.get('ht') || '');
    const [timerange, setTimerange] = useState(parseInt(searchParams.get('tf')) || 0);
    const [hasStar, setHasStar] = useState(!!searchParams.get('hs') || false);
    const encodedJson = searchParams.get('tm');
    let default_terms = [{'logic': 'OR', 'terms': [{'phrase': '', 'exclude': false}]}];
    if (encodedJson) {
        try {
            default_terms = JSON.parse(decodeURIComponent(encodedJson));
        } catch (error) {
            console.error('Error parsing terms from URL parameter', error);
        }
    }
    const [terms, setTerms] = useState(default_terms);
    // const [include, setInclude] = useState('');
    // const [exclude, setExclude] = useState('');
    const [resultsCount, setResultsCount] = useState(0);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [lastElmId, setLastElmId] = useState(null);
    const [error, setError] = useState(false);
    const [pageId, setPageId] = useState(null);
    const [nextPageId, setNextPageId] = useState(null);
    const [forceRefresh, setForceRefresh] = useState(false);
    const observer = useRef();

    useEffect(() => onSubmit(true), [pageId, forceRefresh])
    useEffect(() => {
            //fetching hashtag resources
            // TODO: this is not gonna give us all hashtags. bc it has no backend filter and also has no pagination
            axiosInstance.get("/raqib6/resources").then((res) => {
                setHashtags(res.data.map(rsr => ({label: rsr.title, value: rsr.resource_id})))
                setIsLoading(false);
                setError(false);
            }).catch((error) => {
                setIsLoading(false);
                if (error.response && error.response.status === 404) return;
                setError(true);
                console.error(error);
            });
        }, []
    )

    const lastPostElmRef = useCallback(node => {
        if (isLoading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && nextPageId) {
                setPageId(nextPageId)
            }
        })
        if (node) observer.current.observe(node);
    }, [isLoading, nextPageId])

    const onSubmit = (paginate = false) => {
        setLastElmId(null);
        setIsLoading(true);
        setError(false);
        let cancel;

        const theTerms = terms.filter(paranthesis => paranthesis['terms'].filter(item => item['phrase'].replace(/\s/g, '').length > 0).length > 0);

        let params = {
            platform: socialplatform,
            period: timerange,
            hashtag: hashtag,
            // use terms instead of include and exclude
            terms: JSON.stringify(theTerms),
            // exclude: exclude,
            has_star: hasStar
        }

        if (paginate) {
            params = {...params, ...{after: pageId}}
        } else {
            setNextPageId(null);
            setPageId(null);
            setData([]);
            setResultsCount(0);
        }
        axiosInstance.get("/raqib6/", {
            headers: {'Client-Timezone': configs.time_locale},
            params: params,
            cancelToken: new axios.CancelToken(c => cancel = c)
        }).then((res) => {
            setResultsCount(res?.data?.count || 0);
            setData(prev => {
                // discard previous results if its not paginating
                const theData = paginate ? [...prev, ...(res?.data?.posts || [])] : [...(res?.data?.posts || [])];
                // uniqify data combination
                let itteratedKeys = [];
                let newData = [];
                theData.map(pst => {
                    if (!itteratedKeys.includes(pst.id)) {
                        itteratedKeys.push(pst.id);
                        newData.push(pst);
                    }
                })

                // the nextPageId is determined by least sort (desc)
                let sortedData = theData;
                sortedData.sort((a, b) => parseFloat(a.sort) - parseFloat(b.sort));
                setLastElmId(sortedData.length ? sortedData[0].id : null);
                setNextPageId(sortedData.length ? sortedData[0].sort : null)
                setIsLoading(false);
                setError(false);

                return newData;
            });
        }).catch((error) => {
            if (axios.isCancel(error)) return;
            setIsLoading(false);
            if (error.response && error.response.status === 404) return;
            setError(true);
            console.error(error);
        });
        return () => cancel();
    }

    const onClear = () => {
        setSocialPlatform('');
        setHashtag('');
        setTimerange(0);
        setHasStar(false);
        // setInclude('');
        // setExclude('');
        setPageId(null);
        setData([]);
        setLastElmId(null);
        setIsLoading(false);
        setError(false);
        onSubmit();
    }

    useEffect(() => {

        if (!configs.raqib6.should_refresh_default) {
            return
        }

        const theInterval = setInterval(() => {
            setData([]);
            setIsLoading(false);
            setLastElmId(null);
            setError(false);
            setNextPageId(null);
            setPageId(null);
            setForceRefresh(prevForceRefresh => !prevForceRefresh);
        }, configs.raqib6.refresh_interval_default * 1000);

        return () => {
            clearInterval(theInterval);
        };
    }, []);

    return (
        <>
            <NavBar
                resultsCount={resultsCount}
                hashtags={hashtags}
                hashtag={hashtag}
                setHashtag={setHashtag}
                socialPlatform={socialplatform}
                setSocialPlatform={setSocialPlatform}
                timerange={timerange}
                setTimerange={setTimerange}
                terms={terms}
                setTerms={setTerms}
                hasStar={hasStar}
                setHasStar={setHasStar}
                // include={include}
                // setInclude={setInclude}
                // exclude={exclude}
                // setExclude={setExclude}
                onSubmit={onSubmit}
                onClear={onClear}
            />
            <Box p={5} pb={{base: 100, md: 5}}>

                <Masonry
                    breakpointCols={{
                        default: 5,
                        1100: 3,
                        700: 2,
                        500: 1
                    }}
                    className="masonry-grid"
                    columnClassName="masonry-grid_column"
                >
                    {/*<Grid templateColumns={{base: '1fr', md: 'repeat(3, 1fr)', lg: 'repeat(4, 1fr)', xl: 'repeat(5, 1fr)'}}*/}
                    {/*      gap={6}>*/}
                    {data && data.map(pst => <Post key={pst.id}
                                                   refr={pst.id === lastElmId ? lastPostElmRef : undefined}
                                                   {...pst}/>)}
                    {/*</Grid>*/}
                </Masonry>
                <Box w='100%' textAlign='center'>
                    {!data.length && !isLoading &&
                        <Text>
                            <FormattedMessage
                                id="responses.noData"
                                defaultMessage="No Data"
                            />
                        </Text>}
                    {!isLoading && error &&
                        <Text>
                            <FormattedMessage
                                id="responses.connectionFailed"
                                defaultMessage="Connection Failed"
                            />
                        </Text>}
                    {isLoading && <Spinner mt={5} color="green"/>}
                </Box>
            </Box>
        </>
    );
}
