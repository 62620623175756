export default {
    "crs": {
        "properties": {
            "name": "urn:ogc:def:crs:OGC:1.3:CRS84"
        },
        "type": "name"
    },
    "features": [
        {
            "geometry": {
                "coordinates": [
                    [
                        [
                            [-180, -90],
                            [-180, 90],
                            [180, 90],
                            [180, -90],
                            [-180, -90]
                        ],
                        [
                            [
                                50.8012,
                                25.6509
                            ],
                            [
                                50.8085,
                                25.6492
                            ],
                            [
                                50.8123,
                                25.6495
                            ],
                            [
                                50.8183,
                                25.643
                            ],
                            [
                                50.8137,
                                25.6366
                            ],
                            [
                                50.8165,
                                25.6351
                            ],
                            [
                                50.8151,
                                25.632
                            ],
                            [
                                50.8063,
                                25.6294
                            ],
                            [
                                50.807,
                                25.633
                            ],
                            [
                                50.7991,
                                25.6331
                            ],
                            [
                                50.7912,
                                25.629
                            ],
                            [
                                50.7862,
                                25.6385
                            ],
                            [
                                50.7887,
                                25.642
                            ],
                            [
                                50.7833,
                                25.6475
                            ],
                            [
                                50.7835,
                                25.6532
                            ],
                            [
                                50.782,
                                25.6574
                            ],
                            [
                                50.7862,
                                25.6574
                            ],
                            [
                                50.7886,
                                25.6603
                            ],
                            [
                                50.7936,
                                25.6576
                            ],
                            [
                                50.7942,
                                25.6555
                            ],
                            [
                                50.7915,
                                25.6519
                            ],
                            [
                                50.7967,
                                25.6501
                            ],
                            [
                                50.8012,
                                25.6509
                            ]
                        ],

                        [
                            [
                                50.795,
                                25.7144
                            ],
                            [
                                50.7892,
                                25.7075
                            ],
                            [
                                50.7895,
                                25.7037
                            ],
                            [
                                50.7847,
                                25.703
                            ],
                            [
                                50.7804,
                                25.6976
                            ],
                            [
                                50.7795,
                                25.6933
                            ],
                            [
                                50.7822,
                                25.6917
                            ],
                            [
                                50.7857,
                                25.6928
                            ],
                            [
                                50.7878,
                                25.689
                            ],
                            [
                                50.793,
                                25.6879
                            ],
                            [
                                50.7967,
                                25.6836
                            ],
                            [
                                50.7908,
                                25.6817
                            ],
                            [
                                50.7842,
                                25.684
                            ],
                            [
                                50.7776,
                                25.6815
                            ],
                            [
                                50.7718,
                                25.674
                            ],
                            [
                                50.7673,
                                25.6739
                            ],
                            [
                                50.7582,
                                25.6705
                            ],
                            [
                                50.7578,
                                25.6634
                            ],
                            [
                                50.762,
                                25.6585
                            ],
                            [
                                50.7655,
                                25.6511
                            ],
                            [
                                50.7691,
                                25.6505
                            ],
                            [
                                50.7728,
                                25.6446
                            ],
                            [
                                50.7723,
                                25.6412
                            ],
                            [
                                50.774,
                                25.6373
                            ],
                            [
                                50.7677,
                                25.6379
                            ],
                            [
                                50.7624,
                                25.6292
                            ],
                            [
                                50.7628,
                                25.6256
                            ],
                            [
                                50.7661,
                                25.623
                            ],
                            [
                                50.7612,
                                25.6199
                            ],
                            [
                                50.7589,
                                25.6169
                            ],
                            [
                                50.7591,
                                25.6141
                            ],
                            [
                                50.764,
                                25.6129
                            ],
                            [
                                50.7705,
                                25.6172
                            ],
                            [
                                50.7729,
                                25.6165
                            ],
                            [
                                50.7744,
                                25.6093
                            ],
                            [
                                50.7646,
                                25.6053
                            ],
                            [
                                50.7623,
                                25.6011
                            ],
                            [
                                50.7636,
                                25.5978
                            ],
                            [
                                50.7608,
                                25.5917
                            ],
                            [
                                50.7563,
                                25.5885
                            ],
                            [
                                50.7568,
                                25.5836
                            ],
                            [
                                50.7617,
                                25.5804
                            ],
                            [
                                50.7589,
                                25.5791
                            ],
                            [
                                50.7559,
                                25.5811
                            ],
                            [
                                50.7513,
                                25.5816
                            ],
                            [
                                50.7433,
                                25.5881
                            ],
                            [
                                50.7424,
                                25.5971
                            ],
                            [
                                50.7479,
                                25.6045
                            ],
                            [
                                50.751,
                                25.6137
                            ],
                            [
                                50.7504,
                                25.6231
                            ],
                            [
                                50.7483,
                                25.6264
                            ],
                            [
                                50.7507,
                                25.6328
                            ],
                            [
                                50.7508,
                                25.6426
                            ],
                            [
                                50.7387,
                                25.6558
                            ],
                            [
                                50.7424,
                                25.6637
                            ],
                            [
                                50.7431,
                                25.6711
                            ],
                            [
                                50.7412,
                                25.6761
                            ],
                            [
                                50.744,
                                25.6798
                            ],
                            [
                                50.7467,
                                25.6886
                            ],
                            [
                                50.7516,
                                25.6967
                            ],
                            [
                                50.762,
                                25.7076
                            ],
                            [
                                50.7643,
                                25.7125
                            ],
                            [
                                50.7677,
                                25.7152
                            ],
                            [
                                50.7719,
                                25.7212
                            ],
                            [
                                50.7765,
                                25.7199
                            ],
                            [
                                50.78,
                                25.7207
                            ],
                            [
                                50.781,
                                25.7179
                            ],
                            [
                                50.7842,
                                25.7182
                            ],
                            [
                                50.7869,
                                25.7256
                            ],
                            [
                                50.7931,
                                25.7265
                            ],
                            [
                                50.793,
                                25.7287
                            ],
                            [
                                50.7992,
                                25.729
                            ],
                            [
                                50.7959,
                                25.726
                            ],
                            [
                                50.7961,
                                25.7211
                            ],
                            [
                                50.7944,
                                25.7171
                            ],
                            [
                                50.795,
                                25.7144
                            ]
                        ]
                        ,
                        [
                            [
                                50.7767,
                                25.6661
                            ],
                            [
                                50.7798,
                                25.6629
                            ],
                            [
                                50.7742,
                                25.658
                            ],
                            [
                                50.7712,
                                25.6625
                            ],
                            [
                                50.7738,
                                25.6664
                            ],
                            [
                                50.7767,
                                25.6661
                            ]
                        ]
                        ,
                        [
                            [
                                50.8078,
                                25.6659
                            ],
                            [
                                50.8025,
                                25.6685
                            ],
                            [
                                50.8002,
                                25.6724
                            ],
                            [
                                50.8014,
                                25.6778
                            ],
                            [
                                50.8057,
                                25.6809
                            ],
                            [
                                50.8108,
                                25.6814
                            ],
                            [
                                50.8142,
                                25.6845
                            ],
                            [
                                50.8178,
                                25.6832
                            ],
                            [
                                50.8201,
                                25.6805
                            ],
                            [
                                50.8212,
                                25.6788
                            ],
                            [
                                50.8173,
                                25.669
                            ],
                            [
                                50.8096,
                                25.6675
                            ],
                            [
                                50.8078,
                                25.6659
                            ]
                        ]
                        ,
                        [
                            [
                                50.773,
                                25.75
                            ],
                            [
                                50.7752,
                                25.7543
                            ],
                            [
                                50.7798,
                                25.7542
                            ],
                            [
                                50.785,
                                25.7565
                            ],
                            [
                                50.7869,
                                25.7558
                            ],
                            [
                                50.7902,
                                25.7534
                            ],
                            [
                                50.788,
                                25.7476
                            ],
                            [
                                50.7786,
                                25.7448
                            ],
                            [
                                50.773,
                                25.75
                            ]
                        ]
                        ,
                        [
                            [
                                50.7595,
                                25.7524
                            ],
                            [
                                50.7664,
                                25.7558
                            ],
                            [
                                50.7695,
                                25.7557
                            ],
                            [
                                50.7714,
                                25.7514
                            ],
                            [
                                50.7686,
                                25.7487
                            ],
                            [
                                50.764,
                                25.747
                            ],
                            [
                                50.7611,
                                25.7484
                            ],
                            [
                                50.7595,
                                25.7524
                            ]
                        ]
                        ,
                        [
                            [
                                50.6509,
                                26.3024
                            ],
                            [
                                50.6488,
                                26.3012
                            ],
                            [
                                50.6461,
                                26.2935
                            ],
                            [
                                50.643,
                                26.2878
                            ],
                            [
                                50.647,
                                26.2846
                            ],
                            [
                                50.6568,
                                26.2798
                            ],
                            [
                                50.663,
                                26.2749
                            ],
                            [
                                50.6649,
                                26.2765
                            ],
                            [
                                50.6547,
                                26.2817
                            ],
                            [
                                50.6565,
                                26.2845
                            ],
                            [
                                50.6609,
                                26.2855
                            ],
                            [
                                50.6629,
                                26.2901
                            ],
                            [
                                50.6613,
                                26.2927
                            ],
                            [
                                50.6616,
                                26.2985
                            ],
                            [
                                50.6675,
                                26.3005
                            ],
                            [
                                50.6711,
                                26.298
                            ],
                            [
                                50.6778,
                                26.2955
                            ],
                            [
                                50.6795,
                                26.2915
                            ],
                            [
                                50.6793,
                                26.2872
                            ],
                            [
                                50.6773,
                                26.2858
                            ],
                            [
                                50.6753,
                                26.2808
                            ],
                            [
                                50.6693,
                                26.2819
                            ],
                            [
                                50.6676,
                                26.2781
                            ],
                            [
                                50.6739,
                                26.2777
                            ],
                            [
                                50.6736,
                                26.2743
                            ],
                            [
                                50.6638,
                                26.2742
                            ],
                            [
                                50.6651,
                                26.2697
                            ],
                            [
                                50.6704,
                                26.2706
                            ],
                            [
                                50.6781,
                                26.2781
                            ],
                            [
                                50.6833,
                                26.2741
                            ],
                            [
                                50.6856,
                                26.2701
                            ],
                            [
                                50.6822,
                                26.2669
                            ],
                            [
                                50.6756,
                                26.2667
                            ],
                            [
                                50.6712,
                                26.2654
                            ],
                            [
                                50.6703,
                                26.2695
                            ],
                            [
                                50.6652,
                                26.2685
                            ],
                            [
                                50.6647,
                                26.2611
                            ],
                            [
                                50.6589,
                                26.2612
                            ],
                            [
                                50.6584,
                                26.2547
                            ],
                            [
                                50.6674,
                                26.2537
                            ],
                            [
                                50.668,
                                26.2602
                            ],
                            [
                                50.6703,
                                26.2614
                            ],
                            [
                                50.6759,
                                26.2608
                            ],
                            [
                                50.6749,
                                26.2528
                            ],
                            [
                                50.6584,
                                26.2532
                            ],
                            [
                                50.6607,
                                26.2499
                            ],
                            [
                                50.6621,
                                26.2441
                            ],
                            [
                                50.6705,
                                26.2439
                            ],
                            [
                                50.6754,
                                26.241
                            ],
                            [
                                50.6822,
                                26.2345
                            ],
                            [
                                50.68,
                                26.2304
                            ],
                            [
                                50.6689,
                                26.2307
                            ],
                            [
                                50.6691,
                                26.2252
                            ],
                            [
                                50.685,
                                26.2253
                            ],
                            [
                                50.6987,
                                26.2242
                            ],
                            [
                                50.7,
                                26.225
                            ],
                            [
                                50.7035,
                                26.225
                            ],
                            [
                                50.7037,
                                26.2276
                            ],
                            [
                                50.7085,
                                26.2275
                            ],
                            [
                                50.7099,
                                26.2247
                            ],
                            [
                                50.7014,
                                26.2247
                            ],
                            [
                                50.7027,
                                26.2231
                            ],
                            [
                                50.7008,
                                26.2209
                            ],
                            [
                                50.7225,
                                26.1836
                            ],
                            [
                                50.7181,
                                26.1807
                            ],
                            [
                                50.7161,
                                26.1827
                            ],
                            [
                                50.7083,
                                26.1954
                            ],
                            [
                                50.7117,
                                26.1993
                            ],
                            [
                                50.7063,
                                26.2084
                            ],
                            [
                                50.7014,
                                26.2063
                            ],
                            [
                                50.6963,
                                26.2164
                            ],
                            [
                                50.6994,
                                26.2193
                            ],
                            [
                                50.6861,
                                26.2191
                            ],
                            [
                                50.6863,
                                26.2084
                            ],
                            [
                                50.6906,
                                26.202
                            ],
                            [
                                50.6856,
                                26.2001
                            ],
                            [
                                50.6883,
                                26.1941
                            ],
                            [
                                50.6808,
                                26.191
                            ],
                            [
                                50.6744,
                                26.2025
                            ],
                            [
                                50.6685,
                                26.2026
                            ],
                            [
                                50.6749,
                                26.1914
                            ],
                            [
                                50.6685,
                                26.1846
                            ],
                            [
                                50.6643,
                                26.1876
                            ],
                            [
                                50.6626,
                                26.191
                            ],
                            [
                                50.6631,
                                26.1984
                            ],
                            [
                                50.6587,
                                26.2047
                            ],
                            [
                                50.6587,
                                26.209
                            ],
                            [
                                50.6534,
                                26.2153
                            ],
                            [
                                50.6532,
                                26.2193
                            ],
                            [
                                50.6575,
                                26.2175
                            ],
                            [
                                50.6573,
                                26.2221
                            ],
                            [
                                50.6546,
                                26.224
                            ],
                            [
                                50.638,
                                26.2246
                            ],
                            [
                                50.6318,
                                26.2218
                            ],
                            [
                                50.6318,
                                26.2269
                            ],
                            [
                                50.6421,
                                26.2265
                            ],
                            [
                                50.644,
                                26.2326
                            ],
                            [
                                50.649,
                                26.2376
                            ],
                            [
                                50.6441,
                                26.2418
                            ],
                            [
                                50.6437,
                                26.2471
                            ],
                            [
                                50.6385,
                                26.2468
                            ],
                            [
                                50.634,
                                26.2435
                            ],
                            [
                                50.629,
                                26.246
                            ],
                            [
                                50.6254,
                                26.2557
                            ],
                            [
                                50.6224,
                                26.2554
                            ],
                            [
                                50.621,
                                26.2501
                            ],
                            [
                                50.621,
                                26.2454
                            ],
                            [
                                50.6176,
                                26.2415
                            ],
                            [
                                50.6118,
                                26.241
                            ],
                            [
                                50.6079,
                                26.2443
                            ],
                            [
                                50.6062,
                                26.2476
                            ],
                            [
                                50.6035,
                                26.2487
                            ],
                            [
                                50.6043,
                                26.2521
                            ],
                            [
                                50.6034,
                                26.2598
                            ],
                            [
                                50.6003,
                                26.2598
                            ],
                            [
                                50.5962,
                                26.2555
                            ],
                            [
                                50.5911,
                                26.2598
                            ],
                            [
                                50.5911,
                                26.2632
                            ],
                            [
                                50.5952,
                                26.2659
                            ],
                            [
                                50.5937,
                                26.2739
                            ],
                            [
                                50.5896,
                                26.2797
                            ],
                            [
                                50.5789,
                                26.2855
                            ],
                            [
                                50.5827,
                                26.2875
                            ],
                            [
                                50.5783,
                                26.2937
                            ],
                            [
                                50.5796,
                                26.2953
                            ],
                            [
                                50.5896,
                                26.292
                            ],
                            [
                                50.5925,
                                26.2928
                            ],
                            [
                                50.5937,
                                26.2876
                            ],
                            [
                                50.5997,
                                26.2833
                            ],
                            [
                                50.6075,
                                26.2824
                            ],
                            [
                                50.6121,
                                26.2807
                            ],
                            [
                                50.6138,
                                26.2832
                            ],
                            [
                                50.6185,
                                26.2849
                            ],
                            [
                                50.621,
                                26.2871
                            ],
                            [
                                50.6349,
                                26.2876
                            ],
                            [
                                50.6389,
                                26.2869
                            ],
                            [
                                50.6413,
                                26.289
                            ],
                            [
                                50.6417,
                                26.2929
                            ],
                            [
                                50.6446,
                                26.2938
                            ],
                            [
                                50.6179,
                                26.3096
                            ],
                            [
                                50.6285,
                                26.323
                            ],
                            [
                                50.6344,
                                26.3229
                            ],
                            [
                                50.6389,
                                26.3199
                            ],
                            [
                                50.6396,
                                26.3147
                            ],
                            [
                                50.6493,
                                26.3152
                            ],
                            [
                                50.6531,
                                26.312
                            ],
                            [
                                50.6544,
                                26.3075
                            ],
                            [
                                50.6509,
                                26.3024
                            ]
                        ]
                        ,
                        [
                            [
                                50.6478,
                                26.3239
                            ],
                            [
                                50.6507,
                                26.3248
                            ],
                            [
                                50.6541,
                                26.3302
                            ],
                            [
                                50.6592,
                                26.3283
                            ],
                            [
                                50.6645,
                                26.322
                            ],
                            [
                                50.6611,
                                26.3148
                            ],
                            [
                                50.6575,
                                26.3107
                            ],
                            [
                                50.652,
                                26.3154
                            ],
                            [
                                50.6456,
                                26.3197
                            ],
                            [
                                50.6416,
                                26.3189
                            ],
                            [
                                50.6391,
                                26.3229
                            ],
                            [
                                50.6319,
                                26.3251
                            ],
                            [
                                50.6322,
                                26.3278
                            ],
                            [
                                50.6381,
                                26.3293
                            ],
                            [
                                50.6372,
                                26.3261
                            ],
                            [
                                50.6406,
                                26.3238
                            ],
                            [
                                50.6445,
                                26.3254
                            ],
                            [
                                50.6478,
                                26.3239
                            ]
                        ]
                        ,
                        [
                            [
                                50.6243,
                                26.1093
                            ],
                            [
                                50.6199,
                                26.1049
                            ],
                            [
                                50.6179,
                                26.0987
                            ],
                            [
                                50.6207,
                                26.095
                            ],
                            [
                                50.6288,
                                26.0954
                            ],
                            [
                                50.6285,
                                26.0763
                            ],
                            [
                                50.6224,
                                26.0771
                            ],
                            [
                                50.6243,
                                26.0699
                            ],
                            [
                                50.619,
                                26.0682
                            ],
                            [
                                50.619,
                                26.0604
                            ],
                            [
                                50.6201,
                                26.0543
                            ],
                            [
                                50.6207,
                                26.0443
                            ],
                            [
                                50.6254,
                                26.0449
                            ],
                            [
                                50.6274,
                                26.0415
                            ],
                            [
                                50.6299,
                                26.0424
                            ],
                            [
                                50.6321,
                                26.0388
                            ],
                            [
                                50.6299,
                                26.034
                            ],
                            [
                                50.6263,
                                26.031
                            ],
                            [
                                50.6246,
                                26.0215
                            ],
                            [
                                50.6249,
                                26.011
                            ],
                            [
                                50.6204,
                                26.0096
                            ],
                            [
                                50.6196,
                                25.9976
                            ],
                            [
                                50.6176,
                                25.9865
                            ],
                            [
                                50.6154,
                                25.979
                            ],
                            [
                                50.6154,
                                25.9751
                            ],
                            [
                                50.6132,
                                25.9701
                            ],
                            [
                                50.614,
                                25.9604
                            ],
                            [
                                50.6129,
                                25.9557
                            ],
                            [
                                50.6157,
                                25.9543
                            ],
                            [
                                50.6143,
                                25.951
                            ],
                            [
                                50.614,
                                25.9454
                            ],
                            [
                                50.6115,
                                25.9435
                            ],
                            [
                                50.6115,
                                25.9376
                            ],
                            [
                                50.6138,
                                25.9349
                            ],
                            [
                                50.6115,
                                25.9313
                            ],
                            [
                                50.6118,
                                25.9279
                            ],
                            [
                                50.6101,
                                25.9246
                            ],
                            [
                                50.6068,
                                25.9135
                            ],
                            [
                                50.6104,
                                25.9107
                            ],
                            [
                                50.6101,
                                25.9057
                            ],
                            [
                                50.6049,
                                25.8951
                            ],
                            [
                                50.6071,
                                25.8932
                            ],
                            [
                                50.6051,
                                25.884
                            ],
                            [
                                50.6051,
                                25.8754
                            ],
                            [
                                50.6062,
                                25.869
                            ],
                            [
                                50.6032,
                                25.8604
                            ],
                            [
                                50.6051,
                                25.8579
                            ],
                            [
                                50.6076,
                                25.851
                            ],
                            [
                                50.6007,
                                25.8451
                            ],
                            [
                                50.5993,
                                25.8415
                            ],
                            [
                                50.5954,
                                25.8368
                            ],
                            [
                                50.5946,
                                25.834
                            ],
                            [
                                50.5913,
                                25.8332
                            ],
                            [
                                50.5865,
                                25.8268
                            ],
                            [
                                50.5835,
                                25.8262
                            ],
                            [
                                50.5801,
                                25.8235
                            ],
                            [
                                50.5749,
                                25.8165
                            ],
                            [
                                50.571,
                                25.8137
                            ],
                            [
                                50.5668,
                                25.8057
                            ],
                            [
                                50.5629,
                                25.8046
                            ],
                            [
                                50.5601,
                                25.8121
                            ],
                            [
                                50.5549,
                                25.8204
                            ],
                            [
                                50.5512,
                                25.8363
                            ],
                            [
                                50.5512,
                                25.8465
                            ],
                            [
                                50.5468,
                                25.8568
                            ],
                            [
                                50.5343,
                                25.874
                            ],
                            [
                                50.5312,
                                25.8796
                            ],
                            [
                                50.5221,
                                25.894
                            ],
                            [
                                50.521,
                                25.9004
                            ],
                            [
                                50.5165,
                                25.9054
                            ],
                            [
                                50.5143,
                                25.901
                            ],
                            [
                                50.5046,
                                25.9104
                            ],
                            [
                                50.4996,
                                25.9165
                            ],
                            [
                                50.4954,
                                25.9196
                            ],
                            [
                                50.4924,
                                25.9243
                            ],
                            [
                                50.4851,
                                25.9288
                            ],
                            [
                                50.4796,
                                25.9299
                            ],
                            [
                                50.474,
                                25.9332
                            ],
                            [
                                50.4699,
                                25.9368
                            ],
                            [
                                50.4624,
                                25.9468
                            ],
                            [
                                50.4574,
                                25.956
                            ],
                            [
                                50.4562,
                                25.9599
                            ],
                            [
                                50.4565,
                                25.9704
                            ],
                            [
                                50.4546,
                                25.979
                            ],
                            [
                                50.456,
                                25.9804
                            ],
                            [
                                50.4596,
                                25.9899
                            ],
                            [
                                50.4643,
                                25.994
                            ],
                            [
                                50.4718,
                                26.0035
                            ],
                            [
                                50.4724,
                                26.0096
                            ],
                            [
                                50.4718,
                                26.0165
                            ],
                            [
                                50.4724,
                                26.0229
                            ],
                            [
                                50.4765,
                                26.0251
                            ],
                            [
                                50.4779,
                                26.0307
                            ],
                            [
                                50.4821,
                                26.0357
                            ],
                            [
                                50.4851,
                                26.0415
                            ],
                            [
                                50.4851,
                                26.0454
                            ],
                            [
                                50.4815,
                                26.0493
                            ],
                            [
                                50.4832,
                                26.0587
                            ],
                            [
                                50.4801,
                                26.0624
                            ],
                            [
                                50.4804,
                                26.0684
                            ],
                            [
                                50.4818,
                                26.0799
                            ],
                            [
                                50.4804,
                                26.0804
                            ],
                            [
                                50.4776,
                                26.0968
                            ],
                            [
                                50.4757,
                                26.1021
                            ],
                            [
                                50.4682,
                                26.1076
                            ],
                            [
                                50.4665,
                                26.114
                            ],
                            [
                                50.4585,
                                26.1268
                            ],
                            [
                                50.4585,
                                26.1315
                            ],
                            [
                                50.454,
                                26.1368
                            ],
                            [
                                50.4476,
                                26.1349
                            ],
                            [
                                50.4468,
                                26.1387
                            ],
                            [
                                50.4532,
                                26.1407
                            ],
                            [
                                50.4529,
                                26.1449
                            ],
                            [
                                50.4504,
                                26.1496
                            ],
                            [
                                50.4499,
                                26.1565
                            ],
                            [
                                50.4471,
                                26.1621
                            ],
                            [
                                50.4476,
                                26.1674
                            ],
                            [
                                50.4493,
                                26.1713
                            ],
                            [
                                50.4415,
                                26.1707
                            ],
                            [
                                50.4201,
                                26.1674
                            ],
                            [
                                50.4207,
                                26.1618
                            ],
                            [
                                50.4187,
                                26.1574
                            ],
                            [
                                50.4193,
                                26.1537
                            ],
                            [
                                50.4168,
                                26.1471
                            ],
                            [
                                50.4185,
                                26.136
                            ],
                            [
                                50.4229,
                                26.134
                            ],
                            [
                                50.4243,
                                26.1315
                            ],
                            [
                                50.4224,
                                26.1282
                            ],
                            [
                                50.4232,
                                26.1226
                            ],
                            [
                                50.4207,
                                26.1204
                            ],
                            [
                                50.416,
                                26.1232
                            ],
                            [
                                50.4068,
                                26.1243
                            ],
                            [
                                50.4015,
                                26.1229
                            ],
                            [
                                50.3996,
                                26.121
                            ],
                            [
                                50.3904,
                                26.1218
                            ],
                            [
                                50.3846,
                                26.124
                            ],
                            [
                                50.386,
                                26.1276
                            ],
                            [
                                50.386,
                                26.1324
                            ],
                            [
                                50.3821,
                                26.1338
                            ],
                            [
                                50.3821,
                                26.1404
                            ],
                            [
                                50.3787,
                                26.1443
                            ],
                            [
                                50.3779,
                                26.1501
                            ],
                            [
                                50.3782,
                                26.1574
                            ],
                            [
                                50.3807,
                                26.1596
                            ],
                            [
                                50.3826,
                                26.1693
                            ],
                            [
                                50.3854,
                                26.1693
                            ],
                            [
                                50.384,
                                26.1646
                            ],
                            [
                                50.3857,
                                26.1637
                            ],
                            [
                                50.3907,
                                26.1688
                            ],
                            [
                                50.3937,
                                26.1699
                            ],
                            [
                                50.3896,
                                26.174
                            ],
                            [
                                50.384,
                                26.1771
                            ],
                            [
                                50.3726,
                                26.1801
                            ],
                            [
                                50.334,
                                26.1821
                            ],
                            [
                                50.3304,
                                26.1801
                            ],
                            [
                                50.3232,
                                26.1829
                            ],
                            [
                                50.3176,
                                26.1835
                            ],
                            [
                                50.3154,
                                26.1874
                            ],
                            [
                                50.3124,
                                26.189
                            ],
                            [
                                50.2635,
                                26.2129
                            ],
                            [
                                50.2393,
                                26.219
                            ],
                            [
                                50.2396,
                                26.2201
                            ],
                            [
                                50.2637,
                                26.214
                            ],
                            [
                                50.3151,
                                26.1885
                            ],
                            [
                                50.3235,
                                26.1879
                            ],
                            [
                                50.3251,
                                26.1851
                            ],
                            [
                                50.3321,
                                26.186
                            ],
                            [
                                50.3343,
                                26.1829
                            ],
                            [
                                50.3701,
                                26.1815
                            ],
                            [
                                50.3763,
                                26.1807
                            ],
                            [
                                50.3843,
                                26.1782
                            ],
                            [
                                50.3924,
                                26.1732
                            ],
                            [
                                50.4012,
                                26.1701
                            ],
                            [
                                50.4043,
                                26.1704
                            ],
                            [
                                50.4065,
                                26.1785
                            ],
                            [
                                50.4057,
                                26.1904
                            ],
                            [
                                50.3985,
                                26.1937
                            ],
                            [
                                50.4024,
                                26.1962
                            ],
                            [
                                50.411,
                                26.1932
                            ],
                            [
                                50.4065,
                                26.189
                            ],
                            [
                                50.4074,
                                26.1796
                            ],
                            [
                                50.4065,
                                26.1721
                            ],
                            [
                                50.4135,
                                26.1735
                            ],
                            [
                                50.419,
                                26.1729
                            ],
                            [
                                50.4207,
                                26.1682
                            ],
                            [
                                50.4429,
                                26.1715
                            ],
                            [
                                50.4468,
                                26.1718
                            ],
                            [
                                50.451,
                                26.1768
                            ],
                            [
                                50.4546,
                                26.1826
                            ],
                            [
                                50.4551,
                                26.1874
                            ],
                            [
                                50.4532,
                                26.1879
                            ],
                            [
                                50.4529,
                                26.1937
                            ],
                            [
                                50.4554,
                                26.1985
                            ],
                            [
                                50.4529,
                                26.2038
                            ],
                            [
                                50.4496,
                                26.2026
                            ],
                            [
                                50.4474,
                                26.2093
                            ],
                            [
                                50.4449,
                                26.2107
                            ],
                            [
                                50.4474,
                                26.2182
                            ],
                            [
                                50.4504,
                                26.2179
                            ],
                            [
                                50.461,
                                26.2243
                            ],
                            [
                                50.4601,
                                26.2279
                            ],
                            [
                                50.4765,
                                26.2343
                            ],
                            [
                                50.4849,
                                26.2354
                            ],
                            [
                                50.491,
                                26.2349
                            ],
                            [
                                50.4982,
                                26.236
                            ],
                            [
                                50.5104,
                                26.2354
                            ],
                            [
                                50.5154,
                                26.236
                            ],
                            [
                                50.5226,
                                26.2357
                            ],
                            [
                                50.5261,
                                26.2419
                            ],
                            [
                                50.5333,
                                26.2458
                            ],
                            [
                                50.538,
                                26.2474
                            ],
                            [
                                50.5481,
                                26.2424
                            ],
                            [
                                50.552,
                                26.2424
                            ],
                            [
                                50.5527,
                                26.2511
                            ],
                            [
                                50.558,
                                26.245
                            ],
                            [
                                50.5591,
                                26.2422
                            ],
                            [
                                50.5591,
                                26.2361
                            ],
                            [
                                50.5634,
                                26.2361
                            ],
                            [
                                50.5629,
                                26.2466
                            ],
                            [
                                50.5659,
                                26.2486
                            ],
                            [
                                50.5698,
                                26.2456
                            ],
                            [
                                50.5698,
                                26.2382
                            ],
                            [
                                50.5751,
                                26.24
                            ],
                            [
                                50.5737,
                                26.2459
                            ],
                            [
                                50.5776,
                                26.251
                            ],
                            [
                                50.5786,
                                26.257
                            ],
                            [
                                50.585,
                                26.2579
                            ],
                            [
                                50.5881,
                                26.2527
                            ],
                            [
                                50.5852,
                                26.2494
                            ],
                            [
                                50.5809,
                                26.2487
                            ],
                            [
                                50.5812,
                                26.2458
                            ],
                            [
                                50.5768,
                                26.242
                            ],
                            [
                                50.581,
                                26.241
                            ],
                            [
                                50.5907,
                                26.2493
                            ],
                            [
                                50.5966,
                                26.2507
                            ],
                            [
                                50.5996,
                                26.2497
                            ],
                            [
                                50.6017,
                                26.2408
                            ],
                            [
                                50.601,
                                26.2374
                            ],
                            [
                                50.6012,
                                26.2279
                            ],
                            [
                                50.6036,
                                26.2244
                            ],
                            [
                                50.6131,
                                26.2269
                            ],
                            [
                                50.6163,
                                26.2257
                            ],
                            [
                                50.6171,
                                26.2214
                            ],
                            [
                                50.6231,
                                26.2186
                            ],
                            [
                                50.6246,
                                26.2147
                            ],
                            [
                                50.6235,
                                26.2085
                            ],
                            [
                                50.6157,
                                26.2013
                            ],
                            [
                                50.6107,
                                26.1987
                            ],
                            [
                                50.6062,
                                26.1982
                            ],
                            [
                                50.5946,
                                26.1937
                            ],
                            [
                                50.5865,
                                26.1985
                            ],
                            [
                                50.5871,
                                26.2024
                            ],
                            [
                                50.5821,
                                26.2029
                            ],
                            [
                                50.5804,
                                26.2051
                            ],
                            [
                                50.5768,
                                26.2043
                            ],
                            [
                                50.5696,
                                26.1996
                            ],
                            [
                                50.5651,
                                26.1996
                            ],
                            [
                                50.5651,
                                26.1918
                            ],
                            [
                                50.5629,
                                26.1882
                            ],
                            [
                                50.5668,
                                26.1787
                            ],
                            [
                                50.5757,
                                26.1715
                            ],
                            [
                                50.5801,
                                26.1654
                            ],
                            [
                                50.5846,
                                26.1649
                            ],
                            [
                                50.5857,
                                26.1626
                            ],
                            [
                                50.5918,
                                26.1593
                            ],
                            [
                                50.5943,
                                26.1563
                            ],
                            [
                                50.5979,
                                26.1479
                            ],
                            [
                                50.6046,
                                26.1482
                            ],
                            [
                                50.6085,
                                26.1493
                            ],
                            [
                                50.6154,
                                26.146
                            ],
                            [
                                50.6164,
                                26.1465
                            ],
                            [
                                50.6154,
                                26.149
                            ],
                            [
                                50.6096,
                                26.1515
                            ],
                            [
                                50.6082,
                                26.154
                            ],
                            [
                                50.6043,
                                26.1551
                            ],
                            [
                                50.6021,
                                26.1657
                            ],
                            [
                                50.5999,
                                26.1682
                            ],
                            [
                                50.6026,
                                26.1707
                            ],
                            [
                                50.5982,
                                26.174
                            ],
                            [
                                50.6021,
                                26.1793
                            ],
                            [
                                50.6051,
                                26.1804
                            ],
                            [
                                50.6101,
                                26.1865
                            ],
                            [
                                50.6138,
                                26.1879
                            ],
                            [
                                50.6174,
                                26.1871
                            ],
                            [
                                50.6249,
                                26.1871
                            ],
                            [
                                50.626,
                                26.1835
                            ],
                            [
                                50.6237,
                                26.1799
                            ],
                            [
                                50.6251,
                                26.1724
                            ],
                            [
                                50.6282,
                                26.1696
                            ],
                            [
                                50.6257,
                                26.1607
                            ],
                            [
                                50.6237,
                                26.1604
                            ],
                            [
                                50.6261,
                                26.1517
                            ],
                            [
                                50.6268,
                                26.1457
                            ],
                            [
                                50.6288,
                                26.1451
                            ],
                            [
                                50.6432,
                                26.1506
                            ],
                            [
                                50.6435,
                                26.1526
                            ],
                            [
                                50.6546,
                                26.1582
                            ],
                            [
                                50.6604,
                                26.1582
                            ],
                            [
                                50.6621,
                                26.156
                            ],
                            [
                                50.666,
                                26.1568
                            ],
                            [
                                50.6674,
                                26.159
                            ],
                            [
                                50.6718,
                                26.1607
                            ],
                            [
                                50.6765,
                                26.1551
                            ],
                            [
                                50.6693,
                                26.1551
                            ],
                            [
                                50.6665,
                                26.1537
                            ],
                            [
                                50.6593,
                                26.1532
                            ],
                            [
                                50.6529,
                                26.1543
                            ],
                            [
                                50.6454,
                                26.151
                            ],
                            [
                                50.6499,
                                26.1426
                            ],
                            [
                                50.6418,
                                26.1387
                            ],
                            [
                                50.6385,
                                26.1421
                            ],
                            [
                                50.6329,
                                26.1393
                            ],
                            [
                                50.629,
                                26.1396
                            ],
                            [
                                50.6299,
                                26.1343
                            ],
                            [
                                50.6335,
                                26.1318
                            ],
                            [
                                50.6318,
                                26.1276
                            ],
                            [
                                50.6346,
                                26.1251
                            ],
                            [
                                50.6393,
                                26.1146
                            ],
                            [
                                50.631,
                                26.1118
                            ],
                            [
                                50.6288,
                                26.1193
                            ],
                            [
                                50.6229,
                                26.1307
                            ],
                            [
                                50.6201,
                                26.1335
                            ],
                            [
                                50.616,
                                26.1415
                            ],
                            [
                                50.6146,
                                26.1393
                            ],
                            [
                                50.6177,
                                26.1333
                            ],
                            [
                                50.6182,
                                26.1312
                            ],
                            [
                                50.6182,
                                26.1309
                            ],
                            [
                                50.6229,
                                26.1213
                            ],
                            [
                                50.6271,
                                26.1179
                            ],
                            [
                                50.629,
                                26.1107
                            ],
                            [
                                50.6243,
                                26.1093
                            ]
                        ]
                        ,
                        [
                            [
                                50.4725,
                                26.2529
                            ],
                            [
                                50.4813,
                                26.2587
                            ],
                            [
                                50.4835,
                                26.2551
                            ],
                            [
                                50.4802,
                                26.2514
                            ],
                            [
                                50.4735,
                                26.2479
                            ],
                            [
                                50.4703,
                                26.244
                            ],
                            [
                                50.4625,
                                26.2439
                            ],
                            [
                                50.462,
                                26.2394
                            ],
                            [
                                50.4655,
                                26.2396
                            ],
                            [
                                50.4749,
                                26.2429
                            ],
                            [
                                50.4764,
                                26.2406
                            ],
                            [
                                50.4723,
                                26.2363
                            ],
                            [
                                50.461,
                                26.2316
                            ],
                            [
                                50.4549,
                                26.226
                            ],
                            [
                                50.4508,
                                26.2244
                            ],
                            [
                                50.4408,
                                26.2168
                            ],
                            [
                                50.4374,
                                26.2179
                            ],
                            [
                                50.4392,
                                26.2314
                            ],
                            [
                                50.443,
                                26.2427
                            ],
                            [
                                50.4518,
                                26.2498
                            ],
                            [
                                50.4579,
                                26.2518
                            ],
                            [
                                50.468,
                                26.2523
                            ],
                            [
                                50.4655,
                                26.2565
                            ],
                            [
                                50.4665,
                                26.2603
                            ],
                            [
                                50.4728,
                                26.2643
                            ],
                            [
                                50.477,
                                26.2637
                            ],
                            [
                                50.4826,
                                26.2669
                            ],
                            [
                                50.4852,
                                26.264
                            ],
                            [
                                50.4789,
                                26.258
                            ],
                            [
                                50.4759,
                                26.2608
                            ],
                            [
                                50.4712,
                                26.2603
                            ],
                            [
                                50.4725,
                                26.2529
                            ]
                        ]
                        ,
                        [
                            [
                                50.6442,
                                26.1708
                            ],
                            [
                                50.6488,
                                26.1736
                            ],
                            [
                                50.6487,
                                26.1692
                            ],
                            [
                                50.6441,
                                26.1555
                            ],
                            [
                                50.638,
                                26.1533
                            ],
                            [
                                50.6369,
                                26.1516
                            ],
                            [
                                50.6316,
                                26.1516
                            ],
                            [
                                50.6326,
                                26.1543
                            ],
                            [
                                50.6313,
                                26.1579
                            ],
                            [
                                50.6314,
                                26.1707
                            ],
                            [
                                50.6442,
                                26.1708
                            ]
                        ]
                        ,
                        [
                            [
                                50.5798,
                                26.1787
                            ],
                            [
                                50.5818,
                                26.1868
                            ],
                            [
                                50.585,
                                26.188
                            ],
                            [
                                50.5872,
                                26.1871
                            ],
                            [
                                50.5925,
                                26.1788
                            ],
                            [
                                50.586,
                                26.1798
                            ],
                            [
                                50.5812,
                                26.1769
                            ],
                            [
                                50.5798,
                                26.1787
                            ]
                        ]
                        ,
                        [
                            [
                                50.5035,
                                26.2486
                            ],
                            [
                                50.5092,
                                26.2498
                            ],
                            [
                                50.5139,
                                26.2495
                            ],
                            [
                                50.5174,
                                26.2422
                            ],
                            [
                                50.504,
                                26.2422
                            ],
                            [
                                50.5035,
                                26.2486
                            ]
                        ]
                        ,
                        [
                            [
                                50.4996,
                                26.2603
                            ],
                            [
                                50.5036,
                                26.2569
                            ],
                            [
                                50.4913,
                                26.2515
                            ],
                            [
                                50.488,
                                26.2504
                            ],
                            [
                                50.4881,
                                26.2604
                            ],
                            [
                                50.4998,
                                26.2641
                            ],
                            [
                                50.4996,
                                26.2603
                            ]
                        ]
                    ]
                ],
                "type": "MultiPolygon"
            },
            "properties": {
                "CNTRY": "Bahrain",
                "DIVISION": "Governorate",
                "NAME2": "Northern",
                "id": "BH-17",
                "name": "Ash Shamālīyah"
            },
            "type": "Feature"
        }
    ],
    "name": "ddd",
    "type": "FeatureCollection"
}