import {
    Box,
    ButtonGroup,
    Card,
    CardBody,
    Divider,
    Flex,
    Heading,
    IconButton,
    Image,
    Link,
    Spacer,
    Stack,
    Text,
    Tooltip
} from "@chakra-ui/react";
import {ArrowForwardIcon, ChevronDownIcon, ChevronUpIcon, ExternalLinkIcon} from "@chakra-ui/icons";
import {configs} from "../../../Configs";
import {useEffect, useRef, useState} from "react";
import {dtToLocaleString, gregorianJalaliDTConversion, setTextDirection} from '../../../utils/helpers';
import HTMLParser from "../../../utils/HtmlParser";
import {useIntl} from "react-intl";
import {FaGoogle, FaPencilAlt} from 'react-icons/fa';
import {useAuth} from "../../../hooks/useAuth";

export const Post = ({
                         image = null,
                         title,
                         description,
                         published_at,
                         source,
                         link,
                         refr,
                         id,
                         resource,
                         ...rest
                     }) => {

    const intl = useIntl();
    const {hasPermission} = useAuth();
    const [isExpanded, setIsExpanded] = useState(false);
    const [isOverflowing, setIsOverflowing] = useState(false);
    const titleRef = useRef(null);
    const textRef = useRef(null);

    useEffect(() => {
        setTextDirection(titleRef.current, title, 'justify');
        setTextDirection(textRef.current, description.replace(/<[^>]*>?/gm, ''));
    }, [title, description]);

    const thePlatform = configs.raqib3.rss_platforms.find(item => item.value === source);

    const handleShowMore = () => {
        setIsExpanded(!isExpanded);
    };
    useEffect(() => {
        const checkOverflow = () => {
            const el = textRef.current;
            if (el) {
                setIsOverflowing(el.scrollHeight > 96 * 5 * 0.1);
            }
        };
        // Check overflow on mount
        checkOverflow();

        // Recheck overflow on window resize
        window.addEventListener("resize", checkOverflow);
        return () => {
            window.removeEventListener("resize", checkOverflow);
        };
    }, [textRef.current]);
    return (<Card ref={refr} mb={3}>
            <Box textAlign="center" style={{backgroundColor: `rgb(${configs.raqib3.colors[source]})`}}
                 color={'white'}
                 borderTopRightRadius='lg'
                 borderTopLeftRadius='lg'
            >
                <Text>
                    <Link href={resource.origin_url || link} isExternal>
                        [{resource.title}] - {intl.formatMessage({
                        id: "raqib3.on",
                        defaultMessage: "On"
                    })} {intl.formatMessage({
                        id: `raqib3.${thePlatform.value}`,
                        defaultMessage: thePlatform.label
                    })}
                    </Link>
                </Text>
            </Box>
            <CardBody textAlign={'left'}>

                {image && <Image
                    w={'100%'}
                    src={image}
                    alt={title}
                    borderBottomRightRadius='lg'
                    borderBottomLeftRadius='lg'
                />}
                <Stack mt='6' spacing='3'>
                    <Heading size='md' fontFamily={configs.posts_heading_font_family} ref={titleRef}>{title}</Heading>
                    <Box ref={textRef} noOfLines={isExpanded ? undefined : 5} className={"accented-scrollbar"}
                         overflowY={"scroll"}
                         fontFamily={configs.posts_body_font_family} whiteSpace={'pre-line'}>
                        <HTMLParser htmlContent={description}/>
                    </Box>
                    {isOverflowing &&
                        <IconButton onClick={handleShowMore} variant={"ghost"} aria-label="text show options" mt={2}
                                    icon={isExpanded ? <ChevronUpIcon/> : <ChevronDownIcon/>}/>}
                </Stack>
            </CardBody>
            <Divider/>
            <Flex>
                <Box p='2'>
                    <ButtonGroup spacing='2'>
                        <Tooltip className="tooltips"
                                 label={intl.formatMessage({
                                     id: 'raqib3.viewInSource',
                                     defaultMessage: "View in source"
                                 })}>
                            <Link href={link} isExternal>
                                <ExternalLinkIcon color={`rgb(${configs.raqib3.colors[source]})`} mx="2px"/>
                            </Link>
                        </Tooltip>
                    </ButtonGroup>
                    <Tooltip className="tooltips" label={intl.formatMessage({
                        id: 'raqib3.showInGoogleTranslate',
                        defaultMessage: "Show in google translate"
                    })}>
                        <Link
                            href={'https://translate.google.com/#view=home&op=translate&sl=auto&tl=fa&text=' + encodeURIComponent(description.replace(/<[^>]*>?/gm, ''))}
                            isExternal>
                            <FaGoogle style={{width: '13px', display: 'inline-block', marginBottom: '-4px'}}
                                      color="blue"/>
                        </Link>
                    </Tooltip>
                    <Tooltip className="tooltips"
                             label={intl.formatMessage({
                                 id: 'raqib3.shareOnTelegram',
                                 defaultMessage: "Share on telegram"
                             })}>
                        <Link ms={1}
                              href={`https://t.me/share/url?url=${link}&text=` + encodeURIComponent(description.replace(/<[^>]*>?/gm, ''))}
                              isExternal><ArrowForwardIcon color="cyan.400"/></Link>
                    </Tooltip>

                    {source !== 'NEWS' && hasPermission('post', 'fe_edit') && <Tooltip
                        label={intl.formatMessage({id: 'raqib6.editPost'})}>
                        <Link ms={1} href={`/posts/${id}`} isExternal>
                            <FaPencilAlt style={{width: '13px', display: 'inline-block', marginBottom: '-4px'}}
                                         color="olive"/>
                        </Link>
                    </Tooltip>}

                    {source === 'NEWS' && hasPermission('news', 'fe_edit') && <Tooltip
                        label={intl.formatMessage({id: 'raqib6.editArticle'})}>
                        <Link ms={1} href={`/news_articles/${id}`}>
                            <FaPencilAlt style={{width: '13px', display: 'inline-block', marginBottom: '-4px'}}
                                         color="olive"/>
                        </Link>
                    </Tooltip>}
                </Box>
                <Spacer/>
                <Box p='2'>
                    <Tooltip className="tooltips" label={gregorianJalaliDTConversion(published_at)}
                             aria-label='datetime-conversion'>
                        <Text color='gray.500' fontSize='sm'>{dtToLocaleString(published_at)}</Text>
                    </Tooltip>
                </Box>
            </Flex>
        </Card>
    );
}