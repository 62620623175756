import {
    Box,
    Button,
    Flex,
    Heading,
    Link,
    Spinner,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useToast
} from '@chakra-ui/react';
import {useCallback, useEffect, useRef, useState} from "react";
import NewsArticleTableRow from './NewsArticleTableRow';
import {Category} from './Category';
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../hooks/useAuth";
import useAxios from "../../hooks/useAxois";
import {FormattedMessage, useIntl} from 'react-intl';
import axios from "axios";


const NewsArticles = () => {
    const intl = useIntl();
    const axiosInstance = useAxios();
    const {hasPermission} = useAuth();
    const toast = useToast();
    const navigate = useNavigate();
    const [newsCats, setNewsCats] = useState([]);
    const [statistics, setStatistics] = useState({});
    const [data, setData] = useState([]);
    const [isOthersLoading, setIsOthersLoading] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [catError, setCatError] = useState(false);
    const [statsError, setStatsError] = useState(false);

    const [lastElmId, setLastElmId] = useState(null);
    const [pageId, setPageId] = useState(null);
    const [nextPageId, setNextPageId] = useState(null);
    const [forceRefresh, setForceRefresh] = useState(false);
    const observer = useRef();

    useEffect(() => {

        // load first data batch
        // loadNews();

        setIsOthersLoading(v => v + 1);

        // get news cats
        axiosInstance.get('/post_tag').then((data) => {
            setNewsCats(data.data);
            setCatError(false);
        }).catch((error) => {
            if (error.response && error.response.status === 404) return;
            setCatError(true);
            console.error(error);
        }).finally(() => setIsOthersLoading(v => v - 1));

        // statistics
        setIsOthersLoading(v => v + 1);
        axiosInstance.get('/news/statistics').then((data) => {
            setStatistics(data.data);
            setStatsError(false);
        }).catch((error) => {
            if (error.response && error.response.status === 404) return;
            setStatsError(true);
            console.error(error);
        }).finally(() => setIsOthersLoading(v => v - 1));
    }, []);

    useEffect(() => {
        setLastElmId(null);
        setIsLoading(true);
        setError(false);
        let cancel;

        // handle pagination
        let payload = {
            cancelToken: new axios.CancelToken(c => cancel = c),
            params: {before: pageId}
        }

        axiosInstance.get('/news', payload).then((res) => setData(prev => {
            const {news} = res.data;
            const theData = [...prev, ...news];

            // uniqify data combination
            let itteratedKeys = [];
            let newData = [];
            theData.map(newsItem => {
                if (!itteratedKeys.includes(newsItem.id)) {
                    itteratedKeys.push(newsItem.id);
                    newData.push(newsItem);
                }
            });

            // the nextPageId is determined by least sort (desc)
            let sortedData = theData;
            sortedData.sort((a, b) => parseFloat(a.sort) - parseFloat(b.sort));
            setLastElmId(sortedData.length ? sortedData[sortedData.length - 1].id : null);
            setNextPageId(sortedData.length ? sortedData[sortedData.length - 1].published_at : null)
            setIsLoading(false);
            setError(false);

            return newData;
        })).catch((error) => {
            if (axios.isCancel(error)) return;
            setIsLoading(false);
            if (error.response && error.response.status === 404) return;
            setError(true);
            console.error(error);
        });
        return () => cancel();
    }, [pageId, forceRefresh]);

    const lastRowElmRef = useCallback(node => {
        if (isLoading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && nextPageId) {
                setPageId(nextPageId)
            }
        })
        if (node) observer.current.observe(node);
    }, [isLoading, nextPageId]);

    const delateNews = (newsId) => {
        setIsLoading(v => v + 1);
        axiosInstance
            .delete(`/news/${newsId}`)
            .then(() => {
                toast({
                    title: intl.formatMessage({
                        id: 'news_articles.deleted', defaultMessage: 'Article Deleted'
                    }),
                    status: "success",
                    isClosable: true,
                    diration: 1500,
                });
                setData((old) => old.filter((item) => item.id !== newsId));
                navigate("/news_articles");
            })
            .catch((err) => {
                toast({
                    title: intl.formatMessage({
                        id: 'news_articles.notDeleted', defaultMessage: 'Could not delete article'
                    }),
                    status: "error",
                    isClosable: true,
                    diration: 2000,
                });
            })
            .finally(() => setIsLoading(v => v - 1));
    };

    return (
        <Flex
            wrap={'wrap'}
            p={5}
            m={'auto'}
            w={{base: '100%', md: '80%', xl: '75%'}}
        >
            <Box w={['100%', '80%']}>
                <Flex wrap={'wrap'} p={5} borderBottomWidth={'1px'} borderBottomColor={'#e8e8e8'} mb={4}>
                    <Box w={'50%'} textAlign={'start'}>
                        <Heading>
                            <FormattedMessage
                                id="news_articles.title"
                                defaultMessage="Articles"
                            />
                        </Heading>
                    </Box>

                    {hasPermission('news', 'fe_create') && <Box w={'50%'} textAlign={'end'}>
                        <Button mt={2} size={'sm'} as={Link} href={'/news_articles/new'} colorScheme={'green'}>
                            <FormattedMessage
                                id="news_articles.addArticle"
                                defaultMessage="New Article"
                            />
                        </Button>
                    </Box>}
                </Flex>

                <Box overflowX="auto">
                    <Table>
                        <Thead>
                            <Tr>
                                <Th p={3} minW={10}>
                                    <FormattedMessage
                                        id="news_articles.rowId"
                                        defaultMessage="ID"
                                    />
                                </Th>
                                <Th p={3} minW={300}>
                                    <FormattedMessage
                                        id="news_articles.articleTitle"
                                        defaultMessage="Title"
                                    />
                                </Th>
                                <Th p={3} minW={180}>
                                    <FormattedMessage
                                        id="news_articles.publishedAt"
                                        defaultMessage="Published At"
                                    />
                                </Th>
                                <Th p={3} minW={110}>
                                    <FormattedMessage
                                        id="news_articles.articleAction"
                                        defaultMessage="Action"
                                    />
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody>

                            {data.length > 0 && data.map((news, idx) => {
                                return <NewsArticleTableRow
                                    key={news.id}
                                    row_id={idx + 1}
                                    stripped={idx % 2 === 0}
                                    onDelete={delateNews}
                                    refr={news.id === lastElmId ? lastRowElmRef : undefined}
                                    {...news}/>

                            })}

                            {!data.length && !isLoading > 0 &&
                                <Tr><Td colSpan={3} textAlign={'center'}>
                                    <Text>
                                        <FormattedMessage
                                            id="responses.noData"
                                            defaultMessage="No Data"
                                        />
                                    </Text>
                                </Td></Tr>}
                            {!isLoading > 0 && error &&
                                <Tr><Td colSpan={3} textAlign={'center'}>
                                    <Text>
                                        <FormattedMessage
                                            id="responses.connectionFailed"
                                            defaultMessage="Connection Failed"
                                        />
                                    </Text>
                                </Td></Tr>}
                            {isLoading > 0 &&
                                <Tr><Td colSpan={3} textAlign={'center'}><Spinner mt={5} color="green"/></Td></Tr>}
                        </Tbody>
                    </Table>
                </Box>
            </Box>
            <Box w={['100%', '20%']} p={5}>
                <Box p={5} bgColor={'#f2f2f2'}>
                    <Box>
                        <Heading mb={4} pb={4} borderBottomWidth={'2px'} borderBottomColor={'#dee2e6'} fontSize={'md'}
                        >
                            <FormattedMessage
                                id="news_articles.articleStatistics"
                                defaultMessage="Article Statistics"
                            />
                        </Heading>
                        <Flex
                            p={2}
                            wrap={'wrap'}
                            _hover={{bgColor: '#e2e2e2'}}
                        >
                            <Box w={'50%'}>
                                <Text>
                                    <FormattedMessage
                                        id="news_articles.today"
                                        defaultMessage="Today"
                                    />
                                </Text>
                            </Box>
                            <Box w={'50%'} textAlign={'end'}>
                                <Text>{statistics?.today || 0}</Text>
                            </Box>
                        </Flex>
                        <Flex
                            p={2}
                            wrap={'wrap'}
                            _hover={{bgColor: '#e2e2e2'}}
                        >
                            <Box w={'50%'}>
                                <Text>
                                    <FormattedMessage
                                        id="news_articles.thisWeek"
                                        defaultMessage="This Week"
                                    />
                                </Text>
                            </Box>
                            <Box w={'50%'} textAlign={'end'}>
                                <Text
                                >{statistics?.this_week || 0}</Text>
                            </Box>
                        </Flex>
                        <Flex
                            p={2}
                            wrap={'wrap'}
                            _hover={{bgColor: '#e2e2e2'}}
                        >
                            <Box w={'50%'}>
                                <Text>
                                    <FormattedMessage
                                        id="news_articles.thisMonth"
                                        defaultMessage="This Month"
                                    />
                                </Text>
                            </Box>
                            <Box w={'50%'} textAlign={'end'}>
                                <Text
                                >{statistics?.this_month || 0}</Text>
                            </Box>
                        </Flex>
                        <Flex
                            p={2}
                            wrap={'wrap'}
                            _hover={{bgColor: '#e2e2e2'}}
                        >
                            <Box w={'50%'}>
                                <Text>
                                    <FormattedMessage
                                        id="news_articles.allArticles"
                                        defaultMessage="All Articles"
                                    />
                                </Text>
                            </Box>
                            <Box w={'50%'} textAlign={'end'}>
                                <Text>{statistics?.total || 0}</Text>
                            </Box>
                        </Flex>
                    </Box>
                    <Box>
                        <Heading mt={4} py={4} borderTopWidth={'2px'} borderTopColor={'#dee2e6'} fontSize={'md'}
                        >
                            <FormattedMessage
                                id="news_articles.yourCats"
                                defaultMessage="Your Categories"
                            />
                        </Heading>
                        <Box>

                            {newsCats.length > 0 && newsCats.map(newsCat => <Category
                                key={`news:cats-${newsCat.tag_id}`} {...newsCat}/>)}

                        </Box>
                    </Box>
                </Box>
            </Box>
        </Flex>
    );
};

export default NewsArticles;
