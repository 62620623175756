import {Box, Flex, Image, Text} from "@chakra-ui/react";
import {dtToLocaleString, setTextDirection} from '../../utils/helpers';
import {useEffect, useRef} from "react";

export const NewsItems = ({id, title, image, published_at, ...rest}) => {
    const titleRef = useRef(null);

    useEffect(() => {
        setTextDirection(titleRef.current, title, 'justify');
    }, [title]);

    return (
        <Flex
            justify="space-between"
            wrap="wrap"
            alignContent={'center'}
            bgColor={'#f4f4f4'}
            p={2}
            mb={2}
            _hover={{bgColor: '#e2e2e2', cursor: "pointer"}}
            onClick={() => window.open(`/articles/${id}`, '_blank')}
        >
            <Box width={["100%", "100%", "25%"]}>
                <Image
                    w={'100%'}
                    src={image}
                    alt={title}
                />
            </Box>
            <Box ref={titleRef} width={["100%", "100%", "50%"]} p={2}>
                <Text fontFamily={"Cairo, sans-serif"} whiteSpace={'pre-line'}
                      fontSize={'sm'}>{title}</Text>
            </Box>
            <Box width={["100%", "100%", "25%"]} bgColor={'#e8e8e8'} p={2} display="flex" justifyContent="center"
                 alignItems="center">
                <Text color={'#737373'} fontSize='xs' fontFamily={"Cairo, sans-serif"}>{
                    dtToLocaleString(published_at, {
                        day: "numeric",
                        month: "short",
                        hour: "numeric",
                        minute: "2-digit"
                    })
                }</Text>
            </Box>
        </Flex>
    );
};
