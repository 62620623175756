import {Box, Spinner, Text} from "@chakra-ui/react";
import {FormattedMessage, useIntl} from "react-intl";
import {useContext, useEffect, useLayoutEffect, useState} from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {LocaleContext} from "../Theme/LocaleProvider";
import useAxios from "../../hooks/useAxois";
import {dtToLocaleString, gregorianJalaliDTConversion} from "../../utils/helpers";
import {useAuth} from "../../hooks/useAuth";

export const UserActivityAreaChart = ({userId, ...rest}) => {
    const axiosInstance = useAxios();
    const context = useContext(LocaleContext);
    const intl = useIntl();
    const {hasPermission} = useAuth();
    const hasPermit = hasPermission('charts:users:activity-area', 'fe_activity_area');
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(hasPermit);
    const [error, setError] = useState(false);

    useEffect(() => {
        if (!hasPermit) return;
        axiosInstance.get(`/charts/users/activity-area/${userId}`, {
            // headers: {
            // 'Client-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone
            // }
        }).then((res) => {
            setData(res.data?.map(item => ({...item, date: (new Date(item.date)).getTime()})));
            setError(false);
        }).catch((error) => {
            if (error.response && error.response.status === 404) return;
            setError(true);
            console.error(error);
        }).finally(() => setIsLoading(false));
    }, []);

    useLayoutEffect(() => {
        if (isLoading || !hasPermit) return;

        const convertDt = (dtText, isLabels = false) => {
            // console.log(dtText)
            if (!dtText) return dtText
            if (context.locale === 'fa') {
                const datetime = gregorianJalaliDTConversion(dtText, true);
                return isLabels ? `${datetime.day} ${datetime.monthName}` : `${datetime.year}-${datetime.month}-${datetime.day} | ${datetime.hours}:${datetime.minutes}`;
            }

            const format = isLabels ? {month: 'short', day: 'numeric'} : {
                day: 'numeric',
                month: 'short',
                year: 'numeric'
            };
            return dtToLocaleString(dtText, format)
        }

        // define root chart with custom theme based on rtl/ltr
        let root = am5.Root.new("user-activity-area");
        // root.utc = true;
        const customTheme = am5.Theme.new(root);
        if (context.isRTL) {
            customTheme.rule("Label").setAll({
                fontFamily: "IRANSans",
                direction: 'rtl'
            });
        }
        root.setThemes([am5themes_Animated.new(root), customTheme]);

        let chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: true,
            panY: true,
            wheelY: "zoomX",
            layout: root.verticalLayout,
            pinchZoomX: true,
            // paddingTop: 50
        }));

        // chart.children.unshift(am5.Label.new(root, {
        //     text: intl.formatMessage({id: 'charts.users.userActivityAreaTitle'}),
        //     fontSize: 25,
        //     fontWeight: "500",
        //     textAlign: "center",
        //     x: am5.percent(50),
        //     centerX: am5.percent(50),
        //     paddingTop: -50,
        //     paddingBottom: 0
        // }));

        // Create Y-axis
        let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            maxDeviation: 1,
            extraTooltipPrecision: 1,
            renderer: am5xy.AxisRendererY.new(root, {
                opposite: !context.isRTL,
                pan: "zoom"
            })
        }));

        const xRenderer = am5xy.AxisRendererX.new(root, {
            minGridDistance: 80,
            minorGridEnabled: true,
            minorLabelsEnabled: true,
            pan: "zoom"
        });

        xRenderer.labels.template.setAll({
            rotation: -45,
            centerY: am5.p50,
            centerX: am5.p100,
            paddingRight: 15,
        });

        // Create X-Axis
        let xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
            groupData: true,
            maxDeviation: 0.5,
            baseInterval: {timeUnit: "hour", count: 1},
            markUnitChange: false,
            dateFormats: {
                hour: "YYYY-MM-ddTHH:mm:ss"
            },
            tooltipDateFormat: "YYYY-MM-ddTHH:mm:ss",
            tooltip: am5.Tooltip.new(root, {}),
            renderer: xRenderer
        }));

        // convert tooltips/chart label to jalali/gregorian and format date if needed
        xRenderer.labels.template.adapters.add('text', (text) => convertDt(text, true));
        xAxis.get("tooltip").label.adapters.add("text", (text) => convertDt(text));

        // Create series
        let series = chart.series.push(am5xy.LineSeries.new(root, {
            name: "Series",
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "value",
            valueXField: "date",
            tooltip: am5.Tooltip.new(root, {
                pointerOrientation: "horizontal",
                labelText: "{valueY}"
            })
        }));

        series.strokes.template.set("strokeWidth", 2);
        series.fills.template.setAll({
            visible: true,
            fillOpacity: 0.4
        });

        // Actual bullet
        series.bullets.push(function () {
            let bulletCircle = am5.Circle.new(root, {
                strokeWidth: 2,
                radius: 2,
                stroke: series.get("stroke"),
                // fill: series.get("fill")
                fill: root.interfaceColors.get("background"),
            });
            return am5.Bullet.new(root, {
                sprite: bulletCircle
            })
        });

        series.data.setAll(data);

        // Pre-zoom X axis to last hour
        // series.events.once("datavalidated", function (ev, target) {
        //     let lastDate = new Date(data[data.length - 1].date);
        //     let firstDate = new Date(lastDate.getTime() - 3600000);
        //     xAxis.zoomToDates(firstDate, lastDate);
        // })

        // Add cursor
        chart.set("cursor", am5xy.XYCursor.new(root, {
            behavior: "none",
            xAxis: xAxis
        }));

        // add horizontal scrollbar
        let scrollbarX = am5xy.XYChartScrollbar.new(root, {
            orientation: "horizontal",
            height: 50
        });
        chart.set("scrollbarX", scrollbarX);

        let sbxRenderer = am5xy.AxisRendererX.new(root, {
                opposite: false,
                strokeOpacity: 0,
                minorGridEnabled: true,
                minGridDistance: 60
            });

        // convert tooltips/chart label to jalali/gregorian and format date if needed on scrollbar
        sbxRenderer.labels.template.adapters.add('text', (text) => convertDt(text, true));

        let sbxAxis = scrollbarX.chart.xAxes.push(am5xy.DateAxis.new(root, {
            baseInterval: {timeUnit: "hour", count: 1},
            renderer: sbxRenderer
        }));

        let sbyAxis = scrollbarX.chart.yAxes.push(am5xy.ValueAxis.new(root, {
            renderer: am5xy.AxisRendererY.new(root, {})
        }));

        let sbseries = scrollbarX.chart.series.push(am5xy.LineSeries.new(root, {
            xAxis: sbxAxis,
            yAxis: sbyAxis,
            valueYField: "value",
            valueXField: "date"
        }));
        sbseries.data.setAll(data);

        return () => {
            root.dispose();
        };
    }, [isLoading, context.isRTL]);

    if (!hasPermit) return <></>

    return (<Box
        bg={"gray.100"}
        minHeight="3rem"
        my={2}
        p={3}
        py={5}
        rounded="lg"
        dir='ltr'
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        {...rest}
    >
        {isLoading && <Spinner color="green"/>}
        {!isLoading && <Text
            style={{fontSize: 25, fontFamily: "IRANSans", textAlign: 'center', fontWeight: 500}}
            dir={context.isRTL ? 'rtl' : 'ltr'}
        >
            <FormattedMessage id="charts.users.userActivityAreaTitle"/>
        </Text>}
        {!isLoading && <div id="user-activity-area" style={{
            width: "100%",
            height: "400px",
            fontFamily: 'IRANSans, sans-serif'
        }}></div>}
    </Box>);
};
