import {DateTime} from 'luxon';
import {configs} from "../Configs";
import jalaali from 'jalaali-js';
import platform from 'platform';

export const generateRandomKey = () => {
    return Math.random().toString(36).substring(2, 11);
}
export const fillMissingHours = (items, hours, groupByField, days = []) => {
    let filledData = [];

    // Group items by day
    let groupedData = items.reduce((acc, obj) => {
        // Check if the accumulator (acc) already has a key for the current object's groupByField value
        if (!acc[obj[groupByField]]) {
            // If not, initialize it as an empty array
            acc[obj[groupByField]] = [];
        }

        // Push the current object (obj) into the array for its groupByField value
        acc[obj[groupByField]].push(obj);

        // Return the updated accumulator for the next iteration
        return acc;
    }, {});

    // append days that have no data
    if (days) {
        const curDays = Object.keys(groupedData)
        const toBeAddedDays = days.filter(dayItem => !curDays.includes(dayItem));
        toBeAddedDays.forEach(key => {
            groupedData[key] = [];
        });
    }

    // Fill missing hours for each day
    for (let day in groupedData) {
        let dayData = groupedData[day];
        let hourSet = new Set(dayData.map(d => d.hour));

        hours.forEach(hour => {
            if (!hourSet.has(hour)) {
                dayData.push({[groupByField]: day, count: 0, hour: hour});
            }
        });

        // Sort the dayData by hour to maintain order
        dayData.sort((a, b) => hours.indexOf(a.hour) - hours.indexOf(b.hour));

        filledData = filledData.concat(dayData);
    }

    return filledData;
}
export const parseDashboardChartsFilters = (url, tag_ids = null, faction_ids = null, resource_ids = null) => {
    if (tag_ids) {
        if (!url.endsWith('?')) {
            url += '&'
        }
        url += tag_ids.map(tag_id => `tag_ids=${tag_id}`).join('&');
    }
    if (faction_ids) {
        if (!url.endsWith('?')) {
            url += '&'
        }
        url += faction_ids.map(faction_id => `faction_ids=${faction_id}`).join('&');
    }
    if (resource_ids) {
        if (!url.endsWith('?')) {
            url += '&'
        }
        url += resource_ids.map(resource_id => `resource_ids=${resource_id}`).join('&');
    }
    return url;
}

export const getClientData = (username = null) => {
    const rtlLangs = ['ar', 'he', 'fa', 'ur'];
    const languageCode = navigator.language.split('-')[1] || null;

    const data = {
        app_info: {
            name: 'Web UI'
        },
        device_info: {
            os_name: `${platform.os.family} arc:${platform.os.architecture}`,
            os_version: platform.os.version,
            locales: [
                {
                    languageCode: languageCode,
                    textDirection: rtlLangs.includes(languageCode) ? 'rtl' : 'ltr',
                    languageTag: navigator.language
                }
            ]
        }
    }
    if (username) {
        data.username = username
    }

    return JSON.stringify(data)
}
export const rgbToHex = (r, g, b, as_str = true) => {
    // Ensure that the RGB values are within the valid range [0, 255]
    r = Math.max(0, Math.min(255, r));
    g = Math.max(0, Math.min(255, g));
    b = Math.max(0, Math.min(255, b));

    // Convert each component to its hexadecimal representation
    const hexR = r.toString(16).padStart(2, '0');
    const hexG = g.toString(16).padStart(2, '0');
    const hexB = b.toString(16).padStart(2, '0');

    // Combine the components to form the HEX color
    return `#${hexR}${hexG}${hexB}`;
}

// Helper function to set text direction
export function setTextDirection(element, text, forceAlignment) {
    const isRTL = /[\u0591-\u07FF\uFB1D-\uFDFD\uFE70-\uFEFC\u05D0-\u05EA]/.test(text); // Check for Arabic, Hebrew, and Farsi
    element.setAttribute('dir', isRTL ? 'rtl' : 'ltr');
    element.style.textAlign = isRTL ? 'right' : 'left'; // Set text alignment
    if (forceAlignment) {
        element.style.textAlign = forceAlignment;
    }
    element.style.textJustify = 'inter-word'; // Set text justification
}

export const dtRange = (timedelta) => {
    // Get the current date and time
    const currentDate = new Date();

    // Subtract timedelta in milliseconds from the current date
    const newDate = new Date(currentDate - timedelta);

    // Format the date as a string in ISO 8601 with milliseconds and timezone offset
    return newDate.toISOString();
}

export const dtToLocaleString = (date, format = {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit'
}, inpZone = 'utc') => {
    const originDateTime = typeof date === 'string' ? DateTime.fromISO(date, {zone: inpZone}) : DateTime.fromJSDate(date);
    const utcDateTime = originDateTime.setZone(inpZone, {keepLocalTime: true});
    return utcDateTime.setZone(configs.time_locale).toLocaleString(format);

    // return (new Date(datetime)).toLocaleString(locale, {
    //     day: "numeric",
    //     month: "short",
    //     year: "numeric",
    //     hour: "numeric",
    //     minute: "2-digit"
    // });
}

export const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export const parseUTC = (date) => {
    return new Date(
        Date.UTC(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            date.getHours(),
            date.getMinutes(),
            date.getSeconds(),
            date.getMilliseconds()
        )
    );
}

function replaceEnglishNumbersWithFarsi(inputString) {
    const numbersMap = {
        '0': '۰',
        '1': '۱',
        '2': '۲',
        '3': '۳',
        '4': '۴',
        '5': '۵',
        '6': '۶',
        '7': '۷',
        '8': '۸',
        '9': '۹'
    };
    return inputString.replace(/\d/g, function (match) {
        return numbersMap[match];
    });
}

export function gregorianJalaliDTConversion(inpDate, raw = false) {
    const date = new Date(inpDate);
    // const originDateTime = typeof date === 'string' ? DateTime.fromISO(date, {zone: inpZone}) : DateTime.fromJSDate(date);
    // const utcDateTime = originDateTime.setZone(inpZone, {keepLocalTime: true});
    const gregorianYear = date.getFullYear();
    const gregorianMonth = date.getMonth() + 1;
    const gregorianDay = date.getDate();

    const jalaliDate = jalaali.toJalaali(gregorianYear, gregorianMonth, gregorianDay);

    const hours = date.getHours();
    const minutes = date.getMinutes();
    const jdtMonths = ['فروردین', 'اردیبهشت', 'خرداد', 'تیر', 'مرداد', 'شهریور', 'مهر', 'آبان', 'آذر', 'دی', 'بهمن', 'اسفند']

    if (raw) {
        return {
            year: jalaliDate.jy,
            month: jalaliDate.jm,
            monthName: jdtMonths[jalaliDate.jm - 1],
            day: jalaliDate.jd,
            hours: hours,
            minutes: minutes
        }
    }
    return `${hours}:${minutes} | ${jalaliDate.jy}-${jalaliDate.jm}-${jalaliDate.jd}`
}

export const truncateText = (text, maxLength) => {
    if (!text) return text

    if (text.length > maxLength) {
        // Truncate the text to maxLength characters
        text = text.substring(0, maxLength);
        // Trim any trailing spaces
        text = text.trim();
        // Add "..." at the end
        text += "...";
    }
    return text;
}

