import {Box, Spinner, Text} from "@chakra-ui/react";
import {useContext, useEffect, useLayoutEffect, useState} from 'react';
import {configs} from "../../Configs";
import {FormattedMessage, useIntl} from "react-intl";
import * as am5 from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map";
import russiaLow from "@amcharts/amcharts5-geodata/russiaLow";
import iraqLow from "@amcharts/amcharts5-geodata/iraqLow";
import syriaLow from "@amcharts/amcharts5-geodata/syriaLow";
import lebanonLow from "@amcharts/amcharts5-geodata/lebanonLow";
import azerbaijanLow from "@amcharts/amcharts5-geodata/azerbaijanLow";
import afghanistanLow from "@amcharts/amcharts5-geodata/afghanistanLow";
import turkeyLow from "@amcharts/amcharts5-geodata/turkeyLow";
import worldLow from "@amcharts/amcharts5-geodata/worldLow";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {LocaleContext} from "../Theme/LocaleProvider";
import useAxios from "../../hooks/useAxois";
import {useAuth} from "../../hooks/useAuth";
import {parseDashboardChartsFilters} from "../../utils/helpers";

export const ResourcesByStatesMapChart = ({
                                              parentIsLoading,
                                              since,
                                              until,
                                              resource_ids,
                                              tag_ids,
                                              faction_ids,
                                              ...rest
                                          }) => {
    const axiosInstance = useAxios();
    const context = useContext(LocaleContext);
    const intl = useIntl();
    const {hasPermission} = useAuth();
    const hasPermit = hasPermission('charts:resources:states-map', 'fe_states_map');
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(hasPermit);
    const [error, setError] = useState(false);

    useEffect(() => {
        if (!hasPermit || parentIsLoading) return;
        setIsLoading(hasPermit);
        const url = parseDashboardChartsFilters("/charts/resources/states-map?", tag_ids, faction_ids, resource_ids);
        axiosInstance.get(url).then((res) => {
            setData(res.data.map(item => ({
                ...item,
                name: intl.formatMessage({id: `charts.resources.${item.name}`}),
                // radius: 10
            })));
            setError(false);
        }).catch((error) => {
            if (error.response && error.response.status === 404) return;
            setError(true);
            console.error(error);
        }).finally(() => setIsLoading(false));
    }, [since, until, resource_ids, tag_ids, faction_ids]);

    useLayoutEffect(() => {
        if (isLoading || !hasPermit || parentIsLoading) return;
        // define root chart with custom theme based on rtl/ltr
        let root = am5.Root.new("resources-by-states-map");
        const customTheme = am5.Theme.new(root);
        if (context.isRTL) {
            customTheme.rule("Label").setAll({
                fontFamily: "IRANSans",
                direction: 'rtl'
            });
        }
        root.setThemes([am5themes_Animated.new(root), customTheme]);

        let chart = root.container.children.push(am5map.MapChart.new(root, {
            panX: "rotateX",
            panY: "rotateY",
            // projection: am5map.geoOrthographic(),
            // paddingTop: 50,
            // marginBottom: 50
        }));

        // chart.children.unshift(am5.Label.new(root, {
        //     text: intl.formatMessage({id: 'charts.resources.resourcesByStatesMapTitle'}),
        //     fontSize: 25,
        //     fontWeight: "500",
        //     textAlign: "center",
        //     x: am5.percent(50),
        //     centerX: am5.percent(50),
        //     paddingTop: -50
        // }));

        // select map by location country_code
        let geodata = worldLow;
        if (configs.country_code.toLowerCase() === 'ea') {
            chart.setAll({
                rotationX: -80,
                rotationY: -40,
                rotationZ: 10,
                zoomLevel: 6
            });
        } else if (configs.country_code === 'iq') {
            geodata = iraqLow;
        } else if (configs.country_code === 'sy') {
            geodata = syriaLow;
        } else if (configs.country_code === 'af') {
            geodata = afghanistanLow;
        } else if (configs.country_code === 'az') {
            geodata = azerbaijanLow;
        } else if (configs.country_code === 'lb') {
            geodata = lebanonLow;
        } else if (configs.country_code === 'ru') {
            geodata = russiaLow;
        } else if (configs.country_code === 'tr') {
            geodata = turkeyLow;
        }

        let polygonSeries = chart.series.push(
            am5map.MapPolygonSeries.new(root, {
                geoJSON: geodata,
            })
        );

        let bubbleSeries = chart.series.push(
            am5map.MapPointSeries.new(root, {
                valueField: "value",
                // calculateAggregates: true,
                polygonIdField: "id"
            })
        );

        let circleTemplate = am5.Template.new({});
        bubbleSeries.bullets.push(function (root, series, dataItem) {
            let container = am5.Container.new(root, {});
            let circle = container.children.push(
                am5.Circle.new(root, {
                    radius: 20,
                    fillOpacity: 0.7,
                    fill: am5.color(0xff0000),
                    cursorOverStyle: "pointer",
                    tooltipText: "{name}: {value}"
                }, circleTemplate)
            );

            let stateLabel = container.children.push(
                am5.Label.new(root, {
                    text: "{name}",
                    centerX: am5.p100,
                    centerY: am5.p100,
                    textAlign: "center",
                    // paddingTop: 30,
                    populateText: true,
                    fontWeight: "bold",
                    fontSize: 13
                })
            );

            circle.on("radius", function (radius) {
                stateLabel.set("x", radius);
            })

            return am5.Bullet.new(root, {
                sprite: container,
                dynamic: true
            });
        });


        bubbleSeries.bullets.push(function (root, series, dataItem) {
            return am5.Bullet.new(root, {
                sprite: am5.Label.new(root, {
                    text: "{value.formatNumber('#.')}",
                    fill: am5.color(0xffffff),
                    populateText: true,
                    centerX: am5.p50,
                    centerY: am5.p50,
                    textAlign: "center"
                }),
                dynamic: true
            });
        });


        // minValue and maxValue must be set for the animations to work
        bubbleSeries.set("heatRules", [
            {
                target: circleTemplate,
                dataField: "radius",
                min: 10,
                max: 50,
                minValue: 0,
                maxValue: 100,
                key: "radius"
            }
        ]);

        bubbleSeries.data.setAll(data);

        return () => {
            root.dispose();
        };
    }, [isLoading, context.isRTL]);

    if (!hasPermit) return <></>

    return (<Box
        bg={"gray.100"}
        minHeight="3rem"
        my={2}
        p={3}
        py={5}
        rounded="lg"
        dir='ltr'
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        {...rest}
    >
        <Text mb={5} fontSize={25} fontWeight={500} dir={context.isRTL ? 'rtl' : 'ltr'}>
            <FormattedMessage id="charts.resources.resourcesByStatesMapTitle"/>
        </Text>

        {(isLoading || parentIsLoading) && <Spinner color="green"/>}

        {/*{!data.length && !isLoading && !parentIsLoading &&*/}
        {/*    <Text><FormattedMessage id="responses.noData"/></Text>}*/}

        {/*{!data.length && !isLoading && !parentIsLoading && error &&*/}
        {/*    <Text><FormattedMessage id="responses.connectionFailed"/></Text>}*/}

        {!isLoading && !parentIsLoading && <Box id="resources-by-states-map" w="100%" h="400px"></Box>}
    </Box>);
};
