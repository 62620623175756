import {Badge, Box, Flex, IconButton, Switch, Text, Tooltip, useColorModeValue, useToast} from "@chakra-ui/react";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../hooks/useAuth";
import {configs} from "../../Configs";
import {FaAt, FaRegClock, FaRegStar, FaStar} from "react-icons/fa";
import useAxios from "../../hooks/useAxois";
import {useState} from "react";
import {useIntl} from 'react-intl';

export const ResourceCard = ({resource, selectedResources, setSelectedResources, refr}) => {
    const intl = useIntl();
    const toast = useToast();
    const axiosInstance = useAxios();
    const {hasPermission} = useAuth();
    const navigate = useNavigate();

    const [isStarred, setIsStarred] = useState(resource.has_star?.includes('RESOURCE'));

    const faIconStyle = {width: '13px', display: 'inline-block', marginBottom: '-2px'};
    let at = configs.sources.find((source) => source.value === resource.source)?.label;
    at = resource.source === 'RSS' ? `${at} / ${configs.rss_platforms.find(rp => rp.value === resource.rss_platform)?.label}` : at;

    const toggleStar = async () => {
        try {
            await axiosInstance.patch(`/resource/${resource.resource_id}`);
            if (!isStarred) {
                setIsStarred(true);
                toast({
                    title: intl.formatMessage({id: 'resources.marked', defaultMessage: "Resource Marked"}),
                    status: "success",
                    isClosable: true,
                    diration: 1500,
                });
            } else {
                setIsStarred(false);

                toast({
                    title: intl.formatMessage({id: 'resources.unmarked', defaultMessage: "Resource Unmarked"}),
                    status: "success",
                    isClosable: true,
                    diration: 1500,
                });
            }
        } catch (err) {
            let msg = err?.response?.data?.detail || [];
            if (msg && !Array.isArray(msg)) {
                msg = [{msg: msg}]
            }
            if (!msg.length) {
                msg = [{
                    msg:
                        intl.formatMessage({
                            id: 'responses.unknownError',
                            defaultMessage: 'Something went wrong. Please try again'
                        })
                }]
            }
            msg.map(err =>
                toast({
                    title: err.msg,
                    status: "error",
                    isClosable: true,
                    diration: 1500,
                })
            );
        }
    }
    const goToResource = (e) => {
        // takes no effect when clicked on elements with aria-label="no-action" and their children
        let element = e.target;
        while (element) {
            if (element.getAttribute('aria-label') === 'no-action') {
                return;
            }
            element = element.parentElement;
        }

        // otherwise redirect to soecified resource
        return hasPermission('resource', 'fe_edit') || hasPermission('resource', 'fe_delete') ? navigate(`/resources/${resource.resource_id}`, {replace: true}) : null;
    }

    return (<Box
        ref={refr}
        bg={useColorModeValue("gray.200", "gray.300")}
        minHeight="3rem"
        my={3}
        p={3}
        rounded="lg"
        _hover={{
            opacity: 0.9, cursor: "pointer", transform: "translateY(-3px)",
        }}
        onClick={goToResource}
    >
        <Flex
            width={'100%'}
            alignItems="center"
            justifyContent="space-between"
            mb={2}
        >
            <Text style={{overflow: 'hidden'}}>{resource.title}</Text>
            <Badge colorScheme={resource.status ? "green" : "purple"}>
                {resource.status ? intl.formatMessage({
                    id: 'resources.enabled',
                    defaultMessage: "Enabled"
                }) : intl.formatMessage({id: 'resources.disabled', defaultMessage: "Disabled"})}
            </Badge>
        </Flex>
        <Flex
            width={'100%'}
            alignItems="center"
            justifyContent="space-between"
        >
            <Text style={{overflow: 'hidden'}}>
                <Tooltip label={
                    intl.formatMessage({id: 'resources.resourceTz', defaultMessage: "Resource Timezone"})
                }>
                    <span><FaRegClock style={faIconStyle}/> {resource.timezone}</span>
                </Tooltip>
            </Text>
            <Text style={{overflow: 'hidden'}}>
                <Tooltip label={
                    intl.formatMessage({id: 'resources.resourcePlatform', defaultMessage: "Resource Platform"})
                }>
                    <span><FaAt style={faIconStyle}/>{at}</span>
                </Tooltip>
            </Text>
            <span aria-label={'no-action'}>
                {hasPermission('resource', 'fe_star') && <Tooltip label={isStarred ? intl.formatMessage({
                    id: 'resources.unmark',
                    defaultMessage: "Unmark"
                }) : intl.formatMessage({id: 'resources.mark', defaultMessage: "Mark"})}>
                    <IconButton
                        me={1}
                        size="sm"
                        icon={
                            isStarred ? <FaStar aria-label={'no-action'}
                                                style={{
                                                    color: 'orange',
                                                    display: 'inline-block',
                                                    marginBottom: '-1px'
                                                }}/> :
                                <FaRegStar aria-label={'no-action'}
                                           style={{color: 'orange', display: 'inline-block', marginBottom: '-1px'}}/>
                        }
                        onClick={toggleStar}
                    />
                </Tooltip>}
                <Tooltip label={selectedResources.includes(resource.resource_id) ? intl.formatMessage({
                    id: 'resources.deselect',
                    defaultMessage: "Deselect"
                }) : intl.formatMessage({id: 'resources.select', defaultMessage: "Select"})}>
                    <span>
                        <Switch
                            colorScheme={"yellow"}
                            isDisabled={resource.source === 'NITTER'}
                            isChecked={selectedResources.includes(resource.resource_id)}
                            onChange={() => setSelectedResources(prevItems => {
                                if (prevItems.includes(resource.resource_id)) {
                                    return prevItems.filter(item => item !== resource.resource_id);
                                }
                                return [...prevItems, resource.resource_id];
                            })}/>
                    </span>
                </Tooltip>
            </span>
        </Flex>
    </Box>);
};
