import {Text, Box, Button, ButtonGroup, Flex, IconButton, useColorModeValue} from "@chakra-ui/react";
import ReactSelect from 'react-select';
import {FaTh, FaThList} from "react-icons/fa";
import {FormattedMessage, useIntl} from 'react-intl';
import {LocaleContext} from "../../Theme/LocaleProvider";
import {useContext} from 'react';
export const NavBar = ({
                           resultsCount,
                           postCats,
                           setSelPostCats,
                           selPostCats,
                           gridView,
                           setGridView,
                           onSubmit,
                           ...rest
                       }) => {
    const intl = useIntl();
    const context = useContext(LocaleContext);
    const cstmMutliSelectMenu = {
        background: useColorModeValue("var(--chakra-colors-gray-300)", "var(--chakra-colors-gray-600)"),
        color: useColorModeValue("var(--chakra-colors-gray-800)", "var(--chakra-colors-gray-300)"),
    }

    const cstmMutliSelectOptions = {
        background: useColorModeValue("var(--chakra-colors-gray-300)", "var(--chakra-colors-gray-600)"),

        "&:hover": {
            background: useColorModeValue('var(--chakra-colors-gray-200)', 'var(--chakra-colors-whiteAlpha-100)'),
        },
        "&:focus-visible": {
            background: 'var(--chakra-colors-transparent)',
            borderColor: '#63b3ed'
        }
    }

    const cstmMutliSelect = {
        width: '100%',
        minWidth: '0px',
        outline: '2px solid transparent',
        outlineOffset: '2px',
        position: 'relative',
        appearance: 'none',
        transitionProperty: 'var(--chakra-transition-property-common)',
        transitionDuration: 'var(--chakra-transition-duration-normal)',
        fontSize: 'var(--chakra-fontSizes-lg)',
        paddingInlineStart: 'var(--chakra-space-4)',
        paddingInlineEnd: 'var(--chakra-space-4)',
        minHeight: 'var(--chakra-sizes-12)',
        borderRadius: 'var(--chakra-radii-md)',
        border: '0px solid',
        borderColor: 'var(--chakra-colors-transparent)',
        background: useColorModeValue("var(--chakra-colors-gray-300)", "var(--chakra-colors-gray-600)"),
        marginTop: 'var(--chakra-space-6)',
        "&:hover": {
            background: useColorModeValue('var(--chakra-colors-gray-200)', 'var(--chakra-colors-whiteAlpha-100)'),
        },
        "&:focus-visible": {
            background: 'var(--chakra-colors-transparent)',
            borderColor: '#63b3ed'
        }
    }

    const options = postCats.map(tag => ({value: tag.tag_id, label: tag.title}));

    return (
        <Box p={5}>
            <Flex
                as="nav"
                align="center"
                justify="center"
                direction={{base: 'column', md: 'row'}}
            >
                <Box width={{base: '100%', md: '3xl'}}>
                    <ReactSelect
                        value={options.find(opt => selPostCats.includes(opt.value))}
                        onChange={(opts) => setSelPostCats(opts)}
                        placeholder={intl.formatMessage({id: 'raqib7.tags', defaultMessage: 'Tags'}) + '...'}
                        isMulti
                        options={options}
                        closeMenuOnSelect={false}
                        classNames={
                            {
                                control: (state) => state.isFocused ? 'border-red-600' : 'border-grey-300'
                            }
                        }
                        styles={{
                            control: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelect}),
                            menu: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelectMenu}),
                            option: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelectOptions})
                        }}
                    />
                </Box>
                <Button w={{base: '100%', md: 'auto'}} mt={6}
                        ms={{base: 0, md: 5}} onClick={() => onSubmit()}
                        colorScheme={'green'} size={"lg"}>
                    <FormattedMessage
                        id="raqib7.filter"
                        defaultMessage="Filter"
                    />
                </Button>
                <ButtonGroup ms={1} mt={6} size='lg' isAttached>
                    <IconButton onClick={() => setGridView(true)} aria-label='Grid'
                                icon={<FaTh/>} {...(gridView ? {colorScheme: "facebook"} : {})}/>
                    <IconButton onClick={() => setGridView(false)} aria-label='Row'
                                icon={<FaThList/>} {...(!gridView ? {colorScheme: "facebook"} : {})}/>
                </ButtonGroup>
            </Flex>

            {!!resultsCount && <Box w={'100%'} textAlign='center' mt={6}>
                <Text color={'#767070'}>
                    <FormattedMessage id={'responses.resultCounts'}
                                      values={{count: resultsCount.toLocaleString(context.locale)}}/>
                </Text>
            </Box>}

        </Box>
    );
};
