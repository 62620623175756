import React from 'react';

export const PopIcon = ({icon}) => {

    const cssStyles = {
        position: 'fixed',
        bottom: '3%',
        right: '1%'
    }

    return (
        <div style={cssStyles}>
            {icon}
        </div>
    )
}

export default PopIcon