import {Box, Flex, Heading, Image, Link, Spinner, Text} from '@chakra-ui/react';
import {useEffect, useRef, useState} from "react";
import HTMLParser from "../../utils/HtmlParser";
import {Navigate, useParams} from "react-router-dom";
import {dtToLocaleString, setTextDirection} from '../../utils/helpers';
import useAxios from "../../hooks/useAxois";
import {FormattedMessage} from "react-intl";
import {configs} from "../../Configs";

const SingleArticle = () => {
    const axiosInstance = useAxios();
    const {newsId} = useParams();
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(false);

    const titleRef = useRef(null);
    const leadRef = useRef(null);
    const descriptionRef = useRef(null);

    useEffect(() => {
        axiosInstance.get(`/news/${newsId}`).then((data) => {
            setData(data.data);
            setError(false);
        }).catch((error) => {
            if (error.response && error.response.status === 404) return;
            setError(true);
            console.error(error);
        }).finally(() => setIsLoading(false));
    }, []);

    useEffect(() => {
        if (!data) return;
        setTextDirection(titleRef.current, data.title, 'justify');
        setTextDirection(leadRef.current, data.lead?.replace(/<[^>]*>?/gm, ''));
        // setTextDirection(descriptionRef.current, data.description.replace(/<[^>]*>?/gm, ''));
    }, [data]);

    if (!data && !isLoading && !error) {
        return <Navigate to="/error/404"/>
    }

    if (isLoading || error) {
        return <Box
            width={"100%"}
            textAlign={'center'}
            display="flex"
            alignItems="center"
            justifyContent="center"
        >
            {!isLoading && error && <Text>
                <FormattedMessage
                    id="responses.noData"
                    defaultMessage="No Data"
                />
            </Text>}
            {isLoading && <Spinner mt={5} color="green"/>}
        </Box>
    }

    return (
        <Box
            p={5}
            m={'auto'}
            pb={{base: 100, md: 50}}
            w={{base: '100%', md: '80%', xl: '75%'}}
            className="articles-font"
        >
            <Box p={5}><Heading fontFamily={configs.posts_heading_font_family}
                                ref={titleRef}>{data.title}</Heading></Box>
            <Flex
                wrap="wrap"
                borderBottomWidth={'1px'} borderBottomColor={'#e8e8e8'}
                color={'#202020'}
                fontWeight={500}
                mb={4}
            >
                <Box w={{base: '100%', md: '50%'}} textAlign={{base: 'center', md: 'start'}} p={5}
                     className="article-cats">
                    <FormattedMessage
                        id="articles.categories"
                        defaultMessage="Categories"
                    />: {data.categories ? <Link key={`cats:0`} target={'_blank'}
                                                 href={`/articles/tag/${data.tags[0].tag_id}`}>{data.tags[0].title}</Link> : ''}
                </Box>
                <Box w={['100%', '50%']} p={5} color={'#6c6c6c'}
                     textAlign={{base: 'center', md: 'end'}}>{dtToLocaleString(data.published_at)}</Box>
            </Flex>
            <Box ref={leadRef} borderStartColor={'#ff6c00'} borderStartWidth={'3px'} bgColor={'#f7f7f7'} p={5} mb={6}>
                <Text fontFamily={configs.posts_body_font_family}>{data.lead}</Text>
            </Box>
            <Box p={1} alignItems={'center'} alignContent={'center'} justifyContent={"center"} textAlign={'center'}>
                <Image
                    display={'inline-block'}
                    style={{border: '1px solid #e8e8e8', borderRadius: '4px', padding: '5px'}}
                    maxW={'80%'}
                    maxH={'460px'}
                    src={data.image}
                    alt={data.title}
                />
            </Box>
            <Box
                ref={descriptionRef}
                fontFamily={configs.posts_body_font_family}
                borderBottomWidth={'1px'}
                borderBottomColor={'#e8e8e8'}
                p={5}
                pb={6}
                my={5}
            >
                <HTMLParser htmlContent={data.description}/>
            </Box>
            <Box fontFamily={'Cairo'} fontWeight={800}>
                <Link target={'_blank'} href={data.link}>
                    <FormattedMessage
                        id="articles.citationLink"
                        defaultMessage="Citation Link"
                    />
                </Link>
            </Box>
        </Box>
    );
};

export default SingleArticle;
