import {Box, Grid, Spinner, Text} from '@chakra-ui/react';
import {useCallback, useEffect, useRef, useState} from "react";
import {PostGrid} from './PostGrid';
import {PostRow} from './PostRow';
import {configs} from "../../../Configs";
import {NavBar} from "./NavBar";
import useAxios from "../../../hooks/useAxois";
import axios from "axios";
import Masonry from 'react-masonry-css';
import {FormattedMessage} from "react-intl";

export const Raqib7Categorized = () => {
    const axiosInstance = useAxios();
    const [gridView, setGridView] = useState(true);
    const [postCats, setPostCats] = useState([]);
    const [selPostCats, setSelPostCats] = useState([]);

    // pagination related stuff
    const [resultsCount, setResultsCount] = useState(0);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [nextPageId, setNextPageId] = useState(null);
    const [forceRefresh, setForceRefresh] = useState(false);
    const [lastElmId, setLastElmId] = useState(null);
    const [pageId, setPageId] = useState(null);

    const observer = useRef();

    useEffect(() => {
        axiosInstance.get("/raqib7_post_tag").then((data) => {
            setPostCats(data.data)
            setIsLoading(false);
            setError(false);
        }).catch((error) => {
            setIsLoading(false);
            if (error.response && error.response.status === 404) return;
            setError(true);
            console.error(error);
        });
    }, []);

    const lastPostElmRef = useCallback(node => {
        if (isLoading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && nextPageId) {
                setPageId(nextPageId)
            }
        })
        if (node) observer.current.observe(node);
    }, [isLoading, nextPageId])

    useEffect(() => {
        if (!configs.raqib7.should_refresh_default) {
            return
        }

        const theInterval = setInterval(() => {
            setData([]);
            setResultsCount(0);
            setIsLoading(false);
            setLastElmId(null);
            setError(false);
            setNextPageId(null);
            setPageId(null);
            setForceRefresh(prevForceRefresh => !prevForceRefresh);
        }, configs.raqib7.refresh_interval_default * 1000);

        return () => {
            clearInterval(theInterval);
        };
    }, []);

    const onSubmit = (paginate = false) => {
        setLastElmId(null);
        setIsLoading(true);
        setError(false);
        let cancel;

        if (!paginate) {
            setNextPageId(null);
            setPageId(null);
            setData([]);
            setResultsCount(0);
        }
        const tag_ids = selPostCats.map(tag => `tag_ids=${tag.value}`).join('&');
        axiosInstance.get(tag_ids ? `/raqib7/?${tag_ids}` : '/raqib7/', {
            headers: {'Client-Timezone': configs.time_locale},
            params: paginate ? {after: pageId} : {},
            cancelToken: new axios.CancelToken(c => cancel = c)
        }).then((res) => {
            setResultsCount(res?.data?.count || 0);
            setData(prev => {
                // discard previous results if its not paginating
                const theData = paginate ? [...prev, ...(res?.data?.posts || [])] : [...(res?.data?.posts || [])];
                // uniqify data combination
                let itteratedKeys = [];
                let newData = [];
                theData.map(pst => {
                    if (!itteratedKeys.includes(pst.id)) {
                        itteratedKeys.push(pst.id);
                        newData.push(pst);
                    }
                });

                // the nextPageId is determined by least sort (desc)
                let sortedData = theData;
                sortedData.sort((a, b) => parseFloat(a.sort) - parseFloat(b.sort));
                setLastElmId(sortedData.length ? sortedData[0].id : null);
                setNextPageId(sortedData.length ? sortedData[0].sort : null)
                setIsLoading(false);
                setError(false);

                return newData;
            });
        }).catch((error) => {
            if (axios.isCancel(error)) return;
            setIsLoading(false);
            if (error.response && error.response.status === 404) return;
            setError(true);
            console.error(error);
        });
        return () => cancel();
    }

    useEffect(() => onSubmit(true), [pageId, forceRefresh])

    return (
        <>
            <NavBar
                resultsCount={resultsCount}
                postCats={postCats}
                setSelPostCats={setSelPostCats}
                selPostCats={selPostCats}
                gridView={gridView}
                setGridView={setGridView}
                onSubmit={onSubmit}
            />
            <Box p={5}
                 align="center"
                 justify="center"
                 direction={{base: 'column', md: 'row'}}
            >
                {gridView && <Masonry
                    breakpointCols={{
                        default: 5,
                        1100: 3,
                        700: 2,
                        500: 1
                    }}
                    className="masonry-grid"
                    columnClassName="masonry-grid_column"
                >
                    {data && data.map(pst => {
                        if (pst.id === lastElmId) {
                            pst['refr'] = lastPostElmRef
                        }
                        return <PostGrid key={'post:' + pst.id} {...pst}/>;
                    })}
                </Masonry>}

                {!gridView && <Grid templateColumns={'1fr'}
                                    width={{base: '100%', md: '3xl'}}
                                    gap={2}
                >
                    {data && data.map(pst => {
                        if (pst.id === lastElmId) {
                            pst['refr'] = lastPostElmRef
                        }
                        return <PostRow key={'post:' + pst.id} {...pst}/>;
                    })}
                </Grid>}
                <Box w='100%' textAlign='center'>
                    {!data.length && !isLoading &&
                        <Text >
                            <FormattedMessage
                                id="responses.noData"
                                defaultMessage="No Date"
                            />
                        </Text>}
                    {!isLoading && error &&
                        <Text >
                            <FormattedMessage
                                id="responses.connectionFailed"
                                defaultMessage="Connection Failed"
                            />
                        </Text>}
                    {isLoading && <Spinner mt={5} color="green"/>}
                </Box>
            </Box>
        </>
    );
}
