import {Box, Spinner, Table, Tbody, Text, Th, Thead, Tr} from "@chakra-ui/react";
import {useContext, useEffect, useState} from 'react';
import {LocaleContext} from "../Theme/LocaleProvider";
import useAxios from "../../hooks/useAxois";
import {useAuth} from "../../hooks/useAuth";
import {generateRandomKey, parseDashboardChartsFilters} from "../../utils/helpers";
import {MostUsedWordsRow} from "./MostUsedWordsRow";
import {configs} from "../../Configs";
import {FormattedMessage} from 'react-intl';


export const MostUsedWordsTable = ({
                                       parentIsLoading,
                                       since,
                                       until,
                                       resource_ids,
                                       tag_ids,
                                       faction_ids,
                                       ...rest
                                   }) => {
    const axiosInstance = useAxios();
    const context = useContext(LocaleContext);
    const {hasPermission, user} = useAuth();
    const hasPermit = hasPermission('charts:posts:posts-top-words', 'fe_posts_top_words');
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(hasPermit ? 0 : 0);
    const [error, setError] = useState(false);

    useEffect(() => {
        if (!hasPermit || parentIsLoading) return;

        // clear previous filtered data
        setData([]);

        const sourceList = Object.values(configs.raqib1.mapsSourcesVals)

        let apiCalls = [];
        let payload = {
            since: since,
            until: until
        }

        const url = parseDashboardChartsFilters("/charts/posts/posts-top-words?", tag_ids, faction_ids, resource_ids);
        sourceList.map(source => {
            if (!source.length) return;
            setIsLoading((old) => old + 1);
            payload.source = source;
            apiCalls.push(
                axiosInstance.get(url, {
                    headers: {'Client-Timezone': configs.time_locale},
                    params: payload
                }).catch((error) => console.error(error)).finally(() => {
                    setIsLoading(old => old - 1);
                })
            );
        });

        Promise.all(apiCalls).then(responses => {
            const items = [].concat(...responses.map(res => res?.data).filter(res => res !== undefined));
            let newData = items.filter((item) => sourceList.includes(item.source));
            setData((oldData) => {
                let item_cnts = {};
                const parsedData = [...oldData, ...newData].filter(item => {
                    item_cnts[item.source] = item_cnts[item.source] ? item_cnts[item.source] + 1 : 1;
                    return item_cnts[item.source] <= 10
                });
                parsedData.sort((a, b) => b.count - a.count);
                return parsedData
            });
        });
    }, [since, until, resource_ids, tag_ids, faction_ids]);


    return (
        <Box
            overflowX="auto"
            bg={"gray.100"}
            minHeight="3rem"
            my={2}
            p={3}
            py={5}
            rounded="lg"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            {...rest}
        >
            <Text mb={5} fontSize={25} fontWeight={500} dir={context.isRTL ? 'rtl' : 'ltr'}>
                <FormattedMessage id="charts.posts.postsTopWordsTitle"/>
            </Text>

            {(!!isLoading || !!parentIsLoading) && <Spinner color="green"/>}

            {!data.length && !isLoading && !parentIsLoading && <Text><FormattedMessage id="responses.noData"/></Text>}

            {!data.length && !isLoading && !parentIsLoading && error &&
                <Text><FormattedMessage id="responses.connectionFailed"/></Text>}

            {data.length > 0 && <Table>
                <Thead>
                    <Tr>
                        <Th p={3}><FormattedMessage id="charts.posts.postsTopWords.id"/></Th>
                        <Th p={3} minW="100px"><FormattedMessage
                            id="charts.posts.postsTopWords.word"/></Th>
                        <Th p={3} minW="100px"><FormattedMessage id="charts.posts.postsTopWords.count"/></Th>
                        <Th p={3} minW="100px"><FormattedMessage id="charts.posts.postsTopWords.source"/></Th>
                    </Tr>
                </Thead>
                <Tbody style={{fontFamily: "IRANSans, sans-serif"}}>
                    {data.map((record, idx) => {
                        return <MostUsedWordsRow
                            key={generateRandomKey()}
                            row_id={idx + 1}
                            stripped={idx % 2 === 0}
                            {...record}/>
                    })}
                </Tbody>
            </Table>}
        </Box>);
};
