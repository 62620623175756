import {
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    Heading,
    Input,
    useColorModeValue,
    useToast,
} from "@chakra-ui/react";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../hooks/useAuth";
import {FormattedMessage, useIntl} from 'react-intl';

export const Login = () => {
    const intl = useIntl();

    const {
        handleSubmit,
        register,
        formState: {errors, isSubmitting},
    } = useForm();
    const navigate = useNavigate();
    const {login} = useAuth();
    const toast = useToast();

    const onSubmit = async (values) => {
        try {
            await login(values.username, values.password);
        } catch (error) {
            toast({
                title: intl.formatMessage({
                    id: "validation.usernameOrPassword",
                    defaultMessage: "Invalid username or password"
                }),
                status: "error",
                isClosable: true,
                duration: 1500,
            });
        }
    };
    return (
        <Flex height="100vh" alignItems="center" justifyContent="center">
            <Flex
                direction="column"
                alignItems="center"
                background={useColorModeValue("gray.100", "gray.700")}
                p={12}
                rounded={6}
            >
                <img id="logo" width={192} src="/logo-2.png" alt="Raqib"/>
                <Heading mb={6}>
                    <FormattedMessage
                        id="auth.loginTitle"
                        description="Login"
                        defaultMessage="Login"
                    />
                </Heading>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormControl isInvalid={errors.username}>
                        <Input
                            placeholder={
                                intl.formatMessage(
                                    {
                                        id: "auth.username",
                                        defaultMessage: "Username"
                                    }
                                )
                            }
                            background={useColorModeValue("gray.300", "gray.600")}
                            type="text"
                            size="lg"
                            mt={6}
                            {...register("username", {
                                required: intl.formatMessage(
                                    {
                                        id: "validation.fieldRequired",
                                        defaultMessage: "{field} is required"
                                    },
                                    {
                                        field: intl.formatMessage(
                                            {
                                                id: "auth.username",
                                                defaultMessage: "Username"
                                            }
                                        )
                                    }
                                )
                            })}
                        />
                        <FormErrorMessage>
                            {errors.username && errors.username.message}
                        </FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={errors.username}>
                        <Input
                            placeholder={
                                intl.formatMessage(
                                    {
                                        id: "auth.password",
                                        defaultMessage: "Password"
                                    }
                                )
                            }
                            background={useColorModeValue("gray.300", "gray.600")}
                            type="password"
                            size="lg"
                            mt={6}
                            {...register("password", {
                                required: intl.formatMessage(
                                    {
                                        id: "validation.fieldRequired",
                                        defaultMessage: "{field} is required"
                                    },
                                    {
                                        field: intl.formatMessage(
                                            {
                                                id: "auth.password",
                                                defaultMessage: "Password"
                                            }
                                        )
                                    }
                                )
                            })}
                        />
                        <FormErrorMessage>
                            {errors.password && errors.password.message}
                        </FormErrorMessage>
                    </FormControl>
                    <Button
                        isLoading={isSubmitting}
                        loadingText={
                            <FormattedMessage
                                id="auth.loggingIn"
                                description="Logging in"
                                defaultMessage="Logging in..."
                            />
                        }
                        width="100%"
                        colorScheme="green"
                        variant="outline"
                        mt={6}
                        mb={6}
                        type="submit"
                    >
                        <FormattedMessage
                            id="auth.loginButton"
                            description="Login"
                            defaultMessage="Login"
                        />
                    </Button>
                </form>
                {/*<ThemeToggler showLabel={true} />*/}
                <Button
                    onClick={() => navigate("/register", {replace: true})}
                    width="100%"
                    colorScheme="gray"
                    variant="outline"
                    mt={6}
                >
                    <FormattedMessage
                        id="auth.registerButton"
                        description="Register Instead"
                        defaultMessage="Register Instead"
                    />
                </Button>
            </Flex>
        </Flex>
    );
};
