import {Box, Flex, Heading, Spinner, Table, Tbody, Td, Text, Th, Thead, Tr, useToast} from '@chakra-ui/react';
import {useEffect, useState} from "react";
import {Paginator} from './Paginator';
import ExportTableRow from './ExportTableRow';
import {useNavigate} from "react-router-dom";
import {AddExportModal} from "./AddExportModal";
import {useAuth} from "../../hooks/useAuth";
import useAxios from "../../hooks/useAxois";
import {FormattedMessage, useIntl} from 'react-intl';

const Exports = () => {
    const intl = useIntl();
    const axiosInstance = useAxios();
    const {hasPermission} = useAuth()
    const toast = useToast();
    const navigate = useNavigate();
    const [statistics, setStatistics] = useState({});
    const [data, setData] = useState([]);
    const [paginationInfo, setPaginationInfo] = useState(null);
    const [isLoading, setIsLoading] = useState(0);
    const [error, setError] = useState(false);
    const [statsError, setStatsError] = useState(false);
    const [curPage, setCurPage] = useState(1);

    useEffect(() => {
        // statistics
        setIsLoading(v => v + 1);
        axiosInstance.get('/export/statistics').then((data) => {
            setStatistics(data.data);
            setStatsError(false);
        }).catch((error) => {
            if (error.response && error.response.status === 404) return;
            setStatsError(true);
            console.error(error);
        }).finally(() => setIsLoading(v => v - 1));
    }, []);


    const loadExports = (paginate = false) => {
        setIsLoading(v => v + 1);
        setError(false);
        setData([]);

        axiosInstance.get('/export', {params: paginate && curPage > 0 ? {skip: (curPage - 1) * 10} : {}}).then((data) => {
            const {exportItems, ...pagingInfo} = data.data;
            setData(exportItems || []);
            setPaginationInfo(pagingInfo);
            setError(false);
        }).catch((error) => {
            if (error.response && error.response.status === 404) return;
            setError(true);
            console.error(error);
        }).finally(() => setIsLoading(v => v - 1));
    }

    useEffect(() => {
        loadExports(true)
    }, [curPage]);

    const deleteExportItem = (exportId) => {
        setIsLoading(v => v + 1);
        axiosInstance
            .delete(`/export/${exportId}`)
            .then(() => {
                toast({
                    title: intl.formatMessage({
                        id: 'exports.deleted', defaultMessage: 'Export Deleted'
                    }), status: "success", isClosable: true, diration: 1500,
                });
                setData((old) => old.filter((item) => item.export_id !== exportId));
                navigate("/exports");
            })
            .catch((err) => {
                toast({
                    title: intl.formatMessage({
                        id: 'exports.notDeleted', defaultMessage: 'Could not delete export'
                    }), status: "error", isClosable: true, diration: 2000,
                });
            })
            .finally(() => setIsLoading(v => v - 1));
    };


    return (<Flex
        wrap={'wrap'}
        p={5}
        m={'auto'}
        w={{base: '100%', md: '80%', xl: '75%'}}
    >
        <Box w={['100%', '80%']}>
            <Flex wrap={'wrap'} p={5} borderBottomWidth={'1px'} borderBottomColor={'#e8e8e8'} mb={4}>
                <Box w={'50%'} textAlign={'start'}>
                    <Heading>
                        <FormattedMessage
                            id="exports.title"
                            defaultMessage="Exports"
                        />
                    </Heading>
                </Box>

                {hasPermission('export', 'fe_create') && <Box w={'50%'} textAlign={'end'}>
                    <AddExportModal onSuccess={loadExports}/>
                </Box>}
            </Flex>

            <Box overflowX="auto">
                <Table>
                    <Thead>
                        <Tr>
                            <Th p={3} minW={5}>
                                <FormattedMessage
                                    id="exports.rowId"
                                    defaultMessage="ID"
                                />
                            </Th>
                            <Th p={3} minW={150}>
                                <FormattedMessage
                                    id="exports.exportTitle"
                                    defaultMessage="Title"
                                />
                            </Th>
                            <Th p={3} minW={5}>
                                <FormattedMessage
                                    id="exports.exportType"
                                    defaultMessage="Type"
                                />
                            </Th>
                            <Th p={3} minW={180}>
                                <FormattedMessage
                                    id="exports.exportSince"
                                    defaultMessage="Since"
                                />
                            </Th>
                            <Th p={3} minW={180}>
                                <FormattedMessage
                                    id="exports.exportUntil"
                                    defaultMessage="Until"
                                />
                            </Th>
                            <Th p={3} minW={180}>
                                <FormattedMessage
                                    id="exports.exportRequestedAt"
                                    defaultMessage="Requested at"
                                />
                            </Th>
                            <Th p={3} minW={180}>
                                <FormattedMessage
                                    id="exports.exportGeneratedAt"
                                    defaultMessage="Generated at"
                                />
                            </Th>
                            <Th p={3} minW={100}>
                                <FormattedMessage
                                    id="exports.exportState"
                                    defaultMessage="State"
                                />
                            </Th>
                            <Th p={3} minW={100}>
                                <FormattedMessage
                                    id="exports.exportAction"
                                    defaultMessage="Action"
                                />
                            </Th>
                        </Tr>
                    </Thead>
                    <Tbody>

                        {data.length > 0 && data.map((exportItem, idx) => {
                            // const id = curPage * 10 + idx;
                            const id = (curPage - 1) * 10 + (idx + 1);
                            return <ExportTableRow
                                key={`exportItem:row:${id}`}
                                id={id}
                                stripped={idx % 2 === 0}
                                onDelete={deleteExportItem}
                                {...exportItem}/>
                        })}

                        {!data.length && !isLoading > 0 && <Tr>
                            <Td colSpan={8} textAlign={'center'}>
                                <Text>
                                    <FormattedMessage
                                        id="responses.noData"
                                        defaultMessage="No Data"
                                    />
                                </Text>
                            </Td>
                        </Tr>}
                        {!isLoading > 0 && error && <Tr>
                            <Td colSpan={8} textAlign={'center'}>
                                <Text>
                                    <FormattedMessage
                                        id="responses.connectionFailed"
                                        defaultMessage="Connection Failed"
                                    />
                                </Text>
                            </Td>
                        </Tr>}
                        {isLoading > 0 &&
                            <Tr><Td colSpan={8} textAlign={'center'}><Spinner mt={5} color="green"/></Td></Tr>}
                    </Tbody>
                </Table>
            </Box>

            <Box

                p={5}
                pt={6}
                my={5}
            >
                {paginationInfo &&
                    <Paginator curPage={curPage} setCurPage={setCurPage} paginationInfo={paginationInfo}/>}
            </Box>
        </Box>
        <Box w={['100%', '20%']} p={5}>
            <Box p={5} bgColor={'#f2f2f2'}>
                <Box>
                    <Heading mb={4} pb={4} borderBottomWidth={'2px'} borderBottomColor={'#dee2e6'} fontSize={'md'}>
                        <FormattedMessage
                            id="exports.exportStatistics"
                            defaultMessage="Export Statistics"
                        />
                    </Heading>
                    <Flex
                        p={2}
                        wrap={'wrap'}
                        _hover={{bgColor: '#e2e2e2'}}
                    >
                        <Box w={'50%'}>
                            <Text>
                                <FormattedMessage
                                    id="exports.today"
                                    defaultMessage="Today"
                                />
                            </Text>
                        </Box>
                        <Box w={'50%'} textAlign={'end'}>
                            <Text>{statistics?.today || 0}</Text>
                        </Box>
                    </Flex>
                    <Flex
                        p={2}
                        wrap={'wrap'}
                        _hover={{bgColor: '#e2e2e2'}}
                    >
                        <Box w={'50%'}>
                            <Text>
                                <FormattedMessage
                                    id="exports.thisWeek"
                                    defaultMessage="This Week"
                                />
                            </Text>
                        </Box>
                        <Box w={'50%'} textAlign={'end'}>
                            <Text
                            >{statistics?.this_week || 0}</Text>
                        </Box>
                    </Flex>
                    <Flex
                        p={2}
                        wrap={'wrap'}
                        _hover={{bgColor: '#e2e2e2'}}
                    >
                        <Box w={'50%'}>
                            <Text>
                                <FormattedMessage
                                    id="exports.thisMonth"
                                    defaultMessage="This Month"
                                />
                            </Text>
                        </Box>
                        <Box w={'50%'} textAlign={'end'}>
                            <Text
                            >{statistics?.this_month || 0}</Text>
                        </Box>
                    </Flex>
                    <Flex
                        p={2}
                        wrap={'wrap'}
                        _hover={{bgColor: '#e2e2e2'}}
                    >
                        <Box w={'50%'}>
                            <Text>
                                <FormattedMessage
                                    id="exports.allExports"
                                    defaultMessage="All Exports"
                                />
                            </Text>
                        </Box>
                        <Box w={'50%'} textAlign={'end'}>
                            <Text>{statistics?.total || 0}</Text>
                        </Box>
                    </Flex>
                </Box>
            </Box>
        </Box>
    </Flex>);
};

export default Exports;
