import {
    Box,
    Button,
    FormControl,
    FormErrorMessage,
    Heading,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Stack,
    Textarea,
    useColorModeValue,
    useDisclosure,
    useToast,
} from "@chakra-ui/react";
import {FormattedMessage, useIntl} from 'react-intl';

import {Controller, useForm} from "react-hook-form";
import {useParams} from "react-router-dom";
import {configs} from '../../Configs';
import ReactSelect from 'react-select';
import useAxios from "../../hooks/useAxois";
import {LocaleContext} from '../Theme/LocaleProvider';
import {useContext} from "react";

export const AddUpdateUserModal = ({
                                       editable = false,
                                       defaultValues = {},
                                       onSuccess = () => {
                                       },
                                       ...rest
                                   }) => {
    const context = useContext(LocaleContext);
    const intl = useIntl();
    const axiosInstance = useAxios();

    // traslate roles
    const theRoles = configs.roles.map(item => ({
        value: item.value,
        label: intl.formatMessage({
            id: `roles.${item.value}`,
            defaultMessage: item.label
        })
    }));

    const isValidUUID = (uuid) => {
        const uuidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
        return uuidPattern.test(uuid);
    }

    const cstmMutliSelect = {
        width: '100%',
        minWidth: '0px',
        outline: '2px solid transparent',
        outlineOffset: '2px',
        position: 'relative',
        appearance: 'none',
        transitionProperty: 'var(--chakra-transition-property-common)',
        transitionDuration: 'var(--chakra-transition-duration-normal)',
        fontSize: 'var(--chakra-fontSizes-lg)',
        paddingInlineStart: 'var(--chakra-space-4)',
        paddingInlineEnd: 'var(--chakra-space-4)',
        minHeight: 'var(--chakra-sizes-12)',
        borderRadius: 'var(--chakra-radii-md)',
        border: '0px solid',
        borderColor: 'var(--chakra-colors-transparent)',
        background: useColorModeValue("var(--chakra-colors-gray-300)", "var(--chakra-colors-gray-600)"),
        marginTop: 'var(--chakra-space-6)',
        "&:hover": {
            background: useColorModeValue('var(--chakra-colors-gray-200)', 'var(--chakra-colors-whiteAlpha-100)'),
        },
        "&:focus-visible": {
            background: 'var(--chakra-colors-transparent)',
            borderColor: '#63b3ed'
        }
    }

    const cstmMutliSelectMenu = {
        background: useColorModeValue("var(--chakra-colors-gray-300)", "var(--chakra-colors-gray-600)"),
        color: useColorModeValue("var(--chakra-colors-gray-800)", "var(--chakra-colors-gray-300)"),
    }

    const cstmMutliSelectOptions = {
        background: useColorModeValue("var(--chakra-colors-gray-300)", "var(--chakra-colors-gray-600)"),

        "&:hover": {
            background: useColorModeValue('var(--chakra-colors-gray-200)', 'var(--chakra-colors-whiteAlpha-100)'),
        },
        "&:focus-visible": {
            background: 'var(--chakra-colors-transparent)',
            borderColor: '#63b3ed'
        }
    }

    const {isOpen, onOpen, onClose} = useDisclosure();

    const toast = useToast();
    const {userId} = useParams();

    const {
        handleSubmit,
        register,
        control,
        formState: {errors, isSubmitting}
    } = useForm({defaultValues: {...defaultValues}});

    const onSubmit = async (values) => {
        try {
            values.roles = values.roles?.map((role) => role.value);

            if (!values?.password) {
                delete values.password
            }

            if (editable) {
                await axiosInstance.put(`/users/${userId}`, values);
            } else {
                await axiosInstance.post(`/users/`, values);
            }

            toast({
                title: editable ? intl.formatMessage({
                    id: 'users.updated',
                    description: 'User Updated',
                    defaultMessage: 'User Updated'
                }) : intl.formatMessage({
                    id: 'users.created',
                    description: 'User Created',
                    defaultMessage: 'User Created'
                }),
                status: "success",
                isClosable: true,
                diration: 1500,
            });
            onSuccess();
            onClose();
        } catch (err) {
            let msg = err?.response?.data?.detail || [];
            if (msg && !Array.isArray(msg)) {
                msg = [{msg: msg}]
            }
            if (!msg.length) {
                msg = [{
                    msg:
                        intl.formatMessage({
                            id: 'responses.unknownError',
                            defaultMessage: 'Something went wrong. Please try again'
                        })
                }]
            }
            msg.map(err =>
                toast({
                    title: err.msg,
                    status: "error",
                    isClosable: true,
                    diration: 1500,
                })
            );
        }
    }

    const bgColor = useColorModeValue("gray.300", "gray.600");

    return (<Box {...rest}>
        <Button w="100%" colorScheme="green" onClick={onOpen}>
            {editable ? intl.formatMessage({
                id: "users.updateUser",
                defaultMessage: "UPDATE USER"
            }) : intl.formatMessage({
                id: "users.addUser",
                defaultMessage: "ADD USER"
            })}
        </Button>
        <Modal
            closeOnOverlayClick={false}
            size={{base:"full", md:"xl"}}
            onClose={onClose}
            isOpen={isOpen}
            // isCentered
        >
            <ModalOverlay/>
            <form onSubmit={handleSubmit(onSubmit)}>
                <ModalContent>
                    <ModalHeader dir={context.isRTL ? 'rtl' : 'ltr'}>
                        <Heading fontSize='2xl' textAlign={'start'}>
                            {editable ? intl.formatMessage({
                                id: "users.updateUser",
                                defaultMessage: "UPDATE USER"
                            }) : intl.formatMessage({
                                id: "users.addUser",
                                defaultMessage: "ADD USER"
                            })}
                        </Heading>
                    </ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        <FormControl isInvalid={errors.username}>
                            <Input
                                placeholder={intl.formatMessage(
                                    {
                                        id: "auth.username",
                                        defaultMessage: "Username"
                                    }
                                )}
                                background={bgColor}
                                type="text"
                                variant="filled"
                                size="lg"
                                mt={6}
                                disabled={!!editable}
                                {...register("username", {
                                    required: editable ? false : intl.formatMessage(
                                        {
                                            id: "validation.fieldRequired",
                                            defaultMessage: "{field} is required"
                                        },
                                        {
                                            field: intl.formatMessage(
                                                {
                                                    id: "auth.username",
                                                    defaultMessage: "Username"
                                                }
                                            )
                                        }
                                    ),
                                    minLength: {
                                        value: 5,
                                        message: intl.formatMessage(
                                            {
                                                id: "validation.fieldMinLen",
                                                defaultMessage: "{field} must be at least {length} characters"
                                            },
                                            {
                                                field: intl.formatMessage({
                                                    id: "auth.username",
                                                    defaultMessage: "Username"
                                                }),
                                                length: 5
                                            }
                                        )
                                    },
                                    maxLength: {
                                        value: 24,
                                        message: intl.formatMessage(
                                            {
                                                id: "validation.fieldMaxLen",
                                                defaultMessage: "{field} must be at most {length} characters"
                                            },
                                            {
                                                field: intl.formatMessage({
                                                    id: "auth.username",
                                                    defaultMessage: "Username"
                                                }),
                                                length: 24
                                            }
                                        )
                                    },
                                })}
                            />
                            <FormErrorMessage>
                                {errors.username && errors.username.message}
                            </FormErrorMessage>
                        </FormControl>

                        <FormControl isInvalid={errors.password}>
                            <Input
                                placeholder={intl.formatMessage(
                                    {
                                        id: "auth.password",
                                        defaultMessage: "Password"
                                    }
                                )}
                                background={bgColor}
                                type="password"
                                variant="filled"
                                size="lg"
                                mt={6}
                                {...register("password", {
                                    required: editable ? false : intl.formatMessage(
                                        {
                                            id: "validation.fieldRequired",
                                            defaultMessage: "{field} is required"
                                        },
                                        {
                                            field: intl.formatMessage(
                                                {
                                                    id: "auth.password",
                                                    defaultMessage: "Password"
                                                }
                                            )
                                        }
                                    ),
                                    minLength: {
                                        value: 5,
                                        message: intl.formatMessage(
                                            {
                                                id: "validation.fieldMinLen",
                                                defaultMessage: "{field} must be at least {length} characters"
                                            },
                                            {
                                                field: intl.formatMessage({
                                                    id: "auth.password",
                                                    defaultMessage: "Password"
                                                }),
                                                length: 5
                                            }
                                        )
                                    },
                                    maxLength: {
                                        value: 24,
                                        message: intl.formatMessage(
                                            {
                                                id: "validation.fieldMaxLen",
                                                defaultMessage: "{field} must be at least {length} characters"
                                            },
                                            {
                                                field: intl.formatMessage({
                                                    id: "auth.password",
                                                    defaultMessage: "Password"
                                                }),
                                                length: 24
                                            }
                                        )
                                    },
                                })}
                            />
                            <FormErrorMessage>
                                {errors.password && errors.password.message}
                            </FormErrorMessage>
                        </FormControl>

                        <FormControl isInvalid={errors.roles}>
                            <Controller
                                rules={{
                                    required: intl.formatMessage(
                                        {
                                            id: "validation.fieldRequired",
                                            defaultMessage: "{field} is required"
                                        },
                                        {
                                            field: intl.formatMessage(
                                                {
                                                    id: "users.roles",
                                                    defaultMessage: "Roles"
                                                }
                                            )
                                        }
                                    )
                                }}
                                name="roles"
                                control={control}
                                render={({field: {onChange, value}}) => (
                                    <ReactSelect
                                        value={value}
                                        onChange={onChange}
                                        placeholder={intl.formatMessage(
                                            {
                                                id: "users.roles",
                                                defaultMessage: "Roles"
                                            }
                                        )}
                                        isMulti
                                        options={theRoles}
                                        closeMenuOnSelect={false}
                                        classNames={
                                            {
                                                control: (state) => state.isFocused ? 'border-red-600' : 'border-grey-300'
                                            }
                                        }
                                        styles={{
                                            control: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelect}),
                                            menu: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelectMenu}),
                                            option: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelectOptions})
                                        }}
                                    />
                                )}
                            />
                            <FormErrorMessage>
                                {errors.roles && errors.roles.message}
                            </FormErrorMessage>
                        </FormControl>

                        <FormControl isInvalid={errors.description}>
                            <Textarea
                                rows={5}
                                placeholder={intl.formatMessage(
                                    {
                                        id: "users.description",
                                        defaultMessage: "Description"
                                    }
                                )}
                                background={bgColor}
                                type="text"
                                variant="filled"
                                size="lg"
                                mt={6}
                                {...register("description", {
                                    maxLength: {
                                        value: 256,
                                        message: intl.formatMessage(
                                            {
                                                id: "validation.fieldMaxLen",
                                                defaultMessage: "{field} must be at least {length} characters"
                                            },
                                            {
                                                field: intl.formatMessage({
                                                    id: "users.description",
                                                    defaultMessage: "Description"
                                                }),
                                                length: 256
                                            }
                                        )
                                    }
                                })}
                            />
                            <FormErrorMessage>
                                {errors.description && errors.description.message}
                            </FormErrorMessage>
                        </FormControl>

                    </ModalBody>
                    <ModalFooter>
                        <Stack direction="row" spacing={4}>
                            <Button onClick={onClose} disabled={isSubmitting}>
                                <FormattedMessage
                                    id="users.close"
                                    description="Close"
                                    defaultMessage="Close"
                                />
                            </Button>
                            <Button
                                colorScheme="green"
                                type="submit"
                                isLoading={isSubmitting}
                                loadingText={
                                    editable ? intl.formatMessage({
                                        id: "users.updating",
                                        defaultMessage: "Updating..."
                                    }) : intl.formatMessage({
                                        id: "users.creating",
                                        defaultMessage: "Creating..."
                                    })
                                }
                            >
                                {editable ? intl.formatMessage({
                                    id: "users.update",
                                    defaultMessage: "Update"
                                }) : intl.formatMessage({
                                    id: "users.create",
                                    defaultMessage: "Create"
                                })}
                            </Button>
                        </Stack>
                    </ModalFooter>
                </ModalContent>
            </form>
        </Modal>
    </Box>);
};
