import {Box, IconButton, Link, Td, Text, Tooltip, Tr} from '@chakra-ui/react';
import {DeleteIcon, DownloadIcon} from "@chakra-ui/icons";

import {capitalize, dtToLocaleString, gregorianJalaliDTConversion, setTextDirection} from '../../utils/helpers';
import {useEffect, useRef} from "react";
import {useAuth} from "../../hooks/useAuth";
import {FaFileCsv, FaFileWord} from "react-icons/fa";
import {FormattedMessage, useIntl} from 'react-intl';

const ExportTableRow = ({
                            id,
                            export_id,
                            title,
                            is_csv,
                            since,
                            until,
                            created_at,
                            file_generated_at,
                            job_state,
                            onDelete,
                            stripped = false
                        }) => {
    const intl = useIntl();
    const {hasPermission} = useAuth()
    const titleRef = useRef(null);

    useEffect(() => {
        setTextDirection(titleRef.current, title);
    }, [title]);

    return (
        <Tr fontSize={'sm'} bgColor={stripped ? '#f2f2f2' : 'white'}>
            <Td w={'60px'} p={3}>
                <Box maxW="sm" isTruncated>{id}</Box>
            </Td>
            <Td ref={titleRef} p={3}><Box noOfLines={3} lineHeight={2}>{title}</Box></Td>
            <Td p={3}><Box noOfLines={3} lineHeight={2}>{is_csv ? <Tooltip label={
                    intl.formatMessage({
                        id: 'exports.csvFile',
                        defaultMessage: 'CSV file'
                    })}
                ><span><FaFileCsv/></span></Tooltip> :
                <Tooltip label={
                    intl.formatMessage({
                        id: 'exports.wordDocument',
                        defaultMessage: 'Word document'
                    })}><span><FaFileWord/></span></Tooltip>}</Box></Td>
            <Td p={3}>
                <Box noOfLines={3} lineHeight={2}>
                    <Tooltip label={since ? gregorianJalaliDTConversion(since) : '--'} aria-label='datetime-conversion'>
                        <Text fontSize='sm'>{since ? dtToLocaleString(since) : '--'}</Text>
                    </Tooltip>
                </Box>
            </Td>
            <Td p={3}>
                <Box noOfLines={3} lineHeight={2}>
                    <Tooltip label={until ? gregorianJalaliDTConversion(until) : '--'} aria-label='datetime-conversion'>
                        <Text fontSize='sm'>{until ? dtToLocaleString(until) : '--'}</Text>
                    </Tooltip>
                </Box>
            </Td>
            <Td p={3}>
                <Box noOfLines={3} lineHeight={2}>
                    <Tooltip label={gregorianJalaliDTConversion(created_at)} aria-label='datetime-conversion'>
                        <Text fontSize='sm'>{dtToLocaleString(created_at)}</Text>
                    </Tooltip>
                </Box>
            </Td>
            <Td p={3}>
                <Box noOfLines={3} lineHeight={2}>
                    <Tooltip label={file_generated_at ? gregorianJalaliDTConversion(file_generated_at) : '--'}
                             aria-label='datetime-conversion'>
                        <Text fontSize='sm'>{file_generated_at ? dtToLocaleString(file_generated_at) : '--'}</Text>
                    </Tooltip>
                </Box>
            </Td>
            <Td p={3}>
                <Box noOfLines={3} lineHeight={2}>
                    <Text>
                        <FormattedMessage
                            id={`exports.${job_state}`}
                            defaultMessage={capitalize(job_state)}
                        />
                    </Text>
                </Box>
            </Td>
            <Td p={3} w={'120px'}>
                {hasPermission('export', 'fe_download') && <IconButton
                    size="xs"
                    as={Link}
                    variant={'outline'}
                    colorScheme={'blue'}
                    borderRadius={0}
                    disabled={job_state !== 'DONE'}
                    href={`/${is_csv ? "csv" : "docx"}/${export_id}`}
                    icon={<DownloadIcon/>}
                />}
                {hasPermission('export', 'fe_delete') && <IconButton
                    ms={1}
                    size="xs"
                    variant={'outline'}
                    colorScheme={'red'}
                    borderRadius={0}
                    disabled={job_state !== 'DONE'}
                    onClick={() => onDelete(export_id)}
                    icon={<DeleteIcon/>}
                />}
            </Td>
        </Tr>
    );
};

export default ExportTableRow;
