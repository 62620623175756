import {Flex, Text, useColorModeValue} from "@chakra-ui/react";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../hooks/useAuth";

export const CatCard = ({cat}) => {
    const {hasPermission} = useAuth();
    const navigate = useNavigate();

    return (
        <Flex
            bg={useColorModeValue("gray.300", "gray.600")}
            minHeight="3rem"
            my={3}
            p={3}
            rounded="lg"
            alignItems="center"
            justifyContent="space-between"
            _hover={{
                opacity: 0.9,
                cursor: "pointer",
                transform: "translateY(-3px)",
            }}
            onClick={() => hasPermission('news_cat', 'fe_edit') || hasPermission('news_cat', 'fe_delete') ? navigate(`/news_cats/${cat.cat_id}`, {replace: true}) : null}
        >
            <Text style={{overflow: 'hidden'}}>{cat.title}</Text>
        </Flex>
    );
};
