import {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useAuth} from "../../hooks/useAuth";
import {Authenticated} from "./Authenticated";

export const Authorized = ({children, permissions = [], logic = 'OR'}) => {
    const auth = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [isVerified, setIsVerified] = useState(false);

    useEffect(() => {
        const results = permissions.map(({resource, action}) => auth.hasPermission(resource, action))
        const has_permissions = logic === 'AND' ? results.every((bool) => bool === true) : results.some((bool) => bool === true);

        if (!has_permissions || !auth?.isAuthenticated) {
            navigate("/error/404", {replace: true, state: {from: location}});
        } else {
            setIsVerified(true);
        }
    }, [auth.user, location, navigate]);

    if (!isVerified) {
        return null;
    }
    return <><Authenticated>{children}</Authenticated></>;
};
