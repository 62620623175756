import {Flex, Text, useColorModeValue} from "@chakra-ui/react";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../hooks/useAuth";

export const TagCard = ({tag}) => {
    const {hasPermission} = useAuth();
    const navigate = useNavigate();

    return (
        <Flex
            bg={useColorModeValue("gray.300", "gray.600")}
            minHeight="3rem"
            my={3}
            p={3}
            rounded="lg"
            alignItems="center"
            justifyContent="space-between"
            _hover={{
                opacity: 0.9,
                cursor: "pointer",
                transform: "translateY(-3px)",
            }}
            onClick={() => hasPermission('raqib2_tag', 'fe_edit') || hasPermission('raqib2_tag', 'fe_delete') ? navigate(`/raqib2_tags/${tag.tag_id}`, {replace: true}) : null}
        >
            <Text style={{overflow: 'hidden'}}>{tag.title}</Text>
        </Flex>
    );
};
