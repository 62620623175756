import {Box, Spinner, Text} from "@chakra-ui/react";
import {FormattedMessage, useIntl} from "react-intl";
import {useContext, useEffect, useLayoutEffect, useState} from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {LocaleContext} from "../Theme/LocaleProvider";
import useAxios from "../../hooks/useAxois";
import {useAuth} from "../../hooks/useAuth";

export const UsersStackedNegativeChart = ({...rest}) => {
    const axiosInstance = useAxios();
    const context = useContext(LocaleContext);
    const intl = useIntl();
    const {hasPermission} = useAuth();
    const hasPermit = hasPermission('charts:users:counts-stacked-negative', 'fe_counts_stacked_negative');
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(hasPermit);
    const [error, setError] = useState(false);

    useEffect(() => {
        if (!hasPermit) return;
        axiosInstance.get("/charts/users/counts-stacked-negative").then((res) => {
            setData(res.data.map(item => ({
                category: intl.formatMessage({id: `roles.${item["category"]}`, defaultMessage: item["category"]}),
                enabled: item["positive"],
                disabled: item["negative"]
            })));
            setError(false);
        }).catch((error) => {
            if (error.response && error.response.status === 404) return;
            setError(true);
            console.error(error);
        }).finally(() => setIsLoading(false));
    }, []);

    useLayoutEffect(() => {
        if (isLoading || !hasPermit) return;

        // define root chart with custom theme based on rtl/ltr
        let root = am5.Root.new("users-stacked-negative");
        const customTheme = am5.Theme.new(root);
        if (context.isRTL) {
            customTheme.rule("Label").setAll({
                fontFamily: "IRANSans",
                direction: 'rtl'
            });
        }
        root.setThemes([am5themes_Animated.new(root), customTheme]);

        // Create chart
        let chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                panX: false,
                panY: false,
                wheelX: "panX",
                wheelY: "zoomX",
                layout: root.verticalLayout,
                arrangeTooltips: false,
                paddingTop:30
            })
        );

        // chart.children.unshift(am5.Label.new(root, {
        //     text: intl.formatMessage({id: 'charts.users.countsStackedTitle'}),
        //     fontSize: 25,
        //     fontWeight: "500",
        //     textAlign: "center",
        //     x: am5.percent(50),
        //     centerX: am5.percent(50),
        //     paddingTop: 0,
        //     paddingBottom: 50,
        // }));

        // Use only absolute numbers
        chart.getNumberFormatter().set("numberFormat", "#.#s");

        // Create axes
        let yAxis = chart.yAxes.push(
            am5xy.CategoryAxis.new(root, {
                categoryField: "category",
                renderer: am5xy.AxisRendererY.new(root, {
                    opposite: context.isRTL,
                    inversed: true,
                    cellStartLocation: 0.1,
                    cellEndLocation: 0.9,
                    minorGridEnabled: true,
                    minGridDistance: 20,
                })
            })
        );
        yAxis.data.setAll(data);

        yAxis.get("renderer").labels.template.setAll({
            centerX: am5.p0
        });

        let xAxis = chart.xAxes.push(
            am5xy.ValueAxis.new(root, {
                renderer: am5xy.AxisRendererX.new(root, {
                    minGridDistance: 60,
                    strokeOpacity: 0.1,
                })
            })
        );

        // Add series
        function createSeries(field, labelCenterX, pointerOrientation, rangeValue) {
            let tooltip = am5.Tooltip.new(root, {
                pointerOrientation: pointerOrientation,
                labelText: "{categoryY}: {valueX}"
            });
            tooltip.label.setAll({
                centerX: am5.p0
            });

            let series = chart.series.push(
                am5xy.ColumnSeries.new(root, {
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueXField: field,
                    categoryYField: "category",
                    sequencedInterpolation: true,
                    clustered: false,
                    tooltip: tooltip
                })
            );

            series.columns.template.setAll({
                height: am5.p100,
                strokeOpacity: 0,
                fillOpacity: 0.8,
                cornerRadiusTR: 5,
                cornerRadiusBR: 5
            });

            series.bullets.push(function () {
                return am5.Bullet.new(root, {
                    locationX: 1,
                    locationY: 0.5,
                    sprite: am5.Label.new(root, {
                        centerY: am5.p50,
                        text: "{valueX}",
                        populateText: true,
                        centerX: labelCenterX,
                    })
                });
            });

            series.data.setAll(data);
            series.appear();

            let rangeDataItem = xAxis.makeDataItem({
                value: rangeValue
            });
            xAxis.createAxisRange(rangeDataItem);
            rangeDataItem.get("grid").setAll({
                strokeOpacity: 1,
                stroke: series.get("stroke")
            });

            if (data.find(item => item.disabled < 0)) {
                let label = rangeDataItem.get("label");
                label.setAll({
                    text: intl.formatMessage({id: `charts.users.${field.toLowerCase()}`, defaultMessage: field}),
                    fontSize: "1.1em",
                    fill: series.get("stroke"),
                    paddingTop: -30,
                    isMeasured: false,
                    // centerX: labelCenterX,
                    centerX: am5.p0,
                });
                label.adapters.add("dy", () => -chart.plotContainer.height());
            }

            return series;
        }

        createSeries("disabled", am5.p100, "left", -3);
        createSeries("enabled", 0, "right", 4);

        // Add cursor
        let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {behavior: "zoomY"}));
        cursor.lineY.set("forceHidden", true);
        cursor.lineX.set("forceHidden", true);

        // Make stuff animate on load
        chart.appear(1000, 100);


        return () => {
            root.dispose();
        };
    }, [isLoading, context.isRTL]);

    if (!hasPermit) return <></>

    return (<Box
        bg={"gray.100"}
        minHeight="3rem"
        my={2}
        p={3}
        py={5}
        rounded="lg"
        dir='ltr'
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        {...rest}
    >
        {isLoading && <Spinner color="green"/>}
        {!isLoading && <Text
            style={{fontSize: 25, fontFamily: "IRANSans", textAlign: 'center', fontWeight: 500}}
            dir={context.isRTL ? 'rtl' : 'ltr'}
        >
            <FormattedMessage id="charts.users.countsStackedTitle"/>
        </Text>}
        {!isLoading && <div id="users-stacked-negative" style={{
            width: "100%",
            height: "350px",
            fontFamily: 'IRANSans, sans-serif'
        }}></div>}
    </Box>);
};
