import {Box, Card, CardBody, Divider, GridItem, Image, Text, Tooltip} from "@chakra-ui/react";
import {CopyIcon, TimeIcon} from '@chakra-ui/icons';
import {useNavigate} from "react-router-dom";
import {dtToLocaleString, gregorianJalaliDTConversion} from "../../utils/helpers";
import {useAuth} from "../../hooks/useAuth";
import {useIntl} from "react-intl";

const Post = ({image, posts_count, created_at, refr, id, showBottomBox = true, ...rest}) => {
    const intl = useIntl();
    const {hasPermission} = useAuth();
    const navigate = useNavigate();

    return (
        <GridItem>
            {/*<Card maxW='25vw' mb={10} ref={refr}>*/}
            <Card mb={10} ref={refr}
                  onClick={() => hasPermission('raqib3', 'fe_view_cluster') ? navigate(`/raqib3/${id}`) : null}
                  cursor={'pointer'}>
                <CardBody textAlign={'start'} p={0}>
                    <Box style={{display: 'relative'}}>
                        {image && <Image src={image} w={'100%'}/>}
                    </Box>
                    {showBottomBox && <Box className="bottom-text-box">
                        <Box backgroundColor={"gray.800"} color={'white'} p={5}>

                            <Text>
                                <CopyIcon me={3}/>
                                {intl.formatMessage({
                                    id: 'raqib3.repeatedInPosts',
                                    defaultMessage: 'Repeated in posts'
                                })}: {posts_count}
                            </Text>
                            <Tooltip className="tooltips" label={gregorianJalaliDTConversion(created_at)} aria-label='datetime-conversion'>
                                <Text>
                                    <TimeIcon me={3}/>
                                    {dtToLocaleString(created_at)}
                                </Text>
                            </Tooltip>

                        </Box>
                    </Box>}

                </CardBody>
                <Divider/>
            </Card>
        </GridItem>
    );
};

export default Post;