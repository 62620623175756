import {Box, Button, ButtonGroup, Flex, IconButton, Input, Text, useColorModeValue} from "@chakra-ui/react";
import {FaTh, FaThList} from "react-icons/fa";
import {FormattedMessage, useIntl} from "react-intl";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {CloseIcon} from '@chakra-ui/icons'
import {dtToLocaleString} from '../../utils/helpers';
import {configs} from "../../Configs";
import {useContext} from 'react';
import {LocaleContext} from "../Theme/LocaleProvider";

export const NavBar = ({
                           resultsCount,
                           search,
                           setSearch,
                           since,
                           setSince,
                           until,
                           setUntil,
                           gridView,
                           setGridView,
                           onSubmit,
                           ...rest
                       }) => {
    const intl = useIntl();
    const context = useContext(LocaleContext);
    const bgColor = useColorModeValue("gray.300", "gray.600");

    const formatDatetime = (date) => {
        if (!date) {
            return '';
        }
        return dtToLocaleString(date, undefined, configs.time_locale)
    }

    const getLastXHours = (x) => {
        const result = new Date();
        result.setHours((new Date()).getHours() - x);
        return result
    }

    const getLastXDays = (x, zeroTime = false) => {
        const result = new Date();
        result.setDate(result.getDate() - x)

        // clear time to 00:00:00
        if (zeroTime) {
            result.setHours(0, 0, 0, 0);
        }

        return result;
    }

    return (
        <Box p={5}>
            <Flex
                as="nav"
                align="center"
                justify="center"
                direction={{base: 'column', md: 'row'}}
            >
                <Flex width={{base: '100%', md: '3xl'}} direction={{base: "column", md: "row"}}>
                    <Box px={1} mt={6}>
                        <Input
                            type="text"
                            placeholder={intl.formatMessage({id: 'raqib7.search'})}
                            name={'search'}
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            background={bgColor}
                            variant="filled"
                            size="lg"
                        />
                    </Box>

                    <Box px={1} mt={6} dir={'ltr'}>
                        <DatePicker
                            selected={since}
                            onChange={(date) => setSince(date)}
                            placeholderText={intl.formatMessage({
                                id: 'raqib7.since',
                                defaultMessage: 'Since'
                            })}
                            showTimeSelect
                            portalId="date-picker-portal"
                            customInput={
                                // Custom input component
                                <Flex>
                                    <Input
                                        value={since ? formatDatetime(since) : ''}
                                        placeholder={
                                            intl.formatMessage({
                                                id: 'raqib7.since',
                                                defaultMessage: 'Since'
                                            })
                                        }
                                        background={useColorModeValue("gray.300", "gray.600")}
                                        type="text"
                                        variant="filled"
                                        size="lg"
                                        borderRightRadius={0}
                                        onChange={() => {
                                        }} // Add a no-op onChange handler to prevent warnings
                                    />
                                    <IconButton h={12} borderLeftRadius={0} aria-label="clear"
                                                icon={<CloseIcon/>} onClick={() => setSince(null)}/>
                                </Flex>
                            }
                        >
                            <Button
                                size={'xs'}
                                mr={1}
                                mb={1}
                                onClick={() => setSince(getLastXDays(0, true))}
                            >
                                Today
                            </Button>
                            <Button
                                size={'xs'}
                                mr={1}
                                mb={1}
                                onClick={() => setSince(getLastXHours(6))}
                            >
                                Last 6 Hours
                            </Button>
                            <Button
                                size={'xs'}
                                mr={1}
                                mb={1}
                                onClick={() => setSince(getLastXHours(12))}
                            >
                                Last 12 Hours
                            </Button>
                            <Button
                                size={'xs'}
                                mr={1}
                                mb={1}
                                onClick={() => setSince(getLastXHours(24))}
                            >
                                Last 24 Hours
                            </Button>
                            <Button
                                size={'xs'}
                                mr={1}
                                mb={1}
                                onClick={() => setSince(getLastXDays(1, true))}
                            >
                                Yesterday
                            </Button>
                            <Button
                                size={'xs'}
                                mr={1}
                                mb={1}
                                onClick={() => setSince(getLastXDays(7))}
                            >
                                Last 7 Days
                            </Button>
                        </DatePicker>
                    </Box>
                    <Box px={1} mt={6} dir={'ltr'}>
                        <DatePicker
                            selected={until}
                            onChange={(date) => setUntil(date)}
                            placeholderText={intl.formatMessage({
                                id: 'raqib7.until',
                                defaultMessage: 'Until'
                            })}
                            showTimeSelect
                            portalId="date-picker-portal"
                            customInput={
                                // Custom input component
                                <Flex>
                                    <Input
                                        value={until ? formatDatetime(until) : ''}
                                        placeholder={intl.formatMessage({
                                            id: 'raqib7.until',
                                            defaultMessage: 'Until'
                                        })}
                                        background={useColorModeValue("gray.300", "gray.600")}
                                        type="text"
                                        variant="filled"
                                        size="lg"
                                        borderRightRadius={0}
                                        onChange={() => {
                                        }} // Add a no-op onChange handler to prevent warnings
                                    />
                                    <IconButton h={12} borderLeftRadius={0} aria-label="clear until"
                                                icon={<CloseIcon/>} onClick={() => setUntil(null)}/>
                                </Flex>
                            }
                        >
                            <Button
                                size={'xs'}
                                mr={1}
                                mb={1}
                                onClick={() => setUntil(getLastXDays(0, true))}
                            >
                                Today
                            </Button>
                            <Button
                                size={'xs'}
                                mr={1}
                                mb={1}
                                onClick={() => setUntil(getLastXHours(6))}
                            >
                                Last 6 Hours
                            </Button>
                            <Button
                                size={'xs'}
                                mr={1}
                                mb={1}
                                onClick={() => setUntil(getLastXHours(12))}
                            >
                                Last 12 Hours
                            </Button>
                            <Button
                                size={'xs'}
                                mr={1}
                                mb={1}
                                onClick={() => setUntil(getLastXHours(24))}
                            >
                                Last 24 Hours
                            </Button>
                            <Button
                                size={'xs'}
                                mr={1}
                                mb={1}
                                onClick={() => setUntil(getLastXDays(1, true))}
                            >
                                Yesterday
                            </Button>
                            <Button
                                size={'xs'}
                                mr={1}
                                mb={1}
                                onClick={() => setUntil(getLastXDays(7))}
                            >
                                Last 7 Days
                            </Button>
                        </DatePicker>
                    </Box>
                </Flex>
                <Box px={1} w={{base: '100%', md: 'auto'}}>
                    <Button w={{base: '100%', md: 'auto'}} mt={6}
                            ms={{base: 0, md: 5}}
                            onClick={() => onSubmit()}
                            colorScheme={'green'} size={"lg"}
                    >
                        <FormattedMessage
                            id="raqib7.filter"
                            defaultMessage="Filter"
                        />
                    </Button>
                </Box>
                <ButtonGroup ms={1} mt={6} size='lg' isAttached>
                    <IconButton onClick={() => setGridView(true)} aria-label='Grid'
                                icon={<FaTh/>} {...(gridView ? {colorScheme: "facebook"} : {})}/>
                    <IconButton onClick={() => setGridView(false)} aria-label='Row'
                                icon={<FaThList/>} {...(!gridView ? {colorScheme: "facebook"} : {})}/>
                </ButtonGroup>
            </Flex>

            {!!resultsCount && <Box w={'100%'} textAlign='center' mt={6}>
                <Text color={'#767070'}>
                    <FormattedMessage id={'responses.resultCounts'}
                                      values={{count: resultsCount.toLocaleString(context.locale)}}/>
                </Text>
            </Box>}

        </Box>
    );
};
