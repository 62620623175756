import {Button, Center, Container, Spinner, Text, useColorModeValue, useToast,} from "@chakra-ui/react";
import {configs} from '../../Configs';
import {useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {AddUpdateResourceModal} from "./AddUpdateResourceModal";
import {useAuth} from "../../hooks/useAuth";
import useAxios from "../../hooks/useAxois";
import {FormattedMessage, useIntl} from 'react-intl';
import {ResourceActivityAreaChart} from "../Charts/ResourceActivityAreaChart";

export const ResourceDetail = () => {
    const intl = useIntl();
    const axiosInstance = useAxios();
    const {hasPermission} = useAuth();
    const [resource, setResource] = useState({});
    const [loading, setLoading] = useState(true);
    const isMounted = useRef(false);
    const {resourceId} = useParams();
    const navigate = useNavigate();
    const toast = useToast();
    const background = useColorModeValue("gray.300", "gray.600");

    useEffect(() => {
        if (isMounted.current) return;
        fetchResource();
        isMounted.current = true;
    }, [resourceId]);

    const fetchResource = () => {
        setLoading(true);
        axiosInstance
            .get(`/resource/${resourceId}`)
            .then((res) => {
                setResource(res.data);
            })
            .catch((error) => console.error(error))
            .finally(() => {
                setLoading(false);
            });
    };

    const delateResource = () => {
        setLoading(true);
        axiosInstance
            .delete(`/resource/${resourceId}`)
            .then(() => {
                toast({
                    title: intl.formatMessage({id: 'resources.deleted', defaultMessage: "Resource Deleted"}),
                    status: "success",
                    isClosable: true,
                    diration: 1500,
                });
                navigate("/resources");
            })
            .catch((err) => {
                console.error(err);
                toast({
                    title: intl.formatMessage({
                        id: 'resources.notDeleted',
                        defaultMessage: "Could not delete resource"
                    }),
                    status: "error",
                    isClosable: true,
                    diration: 2000,
                });
            })
            .finally(() => setLoading(false));
    };

    if (loading) {
        return (
            <Container mt={6}>
                <Center mt={6}>
                    <Spinner
                        thickness="4px"
                        speed="0.65s"
                        emptyColor="green.200"
                        color="green.500"
                        size="xl"
                    />
                </Center>
            </Container>
        );
    }

    return (
        <>
            <Container mt={6}>
                <Button
                    colorScheme="gray"
                    onClick={() => navigate("/resources", {replace: true})}
                >
                    <FormattedMessage
                        id="navigations.back"
                        defaultMessage="Back"
                    />
                </Button>
            </Container>
            <Container
                bg={background}
                minHeight="7rem"
                mt={3}
                p={3}
                rounded="lg"
                alignItems="center"
                justifyContent="space-between"
            >
                <ResourceActivityAreaChart resourceId={resourceId}/>
                <Text fontSize={22}>{resource.title}</Text>
                <Text fontSize={22}>{resource.target}</Text>
                {resource.description && <Text bg="gray.400" mt={2} p={2} rounded="lg">
                    {resource.description}
                </Text>}
                {hasPermission('resource', 'fe_edit') && <AddUpdateResourceModal
                    mt={3}
                    editable={true}
                    defaultValues={{
                        title: resource.title,
                        description: resource.description,
                        target: resource.target,
                        timezone: resource.timezone,
                        source: resource.source,
                        rss_platform: resource.rss_platform,
                        source_id: resource.source_id,
                        origin_url: resource.origin_url,
                        factions: resource.factions?.map((faction) => {
                                return {label: faction.title, value: faction.tag_id}
                            }
                        ),
                        last_processed_at: resource.last_processed_at,
                        updated_at: resource.updated_at,
                        created_at: resource.created_at,
                        projects: resource.projects?.map((project) => {
                                const projects = configs.projects.filter((proj) => (proj.value == project));
                                if (projects.length) return projects[0];
                            }
                        ),
                        location: resource.location,
                        is_hashtag: resource.is_hashtag,
                        status: resource.status,
                    }}
                    onSuccess={fetchResource}
                />}

                {hasPermission('resource', 'fe_delete') && <Button
                    mt={3}
                    isLoading={loading}
                    colorScheme="red"
                    width="100%"
                    onClick={delateResource}
                >
                    <FormattedMessage
                        id="resources.delete"
                        defaultMessage="Delete Resource"
                    />
                </Button>}
            </Container>
        </>
    );
};
