import {Box, Heading, Spinner, Text} from '@chakra-ui/react';
import {useCallback, useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {setTextDirection} from '../../utils/helpers';
import {NewsRowItem} from './NewsRowItem';
import useAxios from "../../hooks/useAxois";
import {FormattedMessage} from "react-intl";
import axios from "axios";

const SingleTagArticles = () => {
    const axiosInstance = useAxios();
    const {tagId} = useParams();
    const [tagData, setTagData] = useState(null);
    const [data, setData] = useState([]);
    const [paginationInfo, setPaginationInfo] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(false);
    const [tagError, setTagError] = useState(false);
    const [curPage, setCurPage] = useState(1);

    const tagTitleRef = useRef(null);

    const [lastElmId, setLastElmId] = useState(null);
    const [pageId, setPageId] = useState(null);
    const [nextPageId, setNextPageId] = useState(null);
    const [forceRefresh, setForceRefresh] = useState(false);
    const observer = useRef();

    useEffect(() => {
        if (!tagData) return;
        setTextDirection(tagTitleRef.current, tagData.title, 'justify');
    }, [tagData]);

    useEffect(() => {
        axiosInstance.get(`/post_tag/${tagId}`).then((data) => {
            setTagData(data.data);
            setTagError(false);
        }).catch((error) => {
            if (error.response && error.response.status === 404) return;
            setTagError(true);
            console.error(error);
        }).finally(() => setIsLoading(false));
    }, []);


    useEffect(() => {
        setLastElmId(null);
        setIsLoading(true);
        setError(false);
        let cancel;

        // handle pagination
        let payload = {
            cancelToken: new axios.CancelToken(c => cancel = c),
            params: {tag_ids: tagId, before: pageId}
        }

        axiosInstance.get('/news', payload).then((res) => setData(prev => {
            const {news} = res.data;
            const theData = [...prev, ...news];

            // uniqify data combination
            let itteratedKeys = [];
            let newData = [];
            theData.map(newsItem => {
                if (!itteratedKeys.includes(newsItem.id)) {
                    itteratedKeys.push(newsItem.id);
                    newData.push(newsItem);
                }
            });

            // the nextPageId is determined by least sort (desc)
            let sortedData = theData;
            sortedData.sort((a, b) => parseFloat(a.sort) - parseFloat(b.sort));
            setLastElmId(sortedData.length ? sortedData[sortedData.length - 1].id : null);
            setNextPageId(sortedData.length ? sortedData[sortedData.length - 1].published_at : null)
            setIsLoading(false);
            setError(false);

            return newData;
        })).catch((error) => {
            if (axios.isCancel(error)) return;
            setIsLoading(false);
            if (error.response && error.response.status === 404) return;
            setError(true);
            console.error(error);
        });
        return () => cancel();
    }, [tagData, pageId, forceRefresh]);

    const lastNewsElmRef = useCallback(node => {
        if (isLoading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && nextPageId) {
                setPageId(nextPageId)
            }
        })
        if (node) observer.current.observe(node);
    }, [isLoading, nextPageId]);

    // if (!tagData && !isLoading && !tagError) {
    //     return <Navigate to="/error/404"/>
    // }

    if (!tagData && (isLoading || tagError)) {
        return <Box
            width={"100%"}
            textAlign={'center'}
            display="flex"
            alignItems="center"
            justifyContent="center"
        >
            {!isLoading && error && <Text>
                <FormattedMessage
                    id="responses.connectionFailed"
                    defaultMessage="Connection Failed"
                />
            </Text>}
            {isLoading && <Spinner mt={5} color="green"/>}
        </Box>
    }

    return (
        <Box
            p={5}
            pb={{base: 100, md: 50}}
            m={'auto'}
            w={{base: '100%', md: '80%', xl: '75%'}}
        >
            <Box p={5} borderBottomWidth={'1px'} borderBottomColor={'#e8e8e8'} mb={4}>
                <Heading fontFamily={"Cairo"} ref={tagTitleRef}>{tagData?.title}</Heading>
            </Box>

            {data.length > 0 && data.map(news => <NewsRowItem key={news.id}
                                                              refr={news.id === lastElmId ? lastNewsElmRef : undefined}
                                                              {...news} />)}

            <Box
                width={"100%"}
                textAlign={'center'}
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                {!data.length && !isLoading > 0 &&
                    <Text>
                        <FormattedMessage
                            id="responses.noData"
                            defaultMessage="No Data"
                        />
                    </Text>}
                {!isLoading > 0 && error &&
                    <Text>
                        <FormattedMessage
                            id="responses.connectionFailed"
                            defaultMessage="Connection Failed"
                        />
                    </Text>}
                {isLoading > 0 && <Spinner mt={5} color="green"/>}
            </Box>
        </Box>
    );
};

export default SingleTagArticles;
