import {Box, Spinner, Text} from "@chakra-ui/react";
import {useContext, useEffect, useLayoutEffect, useState} from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {FormattedMessage, useIntl} from "react-intl";
import {LocaleContext} from "../Theme/LocaleProvider";
import useAxios from "../../hooks/useAxois";
import {dtToLocaleString, gregorianJalaliDTConversion, parseDashboardChartsFilters} from "../../utils/helpers";
import {useAuth} from "../../hooks/useAuth";

export const PostsPlatformAreaChart = ({
                                           parentIsLoading,
                                           since,
                                           until,
                                           resource_ids,
                                           tag_ids,
                                           faction_ids,
                                           ...rest
                                       }) => {
    const axiosInstance = useAxios();
    const context = useContext(LocaleContext);
    const intl = useIntl();
    const {hasPermission} = useAuth();
    const hasPermit = hasPermission('charts:posts:rss-platforms-area', 'fe_rss_platforms_area');
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(hasPermit);
    const [error, setError] = useState(false);

    useEffect(() => {
        if (!hasPermit || parentIsLoading) return;
        setIsLoading(hasPermit);
        const url = parseDashboardChartsFilters("/charts/posts/rss-platforms-area?", tag_ids, faction_ids, resource_ids);
        axiosInstance.get(url, {params: {since: since, until: until}}).then((res) => {
            setData(res.data?.map(item => item?.map(subitem => ({
                ...subitem,
                date: (new Date(subitem.date)).getTime(),
                platform: intl.formatMessage({id: `charts.resources.${subitem.other}`})
            }))));
            setError(false);
        }).catch((error) => {
            if (error.response && error.response.status === 404) return;
            setError(true);
            console.error(error);
        }).finally(() => setIsLoading(false));
    }, [since, until, resource_ids, tag_ids, faction_ids]);

    useLayoutEffect(() => {
        if (isLoading || !hasPermit || parentIsLoading) return;

        const convertDt = (dtText, isLabels = false) => {
            if (!dtText) return dtText
            if (context.locale === 'fa') {
                const datetime = gregorianJalaliDTConversion(dtText, true)
                return isLabels ? `${datetime.day} ${datetime.monthName}` : `${datetime.year}-${datetime.month}-${datetime.day} | ${datetime.hours}:${datetime.minutes}`
            }

            return dtToLocaleString(dtText, isLabels ? {month: 'short', day: 'numeric'} : {
                day: 'numeric',
                month: 'short',
                year: 'numeric'
            })
        }

        // define root chart with custom theme based on rtl/ltr
        let root = am5.Root.new("posts-platform-area");
        const customTheme = am5.Theme.new(root);
        if (context.isRTL) {
            customTheme.rule("Label").setAll({
                fontFamily: "IRANSans",
                direction: 'rtl'
            });
        }
        root.setThemes([am5themes_Animated.new(root), customTheme]);

        // Create chart
        let chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                focusable: true,
                panX: true,
                panY: true,
                wheelX: "panX",
                wheelY: "zoomX",
                pinchZoomX: true,
                // paddingTop: 50
            })
        );

        // chart.children.unshift(am5.Label.new(root, {
        //     text: intl.formatMessage({id: 'charts.posts.postsPlatformsAreaTitle'}),
        //     fontSize: 25,
        //     fontWeight: "500",
        //     textAlign: "center",
        //     x: am5.percent(50),
        //     centerX: am5.percent(50),
        //     paddingTop: -50,
        //     paddingBottom: 0
        // }));

        let easing = am5.ease.linear;
        chart.get("colors").set("step", 3);

        const xRenderer = am5xy.AxisRendererX.new(root, {
            minGridDistance: 80,
            minorGridEnabled: true,
            minorLabelsEnabled: true
        });

        xRenderer.labels.template.setAll({
            rotation: -90,
            centerY: am5.p50,
            centerX: am5.p100,
            paddingRight: 15,
        });

        // Create axes
        let xAxis = chart.xAxes.push(
            am5xy.DateAxis.new(root, {
                maxDeviation: 100,
                groupData: false,
                baseInterval: {
                    timeUnit: "hour",
                    count: 1
                },
                markUnitChange: false,
                dateFormats: {
                    day: "YYYY-MM-ddTHH:mm:ss"
                },
                tooltipDateFormat: "YYYY-MM-ddTHH:mm:ss",
                renderer: xRenderer,
                tooltip: am5.Tooltip.new(root, {})
            })
        );

        // convert tooltips/chart label to jalali/gregorian and format date if needed
        xRenderer.labels.template.adapters.add('text', (text) => convertDt(text, true));
        xAxis.get("tooltip").label.adapters.add("text", (text) => convertDt(text));


        // Add cursor
        let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
            xAxis: xAxis,
            behavior: "none"
        }));

        // add horizontal scrollbar
        let scrollbarX = am5xy.XYChartScrollbar.new(root, {
            orientation: "horizontal",
            height: 50
        });
        chart.set("scrollbarX", scrollbarX);

        const sbxRenderer = am5xy.AxisRendererX.new(root, {
            opposite: context.isRTL,
            // strokeOpacity: 0,
            minorGridEnabled: true,
            minGridDistance: 60
        });
        let sbxAxis = scrollbarX.chart.xAxes.push(am5xy.DateAxis.new(root, {
            baseInterval: {timeUnit: "hour", count: 1},
            markUnitChange: false,
            dateFormats: {
                day: "YYYY-MM-ddTHH:mm:ss"
            },
            renderer: sbxRenderer
        }));

        // convert tooltips/chart label to jalali/gregorian and format date if needed on scrollbar
        sbxRenderer.labels.template.adapters.add('text', (text) => convertDt(text, true));

        function createAxisAndSeries(chart, xAxis, data, addBullets = false) {
            let yRenderer = am5xy.AxisRendererY.new(root, {
                opposite: !context.isRTL,
                pan: "zoom"
            });
            let yAxis = chart.yAxes.push(
                am5xy.ValueAxis.new(root, {
                    maxDeviation: 1,
                    extraTooltipPrecision: 1,
                    renderer: yRenderer
                })
            );

            if (chart.yAxes.indexOf(yAxis) > 0) {
                yAxis.set("syncWithAxis", chart.yAxes.getIndex(0));
            }

            // Add series
            let series = chart.series.push(
                am5xy.LineSeries.new(root, {
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueYField: "value",
                    valueXField: "date",
                    tooltip: am5.Tooltip.new(root, {
                        pointerOrientation: "horizontal",
                        labelText: "{platform}:{valueY}"
                    })
                })
            );

            // series.fills.template.setAll({ fillOpacity: 0.2, visible: true });
            series.strokes.template.setAll({strokeWidth: 1});

            yRenderer.grid.template.set("strokeOpacity", 0.05);
            yRenderer.labels.template.set("fill", series.get("fill"));
            yRenderer.setAll({
                stroke: series.get("fill"),
                strokeOpacity: 1,
                opacity: 1
            });

            if (addBullets) {
                // Actual bullet
                series.bullets.push(function () {
                    let bulletCircle = am5.Circle.new(root, {
                        strokeWidth: 2,
                        radius: 2,
                        stroke: series.get("stroke"),
                        // fill: series.get("fill")
                        fill: root.interfaceColors.get("background"),
                    });
                    return am5.Bullet.new(root, {
                        sprite: bulletCircle
                    })
                });
            }

            // Set up data processor to parse string dates
            series.data.processor = am5.DataProcessor.new(root, {
                dateFormat: "YYYY-MM-ddTHH:mm:ss",
                dateFields: ["date"]
            });

            series.data.setAll(data);
        }

        // make main chart
        data.map(dataItem => createAxisAndSeries(chart, xAxis, dataItem, true))

        // make scrollbar chart
        data.map(dataItem => createAxisAndSeries(scrollbarX.chart, sbxAxis, dataItem))

        // Make stuff animate on load
        chart.appear(1000, 100);


        return () => {
            root.dispose();
        };
    }, [isLoading, context.isRTL]);

    if (!hasPermit) return <></>

    return (<Box
        bg={"gray.100"}
        minHeight="3rem"
        my={2}
        p={3}
        py={5}
        rounded="lg"
        dir='ltr'
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        {...rest}
    >
        <Text mb={5} fontSize={25} fontWeight={500} dir={context.isRTL ? 'rtl' : 'ltr'}>
            <FormattedMessage id="charts.posts.postsPlatformsAreaTitle"/>
        </Text>

        {(isLoading || parentIsLoading) && <Spinner color="green"/>}

        {/*{!data.length && !isLoading && !parentIsLoading &&*/}
        {/*    <Text><FormattedMessage id="responses.noData"/></Text>}*/}

        {/*{!data.length && !isLoading && !parentIsLoading && error &&*/}
        {/*    <Text><FormattedMessage id="responses.connectionFailed"/></Text>}*/}

        {!isLoading && !parentIsLoading && <Box id="posts-platform-area" w="100%" h="500px"></Box>}
    </Box>);
};
