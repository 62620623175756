import {Box, Card, CardBody, CardHeader, Center, Flex, Heading, Image, Link, Text, Tooltip} from "@chakra-ui/react";
import {configs} from "../../Configs";
import {useEffect, useRef, useState} from "react";
import HTMLParser from "../../utils/HtmlParser";
import {dtToLocaleString, gregorianJalaliDTConversion, setTextDirection} from '../../utils/helpers';
import {useIntl} from "react-intl";

export const PostRow = ({
                            image = null,
                            title,
                            lead,
                            description,
                            published_at,
                            source,
                            link,
                            refr,
                            id,
                            tags,
                            resource,
                            ...rest
                        }) => {
    const intl = useIntl();
    const titleRef = useRef(null);
    const leadRef = useRef(null);
    const textRef = useRef(null);
    const [showMore, setShowMore] = useState(false);

    useEffect(() => {
        setTextDirection(titleRef.current, title, 'justify');
        if (lead){
            setTextDirection(leadRef.current, lead.replace(/<[^>]*>?/gm, ''),'justify');
        }
        setTextDirection(textRef.current, description.replace(/<[^>]*>?/gm, ''));
    }, [title,lead, description]);

    const thePlatform = configs.raqib5.rss_platforms.find(item => item.value === source);

    return (<Card ref={refr} mb={1} style={{overflow: 'hidden'}}
                  onMouseEnter={() => setShowMore(true)}
                  onMouseLeave={() => setShowMore(false)}
        >
            <Box textAlign="center" style={{backgroundColor: `rgb(${configs.raqib5.colors[source]})`}}
                 color={'white'}
                 borderTopRightRadius='lg'
                 borderTopLeftRadius='lg'
            >
                <Link href={resource.origin_url || link} isExternal>
                    [{resource.title}] - {intl.formatMessage({
                    id: "raqib5.on",
                    defaultMessage: "On"
                })} {intl.formatMessage({
                    id: `raqib5.${thePlatform.value}`,
                    defaultMessage: thePlatform.label
                })}
                </Link>
            </Box>
            <Flex>
                {image && showMore && <Box flex="1" alignItems={"center"}>
                    <Center h={'100%'}>
                        <Image
                            w={'100%'}
                            src={image}
                            alt={title}
                        />
                    </Center>
                </Box>}
                <Box flex={image && showMore ? 3 : 1}>
                    <CardHeader p={2}>
                        <Link href={link} isExternal>
                            <Heading size='sm' fontFamily={configs.posts_heading_font_family} ref={titleRef}>{title}</Heading>
                        </Link>
                    </CardHeader>
                    <CardBody textAlign={'left'} hidden={!showMore}>
                        {!!lead && <Text ref={leadRef} borderStartColor={'#ff6c00'} borderStartWidth={'3px'}
                                       bgColor={'#f7f7f7'}
                                       p={5} mb={6} fontFamily={configs.posts_body_font_family} whiteSpace={'pre-line'}>
                            {lead}
                        </Text>}
                        <Box ref={textRef} noOfLines={2} className={"accented-scrollbar"} overflowY={"scroll"}
                             fontFamily={configs.posts_body_font_family} whiteSpace={'pre-line'}>
                            <HTMLParser htmlContent={description}/>
                        </Box>
                    </CardBody>

                    {showMore &&
                        <Text textAlign={"right"} p={2} color='gray.500' fontSize='sm'>
                            <Tooltip label={gregorianJalaliDTConversion(published_at)} aria-label='datetime-conversion'>
                                {dtToLocaleString(published_at)}
                            </Tooltip>
                        </Text>}
                </Box>
            </Flex>
        </Card>
    );
}