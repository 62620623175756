import {Box, chakra, IconButton, Link, Td, Text, Tooltip, Tr} from '@chakra-ui/react';
import {DeleteIcon, EditIcon, ViewIcon} from "@chakra-ui/icons";

import {dtToLocaleString, gregorianJalaliDTConversion, setTextDirection} from '../../utils/helpers';
import {useEffect, useRef} from "react";
import {useAuth} from "../../hooks/useAuth";

const NewsArticleTableRow = ({row_id, id, title, onDelete, refr,published_at, stripped = false}) => {

    const {hasPermission} = useAuth();
    const titleRef = useRef(null);

    useEffect(() => {
        setTextDirection(titleRef.current, title);
    }, [title]);

    const CustomLink = chakra(Link, {
        baseStyle: {
            color: "black",
            _hover: {
                color: "blue",
            },
        },
    });

    return (
        <Tr fontSize={'sm'} bgColor={stripped ? '#f2f2f2' : 'white'} ref={refr}>
            <Td p={3}>
                <Box maxW="sm" isTruncated>{row_id}</Box>
            </Td>
            <Td ref={titleRef} p={3}>
                <Box noOfLines={3} lineHeight={2}>
                    {hasPermission('news', 'fe_view') ?
                        <CustomLink href={`/news_articles/${id}`}>{title}</CustomLink> : title}
                </Box>
            </Td>
            <Td p={3}>
                <Tooltip label={published_at ? gregorianJalaliDTConversion(published_at) : '--'}
                         aria-label='datetime-conversion'>
                    <Text fontSize='sm'>{published_at ? dtToLocaleString(published_at) : '--'}</Text>
                </Tooltip>
            </Td>
            <Td p={3}>
                {hasPermission('news', 'fe_view') && <IconButton
                    size="xs"
                    as={Link}
                    variant={'outline'}
                    colorScheme={'blue'}
                    borderRadius={0}
                    href={`/articles/${id}`}
                    icon={<ViewIcon/>}
                />}
                {hasPermission('news', 'fe_edit') && <IconButton
                    size="xs"
                    ms={1}
                    as={Link}
                    variant={'outline'}
                    colorScheme={'green'}
                    borderRadius={0}
                    href={`/news_articles/${id}`}
                    icon={<EditIcon/>}
                />}
                {hasPermission('news', 'fe_delete') && <IconButton
                    ms={1}
                    size="xs"
                    variant={'outline'}
                    colorScheme={'red'}
                    borderRadius={0}
                    onClick={() => onDelete(id)}
                    icon={<DeleteIcon/>}
                />}
            </Td>
        </Tr>
    );
};

export default NewsArticleTableRow;
