import {Box, Spinner, Text} from "@chakra-ui/react";
import {FormattedMessage, useIntl} from "react-intl";
import {useContext, useEffect, useLayoutEffect, useState} from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5hierarchy from "@amcharts/amcharts5/hierarchy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {LocaleContext} from "../Theme/LocaleProvider";
import useAxios from "../../hooks/useAxois";
import {useAuth} from "../../hooks/useAuth";
import {parseDashboardChartsFilters} from "../../utils/helpers";

export const PostsByTopicSunburstChart = ({
                                              parentIsLoading,
                                              since,
                                              until,
                                              resource_ids,
                                              tag_ids,
                                              faction_ids,
                                              ...rest
                                          }) => {
    const axiosInstance = useAxios();
    const context = useContext(LocaleContext);
    const intl = useIntl();
    const {hasPermission} = useAuth();
    const hasPermit = hasPermission('charts:posts:topics-sunburst', 'fe_topics_sunburst');
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(hasPermit);
    const [error, setError] = useState(false);

    useEffect(() => {
        if (!hasPermit || parentIsLoading) return;
        setIsLoading(hasPermit);
        const url = parseDashboardChartsFilters("/charts/posts/topics-sunburst?", tag_ids, faction_ids, resource_ids);
        axiosInstance.get(url, {params: {since: since, until: until}}).then((res) => {
            setData(res.data.map(item => ({
                category: item.category,
                ...(item?.children?.length > 0 ? {} : {value: item.value}),
                children: item?.children?.map(subItem => ({
                    ...subItem,
                    category: intl.formatMessage({id: `charts.resources.${subItem.category}`})
                }))
            })));
            setError(false);
        }).catch((error) => {
            if (error.response && error.response.status === 404) return;
            setError(true);
            console.error(error);
        }).finally(() => setIsLoading(false));
    }, [since, until, resource_ids, tag_ids, faction_ids]);

    useLayoutEffect(() => {
        if (isLoading || !hasPermit || parentIsLoading) return;

        // define root chart with custom theme based on rtl/ltr
        let root = am5.Root.new("posts-by-topic-sunburst");
        const customTheme = am5.Theme.new(root);
        if (context.isRTL) {
            customTheme.rule("Label").setAll({
                fontFamily: "IRANSans",
                direction: 'rtl'
            });
        }
        root.setThemes([am5themes_Animated.new(root), customTheme]);

        // Create wrapper container
        let container = root.container.children.push(am5.Container.new(root, {
            width: am5.percent(100),
            height: am5.percent(100),
            layout: root.verticalLayout
        }));

        // container.children.unshift(am5.Label.new(root, {
        //     text: intl.formatMessage({id: 'charts.posts.postsByTopicSunburstTitle'}),
        //     fontSize: 25,
        //     fontWeight: "500",
        //     textAlign: "center",
        //     x: am5.percent(50),
        //     centerX: am5.percent(50),
        //     paddingTop: 0,
        //     paddingBottom: 10
        // }));

        // Create series
        let series = container.children.push(am5hierarchy.Sunburst.new(root, {
            singleBranchOnly: true,
            downDepth: 10,
            initialDepth: 10,
            valueField: "value",
            categoryField: "category",
            childDataField: "children",
        }));

        series.nodes.template.set("tooltipText", "{category}: {sum}");
        series.data.setAll([{category: intl.formatMessage({id: 'charts.all'}), children: data}]);
        series.set("selectedDataItem", series.dataItems[0]);

        // Make stuff animate on load
        series.appear(1000, 100);

        return () => {
            root.dispose();
        };
    }, [isLoading, context.isRTL]);

    if (!hasPermit) return <></>

    return (<Box
        bg={"gray.100"}
        minHeight="3rem"
        my={2}
        p={3}
        py={5}
        rounded="lg"
        dir='ltr'
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        {...rest}
    >
        <Text mb={5} fontSize={25} fontWeight={500} dir={context.isRTL ? 'rtl' : 'ltr'}>
            <FormattedMessage id="charts.posts.postsByTopicSunburstTitle"/>
        </Text>

        {(isLoading || parentIsLoading) && <Spinner color="green"/>}

        {/*{!data.length && !isLoading && !parentIsLoading &&*/}
        {/*    <Text><FormattedMessage id="responses.noData"/></Text>}*/}

        {/*{!data.length && !isLoading && !parentIsLoading && error &&*/}
        {/*    <Text><FormattedMessage id="responses.connectionFailed"/></Text>}*/}

        {!isLoading && !parentIsLoading && <Box id="posts-by-topic-sunburst" w="100%" h="400px"></Box>}
    </Box>);
};
