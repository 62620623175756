import {Box, Flex, Heading, Image, Text} from "@chakra-ui/react";
import {dtToLocaleString, setTextDirection} from '../../utils/helpers';
import {useEffect, useRef} from "react";
import {TimeIcon} from '@chakra-ui/icons';

export const NewsRowItem = ({id, title, lead, image, published_at, refr, ...rest}) => {
    const titleRef = useRef(null);
    const leadRef = useRef(null);

    useEffect(() => {
        setTextDirection(titleRef.current, title, 'justify');
        setTextDirection(leadRef.current, lead, 'justify');
    }, [title, lead]);

    return (
        <Flex
            ref={refr}
            wrap="wrap"
            color={'#202020'}
            bgColor={'#f5f5f5'}
            fontWeight={500}
            mb={8}
            _hover={{bgColor: '#e2e2e2', cursor: "pointer"}}
            onClick={() => window.open(`/articles/${id}`, '_blank')}
        >
            <Box w={{base: '100%', md: '30%', lg: '20%'}} textAlign={'center'} p={5}>
                <Box
                    p={1}
                    w={"auto"}
                    display={'inline-block'}
                    borderWidth={'1px'}
                    borderRadius={'2px'}
                    borderColor={'#e8e8e8'}
                    maxW={'80%'}
                    maxH={'252px'}
                >
                    <Image
                        src={image}
                        alt={title}
                    />
                </Box>
            </Box>
            <Box w={['100%', '70%', '80%']} p={5} color={'#6c6c6c'}>
                <Box mb={4}>
                    <Heading noOfLines={3} fontSize={'2xl'} fontFamily={"Cairo"} ref={titleRef}>{title}</Heading>
                </Box>
                <Box ref={leadRef} noOfLines={3} className={"accented-scrollbar"}
                     overflowY={"scroll"}
                     fontFamily={"Cairo, sans-serif"} whiteSpace={'pre-line'} mb={4}>
                    <Text>{lead}</Text>
                </Box>
                <Box fontSize={'sm'} color={'#6c6c6c'}>
                    <Text borderBottomWidth={'1px'} borderBottomColor={'#ddd'} display={'inline-block'}>
                        <TimeIcon me={2} fontSize={'sm'}/>
                        {dtToLocaleString(published_at)}
                    </Text>
                </Box>
            </Box>
        </Flex>
    );
};
