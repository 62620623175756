import pa_config_data from "./Configs.pa";
import ac_config_data from "./Configs.ac";
import af_config_data from "./Configs.af";
import az_config_data from "./Configs.az";
import bh_config_data from "./Configs.bh";
import ea_config_data from "./Configs.ea";
import iq_config_data from "./Configs.iq";
import lb_config_data from "./Configs.lb";
import ru_config_data from "./Configs.ru";
import sy_config_data from "./Configs.sy";
import tr_config_data from "./Configs.tr";

let confs;

if (process.env.REACT_APP_RAQIB_LOC === 'af') {
    confs = {...af_config_data}
} else if (process.env.REACT_APP_RAQIB_LOC === 'ac') {
    confs = {...ac_config_data}
} else if (process.env.REACT_APP_RAQIB_LOC === 'pa') {
    confs = {...pa_config_data}
} else if (process.env.REACT_APP_RAQIB_LOC === 'bh') {
    confs = {...bh_config_data}
} else if (process.env.REACT_APP_RAQIB_LOC === 'az') {
    confs = {...az_config_data}
} else if (process.env.REACT_APP_RAQIB_LOC === 'ea') {
    confs = {...ea_config_data}
} else if (process.env.REACT_APP_RAQIB_LOC === 'iq') {
    confs = {...iq_config_data}
} else if (process.env.REACT_APP_RAQIB_LOC === 'lb') {
    confs = {...lb_config_data}
} else if (process.env.REACT_APP_RAQIB_LOC === 'ru') {
    confs = {...ru_config_data}
} else if (process.env.REACT_APP_RAQIB_LOC === 'sy') {
    confs = {...sy_config_data}
} else if (process.env.REACT_APP_RAQIB_LOC === 'tr') {
    confs = {...tr_config_data}
} else {
    console.log('====== please set RAQIB_LOC=<COUNTRY_CODE> environment variable first ======')
    console.log('====== eg. export RAQIB_LOC=iq ======')
    process.exit(1)
}

export const configs = {...confs};