import {Box, Spinner, Text} from "@chakra-ui/react";
import {FormattedMessage, useIntl} from "react-intl";

import {useContext, useEffect, useLayoutEffect, useState} from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {LocaleContext} from "../Theme/LocaleProvider";
import useAxios from "../../hooks/useAxois";
import {useAuth} from "../../hooks/useAuth";

export const ResourcesCountPieChart = ({...rest}) => {
    const axiosInstance = useAxios();
    const context = useContext(LocaleContext);
    const intl = useIntl();
    const {hasPermission} = useAuth();
    const hasPermit = hasPermission('charts:', 'fe_');
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(hasPermit);
    const [error, setError] = useState(false);

    useEffect(() => {
        if (!hasPermit) return;
        axiosInstance.get("/charts/resources/counts-pie").then((res) => {
            setData(res.data.map(item => ({
                category: intl.formatMessage({
                    id: `charts.resources.${item["category"]}`,
                    defaultMessage: item["category"]
                }),
                value: item["value"]
            })));
            setError(false);
        }).catch((error) => {
            if (error.response && error.response.status === 404) return;
            setError(true);
            console.error(error);
        }).finally(() => setIsLoading(false));
    }, []);

    useLayoutEffect(() => {
        if (isLoading || !hasPermit) return;

        // define root chart with custom theme based on rtl/ltr
        let root = am5.Root.new("resource-count-pie");
        const customTheme = am5.Theme.new(root);
        if (context.isRTL) {
            customTheme.rule("Label").setAll({
                fontFamily: "IRANSans",
                direction: 'rtl'
            });
        }
        root.setThemes([am5themes_Animated.new(root), customTheme]);

        // Create chart
        let chart = root.container.children.push(am5percent.PieChart.new(root, {
            layout: root.verticalLayout,
            endAngle: 270,
            // radius: am5.percent(90),
            // innerRadius: am5.percent(50),
            // layout: root.horizontalLayout
        }));

        // chart.children.unshift(am5.Label.new(root, {
        //     text: intl.formatMessage({id: 'charts.resources.countspieTitle'}),
        //     fontSize: 25,
        //     fontWeight: "500",
        //     textAlign: "center",
        //     x: am5.percent(50),
        //     centerX: am5.percent(50),
        //     paddingTop: 0,
        //     paddingBottom: 10
        // }));

        let tooltip = am5.Tooltip.new(root, {
            labelText: "{category}: {value}"
        });
        tooltip.label.setAll({centerX: am5.p0});

        let series = chart.series.push(am5percent.PieSeries.new(root, {
            name: "Series",
            valueField: "value",
            categoryField: "category",
            endAngle: 270,
            tooltip: tooltip
        }));
        series.data.setAll(data);

        // series.set("colors", am5.ColorSet.new(root, {
        //     colors: [
        //         am5.color(0x73556E),
        //         am5.color(0x9FA1A6),
        //         am5.color(0xF2AA6B),
        //         am5.color(0xF28F6B),
        //         am5.color(0xA95A52),
        //         am5.color(0xE35B5D),
        //         am5.color(0xFFA446)
        //     ]
        // }))

        var gradient = am5.RadialGradient.new(root, {
            stops: [
                {color: am5.color(0x000000)},
                {color: am5.color(0x000000)},
                {}
            ]
        });

        series.slices.template.setAll({
            // fillGradient: gradient,
            strokeWidth: 1,
            stroke: am5.color(0xffffff),
            cornerRadius: 10,
            shadowOpacity: 0.1,
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowColor: am5.color(0x000000),
            // fillPattern: am5.GrainPattern.new(root, {
            //     maxOpacity: 0.2,
            //     density: 0.5,
            //     colors: [am5.color(0x000000)]
            // })
        })

        series.slices.template.states.create("hover", {
            shadowOpacity: 1,
            shadowBlur: 10
        })

        series.ticks.template.setAll({
            strokeOpacity: 0.4,
            strokeDasharray: [2, 2]
        })

        series.states.create("hidden", {
            endAngle: -90
        });

        // enabling labels and ticks
        series.labels.template.set("visible", true);
        series.ticks.template.set("visible", true);

        // Create legend
        let legend = chart.children.push(am5.Legend.new(root, {
            centerY: am5.percent(50),
            // y: am5.percent(50),
            // layout: root.verticalLayout,
            x: am5.percent(10)
        }));

        // set value labels align to right
        // legend.valueLabels.template.setAll({
        //     // textAlign: context.isRTL ? 'left' : 'right',
        //     direction: context.isRTL ? "rtl" : "ltr",
        //     fontFamily: "IRANSans",
        //     oversizedBehavior: "wrap",
        // });
        legend.valueLabels.template.set("visible", false);

        // set width and max width of labels
        legend.labels.template.setAll({
            textAlign: 'left',
            maxWidth: 140,
            width: 140,
            oversizedBehavior: "wrap"
        });
        legend.data.setAll(series.dataItems);

        // Make stuff animate on load
        chart.appear(1000, 100);

        return () => {
            root.dispose();
        };
    }, [isLoading, context.isRTL]);

    if (!hasPermit) return <></>

    return (<Box
        bg={"gray.100"}
        minHeight="3rem"
        my={2}
        p={3}
        py={5}
        rounded="lg"
        dir='ltr'
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        {...rest}
    >
        {isLoading && <Spinner color="green"/>}
        {!isLoading && <Text
            style={{fontSize: 25, fontFamily: "IRANSans", textAlign: 'center', fontWeight: 500}}
            dir={context.isRTL ? 'rtl' : 'ltr'}
        >
            <FormattedMessage id="charts.resources.countspieTitle"/>
        </Text>}
        {!isLoading && <div id="resource-count-pie" style={{
            width: "100%",
            height: "400px",
            fontFamily: 'IRANSans, sans-serif'
        }}></div>}
    </Box>);
};
