import {
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    Heading,
    Input,
    useColorModeValue,
    useToast,
} from "@chakra-ui/react";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import useAxios from "../../hooks/useAxois";
import {FormattedMessage, useIntl} from 'react-intl';

export const Register = () => {
    const intl = useIntl();
    const axiosInstance = useAxios();
    const {
        handleSubmit,
        register,
        formState: {errors, isSubmitting},
    } = useForm();
    const navigate = useNavigate();
    const toast = useToast();

    const onSubmit = async (values) => {
        try {
            await axiosInstance.post("/users/create", values);
            toast({
                title: intl.formatMessage({
                    id: "auth.registered",
                    defaultMessage: "Account created successfully."
                }),
                status: "success",
                isClosable: true,
                duration: 1500,
            });
            navigate("/login", {replace: true});
        } catch (err) {
            toast({
                title: `${err.response.data.detail}`,
                status: "error",
                isCloseable: true,
                duration: 1500,
            });
        }
    };
    return (
        <Flex height="100vh" alignItems="center" justifyContent="center">
            <Flex
                direction="column"
                alignItems="center"
                background={useColorModeValue("gray.100", "gray.700")}
                p={12}
                rounded={6}
            >
                <img id="logo" width={192} src="/logo-2.png" alt="Raqib"/>
                <Heading mb={6}>
                    <FormattedMessage
                        id="auth.registerTitle"
                        description="Register"
                        defaultMessage="Register"
                    />
                </Heading>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormControl isInvalid={errors.username}>
                        <Input
                            placeholder={intl.formatMessage(
                                {
                                    id: "auth.username",
                                    defaultMessage: "Username"
                                }
                            )}
                            background={useColorModeValue("gray.300", "gray.600")}
                            type="text"
                            variant="filled"
                            size="lg"
                            mt={6}
                            {...register("username", {
                                required: intl.formatMessage(
                                    {
                                        id: "validation.fieldRequired",
                                        defaultMessage: "{field} is required"
                                    },
                                    {
                                        field: intl.formatMessage(
                                            {
                                                id: "auth.username",
                                                defaultMessage: "Username"
                                            }
                                        )
                                    }
                                ),
                                minLength: {
                                    value: 5,
                                    message: intl.formatMessage(
                                        {
                                            id: "validation.fieldMinLen",
                                            defaultMessage: "{field} must be at least {length} characters"
                                        },
                                        {
                                            field: intl.formatMessage({
                                                id: "auth.username",
                                                defaultMessage: "Username"
                                            }),
                                            length: 5
                                        }
                                    )
                                },
                                maxLength: {
                                    value: 24,
                                    message: intl.formatMessage(
                                        {
                                            id: "validation.fieldMaxLen",
                                            defaultMessage: "{field} must be at most {length} characters"
                                        },
                                        {
                                            field: intl.formatMessage({
                                                id: "auth.username",
                                                defaultMessage: "Username"
                                            }),
                                            length: 24
                                        }
                                    )
                                },
                            })}
                        />
                        <FormErrorMessage>
                            {errors.username && errors.username.message}
                        </FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={errors.username}>
                        <Input
                            placeholder={intl.formatMessage(
                                {
                                    id: "auth.password",
                                    defaultMessage: "Password"
                                }
                            )}
                            background={useColorModeValue("gray.300", "gray.600")}
                            type="password"
                            size="lg"
                            mt={6}
                            {...register("password", {
                                required: intl.formatMessage(
                                    {
                                        id: "validation.fieldRequired",
                                        defaultMessage: "{field} is required"
                                    },
                                    {
                                        field: intl.formatMessage(
                                            {
                                                id: "auth.password",
                                                defaultMessage: "Password"
                                            }
                                        )
                                    }
                                ),
                                minLength: {
                                    value: 5,
                                    message: intl.formatMessage(
                                        {
                                            id: "validation.fieldMinLen",
                                            defaultMessage: "{field} must be at least {length} characters"
                                        },
                                        {
                                            field: intl.formatMessage({
                                                id: "auth.password",
                                                defaultMessage: "Password"
                                            }),
                                            length: 5
                                        }
                                    )
                                },
                                maxLength: {
                                    value: 24,
                                    message: intl.formatMessage(
                                        {
                                            id: "validation.fieldMaxLen",
                                            defaultMessage: "{field} must be at least {length} characters"
                                        },
                                        {
                                            field: intl.formatMessage({
                                                id: "auth.password",
                                                defaultMessage: "Password"
                                            }),
                                            length: 24
                                        }
                                    )
                                },
                            })}
                        />
                        <FormErrorMessage>
                            {errors.password && errors.password.message}
                        </FormErrorMessage>
                    </FormControl>
                    <Button
                        isLoading={isSubmitting}
                        loadingText={
                            <FormattedMessage
                                id="auth.creatingAccount"
                                description="Creating Account"
                                defaultMessage="Creating account..."
                            />
                        }
                        width="100%"
                        colorScheme="green"
                        variant="outline"
                        mt={6}
                        mb={6}
                        type="submit"
                    >
                        <FormattedMessage
                            id="auth.registerButton2"
                            description="Register"
                            defaultMessage="Register"
                        />
                    </Button>
                </form>
                {/*<ThemeToggler showLabel={true} />*/}
                <Button
                    onClick={() => navigate("/login", {replace: true})}
                    width="100%"
                    colorScheme="gray"
                    variant="outline"
                    mt={6}
                >
                    <FormattedMessage
                        id="auth.loginButton2"
                        description="Login Instead"
                        defaultMessage="Login Instead"
                    />
                </Button>
            </Flex>
        </Flex>
    );
};
