import {Box, Spinner, Table, Tbody, Td, Text, Th, Thead, Tr} from "@chakra-ui/react";
import {useContext, useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from "react-intl";
import {LocaleContext} from "../Theme/LocaleProvider";
import useAxios from "../../hooks/useAxois";
import {UserLatestActivityRow} from "./UserLatestActivityRow";
import {useAuth} from "../../hooks/useAuth";
import {getClientData} from "../../utils/helpers";

export const UserLatestActivityTable = () => {
    const axiosInstance = useAxios();
    const context = useContext(LocaleContext);
    const intl = useIntl();
    const {hasPermission, user} = useAuth();
    const hasPermit = hasPermission('charts:users:latest-activities-table', 'fe_latest_activities_table');
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(hasPermit);
    const [error, setError] = useState(false);

    useEffect(() => {
        if (!hasPermit) return;
        axiosInstance.get("/charts/users/latest-activities-table").then((res) => {
            console.log(res.data)
            setData(res.data);
            setError(false);
        }).catch((error) => {
            if (error.response && error.response.status === 404) return;
            setError(true);
            console.error(error);
        }).finally(() => setIsLoading(false));
    }, []);

    return (
        <Box
            overflowX="auto"
            bg={"gray.100"}
            minHeight="3rem"
            my={2}
            p={3}
            py={5}
            rounded="lg"
            alignItems="center"
            justifyContent="center"
        >
            <Text
                mb={2}
                style={{fontSize: 25, fontFamily: "IRANSans", textAlign: 'center', fontWeight: 500}}
                dir={context.isRTL ? 'rtl' : 'ltr'}
            >
                <FormattedMessage id="charts.users.userLatestActivitiesTableTitle"/>
            </Text>

            <Table>
                <Thead>
                    <Tr>
                        <Th p={3}><FormattedMessage id="charts.log-table.id"/></Th>
                        <Th p={3} minW="100px"><FormattedMessage id="charts.log-table.username"/></Th>
                        <Th p={3} minW="100px"><FormattedMessage id="charts.log-table.client"/></Th>
                        <Th p={3} minW="150px"><FormattedMessage id="charts.log-table.device"/></Th>
                        <Th p={3} minW="100px"><FormattedMessage id="charts.log-table.OS"/></Th>
                        <Th p={3} minW="150px"><FormattedMessage id="charts.log-table.ip-location"/></Th>
                        <Th p={3} minW="100px"><FormattedMessage id="charts.log-table.status"/></Th>
                        <Th p={3} minW="200px"><FormattedMessage id="charts.log-table.created_at"/></Th>
                    </Tr>
                </Thead>
                <Tbody style={{fontFamily: "IRANSans, sans-serif"}}>

                    {data.length > 0 && data.map((record, idx) => {
                        return <UserLatestActivityRow
                            key={record.id}
                            row_id={idx + 1}
                            stripped={idx % 2 === 0}
                            {...record}/>
                    })}

                    {!data.length && !isLoading > 0 &&
                        <Tr><Td colSpan={7} textAlign={'center'}>
                            <Text>
                                <FormattedMessage
                                    id="responses.noData"
                                    defaultMessage="No Data"
                                />
                            </Text>
                        </Td></Tr>}
                    {!isLoading > 0 && error &&
                        <Tr><Td colSpan={7} textAlign={'center'}>
                            <Text>
                                <FormattedMessage
                                    id="responses.connectionFailed"
                                    defaultMessage="Connection Failed"
                                />
                            </Text>
                        </Td></Tr>}
                    {isLoading > 0 &&
                        <Tr><Td colSpan={7} textAlign={'center'}><Spinner mt={5} color="green"/></Td></Tr>}
                </Tbody>
            </Table>
        </Box>);
};
