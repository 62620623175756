import {Box, Center, Container, Spinner} from "@chakra-ui/react";
import {useEffect, useRef, useState} from "react";
import {AddUpdateTagsInfoModal} from "./AddUpdateTagsInfoModal";
import {TagsInfoCard} from "./TagsInfoCard";
import {useAuth} from "../../hooks/useAuth";
import useAxios from "../../hooks/useAxois";

export const TagsInfoList = () => {
    const axiosInstance = useAxios()
    const {hasPermission} = useAuth();
    const [tagInfos, setTagInfos] = useState([]);
    const [loading, setLoading] = useState(true);
    const isMounted = useRef(false);

    useEffect(() => {
        if (isMounted.current) return;
        fetchTagInfos();
        isMounted.current = true;
    }, []);

    const fetchTagInfos = () => {
        setLoading(true);
        axiosInstance
            .get("/trend_tags_info/")
            .then((res) => {
                setTagInfos(res.data);
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Container mt={9}>
            {hasPermission('trend_tags_info', 'fe_create') && <AddUpdateTagsInfoModal onSuccess={fetchTagInfos}/>}
            {loading ? (
                <Center mt={6}>
                    <Spinner
                        thickness="4px"
                        speed="0.65s"
                        emptyColor="green.200"
                        color="green.500"
                        size="xl"
                    />
                </Center>
            ) : (
                <Box mt={6}>
                    {tagInfos?.map((tagsInfo) => (<TagsInfoCard tagsInfo={tagsInfo} key={tagsInfo.tags_info_id}/>))}
                </Box>
            )}
        </Container>
    );
};
