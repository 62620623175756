import {Navigate, useSearchParams} from 'react-router-dom'
import {Raqib2PostsColumn} from './Raqib2PostsColumn';
import {Box, Flex} from '@chakra-ui/react';
import {useEffect, useState} from "react";
import {configs} from "../../Configs";
import useAxios from "../../hooks/useAxois";


export const Raqib2Posts = () => {
    const axiosInstance = useAxios();
    // essential data requirements
    const [shouldRedirect, setShouldRedirect] = useState(false);
    const [factions, setFactions] = useState([]);
    const [selectedFactions, setSelectedFactions] = useState([]);
    const [queryParams] = useSearchParams();


    useEffect(() => {
        let factionsList = [];
        for (const entry of queryParams.entries()) {
            factionsList.push(entry[1])
        }
        factionsList = factionsList.filter(Boolean);

        setShouldRedirect(factionsList.length <= 0)

        setSelectedFactions(factions.filter(faction => factionsList.includes(faction.value)));
    }, [factions]);

    useEffect(() => {
        axiosInstance.get('/raqib2_tag').then((data) => setFactions(data.data.map(item => (
            {
                value: item.tag_id,
                label: item.title
            }
        ))));
    }, []);

    if (shouldRedirect) return <Navigate to="/error/404"/>
    return (
        <>
            {/*<NavBar factions={selectedFactions}/>*/}
            <Box overflowX={{base: "scroll", md: 'hidden'}}>
                <Flex
                    className={'transparent-scrollbar'}
                    justify="space-between"
                    wrap="wrap"
                    padding={{md: '1rem'}}
                    overflowX={'scroll'}
                    minW={{
                        base: `calc(${95 * (selectedFactions.length + 1)}% + ${(selectedFactions.length + 1) * 15}px)`,
                        md: 'auto'
                    }}
                    maxW={{md: '100%'}}
                >
                    {selectedFactions.map(faction => {
                        return <Raqib2PostsColumn
                            key={'col:' + faction.value}
                            faction={faction}
                            factionsCount={selectedFactions.length}
                            shouldRefresh={configs.raqib2.should_refresh_default}
                            refreshInterval={configs.raqib2.refresh_interval_default}
                        />
                    })}
                </Flex>
            </Box>
        </>
    );
}
