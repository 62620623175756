import {
    Box,
    Button,
    Center,
    Flex,
    FormControl,
    FormErrorMessage,
    Heading,
    Input,
    Spinner,
    Textarea,
    useToast
} from '@chakra-ui/react';
import {useEffect, useState} from "react";
import {Navigate, useParams, useSearchParams} from "react-router-dom";
import {Controller, useForm} from "react-hook-form";
import ReactSelect from 'react-select';
import 'react-trumbowyg/dist/trumbowyg.min.css'
import Trumbowyg from 'react-trumbowyg'
import '../../../node_modules/trumbowyg/dist/plugins/fontfamily/trumbowyg.fontfamily';
import useAxios from "../../hooks/useAxois";
import {FormattedMessage, useIntl} from 'react-intl';


const PostEdit = () => {
    const intl = useIntl();
    const [data, setData] = useState(null);
    const toast = useToast();
    const {postId} = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const [staticTags, setStaticTags] = useState([]);
    const [enemyPostTags, setEnemyPostTags] = useState([]);
    const [postTags, setPostTags] = useState([]);
    const [newsCats, setNewsCats] = useState([]);
    const [isLoading, setIsLoading] = useState(1);
    const [loadingTags, setLoadingTags] = useState(true);
    const [loadingCats, setLoadingCats] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [description, setDescription] = useState(null);
    const axiosInstance = useAxios();

    useEffect(() => {
        setDescription(data?.description);
    }, [data]);

    useEffect(() => {
        // get enemy/post tags
        axiosInstance.get(searchParams.get('withEnemyTags') === null ? '/post_tag' : '/raqib7_post_tag').then((data) => {
            setStaticTags(data.data.filter(tag => tag.is_static).map(tag => tag.tag_id));
            setPostTags(data.data.map(tag => ({
                label: tag.title,
                value: tag.tag_id
            })))
        }).catch((error) => {
            if (error.response && error.response.status === 404) return;
            console.error(error);
        }).finally(() => setLoadingTags(false));

        // get news cats
        axiosInstance.get('/news_cat').then((data) => setNewsCats(data.data.map(cat => ({
            label: cat.title,
            value: cat.cat_id
        })))).catch((error) => {
            if (error.response && error.response.status === 404) return;
            console.error(error);
        }).finally(() => setLoadingCats(false));
    }, []);

    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    function removeFontFamilyInlineStyles(html) {
        return html;
        // return html.replace(/style="[^"]*"/g, '');
    }

    const {
        handleSubmit,
        register,
        control,
        setValue,
        setError,
        watch,
        formState: {errors, isSubmitting}
    } = useForm();

    useEffect(() => {
        if (!postId || loadingTags || loadingCats) return;

        // get editable post
        setHasError(false);
        axiosInstance.get(`/post/${postId}`).then((res) => {
            const curTagIds = res.data?.tags?.map(curTags => curTags.tag_id) || [];
            const curCatIds = res.data?.categories?.map(curCats => curCats.cat_id) || [];
            setValue('title', res.data.title);
            setValue('tags', postTags.filter(tag => curTagIds.includes(tag.value)));
            setValue('categories', newsCats.filter(cat => curCatIds.includes(cat.value)));
            setValue('lead', res.data.lead);
            setValue('description', res.data.description);
            setValue('link', res.data.link);
            setData(res.data);
            setHasError(false);
        }).catch((error) => {
            if (error.response && error.response.status === 404) return;
            setHasError(true);
            console.error(error);
        }).finally(() => setIsLoading(v => v - 1));
    }, [loadingTags, loadingCats]);

    const onSubmit = async (values) => {
        try {
            const payload = new FormData();

            payload.append('is_enemy_post', searchParams.get('withEnemyTags') !== null)

            if (values?.title) {
                payload.append('title', values.title);
            }

            if (values?.lead) {
                payload.append('lead', values.lead);
            }

            if (description) {
                payload.append('description', removeFontFamilyInlineStyles(description))
            }

            if (values.tags?.length > 0) {
                values.tags?.forEach((tag) => payload.append('tags', tag.value));
            } else {
                values.tags = []
            }

            if (values.tags?.filter(tag => staticTags.includes(tag.value)).length > 0)
                values.categories?.forEach((cat) => payload.append('categories', cat.value));
            else {
                values.categories = []
            }

            await axiosInstance.put(`/post/${postId}`, payload);

            toast({
                title: intl.formatMessage({id: 'posts_edit.updated'}),
                status: "success",
                isClosable: true,
                diration: 1500,
            });
        } catch (err) {
            let msg = err?.response?.data?.detail || [];
            if (msg && !Array.isArray(msg)) {
                msg = [{msg: msg}]
            }
            if (!msg.length) {
                msg = [{
                    msg:
                        intl.formatMessage({id: 'responses.unknownError'})
                }]
            }
            msg.map(err =>
                toast({
                    title: err.msg,
                    status: "error",
                    isClosable: true,
                    diration: 1500,
                })
            );
        }
    }

    const cstmMutliSelect = {
        width: '100%',
        minWidth: '0px',
        outline: '2px solid transparent',
        outlineOffset: '2px',
        position: 'relative',
        appearance: 'none',
        transitionProperty: 'var(--chakra-transition-property-common)',
        transitionDuration: 'var(--chakra-transition-duration-normal)',
        fontSize: 'var(--chakra-fontSizes-lg)',
        paddingInlineStart: 'var(--chakra-space-4)',
        paddingInlineEnd: 'var(--chakra-space-4)',
        minHeight: 'var(--chakra-sizes-12)',
        borderRadius: 'var(--chakra-radii-md)',
        border: '0px solid',
        borderColor: 'var(--chakra-colors-transparent)',
        background: 'var(--chakra-colors-gray-300)',
        "&:hover": {
            background: 'var(--chakra-colors-gray-200)',
        },
        "&:focus-visible": {
            background: 'var(--chakra-colors-transparent)',
            borderColor: '#63b3ed'
        }
    }

    const cstmMutliSelectMenu = {
        background: "var(--chakra-colors-gray-300)",
        color: "var(--chakra-colors-gray-800)",
    }

    const cstmMutliSelectOptions = {
        background: "var(--chakra-colors-gray-300)",

        "&:hover": {
            background: 'var(--chakra-colors-gray-200)',
        },
        "&:focus-visible": {
            background: 'var(--chakra-colors-transparent)',
            borderColor: '#63b3ed'
        }
    }

    if (!isLoading && !data && postId) {
        return <Navigate to="/error/404"/>
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{width: '100%'}}>
            <Flex
                wrap={'wrap'}
                p={5}
                pb={50}
                m={'auto'}
                w={{base: '100%', md: '80%', xl: '75%'}}
                style={{position: 'relative'}}
            >
                {!!isLoading && <Center w={'100%'} h={'100%'} style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    zIndex: 1,
                    backgroundColor: 'rgba(255, 255, 255, 0.5)'
                }}>
                    <Spinner size={"xl"} color="green"/>
                </Center>}
                <Box w={'100%'} p={5} borderBottomWidth={'1px'} borderBottomColor={'#e8e8e8'} mb={4}>
                    <Heading fontFamily={"Cairo"}>
                        <FormattedMessage id="posts_edit.editPost"/>
                    </Heading>
                </Box>
                <Box w={'100%'}>
                    <Box bgColor={'#f8f8f8'} p={5} mb={4} fontFamily={"Cairo, sans-serif"}>
                        <label htmlFor={'title'} style={{fontWeight: 800}}>
                            <FormattedMessage id="posts_edit.postTitle"/>
                        </label>
                        <FormControl isInvalid={errors.title} mt={2}>
                            <Input
                                id={'title'}
                                placeholder={
                                    intl.formatMessage({id: 'posts_edit.postTitle'})
                                }
                                background={'gray.300'}
                                dir={'auto'}
                                type="text"
                                variant="filled"
                                {...register("title")}
                            />
                            <FormErrorMessage>
                                {errors.title && errors.title.message}
                            </FormErrorMessage>
                        </FormControl>
                    </Box>
                    <Box bgColor={'#f8f8f8'} p={5} mb={4} fontFamily={"Cairo, sans-serif"}>
                        <label htmlFor={'Tags'} style={{fontWeight: 800}}>
                            <FormattedMessage id="posts_edit.postTags"/>
                        </label>
                        <FormControl isInvalid={errors.tags} mt={2}>
                            <Controller
                                name="tags"
                                control={control}
                                render={({field: {onChange, value}}) => (
                                    <ReactSelect
                                        id={'tags'}
                                        onChange={onChange}
                                        placeholder={
                                            intl.formatMessage({id: searchParams.get('withEnemyTags') === null ? 'posts_edit.postTags' : 'posts_edit.enemyPostTags'})
                                        }
                                        value={value}
                                        isMulti
                                        options={postTags}
                                        closeMenuOnSelect={false}
                                        classNames={
                                            {
                                                control: (state) => state.isFocused ? 'border-red-600' : 'border-grey-300'
                                            }
                                        }
                                        styles={{
                                            control: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelect}),
                                            menu: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelectMenu}),
                                            option: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelectOptions})
                                        }}
                                    />
                                )}
                            />
                            <FormErrorMessage>
                                {errors.tags && errors.tags.message}
                            </FormErrorMessage>
                        </FormControl>
                    </Box>

                    {watch('tags')?.filter(tg => staticTags.includes(tg.value))?.length > 0 &&
                        <Box bgColor={'#f8f8f8'} p={5} mb={4} fontFamily={"Cairo, sans-serif"}>
                            <label htmlFor={'categories'} style={{fontWeight: 800}}>
                                <FormattedMessage id="posts_edit.postCategories"/>
                            </label>
                            <FormControl isInvalid={errors.categories} mt={2}>
                                <Controller
                                    rules={{
                                        required: intl.formatMessage({id: "validation.fieldRequired"},
                                            {
                                                field: intl.formatMessage({id: "posts_edit.postCategories"})
                                            }
                                        )
                                    }}
                                    name="categories"
                                    control={control}
                                    render={({field: {onChange, value}}) => (
                                        <ReactSelect
                                            id={'categories'}
                                            onChange={onChange}
                                            placeholder={
                                                intl.formatMessage({id: 'posts_edit.postCategories'})
                                            }
                                            value={value}
                                            isMulti
                                            options={newsCats}
                                            closeMenuOnSelect={false}
                                            classNames={
                                                {
                                                    control: (state) => state.isFocused ? 'border-red-600' : 'border-grey-300'
                                                }
                                            }
                                            styles={{
                                                control: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelect}),
                                                menu: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelectMenu}),
                                                option: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelectOptions})
                                            }}
                                        />
                                    )}
                                />
                                <FormErrorMessage>
                                    {errors.categories && errors.categories.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>}
                    <Box bgColor={'#f8f8f8'} p={5} mb={4} fontFamily={"Cairo, sans-serif"}>
                        <label htmlFor={'lead'} style={{fontWeight: 800}}>
                            <FormattedMessage id="posts_edit.postLead"/>
                        </label>
                        <FormControl isInvalid={errors.lead} mt={2}>
                            <Textarea
                                id={'lead'}
                                dir={'auto'}
                                placeholder={
                                    intl.formatMessage({id: 'posts_edit.postLead'})
                                }
                                background={'gray.300'}
                                type="text"
                                variant="filled"
                                {...register("lead")}
                            />
                            <FormErrorMessage>
                                {errors.lead && errors.lead.message}
                            </FormErrorMessage>
                        </FormControl>
                    </Box>
                    <Box bgColor={'#f8f8f8'} p={5} mb={4} fontFamily={"Cairo, sans-serif"}>
                        <label htmlFor={'description'} style={{fontWeight: 800}}>
                            <FormattedMessage id="posts_edit.postDescription"/>
                        </label>
                        <FormControl isInvalid={errors.description} mt={2}>
                            <Controller
                                name="description"
                                control={control}
                                render={({field: {onChange, value}}) => {
                                    return <Trumbowyg
                                        id='text-editor'
                                        dir={'ltr'}
                                        // data={removeFontFamilyInlineStyles(value || '')}
                                        data={value || ''}
                                        removeformatPasted={true}
                                        // buttons={[
                                        //     'fontfamily',
                                        //     'viewHTML',
                                        //     'undo',
                                        //     'redo',
                                        //     'formatting',
                                        //     'strong',
                                        //     'em',
                                        //     'del',
                                        //     'superscript',
                                        //     'subscript',
                                        //     'link',
                                        //     'insertImage',
                                        //     'justifyLeft',
                                        //     'justifyCenter',
                                        //     'justifyRight',
                                        //     'justifyFull',
                                        //     'unorderedList',
                                        //     'orderedList',
                                        //     'horizontalRule'
                                        // ]}
                                        // plugins={{
                                        //     fontfamily: {
                                        //         fontList: [
                                        //             {name: 'Arial', family: 'Arial, Helvetica, sans-serif'},
                                        //             {name: 'Open Sans', family: '\'Open Sans\', sans-serif'}
                                        //         ]
                                        //     }
                                        // }}
                                        // onChange={(e) => onChange(removeFontFamilyInlineStyles(e.target.innerHTML))}

                                        // somehow using onChange makes farsi text reversed. so im using custom field validation with serError and removing rules from this field
                                        onChange={(e) => setDescription(e.target.innerHTML)}
                                    />
                                }

                                }
                            />
                            <FormErrorMessage>
                                {errors.description && errors.description.message}
                            </FormErrorMessage>
                        </FormControl>
                    </Box>

                    <Box my={15}>
                        <Button
                            colorScheme="green"
                            w={{base: '100%', md: 'auto'}}
                            type="submit"
                            size={{base: 'md', md: 'sm'}}
                            isLoading={isSubmitting}
                            loadingText={intl.formatMessage({id: 'posts_edit.updating'})}
                        >
                            <FormattedMessage id="posts_edit.update"/>
                        </Button>
                    </Box>

                </Box>
            </Flex>
        </form>
    );
};

export default PostEdit;
