import {useContext, useMemo} from 'react';
import axios from 'axios';
import {configs} from "../Configs";
import {getSession} from "../utils/session";
import {getClientData} from "../utils/helpers";

import {useToast} from "@chakra-ui/react";
import {LocaleContext} from '../components/Theme/LocaleProvider';
import {useAuth} from "./useAuth";

export default function useAxios() {
    const localeContext = useContext(LocaleContext)
    const toast = useToast();
    const {user} = useAuth();
    const client_data = user?.username ? 'client_data=' + getClientData(user.username) : ''

    // const location = useLocation();
    // const navigate = useNavigate();
    return useMemo(() => {
        const axiosInstance = axios.create(
            {
                baseURL: configs.base_back_url,
                // withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept-Language': localeContext.locale
                },
            });

        axiosInstance.interceptors.request.use(
            async (config) => {
                const token = getSession();
                if (token && config.headers) {
                    config.headers.Authorization = `Bearer ${token}`;
                }

                // Append the client_data query string to the URL
                if (client_data) {
                    config.url += (config.url.includes('?') ? '&' : '?') + client_data;
                }

                return config;
            },
        );

        axiosInstance.interceptors.response.use(
            (response) => response,
            (error) => {
                const statusCode = error.response ? error.response.status : null;
                if ([403, 401].includes(statusCode)) {
                    toast({
                        title: error.response?.data?.detail || "You dont have required permissions.",
                        status: "warning",
                        isClosable: true,
                        diration: 1500,
                    });
                }
                return Promise.reject(error);
            });
        // axiosInstance.interceptors.response.use(
        // undefined,
        // (error) => {
        //     const statusCode = error.response ? error.response.status : null;
        //     console.log(error, statusCode)
        //     if (statusCode > 299 && statusCode < 399) {
        //         toast({
        //             title: error.response?.data?.detail || "Something went wrong. Please try again.",
        //             status: "warning",
        //             isClosable: true,
        //             diration: 1500,
        //         });
        //         // QSnack(T(error.response?.data?.detail), 'warning', {raw: error});
        //     } else if ([401].includes(statusCode)) {
        //         toast({
        //             title: error.response?.detail,
        //             status: "error",
        //             isClosable: true,
        //             diration: 1500,
        //         });
        //         // QSnack(T(error.response?.detail), 'error', {raw: error});
        //         navigate('/login', {replace: true, location})
        //     } else if ([405].includes(statusCode)) {
        //         toast({
        //             title: error.response?.data?.detail,
        //             status: "error",
        //             isClosable: true,
        //             diration: 1500,
        //         });
        //         // QSnack(T(error.response?.data?.detail), 'error', {raw: error});
        //     } else if (statusCode === 422) {
        //         error.response.data.detail.map((item) => (
        //             toast({
        //                 title: `${item.msg} ${item.loc.join(' ')}`,
        //                 status: "error",
        //                 isClosable: true,
        //                 diration: 1500,
        //             })));
        //         // error.response.data.detail.map((item) => (QSnack(`${T(item.msg)} ${item.loc.join(' ')}`, 'error')))
        //     } else if (statusCode > 399 && statusCode < 499) {
        //         if (isString(error.response.data?.detail?.message)) {
        //             toast({
        //                 title: error.response.data?.detail?.message,
        //                 status: "error",
        //                 isClosable: true,
        //                 diration: 1500,
        //             });
        //             // QSnack(T(error.response.data?.detail?.message), 'error', {raw: error})
        //         } else {
        //             toast({
        //                 title: '[OBJECT]',
        //                 status: "error",
        //                 isClosable: true,
        //                 diration: 1500,
        //             });
        //             // QSnack("[OBJECT]", 'error', {raw: error})
        //         }
        //     } else if (statusCode > 499) {
        //         toast({
        //             title: error?.response?.statusText,
        //             status: "info",
        //             isClosable: true,
        //             diration: 1500,
        //         });
        //         // QSnack(T(error?.response?.statusText), 'default', {raw: error});
        //     } else {
        //         toast({
        //             title: error?.message,
        //             status: "info",
        //             isClosable: true,
        //             diration: 1500,
        //         });
        //         // QSnack(T(error?.message), 'default', {raw: error});
        //     }
        //     return Promise.reject(error);
        // },
        // );

        return axiosInstance;

    }, []);
}