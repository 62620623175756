import React, {useId, useLayoutEffect, useRef} from "react";
import PropTypes from "prop-types";
import {forEach, uniqueId} from "lodash";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themesAnimated from "@amcharts/amcharts5/themes/Animated";
import * as am5pluginsExporting from "@amcharts/amcharts5/.internal/plugins/exporting/Exporting";
import {ExportingMenu} from "@amcharts/amcharts5/.internal/plugins/exporting/ExportingMenu";


ChartBar.propTypes = {
    title: PropTypes.string,
    name: PropTypes.string.isRequired,
    categoryField: PropTypes.string.isRequired,
    labelX: PropTypes.string,
    labelY: PropTypes.string,
    stacked: PropTypes.bool,
    items: PropTypes.array,
    data: PropTypes.array,
    style: PropTypes.shape({
        width: PropTypes.string,
        height: PropTypes.string,
        direction: PropTypes.string,
        fontFamily: PropTypes.string,
    })
}

ChartBar.defaultProps = {
    data: [],
    items: [],
    stacked: false,
    style: {
        width: "100%",
        height: "500px",
        direction: 'ltr',
        fontFamily: 'iranyekan, Public Sans, sans-serif',
    }
}

export default function ChartBar(props) {

    const {
        data, stacked,
        items, name, categoryField, title, labelX, labelY
    } = props
    const {width, height, direction, fontFamily} = props.style
    const xAxisRef = useRef(null);
    const seriesRef = useRef(null);
    const chartID = uniqueId(name + useId())

    // This code will only run one time
    useLayoutEffect(() => {

        /* Chart code */
        const root = am5.Root.new(chartID);

        // Set themes
        root.setThemes([
            am5themesAnimated.new(root)
        ]);

        // Create chart
        const chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: false,
            panY: false,
            wheelX: "panX",
            wheelY: "zoomX",
            layout: root.verticalLayout
        }));

        // Add Title
        chart.topAxesContainer.children.push(am5.Label.new(root, {
            text: title,
            fontSize: 20,
            fontWeight: "400",
            fontFamily,
            x: am5.p50,
            centerX: am5.p50
        }));

        // Add labelY
        chart.leftAxesContainer.children.push(am5.Label.new(root, {
            text: labelY,
            fontSize: 16,
            fontWeight: "400",
            fontFamily,
            y: am5.p50,
            centerY: am5.p50,
            layout: root.verticalLayout
        }));

        // Add labelX
        chart.bottomAxesContainer.children.push(am5.Label.new(root, {
            text: labelX,
            fontSize: 16,
            fontWeight: "400",
            fontFamily,
            y: am5.p50,
            centerY: am5.p50,
            layout: root.horizontalLayout
        }));

        // Add scrollbar
        chart.set("scrollbarX", am5.Scrollbar.new(root, {
            orientation: "horizontal"
        }));

        // Create axes
        const xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
            categoryField,
            renderer: am5xy.AxisRendererX.new(root, {}),
            tooltip: am5.Tooltip.new(root, {})
        }));

        xAxis.data.setAll(data);

        const yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            min: 0,
            renderer: am5xy.AxisRendererY.new(root, {})
        }));


        // Add legend
        const legend = chart.children.push(am5.Legend.new(root, {
            centerX: am5.p50,
            x: am5.p50
        }));


        // Add series
        function makeSeries(name, fieldName, otherStyles = {}) {
            const series = chart.series.push(am5xy.ColumnSeries.new(root, {
                name,
                stacked,
                xAxis,
                yAxis,
                valueYField: fieldName,
                categoryXField: categoryField
            }));

            series.columns.template.setAll({
                ...otherStyles,
                tooltipText: "{name}\n {categoryX}: {valueY}",
                tooltipY: am5.percent(10),
                cornerRadiusTR: !stacked && 8,
                cornerRadiusTL: !stacked && 8
            });
            series.data.setAll(data);
            // Make stuff animate on load
            // https://www.amcharts.com/docs/v5/concepts/animations/
            series.appear();
            series.bullets.push(() => am5.Bullet.new(root, {
                sprite: am5.Label.new(root, {
                    text: "{valueY}",
                    fill: root.interfaceColors.get("alternativeText"),
                    centerY: am5.p50,
                    centerX: am5.p50,
                    populateText: true
                })
            }));
            if (!otherStyles?.legend?.disable) {
                legend.data.push(series);
            }
            seriesRef.current = series;
        }

        forEach(items, (item) => {
            makeSeries(item.name, item.fieldName, item?.otherStyles);
        })

        // Add export menu
        am5pluginsExporting.Exporting.new(root, {
            menu: ExportingMenu.new(root, {}),
            dataSource: data
        });

        // Make stuff animate on load
        chart.appear(1000, 100);

        xAxisRef.current = xAxis;

        return () => {
            root.dispose();
        };
    }, [items]);

    // This code will only run when props.data changes
    useLayoutEffect(() => {
        xAxisRef.current.data.setAll(data);
        seriesRef.current.data.setAll(data);
    }, [data]);


    return <div id={chartID} style={{direction, width, height}}/>;
}
