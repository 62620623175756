import {Box, Flex, Spinner} from "@chakra-ui/react";
import {BrowserRouter as Router, Navigate, Route, Routes,} from "react-router-dom";
import {Authenticated} from "./components/Auth/Authenticated";
import {Authorized} from "./components/Auth/Authorized";
import {Login} from "./components/Auth/Login";
import {PublicRoute} from "./components/Auth/PublicRoute";
import {Register} from "./components/Auth/Register";
import {NavBar} from "./components/Navbar/NavBar";
import {Error404} from "./components/Errors/404";
import {Dashboard} from "./components/Dashboard/Dashboard";
import {ResourceDetail} from "./components/Resource/ResourceDetail";
import {ResourceList} from "./components/Resource/ResourceList";
import {UserDetail} from "./components/User/UserDetail";
import {UserList} from "./components/User/UserList";
// import {ApiDetail} from "./components/Api/ApiDetail";
// import {ApiList} from "./components/Api/ApiList";
import BlacklistsWords from "./components/BlacklistWords/BlacklistWords";
import {TagDetail as PostTagDetail} from "./components/PostTags/TagDetail";
import {TagList as PostTagList} from "./components/PostTags/TagList";
import {TagDetail as Raqib7PostTagDetail} from "./components/Raqib7PostTags/TagDetail";
import {TagList as Raqib7PostTagList} from "./components/Raqib7PostTags/TagList";
import {TagDetail as Raqib2TagDetail} from "./components/Raqib2Tags/TagDetail";
import {TagList as Raqib2TagList} from "./components/Raqib2Tags/TagList";
import {TagsInfoDetail as TrendTagsInfoDetail} from "./components/TrendTagsInfo/TagsInfoDetail";
import {TagsInfoList as TrendTagsInfoList} from "./components/TrendTagsInfo/TagsInfoList";
import {CatDetail as NewsCatDetail} from "./components/NewsCats/CatDetail";
import {CatList as NewsCatList} from "./components/NewsCats/CatList";
import {AuthConsumer, AuthProvider} from "./context/JWTAuthContext";
import {Raqib1Map} from "./components/Raqib1/Raqib1Map";
import {Raqib2} from "./components/Raqib2/Raqib2";
import {Raqib2Posts} from "./components/Raqib2/Raqib2Posts";
import {Raqib3} from "./components/Raqib3/Raqib3";
import Raqib3Cluster from "./components/Raqib3/Cluster/Cluster";
import {Raqib4} from "./components/Raqib4/Raqib4";
import Raqib4Cluster from "./components/Raqib4/Cluster/Cluster";
import {Raqib5} from "./components/Raqib5/Raqib5";
import {Raqib5Categorized} from "./components/Raqib5/Categorized/Raqib5Categorized";
import {Raqib6} from "./components/Raqib6/Raqib6";
import {Raqib7} from "./components/Raqib7/Raqib7";
import {Raqib7Categorized} from "./components/Raqib7/Categorized/Raqib7Categorized";
import {Articles} from "./components/Articles/Articles";
import SingleArticle from "./components/Articles/SingleArticle";
import SingleTagArticles from "./components/Articles/SingleTagArticles";
import NewsArticles from "./components/Articles/NewsArticles";
import Exports from "./components/Exports/Exports";
import NewsArticleAddOrEdit from "./components/Articles/NewsArticleAddOrEdit";
import {useContext} from "react";
import {LocaleContext} from './components/Theme/LocaleProvider';
import PostEdit from "./components/Post/PostEdit";

function App() {
    const context = useContext(LocaleContext);

    return (
        <Box dir={context.isRTL ? 'rtl' : 'ltr'}>
            <AuthProvider>
                <Router>
                    <AuthConsumer>
                        {(auth) => {
                            if (!auth.isInitialized) {
                                return <Flex
                                    height="100vh"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Spinner
                                        thickness="4px"
                                        speed="0.65s"
                                        emptyColor="green.200"
                                        color="green.500"
                                        size="xl"
                                    />
                                </Flex>
                            }

                            return <Routes>
                                <Route
                                    path="/login"
                                    element={
                                        <PublicRoute>
                                            <Login/>
                                        </PublicRoute>
                                    }
                                />
                                <Route
                                    path="/register"
                                    element={
                                        <PublicRoute>
                                            <Register/>
                                        </PublicRoute>
                                    }
                                />
                                {/*<Route path="/todos" element={<NavBar/>}>*/}
                                {/*    <Route*/}
                                {/*        path="/todos"*/}
                                {/*        element={*/}
                                {/*            <Authenticated>*/}
                                {/*                <TodoList/>*/}
                                {/*            </Authenticated>*/}
                                {/*        }*/}
                                {/*    />*/}
                                {/*    <Route*/}
                                {/*        path="/todos/:todoId"*/}
                                {/*        element={*/}
                                {/*            <Authenticated>*/}
                                {/*                <TodoDetail/>*/}
                                {/*            </Authenticated>*/}
                                {/*        }*/}
                                {/*    />*/}
                                {/*</Route>*/}
                                <Route path="/" element={<NavBar/>}>
                                    <Route
                                        path="/"
                                        element={
                                            <Authenticated>
                                                <Dashboard/>
                                            </Authenticated>
                                        }/>
                                </Route>
                                <Route path="/resources" element={<NavBar/>}>
                                    <Route
                                        path="/resources"
                                        element={
                                            <Authorized
                                                permissions={[{resource: 'resource', action: 'fe_manage'}]}
                                            >
                                                <ResourceList/>
                                            </Authorized>
                                        }
                                    />
                                    <Route
                                        path="/resources/:resourceId"
                                        element={
                                            <Authorized
                                                permissions={[
                                                    {resource: 'resource', action: 'fe_edit'},
                                                    {resource: 'resource', action: 'fe_delete'}
                                                ]}
                                            >
                                                <ResourceDetail/>
                                            </Authorized>
                                        }
                                    />
                                </Route>
                                <Route path="/users" element={<NavBar/>}>
                                    <Route
                                        path="/users"
                                        element={
                                            <Authorized
                                                permissions={[{resource: 'users', action: 'fe_manage'}]}
                                            >
                                                <UserList/>
                                            </Authorized>
                                        }
                                    />
                                    <Route
                                        path="/users/:userId"
                                        element={
                                            <Authorized
                                                permissions={[
                                                    {resource: 'users', action: 'fe_edit'},
                                                    {resource: 'users', action: 'fe_delete'}
                                                ]}
                                            >
                                                <UserDetail/>
                                            </Authorized>
                                        }
                                    />
                                </Route>
                                {/*<Route path="/apis" element={<NavBar/>}>*/}
                                {/*    <Route*/}
                                {/*        path="/apis"*/}
                                {/*        element={*/}
                                {/*            <Authenticated>*/}
                                {/*                <AdminRoute>*/}
                                {/*                    <ApiList/>*/}
                                {/*                </AdminRoute>*/}
                                {/*            </Authenticated>*/}
                                {/*        }*/}
                                {/*    />*/}
                                {/*    <Route*/}
                                {/*        path="/apis/:apiId"*/}
                                {/*        element={*/}
                                {/*            <Authenticated>*/}
                                {/*                <AdminRoute>*/}
                                {/*                    <ApiDetail/>*/}
                                {/*                </AdminRoute>*/}
                                {/*            </Authenticated>*/}
                                {/*        }*/}
                                {/*    />*/}
                                {/*</Route>*/}
                                <Route path="/post_tags" element={<NavBar/>}>
                                    <Route
                                        path="/post_tags"
                                        element={
                                            <Authorized
                                                permissions={[{resource: 'post_tag', action: 'fe_manage'}]}
                                            >
                                                <PostTagList/>
                                            </Authorized>
                                        }
                                    />
                                    <Route
                                        path="/post_tags/:tagId"
                                        element={
                                            <Authorized
                                                permissions={[
                                                    {resource: 'post_tag', action: 'fe_edit'},
                                                    {resource: 'post_tag', action: 'fe_delete'}
                                                ]}
                                            >
                                                <PostTagDetail/>
                                            </Authorized>
                                        }
                                    />
                                </Route>
                                <Route path="/raqib7_post_tags" element={<NavBar/>}>
                                    <Route
                                        path="/raqib7_post_tags"
                                        element={
                                            <Authorized
                                                permissions={[{resource: 'raqib7_post_tag', action: 'fe_manage'}]}
                                            >
                                                <Raqib7PostTagList/>
                                            </Authorized>
                                        }
                                    />
                                    <Route
                                        path="/raqib7_post_tags/:tagId"
                                        element={
                                            <Authorized
                                                permissions={[
                                                    {resource: 'raqib7_post_tag', action: 'fe_edit'},
                                                    {resource: 'raqib7_post_tag', action: 'fe_delete'}
                                                ]}
                                            >
                                                <Raqib7PostTagDetail/>
                                            </Authorized>
                                        }
                                    />
                                </Route>
                                <Route path="/raqib2_tags" element={<NavBar/>}>
                                    <Route
                                        path="/raqib2_tags"
                                        element={
                                            <Authorized
                                                permissions={[{resource: 'raqib2_tag', action: 'fe_manage'}]}
                                            >
                                                <Raqib2TagList/>
                                            </Authorized>
                                        }
                                    />
                                    <Route
                                        path="/raqib2_tags/:tagId"
                                        element={
                                            <Authorized
                                                permissions={[
                                                    {resource: 'raqib2_tag', action: 'fe_edit'},
                                                    {resource: 'raqib2_tag', action: 'fe_delete'}
                                                ]}
                                            >
                                                <Raqib2TagDetail/>
                                            </Authorized>
                                        }
                                    />
                                </Route>
                                <Route path="/trend_tags_info" element={<NavBar/>}>
                                    <Route
                                        path="/trend_tags_info"
                                        element={
                                            <Authorized
                                                permissions={[{resource: 'trend_tags_info', action: 'fe_manage'}]}
                                            >
                                                <TrendTagsInfoList/>
                                            </Authorized>
                                        }
                                    />
                                    <Route
                                        path="/trend_tags_info/:tagsInfoId"
                                        element={
                                            <Authorized
                                                permissions={[
                                                    {resource: 'trend_tags_info', action: 'fe_edit'},
                                                    {resource: 'trend_tags_info', action: 'fe_delete'}
                                                ]}
                                            >
                                                <TrendTagsInfoDetail/>
                                            </Authorized>
                                        }
                                    />
                                </Route>
                                <Route path="/news_cats" element={<NavBar/>}>
                                    <Route
                                        path="/news_cats"
                                        element={
                                            <Authorized
                                                permissions={[{resource: 'news_cat', action: 'fe_manage'}]}
                                            >
                                                <NewsCatList/>
                                            </Authorized>
                                        }
                                    />
                                    <Route
                                        path="/news_cats/:catId"
                                        element={
                                            <Authorized
                                                permissions={[
                                                    {resource: 'news_cat', action: 'fe_edit'},
                                                    {resource: 'news_cat', action: 'fe_delete'}
                                                ]}
                                            >
                                                <NewsCatDetail/>
                                            </Authorized>
                                        }
                                    />
                                </Route>
                                <Route path="/news_articles" element={<NavBar/>}>
                                    <Route
                                        path="/news_articles"
                                        element={
                                            <Authorized
                                                permissions={[{resource: 'news', action: 'fe_manage'}]}
                                            >
                                                <NewsArticles/>
                                            </Authorized>
                                        }
                                    />
                                    <Route
                                        path="/news_articles/new"
                                        element={
                                            <Authorized
                                                permissions={[{resource: 'news', action: 'fe_create'}]}
                                            >
                                                <NewsArticleAddOrEdit/>
                                            </Authorized>
                                        }
                                    />
                                    <Route
                                        path="/news_articles/:newsId"
                                        element={
                                            <Authorized
                                                permissions={[
                                                    {resource: 'news', action: 'fe_edit'}
                                                ]}
                                            >
                                                <NewsArticleAddOrEdit/>
                                            </Authorized>
                                        }
                                    />
                                </Route>
                                <Route path="/posts" element={<NavBar/>}>
                                    <Route path="/posts" element={<Navigate to="/error/404"/>}/>
                                    <Route
                                        path="/posts/:postId"
                                        element={
                                            <Authorized
                                                permissions={[
                                                    {resource: 'post', action: 'fe_edit'}
                                                ]}
                                            >
                                                <PostEdit/>
                                            </Authorized>
                                        }
                                    />
                                </Route>
                                <Route path="/blacklists" element={<NavBar/>}>
                                    <Route
                                        path="/blacklists"
                                        element={
                                            <Authorized permissions={[{resource: 'blacklists', action: 'fe_manage'}]}>
                                                <BlacklistsWords/>
                                            </Authorized>
                                        }
                                    />
                                </Route>
                                <Route path="/exports" element={<NavBar/>}>
                                    <Route
                                        path="/exports"
                                        element={
                                            <Authorized permissions={[{resource: 'export', action: 'fe_manage'}]}>
                                                <Exports/>
                                            </Authorized>
                                        }
                                    />
                                    <Route
                                        path="/exports/new"
                                        element={
                                            <Authorized permissions={[{resource: 'export', action: 'fe_create'}]}>
                                                <NewsArticleAddOrEdit/>
                                            </Authorized>
                                        }
                                    />
                                    <Route
                                        path="/exports/:newsId"
                                        element={
                                            <Authorized permissions={[
                                                {resource: 'export', action: 'fe_edit'},
                                                {resource: 'export', action: 'fe_delete'},
                                            ]}>
                                                <NewsArticleAddOrEdit/>
                                            </Authorized>
                                        }
                                    />
                                </Route>
                                <Route path="/raqib1" element={<NavBar/>}>
                                    <Route
                                        path="/raqib1"
                                        element={
                                            <Authorized permissions={[{resource: 'raqib1', action: 'fe_view'}]}>
                                                <Raqib1Map/>
                                            </Authorized>
                                        }
                                    />
                                </Route>
                                <Route path="/raqib2" element={<NavBar/>}>
                                    <Route
                                        path="/raqib2"
                                        element={
                                            <Authorized permissions={[{resource: 'raqib2', action: 'fe_view'}]}>
                                                <Raqib2/>
                                            </Authorized>
                                        }
                                    />
                                    <Route
                                        path="/raqib2/posts"
                                        element={
                                            <Authorized permissions={[{resource: 'raqib2', action: 'fe_view'}]}>
                                                <Raqib2Posts/>
                                            </Authorized>
                                        }
                                    />
                                </Route>
                                <Route path="/raqib3" element={<NavBar/>}>
                                    <Route
                                        path="/raqib3"
                                        element={
                                            <Authorized
                                                permissions={[{resource: 'raqib3', action: 'fe_view_list'}]}>
                                                <Raqib3/>
                                            </Authorized>
                                        }
                                    />
                                    <Route
                                        path="/raqib3/:clusterId"
                                        element={
                                            <Authorized
                                                permissions={[{resource: 'raqib3', action: 'fe_view_cluster'}]}>
                                                <Raqib3Cluster/>
                                            </Authorized>
                                        }
                                    />
                                </Route>
                                <Route path="/raqib4" element={<NavBar/>}>
                                    <Route
                                        path="/raqib4"
                                        element={
                                            <Authorized
                                                permissions={[{resource: 'raqib4', action: 'fe_view_list'}]}>
                                                <Raqib4/>
                                            </Authorized>
                                        }
                                    />
                                    <Route
                                        path="/raqib4/:clusterId"
                                        element={
                                            <Authorized
                                                permissions={[{resource: 'raqib4', action: 'fe_view_cluster'}]}>
                                                <Raqib4Cluster/>
                                            </Authorized>
                                        }
                                    />
                                </Route>
                                <Route path="/raqib5" element={<NavBar/>}>
                                    <Route
                                        path="/raqib5"
                                        element={
                                            <Authorized
                                                permissions={[{resource: 'raqib5', action: 'fe_view_feed'}]}>
                                                <Raqib5/>
                                            </Authorized>
                                        }
                                    />
                                    <Route
                                        path="/raqib5/categorized"
                                        element={
                                            <Authorized
                                                permissions={[{resource: 'raqib5', action: 'fe_view_categorized'}]}>
                                                <Raqib5Categorized/>
                                            </Authorized>
                                        }
                                    />
                                </Route>
                                <Route path="/raqib6" element={<NavBar/>}>
                                    <Route
                                        path="/raqib6"
                                        element={
                                            <Authorized
                                                permissions={[{resource: 'raqib6', action: 'fe_view'}]}>
                                                <Raqib6/>
                                            </Authorized>
                                        }
                                    />
                                </Route>
                                <Route path="/raqib7" element={<NavBar/>}>
                                    <Route
                                        path="/raqib7"
                                        element={
                                            <Authorized
                                                permissions={[{resource: 'raqib7', action: 'fe_view_feed'}]}>
                                                <Raqib7/>
                                            </Authorized>
                                        }
                                    />
                                    <Route
                                        path="/raqib7/categorized"
                                        element={
                                            <Authorized
                                                permissions={[{resource: 'raqib7', action: 'fe_view_categorized'}]}>
                                                <Raqib7Categorized/>
                                            </Authorized>
                                        }
                                    />
                                </Route>
                                <Route path="/articles" element={<NavBar/>}>
                                    <Route
                                        path="/articles"
                                        element={
                                            <Authorized
                                                permissions={[{resource: 'news', action: 'fe_view'}]}>
                                                <Articles/>
                                            </Authorized>
                                        }
                                    />
                                    <Route
                                        path="/articles/tag/:tagId"
                                        element={
                                            <Authorized
                                                permissions={[{resource: 'news', action: 'fe_view'}]}>
                                                <SingleTagArticles/>
                                            </Authorized>
                                        }
                                    />
                                    <Route
                                        path="/articles/:newsId"
                                        element={
                                            <Authorized
                                                permissions={[{resource: 'news', action: 'fe_view'}]}>
                                                <SingleArticle/>
                                            </Authorized>
                                        }
                                    />
                                </Route>
                                <Route path="/error" element={<NavBar/>}>
                                    <Route path="/error/404"
                                           element={auth.isAuthenticated ? <Error404/> : <Navigate to="/login"/>}/>
                                </Route>
                                <Route path="*" element={<Navigate to="/error/404"/>}/>
                            </Routes>
                        }}
                    </AuthConsumer>
                </Router>
            </AuthProvider>
        </Box>
    );
}

export default App;
