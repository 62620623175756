import {Box, Td, Text, Tooltip, Tr} from '@chakra-ui/react';
import {FormattedMessage, useIntl} from "react-intl";
import {dtToLocaleString, gregorianJalaliDTConversion, truncateText} from "../../utils/helpers";

export const UserLatestActivityRow = ({
                                          row_id,
                                          activity_id,
                                          username,
                                          app_info,
                                          device_info,
                                          ip_location,
                                          response_msg,
                                          created_at,
                                          stripped = false
                                      }) => {

    const intl = useIntl();

    let clientInfo = <FormattedMessage id="charts.log-table.unknown"/>;
    if (app_info?.slug || app_info?.version){
        clientInfo = `${app_info?.slug}:${app_info?.version}`;
    }else if (app_info?.name){
        clientInfo = app_info.name;
    }

    return (
        <Tr fontSize={'sm'} bgColor={stripped ? '#f2f2f2' : 'white'}>
            <Td p={3}>
                <Box maxW="sm" isTruncated>{row_id}</Box>
            </Td>
            <Td p={3}>{username}</Td>
            <Td p={3}>{clientInfo}</Td>
            <Td p={3}>
                {device_info?.brand ? `${device_info?.brand} (${device_info?.model_name})` :
                <FormattedMessage id="charts.log-table.unknown"/>}
            </Td>
            <Td p={3}>
                <Tooltip label={device_info?.os_name ? `${device_info?.os_name} (OS version: ${device_info?.os_version})` :
                    <FormattedMessage id="charts.log-table.unknown"/>}>
                    {device_info?.os_name ? truncateText(device_info?.os_name, 20) : <FormattedMessage id="charts.log-table.unknown"/>}
                </Tooltip>
            </Td>
            <Td p={3}>
                <Tooltip label={ip_location?.country ? `${ip_location?.country} (${ip_location?.org})` :
                    <FormattedMessage id="charts.log-table.unknown"/>}>
                    {ip_location?.country ? intl.formatMessage({id: `charts.users.countries.${ip_location?.country}`}) : <FormattedMessage id="charts.log-table.unknown"/>}
                </Tooltip>
            </Td>
            <Td p={3}>
                {response_msg}
            </Td>
            <Td p={3}>
                <Tooltip label={gregorianJalaliDTConversion(created_at)} aria-label='datetime-conversion'>
                    <Text color='gray.500' fontSize='sm'>{dtToLocaleString(created_at)}</Text>
                </Tooltip>
            </Td>
        </Tr>
    );
};