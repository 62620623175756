import {Button, Center, Container, Spinner, Text, useColorModeValue, useToast,} from "@chakra-ui/react";
import {useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {AddUpdateTagsInfoModal} from "./AddUpdateTagsInfoModal";
import {useAuth} from "../../hooks/useAuth";
import useAxios from "../../hooks/useAxois";
import {FormattedMessage, useIntl} from 'react-intl';
import {configs} from '../../Configs';

export const TagsInfoDetail = () => {
    const intl = useIntl();
    const axiosInstance = useAxios();
    const {hasPermission} = useAuth();
    const [tagsInfo, setTagsInfo] = useState({});
    const [loading, setLoading] = useState(true);
    const isMounted = useRef(false);
    const {tagsInfoId} = useParams();
    const navigate = useNavigate();
    const toast = useToast();
    const background = useColorModeValue("gray.300", "gray.600");

    useEffect(() => {
        if (isMounted.current) return;
        fetchApi();
        isMounted.current = true;
    }, [tagsInfoId]);

    const fetchApi = () => {
        setLoading(true);
        axiosInstance
            .get(`/trend_tags_info/${tagsInfoId}`)
            .then((res) => {
                setTagsInfo(res.data);
            })
            .catch((error) => console.error(error))
            .finally(() => {
                setLoading(false);
            });
    };

    const delateApi = () => {
        setLoading(true);
        axiosInstance
            .delete(`/trend_tags_info/${tagsInfoId}`)
            .then(() => {
                toast({
                    title: intl.formatMessage({id: 'trend_tags_info.deleted'}),
                    status: "success",
                    isClosable: true,
                    diration: 1500,
                });
                navigate("/trend_tags_info");
            })
            .catch((err) => {
                console.error(err);
                toast({
                    title: intl.formatMessage({id: 'trend_tags_info.notDeleted'}),
                    status: "error",
                    isClosable: true,
                    diration: 2000,
                });
            })
            .finally(() => setLoading(false));
    };

    if (loading) {
        return (
            <Container mt={6}>
                <Center mt={6}>
                    <Spinner
                        thickness="4px"
                        speed="0.65s"
                        emptyColor="green.200"
                        color="green.500"
                        size="xl"
                    />
                </Center>
            </Container>
        );
    }

    return (
        <>
            <Container mt={6}>
                <Button
                    colorScheme="gray"
                    onClick={() => navigate("/trend_tags_info", {replace: true})}
                >
                    <FormattedMessage
                        id="navigations.back"
                        defaultMessage="Back"
                    />
                </Button>
            </Container>
            <Container
                bg={background}
                minHeight="7rem"
                mt={3}
                p={3}
                rounded="lg"
                alignItems="center"
                justifyContent="space-between"
            >
                <Text fontSize={22}>{tagsInfo.title}</Text>
                <Text fontSize={22}>{tagsInfo.tags.join(', ')}</Text>
                <Text fontSize={22}>{configs.trends_platforms.filter(rss_platform=>tagsInfo.platforms.includes(rss_platform.value)).map(platform=>platform.label).join(', ')}</Text>
                {!!tagsInfo.description && <Text bg="gray.400" mt={2} p={2} rounded="lg">
                    {tagsInfo.description}
                </Text>}
                {hasPermission('trend_tags_info', 'fe_edit') && <AddUpdateTagsInfoModal
                    mt={3}
                    editable={true}
                    defaultValues={{
                        title: tagsInfo.title,
                        platforms: tagsInfo.platforms?.map((platform) => {
                                const platforms = configs.trends_platforms.filter((pfm) => (pfm.value === platform));
                                if (platforms.length) return platforms[0];
                            }
                        ),
                        tags: tagsInfo.tags?.map((tag) => ({label: tag, value: tag})),
                        description: tagsInfo.description,
                        updated_at: tagsInfo.updated_at,
                        created_at: tagsInfo.created_at,
                    }}
                    onSuccess={fetchApi}
                />}

                {hasPermission('trend_tags_info', 'fe_delete') && <Button
                    mt={3}
                    isLoading={loading}
                    colorScheme="red"
                    width="100%"
                    onClick={delateApi}
                >
                    <FormattedMessage id="trend_tags_info.delete"/>
                </Button>}
            </Container>
        </>
    );
};
