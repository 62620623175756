import React from "react";
import DOMPurify from "dompurify";

function HTMLParser({htmlContent}) {
    const sanitizedHTML = DOMPurify.sanitize(htmlContent, {
        FORBID_TAGS: ["img"]
    });

    return (
        <span dangerouslySetInnerHTML={{__html: sanitizedHTML}}/>
    );
}

export default HTMLParser;