import {Box, Spinner, Text} from "@chakra-ui/react";
import {useAuth} from "../../hooks/useAuth";
import {useContext, useEffect, useLayoutEffect, useState} from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {FormattedMessage, useIntl} from "react-intl";
import {LocaleContext} from "../Theme/LocaleProvider";
import useAxios from "../../hooks/useAxois";
import {parseDashboardChartsFilters} from "../../utils/helpers";

export const PostsPlatformPieChart = ({
                                          parentIsLoading,
                                          since,
                                          until,
                                          resource_ids,
                                          tag_ids,
                                          faction_ids,
                                          ...rest
                                      }) => {
    const axiosInstance = useAxios();
    const context = useContext(LocaleContext);
    const intl = useIntl();
    const {hasPermission} = useAuth();
    const hasPermit = hasPermission('charts:posts:platforms-pie', 'fe_platforms_pie');
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(hasPermit);
    const [error, setError] = useState(false);

    useEffect(() => {
        if (!hasPermit || parentIsLoading) return;
        setIsLoading(hasPermit);
        const url = parseDashboardChartsFilters("/charts/posts/platforms-pie?", tag_ids, faction_ids, resource_ids);
        axiosInstance.get(url).then((res) => {
            setData(res.data.map(item => ({
                ...item,
                category: intl.formatMessage({id: `charts.resources.${item.category}`})
            })));
            setError(false);
        }).catch((error) => {
            if (error.response && error.response.status === 404) return;
            setError(true);
            console.error(error);
        }).finally(() => setIsLoading(false));
    }, [since, until, resource_ids, tag_ids, faction_ids]);

    useLayoutEffect(() => {
        if (isLoading || !hasPermit || parentIsLoading) return;

        // define root chart with custom theme based on rtl/ltr
        let root = am5.Root.new("posts-platforms-pie");
        const customTheme = am5.Theme.new(root);
        if (context.isRTL) {
            customTheme.rule("Label").setAll({
                fontFamily: "IRANSans",
                direction: 'rtl'
            });
        }
        root.setThemes([am5themes_Animated.new(root), customTheme]);

        // Create chart
        let chart = root.container.children.push(
            am5percent.PieChart.new(root, {
                endAngle: 270,
                layout: root.verticalLayout,
            })
        );

        // chart.children.unshift(am5.Label.new(root, {
        //     text: intl.formatMessage({id: 'charts.posts.postsPlatformsPieTitle'}),
        //     fontSize: 25,
        //     fontWeight: "500",
        //     textAlign: "center",
        //     x: am5.percent(50),
        //     centerX: am5.percent(50),
        //     paddingTop: 0,
        //     paddingBottom: 10
        // }));

        // Create series
        let series = chart.series.push(
            am5percent.PieSeries.new(root, {
                valueField: "value",
                categoryField: "category",
                endAngle: 270,
                tooltip: am5.Tooltip.new(root, {
                    labelText: "{category}: {value}"
                })
            })
        );

        series.set("colors", am5.ColorSet.new(root, {
            colors: [
                am5.color(0x73556E),
                am5.color(0x9FA1A6),
                am5.color(0xF2AA6B),
                am5.color(0xF28F6B),
                am5.color(0xA95A52),
                am5.color(0xE35B5D),
                am5.color(0xFFA446)
            ]
        }))

        let gradient = am5.RadialGradient.new(root, {
            stops: [
                {color: am5.color(0x000000)},
                {color: am5.color(0x000000)},
                {}
            ]
        })

        series.slices.template.setAll({
            fillGradient: gradient,
            strokeWidth: 2,
            stroke: am5.color(0xffffff),
            cornerRadius: 10,
            shadowOpacity: 0.1,
            shadowOffsetX: 2,
            shadowOffsetY: 2,
            shadowColor: am5.color(0x000000),
            fillPattern: am5.GrainPattern.new(root, {
                maxOpacity: 0.2,
                density: 0.5,
                colors: [am5.color(0x000000)]
            })
        })

        series.slices.template.states.create("hover", {
            shadowOpacity: 1,
            shadowBlur: 10
        })

        series.ticks.template.setAll({
            strokeOpacity: 0.4,
            strokeDasharray: [2, 2]
        })

        series.states.create("hidden", {
            endAngle: -90
        });

        // Set data
        series.data.setAll(data);

        let legend = chart.children.push(am5.Legend.new(root, {
            centerX: am5.percent(50),
            x: am5.percent(50),
            marginTop: 15,
            marginBottom: 15,
        }));
        legend.markerRectangles.template.adapters.add("fillGradient", () => undefined);
        legend.labels.template.setAll({
            // textAlign: 'left',
            oversizedBehavior: "wrap"
        });
        legend.valueLabels.template.set('visible', false)
        legend.data.setAll(series.dataItems);

        series.appear(1000, 100);

        return () => {
            root.dispose();
        };
    }, [isLoading, context.isRTL]);

    if (!hasPermit) return <></>

    return (<Box
        bg={"gray.100"}
        minHeight="3rem"
        my={2}
        p={3}
        py={5}
        rounded="lg"
        dir='ltr'
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        {...rest}
    >
        <Text mb={5} fontSize={25} fontWeight={500} dir={context.isRTL ? 'rtl' : 'ltr'}>
            <FormattedMessage id="charts.posts.postsPlatformsPieTitle"/>
        </Text>

        {(isLoading || parentIsLoading) && <Spinner color="green"/>}

        {/*{!data.length && !isLoading && !parentIsLoading &&*/}
        {/*    <Text><FormattedMessage id="responses.noData"/></Text>}*/}

        {/*{!data.length && !isLoading && !parentIsLoading && error &&*/}
        {/*    <Text><FormattedMessage id="responses.connectionFailed"/></Text>}*/}

        {!isLoading && !parentIsLoading && <Box id="posts-platforms-pie" w="100%" h="400px"></Box>}
    </Box>);
};
