import {Box, Flex, Spinner, Text, Tooltip} from '@chakra-ui/react';
import {useCallback, useEffect, useRef, useState} from "react";
import ClusterPost from '../Post';
import {Post} from './Post';
import {useParams} from "react-router-dom";
import {CopyIcon, TimeIcon} from "@chakra-ui/icons";
import {dtToLocaleString, gregorianJalaliDTConversion} from "../../../utils/helpers";
import useAxios from "../../../hooks/useAxois";
import axios from "axios";
import {FormattedMessage, useIntl} from "react-intl";
import Masonry from 'react-masonry-css';
import "./Masonry.css";

const Cluster = () => {
    const intl = useIntl();
    const axiosInstance = useAxios();
    // pagination related stuff
    const {clusterId} = useParams();
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [nextPageId, setNextPageId] = useState(null);
    const [forceRefresh, setForceRefresh] = useState(false);
    const [lastElmId, setLastElmId] = useState(null);
    const [pageId, setPageId] = useState(null);
    const [cluster, setCluster] = useState(null);
    const observer = useRef();

    const lastPostElmRef = useCallback(node => {
        if (isLoading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && nextPageId) {
                setPageId(nextPageId)
            }
        })
        if (node) observer.current.observe(node);
    }, [isLoading, nextPageId])

    const loadData = (paginate = false) => {
        setLastElmId(null);
        setIsLoading(true);
        setError(false);
        let cancel;

        if (!paginate) {
            setNextPageId(null)
            setPageId(null)
            setData([])
        }

        axiosInstance.get(`/raqib3/cluster/${clusterId}`, {
            params: paginate ? {after: pageId} : {},
            cancelToken: new axios.CancelToken(c => cancel = c)
        }).then((res) => setData(prev => {

            // set cluster itself
            setCluster(res.data.cluster);

            // discard previous results if it's not paginating
            const theData = paginate ? [...prev, ...res.data.posts] : [...res.data.posts];
            // uniqify data combination
            let itteratedKeys = [];
            let newData = [];
            theData.map(pst => {
                if (!itteratedKeys.includes(pst.id)) {
                    itteratedKeys.push(pst.id);
                    newData.push(pst);
                }
            });

            // the nextPageId is determined by least sort (desc)
            let sortedData = theData;
            sortedData.sort((a, b) => parseFloat(a.sort) - parseFloat(b.sort));
            setLastElmId(sortedData.length ? sortedData[0].id : null);
            setNextPageId(sortedData.length ? sortedData[0].sort : null)
            setIsLoading(false);
            setError(false);

            return newData;
        })).catch((error) => {
            if (axios.isCancel(error)) return;
            setIsLoading(false);
            if (error.response && error.response.status === 404) return;
            setError(true);
            console.error(error);
        });
        return () => cancel();
    }

    useEffect(() => loadData(true), [pageId, forceRefresh])

    return (
        <>
            {cluster && <Box
                bgColor="#212529"
                w={'100%'}
                alignItems="center"
                justifyContent="center"
                position="relative"
                overflow="hidden"
            >
                <Box
                    position={'absolute'}
                    top={0}
                    left={0}
                    w={'100%'}
                    h={'100%'}
                    bgSize="cover"
                    bgPosition="center"
                    filter="blur(10px)"
                    bgRepeat="no-repeat"
                    bgImage={`url('${cluster.image}')`}
                ></Box>
                <Flex direction={{base: 'column', md: 'row'}}>
                    <Box w="100%" p={4} justifyContent={'center'}>
                        <Box maxW={{md: '50%'}} m={'auto'}>
                            <ClusterPost key={cluster.id} {...cluster} showBottomBox={false}/>
                        </Box>
                    </Box>
                    <Box w="100%" p={4} justifyContent={'center'}>
                        <Flex
                            alignItems="center" // Center vertically
                            justifyContent="center" // Center horizontally
                            h="100%" // Make the container full viewport height
                        >
                            <Box maxW={{md: '70%'}} bgColor={"gray.800"} color={'white'} px={{base: 5, md: 100}}
                                 py={{base: 5, md: 50}}
                                 m={'auto'}
                                 position={"relative"}>
                                <Text>
                                    <CopyIcon me={3}/>
                                    {intl.formatMessage({
                                        id: 'raqib3.repeatedInPosts',
                                        defaultMessage: "Repeated in posts"
                                    })}: {cluster.posts_count}
                                </Text>
                                <Tooltip className="tooltips" label={gregorianJalaliDTConversion(cluster.created_at)}
                                         aria-label='datetime-conversion'>
                                    <Text>
                                        <TimeIcon me={3}/>
                                        {dtToLocaleString(cluster.created_at)}
                                    </Text>
                                </Tooltip>

                            </Box>
                        </Flex>
                    </Box>
                </Flex>


            </Box>}
            <Box p={5} pb={{base: 100, md: 5}}>
                <Masonry
                    breakpointCols={{
                        default: 5,
                        1100: 3,
                        700: 2,
                        500: 1
                    }}
                    className="masonry-grid"
                    columnClassName="masonry-grid_column"
                >
                    {data && data.map(pst => {
                        if (pst.id === lastElmId) {
                            pst['refr'] = lastPostElmRef
                        }
                        return <Post key={'post:' + pst.id} {...pst}/>
                    })}
                </Masonry>
                {/*<Grid*/}
                {/*    templateColumns={{base: '1fr', md: 'repeat(3, 1fr)', lg: 'repeat(4, 1fr)', xl: 'repeat(5, 1fr)'}}*/}
                {/*    gap={6}>*/}
                {/*    {data && data.map(pst => {*/}
                {/*        if (pst.id === lastElmId) {*/}
                {/*            pst['refr'] = lastPostElmRef*/}
                {/*        }*/}
                {/*        return <Post key={'post:' + pst.id} {...pst}/>*/}
                {/*    })}*/}
                {/*</Grid>*/}
                <Box w='100%' textAlign='center'>
                    {!data.length && !isLoading &&
                        <Text >
                            <FormattedMessage
                                id="responses.noData"
                                defaultMessage="No Data"
                            />
                        </Text>}
                    {!isLoading && error &&
                        <Text >
                            <FormattedMessage
                                id="responses.connectionFailed"
                                defaultMessage="Connection Failed"
                            />
                        </Text>}
                    {isLoading && <Spinner mt={5} color="green"/>}
                </Box>
            </Box>
        </>
    );
};

export default Cluster;