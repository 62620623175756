import {
    Box,
    Heading,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
} from "@chakra-ui/react";
import {configs} from '../../Configs';
import {useContext, useEffect, useState} from "react";
import ChartBar from "../@amcharts-extend/ChartBar";
import useAxios from "../../hooks/useAxois";
import {dtToLocaleString} from "../../utils/helpers";
import {useIntl} from 'react-intl';
import {LocaleContext} from '../Theme/LocaleProvider';

export default function DetailsModal({
                                         timerange = configs.raqib1.mapsFilters.timeranges[3],
                                         entity = {},
                                         ...rest
                                     }) {
    const context = useContext(LocaleContext);
    const intl = useIntl();
    const axiosInstance = useAxios();
    const {isOpen, onOpen, onClose} = useDisclosure()
    const [perMonth, setPerMonth] = useState({})
    const [perState, setPerState] = useState({})

    const getSource = (obj) => {
        if (!obj || !Object.values(obj).length) return '';
        const theSource = configs.raqib1.sources.filter(sorc => sorc.value == obj.source);
        const thePlatform = configs.raqib1.rss_platforms.filter(plt => plt.value == obj.source);

        return (theSource.length ? intl.formatMessage({
            id: `raqib1.${theSource[0]?.value}`,
            defaultMessage: theSource[0]?.label
        }) : intl.formatMessage({
            id: `raqib1.${thePlatform[0]?.value}`,
            defaultMessage: thePlatform[0]?.label || ''
        }));
    }

    useEffect(() => {
        if (Object.keys(entity).length) {
            onOpen()
            axiosInstance
                // .get(`/raqib1/per_date/${entity.source}/${btoa(entity.title)}/`, {
                .get(`/raqib1/per_date/${entity.source}/`, {
                    headers: {'Client-Timezone': configs.time_locale},
                    params: {
                        tag: encodeURIComponent(entity.title),
                        state: entity.state,
                        timerange: `PT${configs.raqib1.mapsFilters.timeranges[3].value}S`
                    }
                })
                .then((res) => {
                    setPerMonth(res.data)
                })
                .catch((error) => {
                    console.error(error);
                });

            axiosInstance
                // .get(`/raqib1/per_state/${entity.source}/${btoa(entity.title)}/`, {
                .get(`/raqib1/per_state/${entity.source}/`, {
                    headers: {'Client-Timezone': configs.time_locale},
                    params: {
                        tag: encodeURIComponent(entity.title),
                        timerange: `PT${configs.raqib1.mapsFilters.timeranges[3].value}S`
                    }
                })
                .then((res) => {
                    setPerState(res.data)
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }, [entity]);

    return (<Box {...rest}>
        <Modal
            closeOnOverlayClick={false}
            size={{base:"full", md:"xl"}}
            onClose={onClose}
            isOpen={isOpen}
            // isCentered
        >
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader>
                    <Heading dir={context.isRTL ? 'rtl' : 'ltr'} fontSize='2xl' textAlign={'start'}>
                        {entity?.title} ({getSource(entity)})
                    </Heading>
                </ModalHeader>
                <ModalCloseButton/>
                <ModalBody>
                    <Box>
                        <ChartBar
                            title={intl.formatMessage({
                                id: `raqib1.timeRange${timerange.value}`,
                                defaultMessage: timerange.label
                            })}
                            name={""}
                            data={perMonth?.details?.map(item => {
                                item.key = dtToLocaleString(new Date(item.key), {
                                    day: 'numeric',
                                    month: 'short',
                                    year: 'numeric'
                                });
                                return item
                            })}
                            categoryField={'key'}
                            labelX={''}
                            labelY={''}
                            stacked={false}
                            style={{
                                height: '45vh',
                                fontFamily: 'IRANSans, sans-serif'
                            }}
                            items={[{
                                name: getSource(entity?.target),
                                fieldName: 'doc_count',
                                otherStyles: {
                                    fill: `rgb(${configs.raqib1.mapsOsmProvider.colors[entity.source]})`,
                                    legend: {disable: true}
                                }
                            }]}

                        />
                    </Box>
                    <Box>
                        <ChartBar
                            title={intl.formatMessage({
                                id: `raqib1.distributionAcrossAllStates`,
                                defaultMessage: "Distribution Across All States"
                            })}
                            name={""}
                            data={perState?.details?.map(item => ({
                                ...item,
                                key: intl.formatMessage({id: `raqib1.${item.key}`, defaultMessage: item.key})
                            }))}
                            categoryField={'key'}
                            legend={{enabled: false}}
                            labelX={''}
                            labelY={''}
                            stacked={false}
                            style={{
                                height: '45vh',
                                fontFamily: 'IRANSans, sans-serif',
                            }}
                            items={[{
                                name: getSource(entity?.target),
                                fieldName: 'doc_count',
                                otherStyles: {
                                    fill: `rgb(${configs.raqib1.mapsOsmProvider.colors[entity.source]})`,
                                    legend: {disable: true}
                                }
                            }]}
                        />
                    </Box>
                </ModalBody>
            </ModalContent>
        </Modal>
    </Box>);
};
