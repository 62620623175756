import {
    Box,
    Button,
    FormControl,
    FormErrorMessage,
    Heading,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Stack,
    useColorModeValue,
    useDisclosure,
    useToast
} from "@chakra-ui/react";
import {useForm} from "react-hook-form";
import {useParams} from "react-router-dom";
import useAxios from "../../hooks/useAxois";
import {FormattedMessage, useIntl} from 'react-intl';
import {LocaleContext} from '../Theme/LocaleProvider';
import {useContext} from "react";

export const AddUpdateCatModal = ({
                                      editable = false,
                                      defaultValues = {},
                                      onSuccess = () => {
                                      },
                                      ...rest
                                  }) => {
    const context = useContext(LocaleContext);
    const intl = useIntl();
    const axiosInstance = useAxios();
    const {isOpen, onOpen, onClose} = useDisclosure();
    const toast = useToast();
    const {catId} = useParams();
    const {
        handleSubmit,
        register,
        control,
        formState: {errors, isSubmitting}
    } = useForm({defaultValues: {...defaultValues}});

    const onSubmit = async (values) => {

        try {
            if (editable) {
                await axiosInstance.put(`/news_cat/${catId}`, values);
            } else {
                await axiosInstance.post(`/news_cat/create/`, values);
            }
            toast({
                title: editable ? intl.formatMessage({
                    id: 'news_cats.updated',
                    defaultMessage: 'Category Updated'
                }) : intl.formatMessage({id: 'news_cats.created', defaultMessage: 'Category Added'}),
                status: "success",
                isClosable: true,
                diration: 1500,
            });
            onSuccess();
            onClose();
        } catch (err) {
            let msg = err?.response?.data?.detail || [];
            if (msg && !Array.isArray(msg)) {
                msg = [{msg: msg}]
            }
            if (!msg.length) {
                msg = [{
                    msg:
                        intl.formatMessage({
                            id: 'responses.unknownError',
                            defaultMessage: 'Something went wrong. Please try again'
                        })
                }]
            }
            msg.map(err =>
                toast({
                    title: err.msg,
                    status: "error",
                    isClosable: true,
                    diration: 1500,
                })
            );
        }
    };

    return (<Box {...rest}>
        <Button w="100%" colorScheme="green" onClick={onOpen}>
            {editable ?
                intl.formatMessage({id: 'news_cats.updateCat', defaultMessage: 'UPDATE CATEGORY'})
                : intl.formatMessage({id: 'news_cats.addCat', defaultMessage: 'ADD CATEGORY'})}
        </Button>
        <Modal
            closeOnOverlayClick={false}
            size={{base:"full", md:"xl"}}
            onClose={onClose}
            isOpen={isOpen}
            // isCentered
        >
            <ModalOverlay/>
            <form onSubmit={handleSubmit(onSubmit)}>
                <ModalContent>
                    <ModalHeader dir={context.isRTL ? 'rtl' : 'ltr'}>
                        <Heading fontSize='2xl' textAlign={'start'}>
                            {editable ?
                                intl.formatMessage({id: 'news_cats.updateCat', defaultMessage: 'UPDATE CATEGORY'})
                                : intl.formatMessage({id: 'news_cats.addCat', defaultMessage: 'ADD CATEGORY'})}
                        </Heading>
                    </ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        <FormControl isInvalid={errors.title}>
                            <Input
                                placeholder={
                                    intl.formatMessage({
                                        id: 'news_cats.title',
                                        defaultMessage: 'Title'
                                    })
                                }
                                background={useColorModeValue("gray.300", "gray.600")}
                                type="text"
                                variant="filled"
                                size="lg"
                                mt={6}
                                {...register("title", {
                                    required: intl.formatMessage(
                                        {
                                            id: "validation.fieldRequired",
                                            defaultMessage: "{field} is required"
                                        },
                                        {
                                            field: intl.formatMessage(
                                                {
                                                    id: "news_cats.title",
                                                    defaultMessage: "Title"
                                                }
                                            )
                                        }
                                    ),
                                    minLength: {
                                        value: 1,
                                        message: intl.formatMessage(
                                            {
                                                id: "validation.fieldMinLen",
                                                defaultMessage: "{field} must be at least {length} characters"
                                            },
                                            {
                                                field: intl.formatMessage({
                                                    id: "news_cats.title",
                                                    defaultMessage: "Title"
                                                }),
                                                length: 1
                                            }
                                        )
                                    },
                                    maxLength: {
                                        value: 55,
                                        message: intl.formatMessage(
                                            {
                                                id: "validation.fieldMaxLen",
                                                defaultMessage: "{field} must be at most {length} characters"
                                            },
                                            {
                                                field: intl.formatMessage({
                                                    id: "news_cats.title",
                                                    defaultMessage: "Title"
                                                }),
                                                length: 55
                                            }
                                        )
                                    },
                                })}
                            />
                            <FormErrorMessage>
                                {errors.title && errors.title.message}
                            </FormErrorMessage>
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Stack direction="row" spacing={4}>
                            <Button onClick={onClose} disabled={isSubmitting}>
                                <FormattedMessage
                                    id="news_cats.close"
                                    defaultMessage="Close"
                                />
                            </Button>
                            <Button
                                colorScheme="green"
                                type="submit"
                                isLoading={isSubmitting}
                                loadingText={editable ?
                                    intl.formatMessage({id: 'news_cats.updating', defaultMessage: 'Updating...'})
                                    : intl.formatMessage({id: 'news_cats.creating', defaultMessage: 'Creating...'})}
                            >
                                {editable ?
                                    intl.formatMessage({id: 'news_cats.update', defaultMessage: 'Update'})
                                    : intl.formatMessage({id: 'news_cats.create', defaultMessage: 'Create'})}
                            </Button>
                        </Stack>
                    </ModalFooter>
                </ModalContent>
            </form>
        </Modal>
    </Box>);
};
