import {Box, Button, Flex, IconButton, Input, useBreakpointValue, useColorModeValue} from "@chakra-ui/react";
import {useIntl} from "react-intl";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {CloseIcon} from '@chakra-ui/icons'
import {dtToLocaleString} from '../../utils/helpers';
import {configs} from "../../Configs";
import AsyncSelect from 'react-select/async';
import useAxios from "../../hooks/useAxois";
import ReactSelect from 'react-select';

export const NavBar = ({
                           factions,
                           selFactions,
                           setSelFactions,
                           postCats,
                           selPostCats,
                           setSelPostCats,
                           resources,
                           setResources,
                           since,
                           setSince,
                           until,
                           setUntil,
                           ...rest
                       }) => {
    const intl = useIntl();
    const axiosInstance = useAxios();

    const cstmMutliSelectMenu = {
        background: useColorModeValue("var(--chakra-colors-gray-300)", "var(--chakra-colors-gray-600)"),
        color: useColorModeValue("var(--chakra-colors-gray-800)", "var(--chakra-colors-gray-300)"),
    }
    const cstmMutliSelectOptions = {
        background: useColorModeValue("var(--chakra-colors-gray-300)", "var(--chakra-colors-gray-600)"),

        "&:hover": {
            background: useColorModeValue('var(--chakra-colors-gray-200)', 'var(--chakra-colors-whiteAlpha-100)'),
        },
        "&:focus-visible": {
            background: 'var(--chakra-colors-transparent)',
            borderColor: '#63b3ed'
        }
    }
    const rsMaxHeightStyle = useBreakpointValue({base: false, md: true}) ? {maxHeight: 'var(--chakra-sizes-12)'} : {};
    const cstmMutliSelect = {
        minWidth: '200px',
        outline: '2px solid transparent',
        outlineOffset: '2px',
        position: 'relative',
        appearance: 'none',
        transitionProperty: 'var(--chakra-transition-property-common)',
        transitionDuration: 'var(--chakra-transition-duration-normal)',
        fontSize: 'var(--chakra-fontSizes-lg)',
        paddingInlineStart: 'var(--chakra-space-4)',
        paddingInlineEnd: 'var(--chakra-space-4)',
        minHeight: 'var(--chakra-sizes-12)',
        ...(rsMaxHeightStyle),
        borderRadius: 'var(--chakra-radii-md)',
        border: '0px solid',
        borderColor: 'var(--chakra-colors-transparent)',
        background: useColorModeValue("var(--chakra-colors-gray-300)", "var(--chakra-colors-gray-600)"),
        "&:hover": {
            background: useColorModeValue('var(--chakra-colors-gray-200)', 'var(--chakra-colors-whiteAlpha-100)'),
        },
        "&:focus-visible": {
            background: 'var(--chakra-colors-transparent)',
            borderColor: '#63b3ed'
        }
    }
    const bgColor = useColorModeValue("gray.300", "gray.600");
    const tagOptions = postCats.map(tag => ({value: tag.tag_id, label: tag.title}));
    const factionOptions = factions.map(tag => ({value: tag.tag_id, label: tag.title}));

    const formatDatetime = (date) => {
        if (!date) {
            return '';
        }
        return dtToLocaleString(date, undefined, configs.time_locale)
    }

    const getLastXHours = (x) => {
        const result = new Date();
        result.setHours((new Date()).getHours() - x);
        return result
    }

    const getLastXDays = (x, zeroTime = false) => {
        const result = new Date();
        result.setDate(result.getDate() - x)

        // clear time to 00:00:00
        if (zeroTime) {
            result.setHours(0, 0, 0, 0);
        }

        return result;
    }

    const fetchResources = async (inputValue) => {
        // fetch resources list
        const response = await axiosInstance.get('/resource', {params: {title: inputValue}});
        return response?.data?.map(item => ({
            value: item.resource_id,
            label: item.title
        }));
    };

    return (
        <Box p={5}>
            <Flex
                as="nav"
                align="center"
                justify="center"
                direction={{base: 'column', md: 'row'}}
            >
                <Flex width={{base: '100%', md: '5xl'}} direction={{base: "column", md: "row"}}>
                    <Box px={1} mt={6}>
                        <ReactSelect
                            value={tagOptions.find(opt => selPostCats.includes(opt.value))}
                            onChange={(opts) => setSelPostCats(opts)}
                            placeholder={intl.formatMessage({id: 'dashboard.tags'}) + '...'}
                            isMulti
                            options={tagOptions}
                            closeMenuOnSelect={false}
                            classNames={
                                {
                                    control: (state) => state.isFocused ? 'border-red-600' : 'border-grey-300'
                                }
                            }
                            styles={{
                                control: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelect}),
                                valueContainer: (provided) => ({...provided, ...rsMaxHeightStyle}),
                                menu: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelectMenu}),
                                option: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelectOptions})
                            }}
                        />
                    </Box>
                    <Box px={1} mt={6}>
                        <AsyncSelect
                            loadOptions={
                                (inputValue, callback) => {
                                    fetchResources(inputValue).then(options => {
                                        callback(options);
                                    });
                                }
                            }
                            onChange={(e) => setResources(e)}
                            cacheOptions
                            defaultOptions
                            isMulti
                            closeMenuOnSelect={false}
                            placeholder={intl.formatMessage({id: 'dashboard.resources'})}
                            classNames={
                                {
                                    control: (state) => state.isFocused ? 'border-red-600' : 'border-grey-300'
                                }
                            }
                            styles={{
                                control: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelect}),
                                valueContainer: (provided) => ({...provided, ...rsMaxHeightStyle}),
                                menu: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelectMenu}),
                                option: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelectOptions}),
                                menuPortal: (provided) => ({...provided, zIndex: 9999})
                            }}
                        />
                    </Box>
                    <Box px={1} mt={6}>
                        <ReactSelect
                            value={factionOptions.find(opt => selFactions.includes(opt.value))}
                            onChange={(opts) => setSelFactions(opts)}
                            placeholder={intl.formatMessage({id: 'dashboard.factions'}) + '...'}
                            isMulti
                            options={factionOptions}
                            closeMenuOnSelect={false}
                            classNames={
                                {
                                    control: (state) => state.isFocused ? 'border-red-600' : 'border-grey-300'
                                }
                            }
                            styles={{
                                control: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelect}),
                                valueContainer: (provided) => ({...provided, ...rsMaxHeightStyle}),
                                menu: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelectMenu}),
                                option: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelectOptions})
                            }}
                        />
                    </Box>
                    <Box px={1} mt={6} dir={'ltr'}>
                        <DatePicker
                            selected={since}
                            onChange={(date) => setSince(date)}
                            placeholderText={intl.formatMessage({id: 'dashboard.since'})}
                            showTimeSelect
                            portalId="date-picker-portal"
                            customInput={
                                // Custom input component
                                <Flex>
                                    <Input
                                        value={since ? formatDatetime(since) : ''}
                                        placeholder={intl.formatMessage({id: 'dashboard.since'})}
                                        background={useColorModeValue("gray.300", "gray.600")}
                                        type="text"
                                        variant="filled"
                                        size="lg"
                                        borderRightRadius={0}
                                        onChange={() => {
                                        }} // Add a no-op onChange handler to prevent warnings
                                    />
                                    <IconButton h={12} borderLeftRadius={0} aria-label="clear"
                                                icon={<CloseIcon/>} onClick={() => setSince(null)}/>
                                </Flex>
                            }
                        >
                            <Button
                                size={'xs'}
                                mr={1}
                                mb={1}
                                onClick={() => setSince(getLastXDays(0, true))}
                            >
                                Today
                            </Button>
                            <Button
                                size={'xs'}
                                mr={1}
                                mb={1}
                                onClick={() => setSince(getLastXHours(6))}
                            >
                                Last 6 Hours
                            </Button>
                            <Button
                                size={'xs'}
                                mr={1}
                                mb={1}
                                onClick={() => setSince(getLastXHours(12))}
                            >
                                Last 12 Hours
                            </Button>
                            <Button
                                size={'xs'}
                                mr={1}
                                mb={1}
                                onClick={() => setSince(getLastXHours(24))}
                            >
                                Last 24 Hours
                            </Button>
                            <Button
                                size={'xs'}
                                mr={1}
                                mb={1}
                                onClick={() => setSince(getLastXDays(1, true))}
                            >
                                Yesterday
                            </Button>
                            <Button
                                size={'xs'}
                                mr={1}
                                mb={1}
                                onClick={() => setSince(getLastXDays(7))}
                            >
                                Last 7 Days
                            </Button>
                        </DatePicker>
                    </Box>
                    <Box px={1} mt={6} dir={'ltr'}>
                        <DatePicker
                            selected={until}
                            onChange={(date) => setUntil(date)}
                            placeholderText={intl.formatMessage({id: 'dashboard.until'})}
                            showTimeSelect
                            portalId="date-picker-portal"
                            customInput={
                                // Custom input component
                                <Flex>
                                    <Input
                                        value={until ? formatDatetime(until) : ''}
                                        placeholder={intl.formatMessage({id: 'dashboard.until'})}
                                        background={useColorModeValue("gray.300", "gray.600")}
                                        type="text"
                                        variant="filled"
                                        size="lg"
                                        borderRightRadius={0}
                                        onChange={() => {
                                        }} // Add a no-op onChange handler to prevent warnings
                                    />
                                    <IconButton h={12} borderLeftRadius={0} aria-label="clear until"
                                                icon={<CloseIcon/>} onClick={() => setUntil(null)}/>
                                </Flex>
                            }
                        >
                            <Button
                                size={'xs'}
                                mr={1}
                                mb={1}
                                onClick={() => setUntil(getLastXDays(0, true))}
                            >
                                Today
                            </Button>
                            <Button
                                size={'xs'}
                                mr={1}
                                mb={1}
                                onClick={() => setUntil(getLastXHours(6))}
                            >
                                Last 6 Hours
                            </Button>
                            <Button
                                size={'xs'}
                                mr={1}
                                mb={1}
                                onClick={() => setUntil(getLastXHours(12))}
                            >
                                Last 12 Hours
                            </Button>
                            <Button
                                size={'xs'}
                                mr={1}
                                mb={1}
                                onClick={() => setUntil(getLastXHours(24))}
                            >
                                Last 24 Hours
                            </Button>
                            <Button
                                size={'xs'}
                                mr={1}
                                mb={1}
                                onClick={() => setUntil(getLastXDays(1, true))}
                            >
                                Yesterday
                            </Button>
                            <Button
                                size={'xs'}
                                mr={1}
                                mb={1}
                                onClick={() => setUntil(getLastXDays(7))}
                            >
                                Last 7 Days
                            </Button>
                        </DatePicker>
                    </Box>
                </Flex>
            </Flex>
        </Box>
    );
};
