import {Box, Flex, Grid, Spinner, Text, useBreakpointValue} from '@chakra-ui/react';
import {useEffect, useState} from "react";
import {configs} from "../../Configs";
import {NavBar} from "./NavBar";
import {NewsTags} from "./NewsTags";
import {Hashtag} from "./Hashtag";
import {dtRange} from "../../utils/helpers";
import ContextPost from "../Raqib4/Post";
import ImgPost from "../Raqib3/Post";
import useAxios from "../../hooks/useAxois";
import {FormattedMessage} from "react-intl";

export const Articles = () => {
    const axiosInstance = useAxios();
    const imageCols = useBreakpointValue({base: 1, md: 2, lg: 3});
    const contextCols = useBreakpointValue({base: 1, md: 2});
    const [newsTags, setNewsTags] = useState([]);
    const [data, setData] = useState([]);
    const [hashtags, setHashtags] = useState([]);
    const [hashtagsError, setHashtagsError] = useState(false);
    const [imageTrends, setImageTrends] = useState([]);
    const [imgError, setImgError] = useState(false);
    const [contextTrends, setContextTrends] = useState([]);
    const [contenxtError, setContextError] = useState(false);
    const [isLoading, setIsLoading] = useState(0);
    const [error, setError] = useState(false);
    const [forceRefresh, setForceRefresh] = useState(false);
    const [period, setPeriod] = useState(configs.articles.periods[0].value)


    useEffect(() => {
        setIsLoading(v => v + 1);
        axiosInstance.get("/post_tag").then((data) => {
            setNewsTags(data.data)
            setIsLoading(v => v - 1);
            setError(false);
        }).catch((error) => {
            setIsLoading(v => v - 1);
            if (error.response && error.response.status === 404) return;
            setError(true);
            console.error(error);
        });
    }, []);

    useEffect(() => {
        if (!configs.articles.should_refresh_default) {
            return
        }

        const theInterval = setInterval(() => {
            setData([]);
            setImageTrends([]);
            setContextTrends([]);
            setIsLoading(v => v > 0 ? v - 1 : 0);
            setError(false);
            setImgError(false);
            setContextError(false);
            setForceRefresh(prevForceRefresh => !prevForceRefresh);
        }, configs.articles.refresh_interval_default * 1000);

        return () => {
            clearInterval(theInterval);
        };
    }, []);

    const loadData = () => {
        setError(false);
        setContextError(false);
        setImgError(false);
        setData([]);
        setHashtags([]);
        setImageTrends([]);
        setContextTrends([]);
        let apiCalls = [];
        let hashtagApiCalls = [];

        // get hashtags
        ['GOOGLE_TRENDS', 'TWITTER', 'RSS_FACEBOOK', 'RSS_INSTAGRAM'].map(source => {
            setIsLoading(v => v + 1);
            const payload = {
                state: 'all',
                source: source
            };
            hashtagApiCalls.push(
                axiosInstance.get("/raqib1/", {
                    headers: {'Client-Timezone': configs.time_locale},
                    params: payload
                }).catch((error) => {
                    if (error.response && error.response.status === 404) return;
                    setHashtagsError(true);
                    console.error(error)
                }).finally(() => {
                    setIsLoading(v => v - 1);
                })
            );
        });

        // get image trends
        setIsLoading(v => v + 1);
        axiosInstance.get("/raqib3/", {
            headers: {'Client-Timezone': configs.time_locale},
            params: {period: period}
        }).then((res) => {
            setImgError(false);
            // only keep 6 items, that's good enough
            setImageTrends([...res.data].slice(0, 6));
        }).catch((error) => {
            if (error.response && error.response.status === 404) return;
            setImgError(true);
            console.error(error);
        }).finally(() => setIsLoading(v => v - 1));

        // get context trends
        setIsLoading(v => v + 1);
        axiosInstance.get("/raqib4/", {
            headers: {'Client-Timezone': configs.time_locale},
            params: {period: period}
        }).then((res) => {
            setContextError(false);
            // only keep 6 items, that's good enough
            setContextTrends([...res.data].slice(0, 6));
        }).catch((error) => {
            if (error.response && error.response.status === 404) return;
            setContextError(true);
            console.error(error);
        }).finally(() => setIsLoading(v => v - 1));

        // get news
        newsTags.map(tag => {
            setIsLoading(v => v + 1);
            let params = {
                after: dtRange(period * 1000)
            }
            const periodIndex = configs.articles.periods.findIndex(obj => obj.value === period);
            if (periodIndex > 0) {
                params.before = dtRange(configs.articles.periods[periodIndex - 1].value * 1000)
            }
            apiCalls.push(
                // should send datetime filter
                axiosInstance.get(`/news?tag_ids=${tag.tag_id}`, {
                    headers: {'Client-Timezone': configs.time_locale},
                    params: params
                }).catch((error) => {
                    if (error.response && error.response.status === 404) return;
                    setError(true);
                    console.error(error)
                }).finally(() => setIsLoading(v => v - 1))
            );
        });

        // set multi-request api calls results
        Promise.all(hashtagApiCalls).then(responses => {
            setHashtags([].concat(...responses.map(res => res?.data || [])));
        });
        Promise.all(apiCalls).then(responses => {
            setData([].concat(...responses.map(res => res?.data?.news || [])));
        });
    }

    useEffect(() => {
        if (!newsTags.length) return;
        loadData();
    }, [newsTags, forceRefresh, period])

    let firstCol = data;
    let secondCol = [];

    if (newsTags.length > 1) {
        const halfLength = Math.ceil(newsTags.length / 2);
        firstCol = newsTags.slice(0, halfLength);
        secondCol = newsTags.slice(halfLength);
    }

    const fb_hashtags = hashtags.filter(ht => ht.source === 'RSS_FACEBOOK');
    const ig_hashtags = hashtags.filter(ht => ht.source === 'RSS_INSTAGRAM');
    const x_hashtags = hashtags.filter(ht => ht.source === 'TWITTER');
    const gt_hashtags = hashtags.filter(ht => ht.source === 'GOOGLE_TRENDS');

    return (
        <>
            <NavBar
                period={period}
                setPeriod={setPeriod}
            />
            <Flex
                className="articles-font"
                flexWrap="wrap"
                justify="space-between"
                pb={{base: 100, md: 0}}
            >
                <Flex
                    w={['100%', '50%', '66%']}
                    flexWrap="wrap"
                    justify="space-between"
                >
                    {firstCol.length > 0 && <Box
                        key={'news-col-1'}
                        width={secondCol.length > 0 ? ["100%", "50%", "50%"] : ["100%", "100%", "100%"]}
                        p={5}
                    >
                        {firstCol.map(tag => <NewsTags
                            key={tag.tag_id}
                            {...tag}
                            isLoading={isLoading}
                            error={error}
                            news={data.filter(news => {
                                for (const tagItem of news.tags) {
                                    if (tagItem.tag_id === tag.tag_id) {
                                        return true;
                                    }
                                }
                                return false;
                            })}/>)}
                    </Box>}

                    {secondCol.length > 0 && <Box
                        width={firstCol.length > 0 ? ["100%", "50%", "50%"] : ["100%", "100%", "100%"]}
                        p={5}
                    >
                        {secondCol.map(tag => <NewsTags
                            key={tag.tag_id}
                            {...tag}
                            isLoading={isLoading}
                            error={error}
                            news={data.filter(news => {
                                for (const tagItem of news.tags) {
                                    if (tagItem.tag_id === tag.tag_id) {
                                        return true;
                                    }
                                }
                                return false;
                            })}/>)}
                    </Box>}

                    <Box
                        width={"100%"}
                        textAlign={'center'}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        {!newsTags.length && !isLoading > 0 &&
                            <Text>
                                <FormattedMessage
                                    id="responses.noData"
                                    defaultMessage="No Data"
                                />
                            </Text>}
                        {!isLoading > 0 && error &&
                            <Text>
                                <FormattedMessage
                                    id="responses.connectionFailed"
                                    defaultMessage="Connection Failed"
                                />
                            </Text>}
                        {isLoading > 0 && <Spinner mt={5} color="green"/>}
                    </Box>
                </Flex>

                <Box
                    width={["100%", "50%", "33%"]}
                    p={5}
                >
                    <Box mb={'20px'}>
                        <Flex
                            wrap="wrap"
                        >
                            <Box w={['100%', 'calc(50% - 10px)', 'calc(50% - 10px)']}
                                 me={[0, '10px', '10px']}
                                 mb={'20px'}
                            >
                                <Box
                                    bgColor={`rgb(${configs.articles.colors.RSS_FACEBOOK})`}
                                    color={'#fff'}
                                    borderRadius={'8px'} pe={'15px'} ps={'15px'} pt={'5px'} pb={'15px'} fontWeight={500}
                                    mb={'-10px'}
                                >
                                    <Text fontFamily={"Cairo"}>
                                        <FormattedMessage
                                            id="articles.facebookHashtags"
                                            defaultMessage="Facebook Hashtags"
                                        />
                                    </Text>
                                </Box>
                                <Box bgColor={'#fff'} borderRadius={'8px'} px={'15px'} py={'5px'}
                                     borderColor={'#e2e2e2'}
                                     borderWidth={'1px'}>

                                    {fb_hashtags.length > 0 && fb_hashtags.map((hashtag, idx) => <Hashtag
                                        key={`fb:ht:${idx}`}{...hashtag}/>)}

                                    <Box
                                        width={"100%"}
                                        textAlign={'center'}
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        {!fb_hashtags.length && !isLoading > 0 &&
                                            <Text>
                                                <FormattedMessage
                                                    id="responses.noData"
                                                    defaultMessage="No Data"
                                                />
                                            </Text>}
                                        {!isLoading > 0 && hashtagsError &&
                                            <Text>Connection
                                                Failed</Text>}
                                        {isLoading > 0 && <Spinner mt={5} color="green"/>}
                                    </Box>
                                </Box>
                            </Box>
                            <Box w={['100%', 'calc(50% - 10px)', 'calc(50% - 10px)']}
                                 ms={[0, '10px', '10px']}
                                 mb={'20px'}
                            >
                                <Box
                                    bgColor={`rgb(${configs.articles.colors.RSS_INSTAGRAM})`}
                                    color={'#fff'}
                                    borderRadius={'8px'} pe={'15px'} ps={'15px'} pt={'5px'} pb={'15px'} fontWeight={500}
                                    mb={'-10px'}
                                >
                                    <Text fontFamily={"Cairo"}>
                                        <FormattedMessage
                                            id="articles.instagramHashtags"
                                            defaultMessage="Instagram Hashtags"
                                        />
                                    </Text>
                                </Box>
                                <Box bgColor={'#fff'} borderRadius={'8px'} px={'15px'} py={'5px'}
                                     borderColor={'#e2e2e2'}
                                     borderWidth={'1px'}>

                                    {ig_hashtags.length > 0 && ig_hashtags.map((hashtag, idx) => <Hashtag
                                        key={`ig:ht:${idx}`}{...hashtag}/>)}

                                    <Box
                                        width={"100%"}
                                        textAlign={'center'}
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        {!ig_hashtags.length && !isLoading > 0 &&
                                            <Text>
                                                <FormattedMessage
                                                    id="responses.noData"
                                                    defaultMessage="No Data"
                                                />
                                            </Text>}
                                        {!isLoading > 0 && hashtagsError &&
                                            <Text>Connection
                                                Failed</Text>}
                                        {isLoading > 0 && <Spinner mt={5} color="green"/>}
                                    </Box>

                                </Box>
                            </Box>
                        </Flex>
                        <Flex
                            wrap="wrap"
                        >
                            <Box w={['100%', 'calc(50% - 10px)', 'calc(50% - 10px)']}
                                 me={[0, '10px', '10px']}
                                 mb={'20px'}
                            >
                                <Box
                                    bgColor={`rgb(${configs.articles.colors.RSS_TWITTER})`}
                                    color={'#fff'}
                                    borderRadius={'8px'} pe={'15px'} ps={'15px'} pt={'5px'} pb={'15px'} fontWeight={500}
                                    mb={'-10px'}
                                >
                                    <Text fontFamily={"Cairo"}>
                                        <FormattedMessage
                                            id="articles.xHashtags"
                                            defaultMessage="X Hashtags"
                                        />
                                    </Text>
                                </Box>
                                <Box bgColor={'#fff'} borderRadius={'8px'} px={'15px'} py={'5px'}
                                     borderColor={'#e2e2e2'}
                                     borderWidth={'1px'}>

                                    {x_hashtags.length > 0 && x_hashtags.map((hashtag, idx) => <Hashtag
                                        key={`x:ht:${idx}`}{...hashtag}/>)}

                                    <Box
                                        width={"100%"}
                                        textAlign={'center'}
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        {!x_hashtags.length && !isLoading > 0 &&
                                            <Text>
                                                <FormattedMessage
                                                    id="responses.noData"
                                                    defaultMessage="No Data"
                                                />
                                            </Text>}
                                        {!isLoading > 0 && hashtagsError &&
                                            <Text>Connection
                                                Failed</Text>}
                                        {isLoading > 0 && <Spinner mt={5} color="green"/>}
                                    </Box>
                                </Box>
                            </Box>
                            <Box w={['100%', 'calc(50% - 10px)', 'calc(50% - 10px)']}
                                 ms={[0, '10px', '10px']}
                                 mb={'20px'}
                            >
                                <Box
                                    bgColor={`rgb(${configs.articles.colors.RSS_TWITTER})`}
                                    color={'#fff'}
                                    borderRadius={'8px'} pe={'15px'} ps={'15px'} pt={'5px'} pb={'15px'} fontWeight={500}
                                    mb={'-10px'}
                                >
                                    <Text fontFamily={"Cairo"}>
                                        <FormattedMessage
                                            id="articles.googleHashtags"
                                            defaultMessage="Google Hashtags"
                                        />
                                    </Text>
                                </Box>
                                <Box bgColor={'#fff'} borderRadius={'8px'} px={'15px'} py={'5px'}
                                     borderColor={'#e2e2e2'}
                                     borderWidth={'1px'}>

                                    {gt_hashtags.length > 0 && ig_hashtags.map((hashtag, idx) => <Hashtag
                                        key={`gt:ht:${idx}`}{...hashtag}/>)}

                                    <Box
                                        width={"100%"}
                                        textAlign={'center'}
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        {!gt_hashtags.length && !isLoading > 0 &&
                                            <Text>
                                                <FormattedMessage
                                                    id="responses.noData"
                                                    defaultMessage="No Data"
                                                />
                                            </Text>}
                                        {!isLoading > 0 && hashtagsError &&
                                            <Text>
                                                <FormattedMessage
                                                    id="responses.connectionFailed"
                                                    defaultMessage="Connection Failed"
                                                />
                                            </Text>}
                                        {isLoading > 0 && <Spinner mt={5} color="green"/>}
                                    </Box>
                                </Box>
                            </Box>
                        </Flex>
                    </Box>
                    <Box mb={'20px'}>
                        <Box bgColor={'#000'} color={'#fff'} borderRadius={'8px'} pe={'15px'} ps={'15px'} pt={'5px'}
                             pb={'15px'} fontWeight={500} mb={'-10px'}>
                            <Text fontFamily={"Cairo"}>
                                <FormattedMessage
                                    id="articles.imageTrends"
                                    defaultMessage="Image Trends"
                                />
                            </Text>
                        </Box>
                        <Box bgColor={'#fff'} borderRadius={'8px'} px={'15px'} py={'5px'} borderColor={'#e2e2e2'}
                             borderWidth={'1px'}>

                            <Grid templateColumns={`repeat(${imageCols}, 1fr)`} gap={2}>
                                {imageTrends.length > 0 && imageTrends.map((item, idx) => <ImgPost showBottomBox={false}
                                                                                                   key={`image-trend:${idx}`} {...item}/>)}
                            </Grid>

                            <Box
                                width={"100%"}
                                textAlign={'center'}
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                {!imageTrends.length && !isLoading > 0 &&
                                    <Text>
                                        <FormattedMessage
                                            id="responses.noData"
                                            defaultMessage="No Data"
                                        />
                                    </Text>}
                                {!isLoading > 0 && imgError &&
                                    <Text>
                                        <FormattedMessage
                                            id="responses.connectionFailed"
                                            defaultMessage="Connection Failed"
                                        />
                                    </Text>}
                                {isLoading > 0 && <Spinner mt={5} color="green"/>}
                            </Box>
                        </Box>
                    </Box>
                    <Box mb={'20px'}>
                        <Box bgColor={'#000'} color={'#fff'} borderRadius={'8px'} pe={'15px'} ps={'15px'} pt={'5px'}
                             pb={'15px'} fontWeight={500} mb={'-10px'}>
                            <Text fontFamily={"Cairo"}>
                                <FormattedMessage
                                    id="articles.contextTrends"
                                    defaultMessage="Context Trends"
                                />
                            </Text>
                        </Box>
                        <Box bgColor={'#fff'} borderRadius={'8px'} px={'15px'} py={'5px'} borderColor={'#e2e2e2'}
                             borderWidth={'1px'}>

                            {/*<Masonry*/}
                            {/*    breakpointCols={{*/}
                            {/*        default: 3*/}
                            {/*    }}*/}
                            {/*    className="masonry-grid"*/}
                            {/*    columnClassName="masonry-grid_column"*/}
                            {/*>*/}
                            {/*    {contextTrends.length > 0 && contextTrends.map((item, idx) => <ContextPost*/}
                            {/*        showBottomBox={false}*/}
                            {/*        key={`context-trend:${idx}`} {...item}/>)}*/}
                            {/*</Masonry>*/}

                            <Grid templateColumns={`repeat(${contextCols}, 1fr)`} gap={4}>
                                {contextTrends.length > 0 && contextTrends.map((item, idx) => <ContextPost
                                    showBottomBox={false}
                                    key={`context-trend:${idx}`} {...item}/>)}
                            </Grid>

                            <Box
                                width={"100%"}
                                textAlign={'center'}
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                {!contextTrends.length && !isLoading > 0 &&
                                    <Text>
                                        <FormattedMessage
                                            id="responses.noData"
                                            defaultMessage="No Data"
                                        />
                                    </Text>}
                                {!isLoading > 0 && contenxtError &&
                                    <Text>
                                        <FormattedMessage
                                            id="responses.connectionFailed"
                                            defaultMessage="Connection Failed"
                                        />
                                    </Text>}
                                {isLoading > 0 && <Spinner mt={5} color="green"/>}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Flex>

        </>
    );
}
