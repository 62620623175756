import {
    Box,
    Button,
    ButtonGroup,
    FormControl,
    FormErrorMessage,
    IconButton,
    Select,
    Tooltip,
    useToast
} from "@chakra-ui/react";
import {CloseIcon, DragHandleIcon} from '@chakra-ui/icons';
import useAxios from "../../hooks/useAxois";
import moment from 'moment-timezone';
import {Controller, useForm} from "react-hook-form";
import {FormattedMessage, useIntl} from 'react-intl';


export const ChangeResourceTimezoneModal = ({
                                                onSuccess,
                                                resources,
                                                selectedResources,
                                                setSelectedResources,
                                                ...rest
                                            }) => {
    const intl = useIntl();
    const axiosInstance = useAxios();
    const toast = useToast();

    const {
        handleSubmit,
        control,
        formState: {errors, isSubmitting}
    } = useForm();

    const onSubmit = async (values) => {
        try {
            if (!values.timezone) {
                delete values.timezone
            }
            if (!'timezone' in values || selectedResources.length === 0) {
                return
            }

            await axiosInstance.put(`/resource`, {resource_ids: selectedResources, timezone: values.timezone});

            toast({
                title: intl.formatMessage(
                    {
                        id: "resources.updatedAll",
                        defaultMessage: "All Resources Updated"
                    }
                ),
                status: "success",
                isClosable: true,
                diration: 1500,
            });
            setSelectedResources([]);
            onSuccess();
        } catch (err) {
            let msg = err?.response?.data?.detail || [];
            if (msg && !Array.isArray(msg)) {
                msg = [{msg: msg}]
            }
            if (!msg.length) {
                msg = [{
                    msg:
                        intl.formatMessage({
                            id: 'responses.unknownError',
                            defaultMessage: 'Something went wrong. Please try again'
                        })
                }]
            }
            msg.map(err =>
                toast({
                    title: err.msg,
                    status: "error",
                    isClosable: true,
                    diration: 1500,
                })
            );
        }
    }

    return (<Box {...rest}>
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl w={{base: '100%', sm: '50%', md: "70%"}} style={{display: 'inline-block'}}
                         isInvalid={errors.timezone}>
                <Controller
                    rules={() => ({required: "This is required field"})}
                    name="timezone"
                    control={control}
                    render={({field: {onChange, value}}) => (
                        <Select
                            disabled={!selectedResources.length > 0}
                            variant="filled"
                            size="sm"
                            w={'100%'}
                            mt={6}
                            style={{borderRadius: '5px'}}
                            value={value}
                            onChange={onChange}
                        >
                            {moment.tz.names().map((zone) => (
                                <option key={"tz:" + zone} value={zone}>{zone}</option>
                            ))}
                        </Select>
                    )}
                />
                <FormErrorMessage>
                    {errors.timezone && errors.timezone.message}
                </FormErrorMessage>
            </FormControl>
            <FormControl w={{base: '100%', sm: '50%', md: "30%"}} style={{display: 'inline-block'}}
                         mt={{base: 1, sm: 0}}>
                <ButtonGroup size='sm' w={'100%'} isAttached variant="outline" ms={{base: 0, sm: 1}}
                             justifyContent={'end'}>
                    <Tooltip label={intl.formatMessage(
                        {
                            id: "resources.updateTz",
                            defaultMessage: "Update Timezone"
                        }
                    )}>
                        <Button
                            w={'100%'}
                            variant={'outline'}
                            colorScheme="green"
                            type="submit"
                            disabled={!selectedResources.length > 0}
                            isLoading={isSubmitting}
                            loadingText={intl.formatMessage(
                                {
                                    id: "resources.updating",
                                    defaultMessage: "Updating..."
                                }
                            )}
                        >
                            <FormattedMessage
                                id="resources.update"
                                defaultMessage="Update"
                            />
                        </Button>
                    </Tooltip>
                    {resources.length > 0 && selectedResources.length < resources.length &&
                        <Tooltip label={
                            intl.formatMessage(
                                {
                                    id: "resources.selectAll",
                                    defaultMessage: "SelectAll"
                                }
                            )
                        }>
                            <IconButton colorScheme='white' icon={<DragHandleIcon/>}
                                        onClick={() => setSelectedResources(resources.filter(resource => resource.source !== 'NITTER').map(resource => resource.resource_id))}/>
                        </Tooltip>}
                    {selectedResources.length > 0 && <Tooltip label={
                        intl.formatMessage(
                            {
                                id: "resources.clearSelection",
                                defaultMessage: "Clear Selection"
                            }
                        )
                    }>
                        <IconButton colorScheme='white' icon={<CloseIcon/>}
                                    onClick={() => setSelectedResources([])}/>
                    </Tooltip>}
                </ButtonGroup>
            </FormControl>
        </form>

    </Box>);
};
