const geoJsonObject = {
    "type": "FeatureCollection",
    "features": [
        {
            "type": "Feature",
            "properties": {},
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [-180, -90],
                        [-180, 90],
                        [180, 90],
                        [180, -90],
                        [-180, -90]
                    ],
                    [
                        [
                            35.97455978393555,
                            34.634055372264775
                        ],
                        [
                            35.97267150878906,
                            34.629252979439194
                        ],
                        [
                            35.982627868652344,
                            34.602410961291504
                        ],
                        [
                            35.98503112792969,
                            34.57951775453086
                        ],
                        [
                            35.98400115966797,
                            34.54049998801135
                        ],
                        [
                            35.97301483154297,
                            34.522681445413944
                        ],
                        [
                            35.958251953125,
                            34.52013562807766
                        ],
                        [
                            35.945892333984375,
                            34.51447797766275
                        ],
                        [
                            35.92460632324219,
                            34.49410725526702
                        ],
                        [
                            35.8978271484375,
                            34.472599425831355
                        ],
                        [
                            35.88752746582031,
                            34.468353804284895
                        ],
                        [
                            35.859031677246094,
                            34.463541838777154
                        ],
                        [
                            35.85044860839844,
                            34.46410796676402
                        ],
                        [
                            35.834312438964844,
                            34.46410796676402
                        ],
                        [
                            35.82435607910156,
                            34.46325877334401
                        ],
                        [
                            35.807533264160156,
                            34.460428066222796
                        ],
                        [
                            35.799293518066406,
                            34.45080294411416
                        ],
                        [
                            35.80513000488281,
                            34.44230926803439
                        ],
                        [
                            35.80821990966797,
                            34.43069984745346
                        ],
                        [
                            35.81611633300781,
                            34.42163771567672
                        ],
                        [
                            35.812339782714844,
                            34.41229136345307
                        ],
                        [
                            35.79895019531249,
                            34.40492683510892
                        ],
                        [
                            35.787620544433594,
                            34.39841151959974
                        ],
                        [
                            35.77217102050781,
                            34.38934588891084
                        ],
                        [
                            35.75328826904297,
                            34.38509603651882
                        ],
                        [
                            35.733375549316406,
                            34.37914588074335
                        ],
                        [
                            35.73028564453125,
                            34.37064492478658
                        ],
                        [
                            35.72032928466797,
                            34.36299332676462
                        ],
                        [
                            35.72273254394531,
                            34.35420729696006
                        ],
                        [
                            35.71998596191406,
                            34.33521498946912
                        ],
                        [
                            35.71311950683593,
                            34.3201881768449
                        ],
                        [
                            35.70659637451172,
                            34.30969616544571
                        ],
                        [
                            35.700416564941406,
                            34.311964819565006
                        ],
                        [
                            35.685997009277344,
                            34.31763618666
                        ],
                        [
                            35.67810058593749,
                            34.31451698217332
                        ],
                        [
                            35.668487548828125,
                            34.30799463462751
                        ],
                        [
                            35.662307739257805,
                            34.29126107845569
                        ],
                        [
                            35.653038024902344,
                            34.28785723534703
                        ],
                        [
                            35.645484924316406,
                            34.277077489205695
                        ],
                        [
                            35.650978088378906,
                            34.266580092204784
                        ],
                        [
                            35.64891815185547,
                            34.25494631082515
                        ],
                        [
                            35.65441131591796,
                            34.24387853788724
                        ],
                        [
                            35.64891815185547,
                            34.22684835281174
                        ],
                        [
                            35.638275146484375,
                            34.214357361365884
                        ],
                        [
                            35.62694549560547,
                            34.19987684946339
                        ],
                        [
                            35.6235122680664,
                            34.16977214177208
                        ],
                        [
                            35.620765686035156,
                            34.150738153785014
                        ],
                        [
                            35.619392395019524,
                            34.14079331968434
                        ],
                        [
                            35.630035400390625,
                            34.13198406047173
                        ],
                        [
                            35.63758850097656,
                            34.11805787482705
                        ],
                        [
                            35.64067840576172,
                            34.08735532648065
                        ],
                        [
                            35.6341552734375,
                            34.06090809595026
                        ],
                        [
                            35.613555908203125,
                            34.030753873451836
                        ],
                        [
                            35.615272521972656,
                            34.01424985509564
                        ],
                        [
                            35.636558532714844,
                            34.007704269556235
                        ],
                        [
                            35.62934875488281,
                            33.992049736544466
                        ],
                        [
                            35.61115264892578,
                            33.989772473236705
                        ],
                        [
                            35.59776306152344,
                            33.97895463913209
                        ],
                        [
                            35.582313537597656,
                            33.94421439362013
                        ],
                        [
                            35.568923950195305,
                            33.914018730035906
                        ],
                        [
                            35.554161071777344,
                            33.90860516848035
                        ],
                        [
                            35.541114807128906,
                            33.91658292864731
                        ],
                        [
                            35.523948669433594,
                            33.91316398001932
                        ],
                        [
                            35.510902404785156,
                            33.91487347147951
                        ],
                        [
                            35.4638671875,
                            33.904616008362325
                        ],
                        [
                            35.461463928222656,
                            33.888942500769986
                        ],
                        [
                            35.469703674316406,
                            33.87725673930016
                        ],
                        [
                            35.47588348388672,
                            33.857871948188674
                        ],
                        [
                            35.469703674316406,
                            33.84732249232822
                        ],
                        [
                            35.47691345214844,
                            33.82422318995612
                        ],
                        [
                            35.47107696533203,
                            33.79255845342771
                        ],
                        [
                            35.441551208496094,
                            33.74746591949786
                        ],
                        [
                            35.431251525878906,
                            33.70549143595723
                        ],
                        [
                            35.412025451660156,
                            33.69578012931697
                        ],
                        [
                            35.41065216064453,
                            33.65806700735442
                        ],
                        [
                            35.39451599121094,
                            33.65120829920497
                        ],
                        [
                            35.391082763671875,
                            33.61547702504824
                        ],
                        [
                            35.38524627685547,
                            33.609758645719346
                        ],
                        [
                            35.382843017578125,
                            33.59374516555303
                        ],
                        [
                            35.37220001220703,
                            33.57229388264518
                        ],
                        [
                            35.3704833984375,
                            33.57744267685642
                        ],
                        [
                            35.36396026611328,
                            33.578014746143985
                        ],
                        [
                            35.3594970703125,
                            33.568861182555565
                        ],
                        [
                            35.35057067871093,
                            33.524796085610056
                        ],
                        [
                            35.33889770507812,
                            33.513346930189016
                        ],
                        [
                            35.3045654296875,
                            33.47899037345071
                        ],
                        [
                            35.28190612792969,
                            33.46524393387907
                        ],
                        [
                            35.25787353515625,
                            33.422272258866045
                        ],
                        [
                            35.24482727050781,
                            33.38271952358268
                        ],
                        [
                            35.23727416992187,
                            33.33683874713408
                        ],
                        [
                            35.2166748046875,
                            33.30987251398259
                        ],
                        [
                            35.20088195800781,
                            33.28060184458917
                        ],
                        [
                            35.18852233886719,
                            33.2817498989783
                        ],
                        [
                            35.1837158203125,
                            33.271990955382115
                        ],
                        [
                            35.19470214843749,
                            33.26280507083056
                        ],
                        [
                            35.207061767578125,
                            33.24500467031837
                        ],
                        [
                            35.19264221191406,
                            33.203073277897694
                        ],
                        [
                            35.18096923828125,
                            33.173192085918075
                        ],
                        [
                            35.163116455078125,
                            33.169743600216165
                        ],
                        [
                            35.14595031738281,
                            33.135251282563836
                        ],
                        [
                            35.12672424316406,
                            33.12777615957276
                        ],
                        [
                            35.104751586914055,
                            33.111673732982595
                        ],
                        [
                            35.102691650390625,
                            33.09499310776808
                        ],
                        [
                            35.15419006347656,
                            33.0909662756286
                        ],
                        [
                            35.1507568359375,
                            33.0875145584254
                        ],
                        [
                            35.15968322753906,
                            33.08578864901153
                        ],
                        [
                            35.17753601074219,
                            33.091541548655215
                        ],
                        [
                            35.19195556640625,
                            33.08348738376751
                        ],
                        [
                            35.206375122070305,
                            33.08808985403573
                        ],
                        [
                            35.21049499511719,
                            33.09786930351166
                        ],
                        [
                            35.2276611328125,
                            33.098444531367186
                        ],
                        [
                            35.234527587890625,
                            33.091541548655215
                        ],
                        [
                            35.278472900390625,
                            33.100745405144245
                        ],
                        [
                            35.29632568359375,
                            33.10649732605987
                        ],
                        [
                            35.303192138671875,
                            33.10132061417708
                        ],
                        [
                            35.31829833984375,
                            33.10304621868762
                        ],
                        [
                            35.32310485839843,
                            33.095568354445476
                        ],
                        [
                            35.32035827636719,
                            33.0875145584254
                        ],
                        [
                            35.32997131347656,
                            33.07658322673801
                        ],
                        [
                            35.35675048828125,
                            33.05701850585396
                        ],
                        [
                            35.363616943359375,
                            33.05126334846858
                        ],
                        [
                            35.37666320800781,
                            33.05183888113547
                        ],
                        [
                            35.38352966308594,
                            33.05932046347212
                        ],
                        [
                            35.43365478515625,
                            33.06622597514673
                        ],
                        [
                            35.448074340820305,
                            33.0909662756286
                        ],
                        [
                            35.5023193359375,
                            33.090390998837975
                        ],
                        [
                            35.50712585449219,
                            33.113399134183744
                        ],
                        [
                            35.51948547363281,
                            33.11569961640373
                        ],
                        [
                            35.53459167480469,
                            33.128351191631566
                        ],
                        [
                            35.52703857421875,
                            33.13870112463704
                        ],
                        [
                            35.53733825683594,
                            33.180088650285235
                        ],
                        [
                            35.536651611328125,
                            33.203073277897694
                        ],
                        [
                            35.53871154785156,
                            33.23294427441437
                        ],
                        [
                            35.54695129394531,
                            33.234667289972165
                        ],
                        [
                            35.55107116699219,
                            33.25534082823907
                        ],
                        [
                            35.56755065917969,
                            33.290359825563534
                        ],
                        [
                            35.583343505859375,
                            33.28232392051035
                        ],
                        [
                            35.57991027832031,
                            33.266824014208815
                        ],
                        [
                            35.59364318847656,
                            33.26050844872995
                        ],
                        [
                            35.62385559082031,
                            33.24098472320831
                        ],
                        [
                            35.62110900878906,
                            33.269694574826076
                        ],
                        [
                            35.630035400390625,
                            33.2817498989783
                        ],
                        [
                            35.654754638671875,
                            33.28060184458917
                        ],
                        [
                            35.67466735839844,
                            33.290933790466376
                        ],
                        [
                            35.684967041015625,
                            33.296673231834106
                        ],
                        [
                            35.69664001464844,
                            33.29782107479825
                        ],
                        [
                            35.70350646972656,
                            33.305281685899445
                        ],
                        [
                            35.71998596191406,
                            33.31388929028309
                        ],
                        [
                            35.734405517578125,
                            33.32708596648148
                        ],
                        [
                            35.738525390625,
                            33.320200993413906
                        ],
                        [
                            35.772857666015625,
                            33.33339671392772
                        ],
                        [
                            35.79002380371094,
                            33.34831120879408
                        ],
                        [
                            35.81336975097656,
                            33.36322314918339
                        ],
                        [
                            35.82023620605469,
                            33.38615960658604
                        ],
                        [
                            35.82847595214844,
                            33.40737044375337
                        ],
                        [
                            35.877227783203125,
                            33.42456461884056
                        ],
                        [
                            35.899200439453125,
                            33.441755389109645
                        ],
                        [
                            35.950698852539055,
                            33.482426642603215
                        ],
                        [
                            35.95481872558593,
                            33.505904166596224
                        ],
                        [
                            35.93971252441406,
                            33.52365123826154
                        ],
                        [
                            35.94657897949219,
                            33.53052009502143
                        ],
                        [
                            35.970611572265625,
                            33.54082235709013
                        ],
                        [
                            35.98915100097656,
                            33.53796074078274
                        ],
                        [
                            36.00700378417969,
                            33.543683878655926
                        ],
                        [
                            36.02760314941406,
                            33.547117579470296
                        ],
                        [
                            36.03996276855469,
                            33.560278835227706
                        ],
                        [
                            36.06330871582031,
                            33.5865953304307
                        ],
                        [
                            36.04820251464843,
                            33.5865953304307
                        ],
                        [
                            36.03034973144531,
                            33.593459183525596
                        ],
                        [
                            36.01936340332031,
                            33.614047465781894
                        ],
                        [
                            35.98846435546875,
                            33.61976556057674
                        ],
                        [
                            35.973358154296875,
                            33.62891372306964
                        ],
                        [
                            35.96992492675781,
                            33.64034756012401
                        ],
                        [
                            35.95550537109375,
                            33.63577420745804
                        ],
                        [
                            35.93971252441406,
                            33.64263414537303
                        ],
                        [
                            35.93353271484375,
                            33.655209278722346
                        ],
                        [
                            35.959625244140625,
                            33.676925635929535
                        ],
                        [
                            35.97129821777344,
                            33.688924428426475
                        ],
                        [
                            35.96992492675781,
                            33.71805737954357
                        ],
                        [
                            35.98983764648437,
                            33.729479368838945
                        ],
                        [
                            35.99876403808594,
                            33.74603855325514
                        ],
                        [
                            36.0186767578125,
                            33.77058594245584
                        ],
                        [
                            36.03446960449219,
                            33.77058594245584
                        ],
                        [
                            36.061248779296875,
                            33.81167280447045
                        ],
                        [
                            36.071548461914055,
                            33.827075293445986
                        ],
                        [
                            36.08940124511719,
                            33.82023008524739
                        ],
                        [
                            36.10107421875,
                            33.82251188219802
                        ],
                        [
                            36.11137390136719,
                            33.83677173366243
                        ],
                        [
                            36.138153076171875,
                            33.83962341851979
                        ],
                        [
                            36.15806579589844,
                            33.85502087238565
                        ],
                        [
                            36.1724853515625,
                            33.83848275599514
                        ],
                        [
                            36.20613098144531,
                            33.83449031726809
                        ],
                        [
                            36.23153686523437,
                            33.841334383760426
                        ],
                        [
                            36.24595642089844,
                            33.858442151927896
                        ],
                        [
                            36.267242431640625,
                            33.85502087238565
                        ],
                        [
                            36.287841796875,
                            33.84076406581977
                        ],
                        [
                            36.29951477050781,
                            33.84361561746012
                        ],
                        [
                            36.3153076171875,
                            33.83506067707558
                        ],
                        [
                            36.35169982910156,
                            33.830497692052425
                        ],
                        [
                            36.36955261230469,
                            33.83277921501038
                        ],
                        [
                            36.39701843261719,
                            33.829356907739296
                        ],
                        [
                            36.39015197753906,
                            33.83962341851979
                        ],
                        [
                            36.38877868652344,
                            33.859012351859946
                        ],
                        [
                            36.37092590332031,
                            33.866424604507536
                        ],
                        [
                            36.357879638671875,
                            33.881817226884806
                        ],
                        [
                            36.32560729980469,
                            33.87896695070692
                        ],
                        [
                            36.31050109863281,
                            33.896637128110214
                        ],
                        [
                            36.27891540527344,
                            33.90974489422525
                        ],
                        [
                            36.29127502441406,
                            33.92569945434823
                        ],
                        [
                            36.333160400390625,
                            33.987779817779554
                        ],
                        [
                            36.37367248535156,
                            34.0105502383134
                        ],
                        [
                            36.39152526855469,
                            34.03274552068691
                        ],
                        [
                            36.401824951171875,
                            34.03502163170625
                        ],
                        [
                            36.40594482421875,
                            34.049814864716275
                        ],
                        [
                            36.419677734375,
                            34.05209051767928
                        ],
                        [
                            36.43341064453125,
                            34.05948596794815
                        ],
                        [
                            36.45538330078125,
                            34.04867701532947
                        ],
                        [
                            36.475982666015625,
                            34.049814864716275
                        ],
                        [
                            36.49383544921875,
                            34.06176136129718
                        ],
                        [
                            36.508941650390625,
                            34.069155967763436
                        ],
                        [
                            36.51237487792969,
                            34.10384511696172
                        ],
                        [
                            36.52885437011719,
                            34.112941557095596
                        ],
                        [
                            36.54052734375,
                            34.13169987553143
                        ],
                        [
                            36.56524658203125,
                            34.13511003175251
                        ],
                        [
                            36.57279968261719,
                            34.15727269301868
                        ],
                        [
                            36.58653259277344,
                            34.1635226487228
                        ],
                        [
                            36.59820556640625,
                            34.19135773925218
                        ],
                        [
                            36.61537170410156,
                            34.19590137180652
                        ],
                        [
                            36.62635803222656,
                            34.201012665793264
                        ],
                        [
                            36.595458984375,
                            34.227699943873155
                        ],
                        [
                            36.58515930175781,
                            34.249838288810324
                        ],
                        [
                            36.582412719726555,
                            34.27651009584797
                        ],
                        [
                            36.59477233886719,
                            34.28842455210738
                        ],
                        [
                            36.60301208496094,
                            34.30033731925641
                        ],
                        [
                            36.59820556640625,
                            34.31394984163214
                        ],
                        [
                            36.58790588378906,
                            34.31394984163214
                        ],
                        [
                            36.561126708984375,
                            34.31791974495676
                        ],
                        [
                            36.55769348144531,
                            34.33719946163336
                        ],
                        [
                            36.54808044433594,
                            34.34060130468122
                        ],
                        [
                            36.53297424316406,
                            34.37517887533528
                        ],
                        [
                            36.55357360839844,
                            34.3972783694641
                        ],
                        [
                            36.55906677246093,
                            34.412574601595
                        ],
                        [
                            36.551513671875,
                            34.42503613021332
                        ],
                        [
                            36.535720825195305,
                            34.42900077257486
                        ],
                        [
                            36.530914306640625,
                            34.43749580157603
                        ],
                        [
                            36.52748107910156,
                            34.43183254485124
                        ],
                        [
                            36.52130126953125,
                            34.43749580157603
                        ],
                        [
                            36.5130615234375,
                            34.43523054493987
                        ],
                        [
                            36.51100158691406,
                            34.44712245718284
                        ],
                        [
                            36.497955322265625,
                            34.44938739141024
                        ],
                        [
                            36.484222412109375,
                            34.459012676670085
                        ],
                        [
                            36.464996337890625,
                            34.46127728843705
                        ],
                        [
                            36.46018981933594,
                            34.4793919710481
                        ],
                        [
                            36.44989013671874,
                            34.48165602990584
                        ],
                        [
                            36.4471435546875,
                            34.50372738344912
                        ],
                        [
                            36.41693115234375,
                            34.500898049218456
                        ],
                        [
                            36.382598876953125,
                            34.5076882900338
                        ],
                        [
                            36.37229919433594,
                            34.500898049218456
                        ],
                        [
                            36.35307312011719,
                            34.49976628863743
                        ],
                        [
                            36.34002685546875,
                            34.51391219149022
                        ],
                        [
                            36.341400146484375,
                            34.527489999202025
                        ],
                        [
                            36.37504577636719,
                            34.54163119530972
                        ],
                        [
                            36.404571533203125,
                            34.56312121279482
                        ],
                        [
                            36.40045166015625,
                            34.58234441765835
                        ],
                        [
                            36.412811279296875,
                            34.610605760914666
                        ],
                        [
                            36.419677734375,
                            34.611170889697355
                        ],
                        [
                            36.43547058105469,
                            34.59704151614417
                        ],
                        [
                            36.45195007324219,
                            34.591954353180675
                        ],
                        [
                            36.45881652832031,
                            34.635467787833214
                        ],
                        [
                            36.423797607421875,
                            34.6252978589571
                        ],
                        [
                            36.39787673950195,
                            34.6313717164268
                        ],
                        [
                            36.382083892822266,
                            34.63320791137959
                        ],
                        [
                            36.37144088745117,
                            34.641540900714524
                        ],
                        [
                            36.36646270751953,
                            34.64295318880934
                        ],
                        [
                            36.3618278503418,
                            34.646625025286475
                        ],
                        [
                            36.3566780090332,
                            34.65297974261105
                        ],
                        [
                            36.35581970214844,
                            34.65721595019747
                        ],
                        [
                            36.34929656982422,
                            34.662299113496196
                        ],
                        [
                            36.35169982910156,
                            34.66582890455924
                        ],
                        [
                            36.350669860839844,
                            34.67077035941283
                        ],
                        [
                            36.355133056640625,
                            34.67415861524134
                        ],
                        [
                            36.35496139526367,
                            34.679099573206415
                        ],
                        [
                            36.35581970214844,
                            34.68248748829269
                        ],
                        [
                            36.354103088378906,
                            34.68404023638139
                        ],
                        [
                            36.35152816772461,
                            34.68404023638139
                        ],
                        [
                            36.344146728515625,
                            34.689404051174506
                        ],
                        [
                            36.340713500976555,
                            34.68883945545667
                        ],
                        [
                            36.33848190307617,
                            34.69236811551462
                        ],
                        [
                            36.33281707763671,
                            34.69081552362158
                        ],
                        [
                            36.327667236328125,
                            34.68954519950226
                        ],
                        [
                            36.32062911987305,
                            34.689404051174506
                        ],
                        [
                            36.31959915161133,
                            34.687145645198406
                        ],
                        [
                            36.31685256958008,
                            34.68587526476131
                        ],
                        [
                            36.31702423095703,
                            34.6841813938546
                        ],
                        [
                            36.31153106689453,
                            34.68319328648835
                        ],
                        [
                            36.30706787109375,
                            34.67655854592756
                        ],
                        [
                            36.306724548339844,
                            34.67274685882317
                        ],
                        [
                            36.30260467529297,
                            34.667805521870406
                        ],
                        [
                            36.308956146240234,
                            34.65467425162703
                        ],
                        [
                            36.305179595947266,
                            34.65354458279873
                        ],
                        [
                            36.30414962768555,
                            34.64789600772051
                        ],
                        [
                            36.300716400146484,
                            34.6404110529208
                        ],
                        [
                            36.297969818115234,
                            34.638857487069885
                        ],
                        [
                            36.298484802246094,
                            34.6346203413782
                        ],
                        [
                            36.2936782836914,
                            34.63419661490387
                        ],
                        [
                            36.287841796875,
                            34.63094797335811
                        ],
                        [
                            36.28252029418945,
                            34.63250168736153
                        ],
                        [
                            36.27685546875,
                            34.628122964254246
                        ],
                        [
                            36.27616882324219,
                            34.629252979439194
                        ],
                        [
                            36.27239227294922,
                            34.62953548083056
                        ],
                        [
                            36.26672744750976,
                            34.62741669694795
                        ],
                        [
                            36.26209259033203,
                            34.63038297923296
                        ],
                        [
                            36.25814437866211,
                            34.630665476776514
                        ],
                        [
                            36.25556945800781,
                            34.63292542249386
                        ],
                        [
                            36.2468147277832,
                            34.633914129385154
                        ],
                        [
                            36.24372482299805,
                            34.6365977029715
                        ],
                        [
                            36.240291595458984,
                            34.63151296363536
                        ],
                        [
                            36.23617172241211,
                            34.63278417769023
                        ],
                        [
                            36.23737335205078,
                            34.62784045805317
                        ],
                        [
                            36.22724533081055,
                            34.629676731165524
                        ],
                        [
                            36.227073669433594,
                            34.62642791261892
                        ],
                        [
                            36.2244987487793,
                            34.62628665675287
                        ],
                        [
                            36.219863891601555,
                            34.63010048072746
                        ],
                        [
                            36.21316909790039,
                            34.630665476776514
                        ],
                        [
                            36.20664596557617,
                            34.6346203413782
                        ],
                        [
                            36.198062896728516,
                            34.63716265476841
                        ],
                        [
                            36.18965148925781,
                            34.6375863660907
                        ],
                        [
                            36.17677688598632,
                            34.633066667056966
                        ],
                        [
                            36.177120208740234,
                            34.629959231113965
                        ],
                        [
                            36.171627044677734,
                            34.628829225548486
                        ],
                        [
                            36.16252899169922,
                            34.63363164290444
                        ],
                        [
                            36.14965438842773,
                            34.63363164290444
                        ],
                        [
                            36.14484786987305,
                            34.63560902806727
                        ],
                        [
                            36.14124298095703,
                            34.634761583055294
                        ],
                        [
                            36.13609313964844,
                            34.638292546817624
                        ],
                        [
                            36.130256652832024,
                            34.63899872153164
                        ],
                        [
                            36.127166748046875,
                            34.642247047768535
                        ],
                        [
                            36.12150192260742,
                            34.64168213060639
                        ],
                        [
                            36.11463546752929,
                            34.63349039930331
                        ],
                        [
                            36.10450744628906,
                            34.63532654735862
                        ],
                        [
                            36.10158920288086,
                            34.634761583055294
                        ],
                        [
                            36.09712600708008,
                            34.63617398659846
                        ],
                        [
                            36.096439361572266,
                            34.63250168736153
                        ],
                        [
                            36.09455108642578,
                            34.63165421060344
                        ],
                        [
                            36.09403610229492,
                            34.629394230255116
                        ],
                        [
                            36.09077453613281,
                            34.628829225548486
                        ],
                        [
                            36.08682632446289,
                            34.63165421060344
                        ],
                        [
                            36.08116149902344,
                            34.630665476776514
                        ],
                        [
                            36.07978820800781,
                            34.63363164290444
                        ],
                        [
                            36.073951721191406,
                            34.628405469493394
                        ],
                        [
                            36.07481002807617,
                            34.63165421060344
                        ],
                        [
                            36.066741943359375,
                            34.634055372264775
                        ],
                        [
                            36.065025329589844,
                            34.63165421060344
                        ],
                        [
                            36.06399536132812,
                            34.63575026806083
                        ],
                        [
                            36.059532165527344,
                            34.633066667056966
                        ],
                        [
                            36.05506896972656,
                            34.63221919607082
                        ],
                        [
                            36.05283737182617,
                            34.62798171127395
                        ],
                        [
                            36.045284271240234,
                            34.62981798126
                        ],
                        [
                            36.03292465209961,
                            34.62741669694795
                        ],
                        [
                            36.034812927246094,
                            34.630665476776514
                        ],
                        [
                            36.0267448425293,
                            34.63094797335811
                        ],
                        [
                            36.02434158325195,
                            34.634761583055294
                        ],
                        [
                            36.0212516784668,
                            34.63532654735862
                        ],
                        [
                            36.005802154541016,
                            34.641540900714524
                        ],
                        [
                            36.0076904296875,
                            34.64521279973051
                        ],
                        [
                            35.99773406982421,
                            34.64309441629591
                        ],
                        [
                            35.99052429199219,
                            34.642388276457766
                        ],
                        [
                            35.99018096923828,
                            34.65057912887339
                        ],
                        [
                            35.98726272583008,
                            34.649308187573105
                        ],
                        [
                            35.981082916259766,
                            34.649590620657044
                        ],
                        [
                            35.98176956176758,
                            34.64422422752932
                        ],
                        [
                            35.97644805908203,
                            34.64394177616416
                        ],
                        [
                            35.97867965698242,
                            34.6346203413782
                        ],
                        [
                            35.97455978393555,
                            34.634055372264775
                        ]
                    ]
                ]
            }
        }
    ]
}
export default geoJsonObject;