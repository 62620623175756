import {Box, Spinner, Text} from "@chakra-ui/react";
import {FormattedMessage, useIntl} from "react-intl";
import {useContext, useEffect, useLayoutEffect, useState} from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {LocaleContext} from "../Theme/LocaleProvider";
import useAxios from "../../hooks/useAxois";
import {useAuth} from "../../hooks/useAuth";
import {fillMissingHours, parseDashboardChartsFilters} from "../../utils/helpers";

export const PostsWeekdayWaketimeChart = ({
                                              parentIsLoading,
                                              since,
                                              until,
                                              resource_ids,
                                              tag_ids,
                                              faction_ids,
                                              ...rest
                                          }) => {
    const axiosInstance = useAxios();
    const context = useContext(LocaleContext);
    const intl = useIntl();
    const {hasPermission} = useAuth();
    const hasPermit = hasPermission('charts:posts:posts-weekday-waketime', 'fe_posts_weekday_waketime');
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(hasPermit);
    const [error, setError] = useState(false);

    useEffect(() => {
        if (!hasPermit || parentIsLoading) return;
        setIsLoading(hasPermit);
        const url = parseDashboardChartsFilters("/charts/posts/posts-weekday-waketime?", tag_ids, faction_ids, resource_ids);
        axiosInstance.get(url, {params: {since: since, until: until}}).then((res) => {
            setData(res.data.map(item => ({
                ...item,
                weekday: intl.formatMessage({id: `charts.posts.${item.weekday}`}),
                hour: item.hour.replace('am', intl.formatMessage({id: `charts.posts.am`})).replace('pm', intl.formatMessage({id: `charts.posts.pm`})),
            })));
            setError(false);
        }).catch((error) => {
            if (error.response && error.response.status === 404) return;
            setError(true);
            console.error(error);
        }).finally(() => setIsLoading(false));
    }, [since, until, resource_ids, tag_ids, faction_ids]);

    useLayoutEffect(() => {
        if (isLoading || !hasPermit || parentIsLoading) return;

        // define root chart with custom theme based on rtl/ltr
        let root = am5.Root.new("posts-weekday-waketime");
        const customTheme = am5.Theme.new(root);
        if (context.isRTL) {
            customTheme.rule("Label").setAll({
                fontFamily: "IRANSans",
                direction: 'rtl'
            });
        }
        root.setThemes([am5themes_Animated.new(root), customTheme]);

        let chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: false,
            panY: false,
            wheelX: "none",
            wheelY: "none",
            paddingLeft: 0,
            layout: root.verticalLayout
        }));

        let yRenderer = am5xy.AxisRendererY.new(root, {
            visible: false,
            minGridDistance: 20,
            inversed: true,
            minorGridEnabled: true
        });

        yRenderer.grid.template.set("visible", false);

        let yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
            maxDeviation: 0,
            renderer: yRenderer,
            categoryField: "weekday"
        }));

        let xRenderer = am5xy.AxisRendererX.new(root, {
            visible: false,
            minGridDistance: 30,
            opposite: true,
            minorGridEnabled: true
        });

        xRenderer.grid.template.set("visible", false);

        let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
            renderer: xRenderer,
            categoryField: "hour"
        }));

        let series = chart.series.push(am5xy.ColumnSeries.new(root, {
            calculateAggregates: true,
            stroke: am5.color(0xffffff),
            clustered: false,
            xAxis: xAxis,
            yAxis: yAxis,
            categoryXField: "hour",
            categoryYField: "weekday",
            valueField: "count"
        }));

        series.columns.template.setAll({
            tooltipText: "{value}",
            strokeOpacity: 1,
            strokeWidth: 2,
            width: am5.percent(100),
            height: am5.percent(100)
        });

        series.columns.template.events.on("pointerover", function (event) {
            let di = event.target.dataItem;
            if (di) {
                heatLegend.showValue(di.get("value", 0));
            }
        });

        series.events.on("datavalidated", function () {
            heatLegend.set("startValue", series.getPrivate("valueHigh"));
            heatLegend.set("endValue", series.getPrivate("valueLow"));
        });

        series.set("heatRules", [{
            target: series.columns.template,
            min: am5.color(0xfffb77),
            max: am5.color(0xfe131a),
            dataField: "value",
            key: "fill"
        }]);

        let heatLegend = chart.bottomAxesContainer.children.push(am5.HeatLegend.new(root, {
            orientation: "horizontal",
            endColor: am5.color(0xfffb77),
            startColor: am5.color(0xfe131a)
        }));

        const weekdays = [
            intl.formatMessage({id: 'charts.posts.Saturday'}),
            intl.formatMessage({id: 'charts.posts.Sunday'}),
            intl.formatMessage({id: 'charts.posts.Monday'}),
            intl.formatMessage({id: 'charts.posts.Tuesday'}),
            intl.formatMessage({id: 'charts.posts.Wednesday'}),
            intl.formatMessage({id: 'charts.posts.Thursday'}),
            intl.formatMessage({id: 'charts.posts.Friday'})
        ];
        yAxis.data.setAll(weekdays.map(item => ({weekday: item})));

        const am_txt = intl.formatMessage({id: `charts.posts.am`});
        const pm_txt = intl.formatMessage({id: `charts.posts.pm`});
        let hours = Array.from({length: 12}, (_, index) => `${index + 1}${am_txt}`);
        hours = hours.concat(Array.from({length: 12}, (_, index) => `${index + 1}${pm_txt}`));
        xAxis.data.setAll(hours.map(item => ({hour: item})));

        series.data.setAll(fillMissingHours(data, hours, 'weekday', weekdays));

        // Make stuff animate on load
        series.appear(1000, 100);

        return () => {
            root.dispose();
        };
    }, [isLoading, context.isRTL]);

    if (!hasPermit) return <></>

    return (<Box
        bg={"gray.100"}
        minHeight="3rem"
        my={2}
        p={3}
        py={5}
        rounded="lg"
        dir='ltr'
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        {...rest}
    >
        <Text mb={5} fontSize={25} fontWeight={500} dir={context.isRTL ? 'rtl' : 'ltr'}>
            <FormattedMessage id="charts.posts.postsWeekdayWaketimeTitle"/>
        </Text>

        {(isLoading || parentIsLoading) && <Spinner color="green"/>}

        {/*{!data.length && !isLoading && !parentIsLoading &&*/}
        {/*    <Text><FormattedMessage id="responses.noData"/></Text>}*/}

        {/*{!data.length && !isLoading && !parentIsLoading && error &&*/}
        {/*    <Text><FormattedMessage id="responses.connectionFailed"/></Text>}*/}

        {!isLoading && !parentIsLoading && <Box id="posts-weekday-waketime" w="100%" h="500px"></Box>}
    </Box>);
};
