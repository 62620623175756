import {Button, Flex} from '@chakra-ui/react';

export const Paginator = ({paginationInfo, setCurPage, curPage}) => {
    const range = 2;
    // const pageNumbers = Array.from({length: paginationInfo.total_pages}, (_, i) => i + 1);
    // Create an array of page numbers to display.
    const pageNumbers = [];
    for (let i = Math.max(1, curPage - range); i <= Math.min(paginationInfo.total_pages, curPage + range); i++) {
        pageNumbers.push(i);
    }

    return (
        <Flex justify="center" align="center" mt="4">
            {curPage > 1 && !pageNumbers.includes(1) && (
                <Button
                    key={'pagination:p-1'}
                    variant={1 === curPage ? 'solid' : 'outline'}
                    disabled={1 === curPage}
                    size="sm"
                    borderRadius={0}
                    onClick={() => setCurPage(1)}
                >
                    {1}
                </Button>
            )}
            {curPage - range > 2 && <Button
                variant={'outline'}
                disabled={true}
                size="sm"
                borderRadius={0}
            >...</Button>}
            {pageNumbers.map(pageNumber => (
                <Button
                    key={`pagination:p-${pageNumber}`}
                    variant={pageNumber === curPage ? 'solid' : 'outline'}
                    disabled={pageNumber === curPage}
                    size="sm"
                    borderRadius={0}
                    onClick={() => setCurPage(pageNumber)}
                >
                    {pageNumber}
                </Button>
            ))}
            {pageNumbers.includes(paginationInfo.total_pages) ? null : <Button
                variant={'outline'}
                disabled={true}
                size="sm"
                borderRadius={0}
            >...</Button>}
            {curPage < paginationInfo.total_pages && !pageNumbers.includes(paginationInfo.total_pages) && (
                <Button
                    key={`pagination:p-${paginationInfo.total_pages}`}
                    variant={curPage === paginationInfo.total_pages ? 'solid' : 'outline'}
                    disabled={paginationInfo.total_pages === curPage}
                    size="sm"
                    borderRadius={0}
                    onClick={() => setCurPage(paginationInfo.total_pages)}
                >
                    {paginationInfo.total_pages}
                </Button>
            )}
        </Flex>
    );
};
