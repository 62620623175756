import {Box, Link} from "@chakra-ui/react";
import {useEffect, useRef} from "react";
import {setTextDirection} from '../../utils/helpers';

export const Category = ({tag_id, title}) => {
    const titleRef = useRef(null);

    useEffect(() => {
        setTextDirection(titleRef.current, title, 'justify');
    }, [title]);

    return (
        <Box>
            <Link
                mb={1}
                size='md'
                target={'_blank'}
                href={`/articles/tag/${tag_id}`}
                fontFamily={"Cairo, sans-serif"}
                ref={titleRef}>{title}</Link>
        </Box>
    );
};
