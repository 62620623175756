import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import LocaleProvider from "./components/Theme/LocaleProvider";
import RtlProvider from "./components/Theme/RtlProvider";

// providing jquery
// $(document).ready(() => {
// Your code here, including rendering your React component
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    // <React.StrictMode>
    <LocaleProvider>
        <RtlProvider>
            <App/>
        </RtlProvider>
    </LocaleProvider>
    // </React.StrictMode>
);

// });