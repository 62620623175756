import {Badge, Flex, Text, useColorModeValue} from "@chakra-ui/react";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../hooks/useAuth";
import {configs} from "../../Configs";
import {FormattedMessage} from 'react-intl';

export const UserCard = ({user}) => {
    const {hasPermission} = useAuth();
    const navigate = useNavigate();

    return (<Flex
        bg={useColorModeValue("gray.300", "gray.600")}
        minHeight="3rem"
        my={3}
        p={3}
        rounded="lg"
        alignItems="right"
        justifyContent="space-between"
        _hover={{
            opacity: 0.9, cursor: "pointer", transform: "translateY(-3px)",
        }}
        onClick={() => hasPermission('users', 'fe_edit') || hasPermission('users', 'fe_delete') ? navigate(`/users/${user.user_id}`, {replace: true}) : null}
    >
        <Text style={{overflow: 'hidden'}}>{user.username}</Text>
        <Text>
            {configs.roles.filter(role => user.roles.includes(role.value)).map(role => (
                <Badge variant='solid' colorScheme={role.value !== 'disabled' ? 'green' : 'red'}
                       mr={1}>
                    <FormattedMessage
                        id={`roles.${role.value}`}
                        description={role.label}
                        defaultMessage={role.label}
                    />
                </Badge>))}
        </Text>
    </Flex>);
};
