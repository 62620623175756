import React, {useContext, useEffect, useState} from 'react';
import {Box, Input, Switch, useColorModeValue} from "@chakra-ui/react";
import {useIntl} from 'react-intl';
import {LocaleContext} from '../Theme/LocaleProvider';

const CountdownTimer = ({onCountdownComplete}) => {
    const context = useContext(LocaleContext);
    const intl = useIntl();
    const [timeLeft, setTimeLeft] = useState(300); // Default 300 seconds (5 minutes)
    const [isTimerActive, setIsTimerActive] = useState(false); // Timer is active by default
    const [inputMinutes, setInputMinutes] = useState(5); // Default 5 minutes

    const cstmMutliSelect = {
        width: '100%',
        minWidth: '0px',
        outline: '2px solid transparent',
        outlineOffset: '2px',
        position: 'relative',
        appearance: 'none',
        transitionProperty: 'var(--chakra-transition-property-common)',
        transitionDuration: 'var(--chakra-transition-duration-normal)',
        fontSize: 'var(--chakra-fontSizes-lg)',
        paddingInlineStart: 'var(--chakra-space-4)',
        paddingInlineEnd: 'var(--chakra-space-4)',
        minHeight: 'var(--chakra-sizes-12)',
        borderRadius: 'var(--chakra-radii-md)',
        border: '0px solid',
        borderColor: 'var(--chakra-colors-transparent)',
        background: useColorModeValue("var(--chakra-colors-gray-300)", "var(--chakra-colors-gray-600)"),
        marginTop: 'var(--chakra-space-6)',
        "&:hover": {
            background: useColorModeValue('var(--chakra-colors-gray-200)', 'var(--chakra-colors-whiteAlpha-100)'),
        },
        "&:focus-visible": {
            background: 'var(--chakra-colors-transparent)',
            borderColor: '#63b3ed'
        }
    }

    const cstmMutliSelectMenu = {
        background: useColorModeValue("var(--chakra-colors-gray-300)", "var(--chakra-colors-gray-600)"),
        color: useColorModeValue("var(--chakra-colors-gray-800)", "var(--chakra-colors-gray-300)"),
    }

    const cstmMutliSelectOptions = {
        background: useColorModeValue("var(--chakra-colors-gray-300)", "var(--chakra-colors-gray-600)"),

        "&:hover": {
            background: useColorModeValue('var(--chakra-colors-gray-200)', 'var(--chakra-colors-whiteAlpha-100)'),
        },
        "&:focus-visible": {
            background: 'var(--chakra-colors-transparent)',
            borderColor: '#63b3ed'
        }
    }

    useEffect(() => {
        let timer;
        if (isTimerActive && timeLeft > 0) {
            timer = setInterval(() => {
                setTimeLeft((prevTime) => prevTime - 1);
            }, 1000);
        } else if (timeLeft === 0) {
            onCountdownComplete()
            // window.location.reload(); // Refresh the page when the timer hits zero
            setTimeLeft(inputMinutes * 60)
        }

        return () => clearInterval(timer); // Cleanup on component unmount
    }, [timeLeft, isTimerActive]);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secondsLeft = seconds % 60;
        return `${minutes}:${secondsLeft < 10 ? '0' : ''}${secondsLeft}`;
    };

    const handleCheckboxChange = () => {
        setIsTimerActive(!isTimerActive);
        if (!isTimerActive) {
            // Reset the timer when enabling it again
            setTimeLeft(inputMinutes * 60);
        }
    };

    const handleMinutesChange = (e) => {
        let value = parseFloat(e.target.value);
        if (isNaN(value) || value < 0.5) {
            value = 0.5; // Minimum value is 0.5 minutes (30 seconds)
        }
        setInputMinutes(value);
        setTimeLeft(value * 60); // Update the timer countdown when the user changes minutes
    };

    return (
        <Box style={styles.overlay}>
            <Box style={styles.controls}>
                {isTimerActive && <span>{formatTime(timeLeft)}</span>}
                {!isTimerActive && <Input
                    w={'50px'}
                    id="minutes"
                    placeholder={intl.formatMessage({id: 'countdown.minutes'})}
                    bgColor={'white'}
                    textColor={'black'}
                    type="number"
                    variant="filled"
                    size="sm"
                    value={inputMinutes}
                    onChange={handleMinutesChange}
                    step="0.5"
                    min="0.5"
                    classNames={
                        {
                            control: (state) => state.isFocused ? 'border-red-600' : 'border-grey-300'
                        }
                    }
                    styles={{
                        control: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelect}),
                        menu: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelectMenu}),
                        option: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelectOptions})
                    }}
                />}
                <Switch
                    mr={1}
                    onChange={handleCheckboxChange}
                    isChecked={isTimerActive}
                    id="countdown"
                    size="sm"
                    colorScheme="blue"
                    variant="ghost"
                />
            </Box>
        </Box>
    );
};

const styles = {
    overlay: {
        position: 'fixed',
        top: '80px',
        left: '10px',
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        color: 'white',
        padding: '10px',
        borderRadius: '5px',
        fontSize: '20px',
        zIndex: 1000
    },
    controls: {
        // position: 'fixed',
        // top: '50px',
        // left: '10px',
        // zIndex: 1000,
    }
};

export default CountdownTimer;
