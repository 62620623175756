import {Box, VStack} from '@chakra-ui/react';
import TextScatterplotMap from './TextScatterplotMap';
import {configs} from "../../Configs";


export const Raqib1Map = () => {

    return (
        <Box w="100%">
            <VStack w="100%">
                <Box w="100vw" h={"calc(100vh - 72px);"} style={{position: 'relative'}}>
                    <TextScatterplotMap
                        cluster={true}
                        shouldRefresh={configs.raqib1.should_refresh_default}
                        refreshInterval={configs.raqib1.refresh_interval_default}
                    />
                </Box>
            </VStack>
        </Box>
    );
};
