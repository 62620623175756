import {validateToken} from "./jwt";

export const getSession = () => {
    const accessToken = localStorage.getItem("accessToken");
    return accessToken && validateToken(accessToken) ? accessToken : null;
}

export const setSession = (accessToken, refreshToken = null) => {
    if (accessToken) {
        localStorage.setItem("accessToken", accessToken);
    } else {
        localStorage.removeItem("accessToken");
    }
    if (refreshToken) {
        localStorage.setItem("refreshToken", refreshToken);
    }
};

export const resetSession = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
};