import {Box, Text,} from "@chakra-ui/react";
import {useEffect, useRef} from "react";
import {setTextDirection} from '../../utils/helpers';

export const Hashtag = ({title, ...rest}) => {
    const titleRef = useRef(null);

    useEffect(() => {
        setTextDirection(titleRef.current, title, 'justify');
    }, [title]);

    return (
        <Box>
            <Text
                size='md'
                fontFamily={"Cairo"}
                // fontFamily={"Cairo, sans-serif"}
                ref={titleRef}>{title}</Text>
        </Box>
    );
};
