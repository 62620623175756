import {
    Box,
    Button,
    Center,
    Flex,
    FormControl,
    FormErrorMessage,
    Heading,
    Image,
    Input,
    Spinner,
    Textarea,
    useToast
} from '@chakra-ui/react';
import {useEffect, useState} from "react";
import {Navigate, useNavigate, useParams} from "react-router-dom";
import {Controller, useForm} from "react-hook-form";

import ReactSelect from 'react-select';
import 'react-trumbowyg/dist/trumbowyg.min.css'
import Trumbowyg from 'react-trumbowyg'
import '../../../node_modules/trumbowyg/dist/plugins/fontfamily/trumbowyg.fontfamily';
import useAxios from "../../hooks/useAxois";
import {FormattedMessage, useIntl} from 'react-intl';


const NewsArticleAddOrEdit = () => {
    const intl = useIntl();
    const [data, setData] = useState(null);
    const navigate = useNavigate();
    const toast = useToast();
    const {newsId} = useParams();
    const [staticTags, setStaticTags] = useState([]);
    const [newsTags, setNewsTags] = useState([]);
    const [newsCats, setNewsCats] = useState([]);
    const [isLoading, setIsLoading] = useState(newsId ? 1 : 0);
    const [loadingTags, setLoadingTags] = useState(true);
    const [loadingCats, setLoadingCats] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [description, setDescription] = useState(null);
    const axiosInstance = useAxios();

    useEffect(() => {
        setDescription(data?.description);
    }, [data]);

    useEffect(() => {
        // get news tags
        axiosInstance.get('/post_tag').then((data) => {
            setStaticTags(data.data.filter(tag => tag.is_static).map(tag => tag.tag_id));
            setNewsTags(data.data.map(tag => ({
                label: tag.title,
                value: tag.tag_id
            })))
        }).catch((error) => {
            if (error.response && error.response.status === 404) return;
            console.error(error);
        }).finally(() => setLoadingTags(false));

        // get news cats
        axiosInstance.get('/news_cat').then((data) => setNewsCats(data.data.map(cat => ({
            label: cat.title,
            value: cat.cat_id
        })))).catch((error) => {
            if (error.response && error.response.status === 404) return;
            console.error(error);
        }).finally(() => setLoadingCats(false));
    }, []);

    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    function removeFontFamilyInlineStyles(html) {
        return html;
        // return html.replace(/style="[^"]*"/g, '');
    }

    const {
        handleSubmit,
        register,
        control,
        setValue,
        setError,
        watch,
        formState: {errors, isSubmitting}
    } = useForm();

    useEffect(() => {
        if (!newsId || loadingTags || loadingCats) return;

        // get editable post
        setHasError(false);
        axiosInstance.get(`/news/${newsId}`).then((res) => {
            const curTagIds = res.data?.tags?.map(curTags => curTags.tag_id) || [];
            const curCatIds = res.data?.categories?.map(curCats => curCats.cat_id) || [];
            setValue('title', res.data.title);
            setValue('tags', newsTags.filter(tag => curTagIds.includes(tag.value)));
            setValue('categories', newsCats.filter(cat => curCatIds.includes(cat.value)));
            setValue('lead', res.data.lead);
            setValue('description', res.data.description);
            setValue('link', res.data.link);
            setData(res.data);
            setHasError(false);
        }).catch((error) => {
            if (error.response && error.response.status === 404) return;
            setHasError(true);
            console.error(error);
        }).finally(() => setIsLoading(v => v - 1));
    }, [loadingTags, loadingCats]);

    const onSubmit = async (values) => {
        try {
            const payload = new FormData();
            if (values?.file?.length) {
                payload.append('file', values.file[0]);
            }
            payload.append('title', values.title);
            payload.append('lead', values.lead);
            // payload.append('description', removeFontFamilyInlineStyles(values.description));
            if (description) {
                payload.append('description', removeFontFamilyInlineStyles(description));
            }
            payload.append('link', values.link)

            values.tags?.forEach((tag) => payload.append('tags', tag.value));
            // console.log(values.tags?.filter(tag => staticTags.includes(tag.value)).length > 0)
            if (values.tags?.filter(tag => staticTags.includes(tag.value)).length > 0)
                values.categories?.forEach((cat) => payload.append('categories', cat.value));
            else {
                values.categories = []
            }


            if (!payload.get('description')) {
                setError('description', {
                    type: 'manual',
                    message: intl.formatMessage(
                        {
                            id: "validation.fieldRequired",
                            defaultMessage: "{field} is required"
                        },
                        {
                            field: intl.formatMessage(
                                {
                                    id: "news_articles.articleDescription",
                                    defaultMessage: "Description"
                                }
                            )
                        }
                    )
                });
                return
            }

            if (data) {
                await axiosInstance.put(`/news/${newsId}`, payload, {headers: {'Content-Type': 'multipart/form-data'}});
            } else {
                await axiosInstance.post(`/news/create`, payload, {headers: {'Content-Type': 'multipart/form-data'}});
            }

            toast({
                title: data ? intl.formatMessage({
                    id: 'news_articles.updated',
                    defaultMessage: 'Article Updated'
                }) : intl.formatMessage({
                    id: 'news_articles.created',
                    defaultMessage: 'Article Created'
                }),
                status: "success",
                isClosable: true,
                diration: 1500,
            });
            navigate('/news_articles');
        } catch (err) {
            let msg = err?.response?.data?.detail || [];
            if (msg && !Array.isArray(msg)) {
                msg = [{msg: msg}]
            }
            if (!msg.length) {
                msg = [{
                    msg:
                        intl.formatMessage({
                            id: 'responses.unknownError',
                            defaultMessage: 'Something went wrong. Please try again'
                        })
                }]
            }
            msg.map(err =>
                toast({
                    title: err.msg,
                    status: "error",
                    isClosable: true,
                    diration: 1500,
                })
            );
        }
    }

    const cstmMutliSelect = {
        width: '100%',
        minWidth: '0px',
        outline: '2px solid transparent',
        outlineOffset: '2px',
        position: 'relative',
        appearance: 'none',
        transitionProperty: 'var(--chakra-transition-property-common)',
        transitionDuration: 'var(--chakra-transition-duration-normal)',
        fontSize: 'var(--chakra-fontSizes-lg)',
        paddingInlineStart: 'var(--chakra-space-4)',
        paddingInlineEnd: 'var(--chakra-space-4)',
        minHeight: 'var(--chakra-sizes-12)',
        borderRadius: 'var(--chakra-radii-md)',
        border: '0px solid',
        borderColor: 'var(--chakra-colors-transparent)',
        background: 'var(--chakra-colors-gray-300)',
        "&:hover": {
            background: 'var(--chakra-colors-gray-200)',
        },
        "&:focus-visible": {
            background: 'var(--chakra-colors-transparent)',
            borderColor: '#63b3ed'
        }
    }

    const cstmMutliSelectMenu = {
        background: "var(--chakra-colors-gray-300)",
        color: "var(--chakra-colors-gray-800)",
    }

    const cstmMutliSelectOptions = {
        background: "var(--chakra-colors-gray-300)",

        "&:hover": {
            background: 'var(--chakra-colors-gray-200)',
        },
        "&:focus-visible": {
            background: 'var(--chakra-colors-transparent)',
            borderColor: '#63b3ed'
        }
    }

    if (!isLoading && !data && newsId) {
        return <Navigate to="/error/404"/>
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{width: '100%'}}>
            <Flex
                wrap={'wrap'}
                p={5}
                pb={50}
                m={'auto'}
                w={{base: '100%', md: '80%', xl: '75%'}}
                style={{position: 'relative'}}
            >
                {!!isLoading && <Center w={'100%'} h={'100%'} style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    zIndex: 1,
                    backgroundColor: 'rgba(255, 255, 255, 0.5)'
                }}>
                    <Spinner size={"xl"} color="green"/>
                </Center>}
                <Box w={'100%'} p={5} borderBottomWidth={'1px'} borderBottomColor={'#e8e8e8'} mb={4}>
                    <Heading fontFamily={"Cairo"}>
                        {data ? <FormattedMessage id="news_articles.editArticle"/> :
                            <FormattedMessage id="news_articles.addArticle"/>}
                    </Heading>
                </Box>
                <Box w={['100%', '80%']}>
                    <Box bgColor={'#f8f8f8'} p={5} mb={4} fontFamily={"Cairo, sans-serif"}>
                        <label htmlFor={'title'} style={{fontWeight: 800}}>
                            <FormattedMessage
                                id="news_articles.articleTitle"
                                defaultMessage="Title"
                            />
                        </label>
                        <FormControl isInvalid={errors.title} mt={2}>
                            <Input
                                id={'title'}
                                placeholder={
                                    intl.formatMessage({
                                        id: 'news_articles.articleTitle',
                                        defaultMessage: 'Title'
                                    })
                                }
                                background={'gray.300'}
                                dir={'auto'}
                                type="text"
                                variant="filled"
                                {...register("title", {
                                    required: intl.formatMessage(
                                        {
                                            id: "validation.fieldRequired",
                                            defaultMessage: "{field} is required"
                                        },
                                        {
                                            field: intl.formatMessage(
                                                {
                                                    id: "news_articles.articleTitle",
                                                    defaultMessage: "Title"
                                                }
                                            )
                                        }
                                    ),
                                    minLength: {
                                        value: 1,
                                        message: intl.formatMessage(
                                            {
                                                id: "validation.fieldMinLen",
                                                defaultMessage: "{field} must be at least {length} characters"
                                            },
                                            {
                                                field: intl.formatMessage({
                                                    id: "news_articles.articleTitle",
                                                    defaultMessage: "Title"
                                                }),
                                                length: 1
                                            }
                                        )
                                    }
                                })}
                            />
                            <FormErrorMessage>
                                {errors.title && errors.title.message}
                            </FormErrorMessage>
                        </FormControl>
                    </Box>
                    <Box bgColor={'#f8f8f8'} p={5} mb={4} fontFamily={"Cairo, sans-serif"}>
                        <label htmlFor={'Tags'} style={{fontWeight: 800}}>
                            <FormattedMessage
                                id="news_articles.articleTags"
                                defaultMessage="Tags"
                            />
                        </label>
                        <FormControl isInvalid={errors.tags} mt={2}>
                            <Controller
                                rules={{
                                    required: intl.formatMessage(
                                        {
                                            id: "validation.fieldRequired",
                                            defaultMessage: "{field} is required"
                                        },
                                        {
                                            field: intl.formatMessage(
                                                {
                                                    id: "news_articles.articleTags",
                                                    defaultMessage: "Tags"
                                                }
                                            )
                                        }
                                    )
                                }}
                                name="tags"
                                control={control}
                                render={({field: {onChange, value}}) => (
                                    <ReactSelect
                                        id={'tags'}
                                        onChange={onChange}
                                        placeholder={
                                            intl.formatMessage({
                                                id: 'news_articles.articleTags',
                                                defaultMessage: 'Tags'
                                            })
                                        }
                                        value={value}
                                        isMulti
                                        options={newsTags}
                                        closeMenuOnSelect={false}
                                        classNames={
                                            {
                                                control: (state) => state.isFocused ? 'border-red-600' : 'border-grey-300'
                                            }
                                        }
                                        styles={{
                                            control: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelect}),
                                            menu: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelectMenu}),
                                            option: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelectOptions})
                                        }}
                                    />
                                )}
                            />
                            <FormErrorMessage>
                                {errors.tags && errors.tags.message}
                            </FormErrorMessage>
                        </FormControl>
                    </Box>

                    {watch('tags')?.filter(tg => staticTags.includes(tg.value))?.length > 0 &&
                        <Box bgColor={'#f8f8f8'} p={5} mb={4} fontFamily={"Cairo, sans-serif"}>
                            <label htmlFor={'categories'} style={{fontWeight: 800}}>
                                <FormattedMessage
                                    id="news_articles.articleCategories"
                                    defaultMessage="Categories"
                                />
                            </label>
                            <FormControl isInvalid={errors.categories} mt={2}>
                                <Controller
                                    rules={{
                                        required: intl.formatMessage(
                                            {
                                                id: "validation.fieldRequired",
                                                defaultMessage: "{field} is required"
                                            },
                                            {
                                                field: intl.formatMessage(
                                                    {
                                                        id: "news_articles.articleCategories",
                                                        defaultMessage: "Categories"
                                                    }
                                                )
                                            }
                                        )
                                    }}
                                    name="categories"
                                    control={control}
                                    render={({field: {onChange, value}}) => (
                                        <ReactSelect
                                            id={'categories'}
                                            onChange={onChange}
                                            placeholder={
                                                intl.formatMessage({
                                                    id: 'news_articles.articleCategories',
                                                    defaultMessage: 'Categories'
                                                })
                                            }
                                            value={value}
                                            isMulti
                                            options={newsCats}
                                            closeMenuOnSelect={false}
                                            classNames={
                                                {
                                                    control: (state) => state.isFocused ? 'border-red-600' : 'border-grey-300'
                                                }
                                            }
                                            styles={{
                                                control: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelect}),
                                                menu: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelectMenu}),
                                                option: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelectOptions})
                                            }}
                                        />
                                    )}
                                />
                                <FormErrorMessage>
                                    {errors.categories && errors.categories.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>}
                    <Box bgColor={'#f8f8f8'} p={5} mb={4} fontFamily={"Cairo, sans-serif"}>
                        <label htmlFor={'lead'} style={{fontWeight: 800}}>
                            <FormattedMessage
                                id="news_articles.articleLead"
                                defaultMessage="Lead"
                            />
                        </label>
                        <FormControl isInvalid={errors.lead} mt={2}>
                            <Textarea
                                id={'lead'}
                                dir={'auto'}
                                placeholder={
                                    intl.formatMessage({
                                        id: 'news_articles.articleLead',
                                        defaultMessage: 'Lead'
                                    })
                                }
                                background={'gray.300'}
                                type="text"
                                variant="filled"
                                {...register("lead", {
                                    required: intl.formatMessage(
                                        {
                                            id: "validation.fieldRequired",
                                            defaultMessage: "{field} is required"
                                        },
                                        {
                                            field: intl.formatMessage(
                                                {
                                                    id: "news_articles.articleLead",
                                                    defaultMessage: "Lead"
                                                }
                                            )
                                        }
                                    )
                                })}
                            />
                            <FormErrorMessage>
                                {errors.lead && errors.lead.message}
                            </FormErrorMessage>
                        </FormControl>
                    </Box>
                    <Box bgColor={'#f8f8f8'} p={5} mb={4} fontFamily={"Cairo, sans-serif"}>
                        <label htmlFor={'description'} style={{fontWeight: 800}}>
                            <FormattedMessage
                                id="news_articles.articleDescription"
                                defaultMessage="Description"
                            />
                        </label>
                        <FormControl isInvalid={errors.description} mt={2}>
                            <Controller
                                // rules={{
                                //     required: intl.formatMessage(
                                //         {
                                //             id: "validation.fieldRequired",
                                //             defaultMessage: "{field} is required"
                                //         },
                                //         {
                                //             field: intl.formatMessage(
                                //                 {
                                //                     id: "news_articles.articleDescription",
                                //                     defaultMessage: "Description"
                                //                 }
                                //             )
                                //         }
                                //     )
                                // }}
                                name="description"
                                control={control}
                                render={({field: {onChange, value}}) => {
                                    return <Trumbowyg
                                        id='text-editor'
                                        dir={'ltr'}
                                        // data={removeFontFamilyInlineStyles(value || '')}
                                        data={value || ''}
                                        removeformatPasted={true}
                                        // buttons={[
                                        //     'fontfamily',
                                        //     'viewHTML',
                                        //     'undo',
                                        //     'redo',
                                        //     'formatting',
                                        //     'strong',
                                        //     'em',
                                        //     'del',
                                        //     'superscript',
                                        //     'subscript',
                                        //     'link',
                                        //     'insertImage',
                                        //     'justifyLeft',
                                        //     'justifyCenter',
                                        //     'justifyRight',
                                        //     'justifyFull',
                                        //     'unorderedList',
                                        //     'orderedList',
                                        //     'horizontalRule'
                                        // ]}
                                        // plugins={{
                                        //     fontfamily: {
                                        //         fontList: [
                                        //             {name: 'Arial', family: 'Arial, Helvetica, sans-serif'},
                                        //             {name: 'Open Sans', family: '\'Open Sans\', sans-serif'}
                                        //         ]
                                        //     }
                                        // }}
                                        // onChange={(e) => onChange(removeFontFamilyInlineStyles(e.target.innerHTML))}

                                        // somehow using onChange makes farsi text reversed. so im using custom field validation with serError and removing rules from this field
                                        onChange={(e) => setDescription(e.target.innerHTML)}

                                    />
                                }

                                }
                            />
                            <FormErrorMessage>
                                {errors.description && errors.description.message}
                            </FormErrorMessage>
                        </FormControl>
                    </Box>
                    <Box bgColor={'#f8f8f8'} p={5} mb={4} fontFamily={"Cairo, sans-serif"}>
                        <label htmlFor={'citation'} style={{fontWeight: 800}}>
                            <FormattedMessage
                                id="news_articles.articleCitation"
                                defaultMessage="Citation URL"
                            />
                        </label>
                        <FormControl isInvalid={errors.link} mt={2}>
                            <Input
                                id={'citation'}
                                placeholder={
                                    intl.formatMessage({
                                        id: 'news_articles.articleCitation',
                                        defaultMessage: 'Citation URL'
                                    })
                                }
                                background={'gray.300'}
                                type="text"
                                variant="filled"
                                {...register("link", {
                                    required: intl.formatMessage(
                                        {
                                            id: "validation.fieldRequired",
                                            defaultMessage: "{field} is required"
                                        },
                                        {
                                            field: intl.formatMessage(
                                                {
                                                    id: "news_articles.articleCitation",
                                                    defaultMessage: "Citation"
                                                }
                                            )
                                        }
                                    )
                                })}
                            />
                            <FormErrorMessage>
                                {errors.link && errors.link.message}
                            </FormErrorMessage>
                        </FormControl>
                    </Box>
                    <Box display={{base: "none", md: "block"}}>
                        <Button
                            colorScheme="green"
                            type="submit"
                            size={'sm'}
                            isLoading={isSubmitting}
                            loadingText={data ? intl.formatMessage({
                                id: 'news_articles.updating',
                                defaultMessage: 'Updating...'
                            }) : intl.formatMessage({
                                id: 'news_articles.creating',
                                defaultMessage: 'Creating...'
                            })}
                        >
                            {data ? intl.formatMessage({
                                id: 'news_articles.update',
                                defaultMessage: 'Update'
                            }) : intl.formatMessage({
                                id: 'news_articles.create',
                                defaultMessage: 'Create'
                            })}
                        </Button>
                    </Box>
                </Box>
                <Box w={['100%', '20%']} p={5} pt={0}>
                    <Box bgColor={'#f8f8f8'} p={5} mb={4} fontFamily={"Cairo, sans-serif"}>
                        <label htmlFor={'image'} style={{fontWeight: 800}}>
                            <FormattedMessage
                                id="news_articles.articleImage"
                                defaultMessage="Image"
                            />
                        </label>
                        <FormControl isInvalid={errors.file} mt={2}>
                            <Controller
                                name="file"
                                control={control}
                                rules={newsId ? {} : {
                                    required: intl.formatMessage(
                                        {
                                            id: "validation.fieldRequired",
                                            defaultMessage: "{field} is required"
                                        },
                                        {
                                            field: intl.formatMessage(
                                                {
                                                    id: "news_articles.articleImage",
                                                    defaultMessage: "Image"
                                                }
                                            )
                                        }
                                    )
                                }}
                                render={({field: {onChange, value}}) => (
                                    <input
                                        id={'image'}
                                        style={{backgroundColor: 'white', padding: '4px'}}
                                        accept=".jpg, .jpeg, .png"
                                        type="file"
                                        onChange={(e) => {
                                            onChange(e.target.files);
                                            handleFileChange(e);
                                        }}
                                    />
                                )}
                            />
                            <FormErrorMessage>
                                {errors.file && errors.file.message}
                            </FormErrorMessage>
                        </FormControl>
                    </Box>

                    {selectedFile &&
                        <Box bgColor={'#f8f8f8'} p={2} mb={4} fontFamily={"Cairo, sans-serif"}>
                            <label htmlFor={'chosen-image'} style={{fontWeight: 800}}>
                                <FormattedMessage
                                    id="news_articles.articleChosenImage"
                                    defaultMessage="Chosen Image"
                                />
                            </label>
                            <Image mt={2} id={'chosen-image'} src={URL.createObjectURL(selectedFile)}
                                   alt="Chosen image"/>
                        </Box>}

                    {data?.image &&
                        <Box bgColor={'#f8f8f8'} p={2} mb={4} fontFamily={"Cairo, sans-serif"}>
                            <label htmlFor={'current-image'} style={{fontWeight: 800}}>
                                <FormattedMessage
                                    id="news_articles.articleCurImage"
                                    defaultMessage="Current Image"
                                />
                            </label>
                            <Image mt={2} id={'current-image'} src={data?.image} alt="Current image"/>
                        </Box>}

                    <Box display={{base: "block", sm: "none"}} my={15}>
                        <Button
                            colorScheme="green"
                            w={'100%'}
                            type="submit"
                            size={'sm'}
                            isLoading={isSubmitting}
                            loadingText={data ? intl.formatMessage({
                                id: 'news_articles.updating',
                                defaultMessage: 'Updating...'
                            }) : intl.formatMessage({
                                id: 'news_articles.creating',
                                defaultMessage: 'Creating...'
                            })}
                        >
                            {data ? intl.formatMessage({
                                id: 'news_articles.update',
                                defaultMessage: 'Update'
                            }) : intl.formatMessage({
                                id: 'news_articles.create',
                                defaultMessage: 'Create'
                            })}
                        </Button>
                    </Box>
                </Box>
            </Flex>
        </form>
    );
};

export default NewsArticleAddOrEdit;
