import {Button, Center, Container, Spinner, Text, useColorModeValue, useToast,} from "@chakra-ui/react";
import {useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {AddUpdateTagModal} from "./AddUpdateTagModal";
import {useAuth} from "../../hooks/useAuth";
import useAxios from "../../hooks/useAxois";
import {FormattedMessage, useIntl} from 'react-intl';

export const TagDetail = () => {
    const intl = useIntl();
    const axiosInstance = useAxios();
    const {hasPermission} = useAuth();
    const [tag, setTag] = useState({});
    const [loading, setLoading] = useState(true);
    const isMounted = useRef(false);
    const {tagId} = useParams();
    const navigate = useNavigate();
    const toast = useToast();
    const background = useColorModeValue("gray.300", "gray.600");

    useEffect(() => {
        if (isMounted.current) return;
        fetchApi();
        isMounted.current = true;
    }, [tagId]);

    const fetchApi = () => {
        setLoading(true);
        axiosInstance
            .get(`/post_tag/${tagId}`)
            .then((res) => {
                setTag(res.data);
            })
            .catch((error) => console.error(error))
            .finally(() => {
                setLoading(false);
            });
    };

    const delateApi = () => {
        setLoading(true);
        axiosInstance
            .delete(`/post_tag/${tagId}`)
            .then(() => {
                toast({
                    title: intl.formatMessage({id: 'post_tags.deleted', defaultMessage: "Tag Deleted"}),
                    status: "success",
                    isClosable: true,
                    diration: 1500,
                });
                navigate("/post_tags");
            })
            .catch((err) => {
                console.error(err);
                toast({
                    title: intl.formatMessage({id: 'post_tags.notDeleted', defaultMessage: "Could not delete tag"}),
                    status: "error",
                    isClosable: true,
                    diration: 2000,
                });
            })
            .finally(() => setLoading(false));
    };

    if (loading) {
        return (
            <Container mt={6}>
                <Center mt={6}>
                    <Spinner
                        thickness="4px"
                        speed="0.65s"
                        emptyColor="green.200"
                        color="green.500"
                        size="xl"
                    />
                </Center>
            </Container>
        );
    }

    return (
        <>
            <Container mt={6}>
                <Button
                    colorScheme="gray"
                    onClick={() => navigate("/post_tags", {replace: true})}
                >
                    <FormattedMessage
                        id="navigations.back"
                        defaultMessage="Back"
                    />
                </Button>
            </Container>
            <Container
                bg={background}
                minHeight="7rem"
                mt={3}
                p={3}
                rounded="lg"
                alignItems="center"
                justifyContent="space-between"
            >
                <Text fontSize={22}>{tag.title}</Text>
                {hasPermission('post_tag', 'fe_edit') && <AddUpdateTagModal
                    mt={3}
                    editable={true}
                    defaultValues={{
                        title: tag.title,
                        is_thematic: tag.is_thematic,
                        is_spectral: tag.is_spectral,
                        updated_at: tag.updated_at,
                        created_at: tag.created_at,
                    }}
                    onSuccess={fetchApi}
                />}

                {hasPermission('post_tag', 'fe_delete') && !tag.is_static && <Button
                    mt={3}
                    isLoading={loading}
                    colorScheme="red"
                    width="100%"
                    onClick={delateApi}
                >
                    <FormattedMessage
                        id="post_tags.delete"
                        defaultMessage="Delete Tag"
                    />
                </Button>}
            </Container>
        </>
    );
};
