import {
    Text,
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    IconButton,
    Select,
    useColorModeValue,
    useDisclosure
} from "@chakra-ui/react";
import {configs} from "../../Configs";
import TermsModal from './TermsModal';
import {FaRegStar, FaStar} from "react-icons/fa";
import {FormattedMessage, useIntl} from 'react-intl';
import {useContext, useState} from 'react';
import {LocaleContext} from "../Theme/LocaleProvider";
import CountdownTimer from "./CountdownTimer";

export const NavBar = ({
                           resultsCount,
                           hashtags,
                           hashtag,
                           setHashtag,
                           socialPlatform,
                           setSocialPlatform,
                           timerange,
                           setTimerange,
                           terms,
                           setTerms,
                           hasStar,
                           setHasStar,
                           // include,
                           // setInclude,
                           // exclude,
                           // setExclude,
                           onSubmit,
                           onClear,
                           ...rest
                       }) => {
    const intl = useIntl();
    const context = useContext(LocaleContext);
    const {isOpen, onOpen, onClose} = useDisclosure()

    const showTermsModal = () => {
        onOpen();
    }

    const clear = () => {
        setTerms([{'logic': 'OR', 'terms': [{'exclude': false, 'phrase': ''}]}]);
        return onClear();
    }

    const validTerms = terms.filter(paranthesis => paranthesis['terms'].filter(item => item['phrase'].replace(/\s/g, '').length > 0).length > 0);
    const validCnt = validTerms.map(paranthesis => paranthesis['terms'].length).reduce((accumulator, currentValue) => accumulator + currentValue, 0);

    return (
        <>
            <CountdownTimer onCountdownComplete={onSubmit}/>
            <TermsModal setTerms={setTerms} terms={terms} isOpen={isOpen} onOpen={onOpen} onClose={onClose}/>
            <Box>
                <Flex
                    as="nav"
                    align="center"
                    justify="center"
                    wrap="wrap"
                    padding="1rem"
                    direction={{base: 'column', md: 'row'}}
                    color={"gray.800"}
                >
                    <FormControl mx={{md: 2}} w={{base: '100%', md: 'auto'}}>
                        <FormLabel>
                            <FormattedMessage
                                id="raqib6.platform"
                                defaultMessage="Platform"
                            />
                        </FormLabel>
                        <Select
                            variant="filled"
                            size="md"
                            value={socialPlatform}
                            onChange={e => setSocialPlatform(e.target.value)}
                            color={useColorModeValue("gray.800", "white")}
                        >
                            <option key={"platfrom:"} value="">
                                <FormattedMessage
                                    id="raqib6.all"
                                    defaultMessage="All"
                                />
                            </option>
                            {configs.raqib6.social_platforms.map((platfrom) => (
                                <option key={"platfrom:" + platfrom.value}
                                        value={platfrom.value}>
                                    <FormattedMessage
                                        id={`raqib6.${platfrom.value}`}
                                        defaultMessage={platfrom.label}
                                    />
                                </option>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl mx={{md: 2}} w={{base: '100%', md: 'auto'}} mt={{base: 3, md: 0}}>
                        <FormLabel>
                            <FormattedMessage
                                id="raqib6.hashtag"
                                defaultMessage="Hashtag"
                            />
                        </FormLabel>
                        <Select
                            variant="filled"
                            size="md"
                            value={hashtag}
                            onChange={e => setHashtag(e.target.value)}
                        >
                            <option key={"hashtag:"} value="">
                                <FormattedMessage
                                    id="raqib6.all"
                                    defaultMessage="All"
                                />
                            </option>
                            {hashtags.map((ht) => (
                                <option key={"hashtag:" + ht.value} value={ht.value}>{ht.label}</option>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl mx={{md: 2}} w={{base: '100%', md: 'auto'}} mt={{base: 3, md: 0}}>
                        <FormLabel>
                            <FormattedMessage
                                id="raqib6.timeFilter"
                                defaultMessage="Time filter"
                            />
                        </FormLabel>
                        <Select
                            variant="filled"
                            size="md"
                            onChange={e => setTimerange(e.target.value)}
                            value={timerange}
                        >
                            <option key={"timerange:"} value="0">
                                <FormattedMessage
                                    id="raqib6.all"
                                    defaultMessage="All"
                                />
                            </option>
                            {configs.raqib6.timeranges.map((tr) => (
                                <option key={"timerange:" + tr.value} value={tr.value}>
                                    <FormattedMessage
                                        id={`raqib6.${tr.label}`}
                                        defaultMessage={tr.label}
                                    />
                                </option>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl mx={{md: 2}} w={{base: '100%', md: 'auto'}} mt={{base: 3, md: 0}}>
                        <FormLabel>
                            <FormattedMessage
                                id="raqib6.star"
                                defaultMessage="Star"
                            />
                        </FormLabel>

                        <IconButton
                            w={{base: '100%', md: 'auto'}}
                            size={'md'}
                            variant={'outline'}
                            icon={
                                hasStar ?
                                    <FaStar style={{color: 'orange', display: 'inline-block'}} cursor={'pointer'}/> :
                                    <FaRegStar style={{color: 'orange', display: 'inline-block'}} cursor={'pointer'}/>
                            }
                            onClick={() => setHasStar(!hasStar)}
                        />
                    </FormControl>

                    {/*<FormControl mx={{md: 2}} w={{base: '100%', md: 'auto'}}>*/}
                    {/*    <FormLabel>Include</FormLabel>*/}
                    {/*    <Input*/}
                    {/*        type="text"*/}
                    {/*        variant="filled"*/}
                    {/*        size="sm"*/}
                    {/*        value={include}*/}
                    {/*        onChange={e => setInclude(e.target.value)}*/}
                    {/*    />*/}
                    {/*</FormControl>*/}

                    {/*<FormControl mx={{md: 2}} w={{base: '100%', md: 'auto'}}>*/}
                    {/*    <FormLabel>Exclude</FormLabel>*/}
                    {/*    <Input*/}
                    {/*        type="text"*/}
                    {/*        variant="filled"*/}
                    {/*        size="sm"*/}
                    {/*        value={exclude}*/}
                    {/*        onChange={e => setExclude(e.target.value)}*/}
                    {/*    />*/}
                    {/*</FormControl>*/}
                    <FormControl mx={{md: 2}} w={{base: '100%', md: 'auto'}}>
                        <Button w={{base: '100%', md: 'auto'}} size="md" onClick={() => showTermsModal()}
                                colorScheme={'blue'}
                                me={{md: 1}}
                                mt={{base: 3, md: 8}}>
                            <FormattedMessage
                                id="raqib6.terms"
                                defaultMessage="Terms"
                            />{validCnt > 0 ? ` (${validCnt})` : ''}
                        </Button>
                        <Button w={{base: '100%', md: 'auto'}} size="md" onClick={() => onSubmit()}
                                colorScheme={'green'}
                                me={{md: 1}} mt={{base: 3, md: 8}}>
                            <FormattedMessage
                                id="raqib6.filter"
                                defaultMessage="Filter"
                            />
                        </Button>
                        <Button w={{base: '100%', md: 'auto'}} size="md" onClick={clear} colorScheme={'red'}
                                mt={{base: 3, md: 8}}>
                            <FormattedMessage
                                id="raqib6.clear"
                                defaultMessage="Clear"
                            />
                        </Button>
                    </FormControl>

                    {!!resultsCount && <Box w={'100%'} textAlign='center' mt={6}>
                        <Text color={'#767070'}>
                            <FormattedMessage id={'responses.resultCounts'}
                                              values={{count: resultsCount.toLocaleString(context.locale)}}/>
                        </Text>
                    </Box>}

                </Flex>
            </Box>
        </>
    );
};
