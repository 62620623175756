import {Box, Spinner, Text} from "@chakra-ui/react";
import {FormattedMessage, useIntl} from "react-intl";
import {useContext, useEffect, useLayoutEffect, useState} from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {LocaleContext} from "../Theme/LocaleProvider";
import useAxios from "../../hooks/useAxois";
import {useAuth} from "../../hooks/useAuth";
import {dtToLocaleString, gregorianJalaliDTConversion, parseDashboardChartsFilters} from "../../utils/helpers";

export const PostsByPlatformsMultibarChart = ({
                                                  parentIsLoading,
                                                  resource_ids,
                                                  tag_ids,
                                                  faction_ids,
                                                  ...rest
                                              }) => {
    const axiosInstance = useAxios();
    const context = useContext(LocaleContext);
    const intl = useIntl();
    const {hasPermission} = useAuth();
    const hasPermit = hasPermission('charts:posts:rss-platforms-multibar', 'fe_rss_platforms_multibar');
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(hasPermit);
    const [error, setError] = useState(false);

    const convertDt = (dtText) => {
        if (!dtText) return dtText
        if (context.locale === 'fa') {
            const datetime = gregorianJalaliDTConversion(dtText, true);
            return `${datetime.monthName} ${datetime.year}`;
        }

        return dtToLocaleString(dtText, {
            month: 'short',
            year: 'numeric'
        })
    }

    useEffect(() => {
        if (!hasPermit || parentIsLoading) return;
        setIsLoading(hasPermit);
        const url = parseDashboardChartsFilters("/charts/posts/rss-platforms-multibar?", tag_ids, faction_ids, resource_ids);
        axiosInstance.get(url).then((res) => {
            setData(res.data?.map(item => {
                let res = {date: convertDt(item?.date), values: item?.values}
                Object.keys(item?.values).forEach(platform => {
                    res[intl.formatMessage({id: `charts.resources.${platform}`})] = item.values[platform]
                })
                return res;
            }));
            setError(false);
        }).catch((error) => {
            if (error.response && error.response.status === 404) return;
            setError(true);
            console.error(error);
        }).finally(() => setIsLoading(false));
    }, [resource_ids, tag_ids, faction_ids]);

    useLayoutEffect(() => {
        if (isLoading || !hasPermit || parentIsLoading) return;
        // define root chart with custom theme based on rtl/ltr
        let root = am5.Root.new("posts-rss-platforms-bar");
        const customTheme = am5.Theme.new(root);
        if (context.isRTL) {
            customTheme.rule("Label").setAll({
                fontFamily: "IRANSans",
                direction: 'rtl'
            });
        }
        root.setThemes([am5themes_Animated.new(root), customTheme]);

        // Create chart
        let chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: false,
            panY: false,
            pinchZoomX: true,
            paddingLeft: 0,
            paddingRight: 1,
            wheelX: "panX",
            wheelY: "zoomX",
            layout: root.verticalLayout
        }));

        // chart.children.unshift(am5.Label.new(root, {
        //     text: intl.formatMessage({id: 'charts.resources.mostActiveBarTitle'}),
        //     fontSize: 25,
        //     fontWeight: "500",
        //     textAlign: "center",
        //     x: am5.percent(50),
        //     centerX: am5.percent(50),
        //     paddingTop: -50
        // }));

        // Add legend
        var legend = chart.children.push(
            am5.Legend.new(root, {
                centerX: am5.p50,
                x: am5.p50
            })
        );

        // Add cursor
        let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
        cursor.lineY.set("visible", false);


        // Create axes
        let xRenderer = am5xy.AxisRendererX.new(root, {
            cellStartLocation: 0.1,
            cellEndLocation: 0.9,
            minGridDistance: 30,
            minorGridEnable: true,
        });

        xRenderer.labels.template.setAll({
            rotation: -45,
            centerY: am5.p50,
            centerX: am5.p100,
            paddingRight: 15
        });

        xRenderer.grid.template.setAll({
            location: 1
        })
        let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
            maxDeviation: 0.3,
            categoryField: "date",
            renderer: xRenderer,
            tooltip: am5.Tooltip.new(root, {})

        }));


        let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            maxDeviation: 0.3,
            renderer: am5xy.AxisRendererY.new(root, {
                strokeOpacity: 0.1,
                opposite: context.isRTL
            })
        }));

        xAxis.data.setAll(data);

        // Add series
        const makeSeries = (fieldName) => {

            // Create series
            let series = chart.series.push(am5xy.ColumnSeries.new(root, {
                name: fieldName,
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: fieldName,
                sequencedInterpolation: true,
                categoryXField: "date",
                // tooltip: am5.Tooltip.new(root, {labelText: "{valueY}"})
            }));

            series.columns.template.setAll({cornerRadiusTL: 5, cornerRadiusTR: 5, strokeOpacity: 0});

            series.columns.template.setAll({
                tooltipText: "{name}: {valueY}",
                width: am5.percent(90),
                tooltipY: 0,
                strokeOpacity: 0
            });

            series.data.setAll(data);

            // Make stuff animate on load
            series.appear();

            series.bullets.push(function () {
                return am5.Bullet.new(root, {
                    locationY: 0,
                    sprite: am5.Label.new(root, {
                        text: "{valueY}",
                        fill: root.interfaceColors.get("alternativeText"),
                        centerY: 0,
                        centerX: am5.p50,
                        populateText: true
                    })
                });
            });

            legend.data.push(series);
        }

        const platforms = [...new Set(data?.flatMap(item => Object.keys(item?.values)?.map(subItem => intl.formatMessage({id: `charts.resources.${subItem}`}))))];
        platforms?.map(platform => makeSeries(platform))

        // Make stuff animate on load
        chart.appear(1000, 100);

        return () => {
            root.dispose();
        };
    }, [isLoading, context.isRTL]);

    if (!hasPermit) return <></>

    return (<Box
        bg={"gray.100"}
        minHeight="3rem"
        my={2}
        p={3}
        py={5}
        rounded="lg"
        dir='ltr'
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        {...rest}
    >
        <Text mb={5} fontSize={25} fontWeight={500} dir={context.isRTL ? 'rtl' : 'ltr'}>
            <FormattedMessage id="charts.posts.postsPlatformsMultiBarTitle"/>
        </Text>

        {(isLoading || parentIsLoading) && <Spinner color="green"/>}

        {/*{!data.length && !isLoading && !parentIsLoading &&*/}
        {/*    <Text><FormattedMessage id="responses.noData"/></Text>}*/}

        {/*{!data.length && !isLoading && !parentIsLoading && error &&*/}
        {/*    <Text><FormattedMessage id="responses.connectionFailed"/></Text>}*/}

        {!isLoading && !parentIsLoading && <Box id="posts-rss-platforms-bar" w="100%" h="500px"></Box>}
    </Box>);
};
