import {Button, Flex, Heading, useColorModeValue} from "@chakra-ui/react";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../hooks/useAuth";

export const Error404 = () => {
    const navigate = useNavigate();

    return (
        <Flex height="80vh" alignItems="center" justifyContent="center">
            <Flex
                width={"50vw"}
                direction="column"
                alignItems="center"
                background={useColorModeValue("gray.100", "gray.700")} p={12} rounded={6}
            >
                <Heading mb={12}>404</Heading>
                <Heading mb={12}>Not Found!</Heading>
                <Button
                    width="100%"
                    variant="outline"
                    mt={6}
                    mb={6}
                    colorScheme="green"
                    type="button"
                    onClick={() => navigate("/")}
                >Home</Button>
            </Flex>
        </Flex>
    );
};
