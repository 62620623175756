import {Badge, Button, Center, Container, Spinner, Text, useColorModeValue, useToast} from "@chakra-ui/react";
import {useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {AddUpdateUserModal} from "./AddUpdateUserModal";
import {useAuth} from "../../hooks/useAuth";
import useAxios from "../../hooks/useAxois";
import {configs} from "../../Configs";
import {FormattedMessage, useIntl} from 'react-intl';
import {UserActivityAreaChart} from "../Charts/UserActivityAreaChart";

export const UserDetail = () => {
    const intl = useIntl();
    const axiosInstance = useAxios();
    const {hasPermission} = useAuth();
    const [user, setUser] = useState({});
    const [loading, setLoading] = useState(true);
    const isMounted = useRef(false);
    const {userId} = useParams();
    const navigate = useNavigate();
    const toast = useToast();
    const background = useColorModeValue("gray.300", "gray.600");

    useEffect(() => {
        if (isMounted.current) return;
        fetchUser();
        isMounted.current = true;
    }, [userId]);

    const fetchUser = () => {
        setLoading(true);
        axiosInstance
            .get(`/users/${userId}`)
            .then((res) => {
                setUser(res.data);
            })
            .catch((error) => console.error(error))
            .finally(() => {
                setLoading(false);
            });
    };

    const delateUser = () => {
        setLoading(true);
        axiosInstance
            .delete(`/users/${userId}`)
            .then(() => {
                toast({
                    title: intl.formatMessage({id: 'users.deleted', defaultMessage: "User Deleted"}),
                    status: "success",
                    isClosable: true,
                    diration: 1500,
                });
                navigate("/users");
            })
            .catch((err) => {
                console.error(err);
                toast({
                    title: intl.formatMessage({id: 'users.notDeleted', defaultMessage: "Could not delete user"}),
                    status: "error",
                    isClosable: true,
                    diration: 2000,
                });
            })
            .finally(() => setLoading(false));
    };

    if (loading) {
        return (
            <Container mt={6}>
                <Center mt={6}>
                    <Spinner
                        thickness="4px"
                        speed="0.65s"
                        emptyColor="green.200"
                        color="green.500"
                        size="xl"
                    />
                </Center>
            </Container>
        );
    }

    return (
        <>
            <Container mt={6}>
                <Button
                    colorScheme="gray"
                    onClick={() => navigate("/users", {replace: true})}
                >
                    <FormattedMessage
                        id="navigations.back"
                        description="Back"
                        defaultMessage="Back"
                    />
                </Button>
            </Container>
            <Container
                bg={background}
                minHeight="7rem"
                mt={3}
                p={3}
                rounded="lg"
                alignItems="center"
                justifyContent="space-between"
            >
                <UserActivityAreaChart  userId={userId}/>
                <Text fontSize={22}>{user.username}</Text>
                {configs.roles.filter(role => user.roles.includes(role.value)).map(role => (
                    <Badge variant='solid' colorScheme='purple' mr={1}>
                        <FormattedMessage
                            id={`roles.${role.value}`}
                            description={role.label}
                            defaultMessage={role.label}
                        />
                    </Badge>))}
                {hasPermission('users', 'fe_edit') && <AddUpdateUserModal
                    mt={3}
                    editable={true}
                    defaultValues={{
                        username: user.username,
                        description: user.description,
                        roles: configs.roles.filter((role) => user.roles.includes(role.value))
                    }}
                    onSuccess={fetchUser}
                />}

                {hasPermission('users', 'fe_delete') && <Button
                    mt={3}
                    isLoading={loading}
                    colorScheme="red"
                    width="100%"
                    onClick={delateUser}
                >
                    <FormattedMessage
                        id="users.delete"
                        defaultMessage="Delete User"
                    />
                </Button>}
            </Container>
        </>
    );
};
