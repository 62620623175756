import {Box, Flex, Heading, Spinner, Table, Tbody, Td, Text, Th, Thead, Tr, useToast} from '@chakra-ui/react';
import {useEffect, useState} from "react";
import {Paginator} from './Paginator';
import BlacklistTableRow from './BlacklistTableRow';
import {useNavigate} from "react-router-dom";
import {AddBlacklistModal} from "./AddBlacklistModal";
import {useAuth} from "../../hooks/useAuth";
import useAxios from "../../hooks/useAxois";
import {FormattedMessage, useIntl} from 'react-intl';

const BlacklistWords = () => {
    const intl = useIntl();
    const axiosInstance = useAxios();
    const {hasPermission} = useAuth()
    const toast = useToast();
    const navigate = useNavigate();
    const [statistics, setStatistics] = useState({});
    const [data, setData] = useState([]);
    const [paginationInfo, setPaginationInfo] = useState(null);
    const [isLoading, setIsLoading] = useState(0);
    const [error, setError] = useState(false);
    const [statsError, setStatsError] = useState(false);
    const [curPage, setCurPage] = useState(1);

    useEffect(() => {
        // statistics
        setIsLoading(v => v + 1);
        axiosInstance.get('/blacklists/statistics').then((data) => {
            setStatistics(data.data);
            setStatsError(false);
        }).catch((error) => {
            if (error.response && error.response.status === 404) return;
            setStatsError(true);
            console.error(error);
        }).finally(() => setIsLoading(v => v - 1));
    }, []);


    const loadBlacklists = (paginate = false) => {
        setIsLoading(v => v + 1);
        setError(false);
        setData([]);

        axiosInstance.get('/blacklists', {params: paginate && curPage > 0 ? {skip: (curPage - 1) * 10} : {}}).then((data) => {
            const {blacklisted_words, ...pagingInfo} = data.data;
            setData(blacklisted_words || []);
            setPaginationInfo(pagingInfo);
            setError(false);
        }).catch((error) => {
            if (error.response && error.response.status === 404) return;
            setError(true);
            console.error(error);
        }).finally(() => setIsLoading(v => v - 1));
    }

    useEffect(() => {
        loadBlacklists(true)
    }, [curPage]);

    const deleteBlacklistItem = (words_list_id) => {
        setIsLoading(v => v + 1);
        axiosInstance
            .delete(`/blacklists/${words_list_id}`)
            .then(() => {
                toast({
                    title: intl.formatMessage({id: 'blacklists.deleted'}),
                    status: "success",
                    isClosable: true,
                    diration: 1500,
                });
                setData((old) => old.filter((item) => item.words_list_id !== words_list_id));
                navigate("/blacklists");
            })
            .catch((err) => {
                toast({
                    title: intl.formatMessage({id: 'exports.notDeleted'}),
                    status: "error",
                    isClosable: true,
                    diration: 2000,
                });
            })
            .finally(() => setIsLoading(v => v - 1));
    };

    const changeStateBlacklistItem = (words_list_id) => {
        setIsLoading(v => v + 1);
        axiosInstance
            .patch(`/blacklists/${words_list_id}`)
            .then(() => {
                toast({
                    title: intl.formatMessage({id: 'blacklists.statusChanged'}),
                    status: "success",
                    isClosable: true,
                    diration: 1500,
                });
                setData(old => old.map((item) => item.words_list_id === words_list_id ? {...item, status: !item.status} : item));

                navigate("/blacklists");
            })
            .catch((err) => {
                toast({
                    title: intl.formatMessage({id: 'exports.notDeleted'}),
                    status: "error",
                    isClosable: true,
                    diration: 2000,
                });
            })
            .finally(() => setIsLoading(v => v - 1));
    };


    return (<Flex
        wrap={'wrap'}
        p={5}
        m={'auto'}
        w={{base: '100%', md: '80%', xl: '75%'}}
    >
        <Box w={['100%', '80%']}>
            <Flex wrap={'wrap'} p={5} borderBottomWidth={'1px'} borderBottomColor={'#e8e8e8'} mb={4}>
                <Box w={'50%'} textAlign={'start'}>
                    <Heading>
                        <FormattedMessage id="blacklists.title"/>
                    </Heading>
                </Box>

                {hasPermission('blacklists', 'fe_create') && <Box w={'50%'} textAlign={'end'}>
                    <AddBlacklistModal onSuccess={loadBlacklists}/>
                </Box>}
            </Flex>

            <Box overflowX="auto">
                <Table>
                    <Thead>
                        <Tr>
                            <Th p={3} minW={5}>
                                <FormattedMessage id="blacklists.rowId"/>
                            </Th>
                            <Th p={3} minW={150}>
                                <FormattedMessage id="blacklists.blacklistTitle"/>
                            </Th>
                            <Th p={3} minW={190}>
                                <FormattedMessage id="blacklists.blacklistGeneratedAt"/>
                            </Th>
                            <Th p={3} minW={100}>
                                <FormattedMessage id="blacklists.blacklistState"/>
                            </Th>
                            <Th p={3} minW={100}>
                                <FormattedMessage id="blacklists.blacklistAction"/>
                            </Th>
                        </Tr>
                    </Thead>
                    <Tbody>

                        {data.length > 0 && data.map((blacklistItem, idx) => {
                            // const id = curPage * 10 + idx;
                            const id = (curPage - 1) * 10 + (idx + 1);
                            return <BlacklistTableRow
                                key={`blacklistItem:row:${id}`}
                                id={id}
                                stripped={idx % 2 === 0}
                                onChangeState={changeStateBlacklistItem}
                                onDelete={deleteBlacklistItem}
                                {...blacklistItem}/>
                        })}

                        {!data.length && !isLoading > 0 && <Tr>
                            <Td colSpan={8} textAlign={'center'}>
                                <Text>
                                    <FormattedMessage id="responses.noData"/>
                                </Text>
                            </Td>
                        </Tr>}
                        {!isLoading > 0 && error && <Tr>
                            <Td colSpan={8} textAlign={'center'}>
                                <Text>
                                    <FormattedMessage id="responses.connectionFailed"/>
                                </Text>
                            </Td>
                        </Tr>}
                        {isLoading > 0 &&
                            <Tr><Td colSpan={8} textAlign={'center'}><Spinner mt={5} color="green"/></Td></Tr>}
                    </Tbody>
                </Table>
            </Box>

            <Box

                p={5}
                pt={6}
                my={5}
            >
                {paginationInfo &&
                    <Paginator curPage={curPage} setCurPage={setCurPage} paginationInfo={paginationInfo}/>}
            </Box>
        </Box>
        <Box w={['100%', '20%']} p={5}>
            <Box p={5} bgColor={'#f2f2f2'}>
                <Box>
                    <Heading mb={4} pb={4} borderBottomWidth={'2px'} borderBottomColor={'#dee2e6'} fontSize={'md'}>
                        <FormattedMessage id="blacklists.blacklistStatistics"/>
                    </Heading>
                    <Flex
                        p={2}
                        wrap={'wrap'}
                        _hover={{bgColor: '#e2e2e2'}}
                    >
                        <Box w={'50%'}>
                            <Text>
                                <FormattedMessage id="blacklists.today"/>
                            </Text>
                        </Box>
                        <Box w={'50%'} textAlign={'end'}>
                            <Text>{statistics?.today || 0}</Text>
                        </Box>
                    </Flex>
                    <Flex
                        p={2}
                        wrap={'wrap'}
                        _hover={{bgColor: '#e2e2e2'}}
                    >
                        <Box w={'50%'}>
                            <Text>
                                <FormattedMessage id="blacklists.thisWeek"/>
                            </Text>
                        </Box>
                        <Box w={'50%'} textAlign={'end'}>
                            <Text
                            >{statistics?.this_week || 0}</Text>
                        </Box>
                    </Flex>
                    <Flex
                        p={2}
                        wrap={'wrap'}
                        _hover={{bgColor: '#e2e2e2'}}
                    >
                        <Box w={'50%'}>
                            <Text>
                                <FormattedMessage id="blacklists.thisMonth"/>
                            </Text>
                        </Box>
                        <Box w={'50%'} textAlign={'end'}>
                            <Text
                            >{statistics?.this_month || 0}</Text>
                        </Box>
                    </Flex>
                    <Flex
                        p={2}
                        wrap={'wrap'}
                        _hover={{bgColor: '#e2e2e2'}}
                    >
                        <Box w={'50%'}>
                            <Text>
                                <FormattedMessage id="blacklists.allBlacklists"/>
                            </Text>
                        </Box>
                        <Box w={'50%'} textAlign={'end'}>
                            <Text>{statistics?.total || 0}</Text>
                        </Box>
                    </Flex>
                </Box>
            </Box>
        </Box>
    </Flex>);
};

export default BlacklistWords;
