import {
    Box,
    Button,
    ButtonGroup,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading,
    IconButton,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Radio,
    RadioGroup,
    Select,
    Stack,
    Textarea,
    Tooltip,
    useColorModeValue,
    useDisclosure,
    useToast
} from "@chakra-ui/react";
import moment from 'moment-timezone';
import {CloseIcon} from '@chakra-ui/icons';
import {Controller, useForm} from "react-hook-form";
import {useContext, useEffect, useState} from 'react';
import {configs} from '../../Configs';
import ReactSelect from 'react-select';
import useAxios from "../../hooks/useAxois";
import {FormattedMessage, useIntl} from 'react-intl';
import {LocaleContext} from '../Theme/LocaleProvider';

export const SearchResourceModal = ({
                                        filters,
                                        setFilters,
                                        ...rest
                                    }) => {
    const context = useContext(LocaleContext);
    const intl = useIntl();
    const axiosInstance = useAxios();
    const [showRssPlatform, setShowRssPlatform] = useState(false);
    const [showCommoninjaFields, setShowCommoninjaFields] = useState(false);
    const [nitterSelected, setNitterSelected] = useState(false);

    const cstmMutliSelect = {
        width: '100%',
        minWidth: '0px',
        outline: '2px solid transparent',
        outlineOffset: '2px',
        position: 'relative',
        appearance: 'none',
        transitionProperty: 'var(--chakra-transition-property-common)',
        transitionDuration: 'var(--chakra-transition-duration-normal)',
        fontSize: 'var(--chakra-fontSizes-lg)',
        paddingInlineStart: 'var(--chakra-space-4)',
        paddingInlineEnd: 'var(--chakra-space-4)',
        minHeight: 'var(--chakra-sizes-12)',
        borderRadius: 'var(--chakra-radii-md)',
        border: '0px solid',
        borderColor: 'var(--chakra-colors-transparent)',
        background: useColorModeValue("var(--chakra-colors-gray-300)", "var(--chakra-colors-gray-600)"),
        marginTop: 'var(--chakra-space-6)',
        "&:hover": {
            background: useColorModeValue('var(--chakra-colors-gray-200)', 'var(--chakra-colors-whiteAlpha-100)'),
        },
        "&:focus-visible": {
            background: 'var(--chakra-colors-transparent)',
            borderColor: '#63b3ed'
        }
    }

    const cstmMutliSelectMenu = {
        background: useColorModeValue("var(--chakra-colors-gray-300)", "var(--chakra-colors-gray-600)"),
        color: useColorModeValue("var(--chakra-colors-gray-800)", "var(--chakra-colors-gray-300)"),
    }

    const cstmMutliSelectOptions = {
        background: useColorModeValue("var(--chakra-colors-gray-300)", "var(--chakra-colors-gray-600)"),

        "&:hover": {
            background: useColorModeValue('var(--chakra-colors-gray-200)', 'var(--chakra-colors-whiteAlpha-100)'),
        },
        "&:focus-visible": {
            background: 'var(--chakra-colors-transparent)',
            borderColor: '#63b3ed'
        }
    }

    const {isOpen, onOpen, onClose} = useDisclosure();

    const toast = useToast();

    const defaultValues = {};
    // set default source if not already done
    if (!('source' in defaultValues)) {
        defaultValues.source = 'all';
        defaultValues.rss_platform = 'all';
    }

    // set default timezone to utc
    if (!('timezone' in defaultValues)) {
        defaultValues.timezone = intl.formatMessage({id: 'resources.allTimezones', defaultMessage: 'All Timezones'})
    }


    const {
        handleSubmit,
        register,
        watch,
        control,
        reset,
        formState: {errors, isSubmitting}
    } = useForm({defaultValues: {...defaultValues}});

    const [factions, setFactions] = useState([]);

    useEffect(() => {
        axiosInstance.get('/raqib2_tag').then((data) => setFactions(data.data.map(item => (
            {
                value: item.tag_id,
                label: item.title
            }
        ))));
    }, []);

    const [isResetted, setIsResetted] = useState(false);

    if (!isResetted && Object.keys(filters || {})?.length === 0) {
        reset();
        setIsResetted(true);
    }
    useEffect(() => setIsResetted(false), [filters]);

    const onSubmit = (values) => {
        try {
            values.projects = values.projects?.map((project) => project.value);
            if (!values.projects || !values.projects.length) {
                delete values.projects;
            }

            if (values?.location) {
                values.location = values.location?.map((loc) => loc.value);
            }
            if (!values.location || !values.location.length || (values?.location && values?.location.includes('all'))) {
                delete values.location;
            }

            values.factions = values.factions?.map((faction) => faction.value);
            if (!values.factions || !values.factions.length) {
                delete values.factions;
            }

            if (!['RSS', 'COMMONINJA'].includes(values.source) || values.rss_platform === 'all') {
                delete values.rss_platform
            }

            // add origin url to exact target url (as it is from twitter)
            const the_target = values.target.replace(/^(https?:\/\/)?(www\.)?(twitter\.com\/|x\.com\/)?@?/, '');
            if (values.source === 'NITTER' && the_target) {
                values.origin_url = 'https://x.com/' + the_target;
                values.timezone = 'UTC'
            }

            if (values.timezone === intl.formatMessage({
                id: 'resources.allTimezones',
                defaultMessage: 'All Timezones'
            })) {
                delete values.timezone;
            }
            if (values.source === 'all') {
                delete values.source;
            }
            if (values.is_hashtag !== 'false' && !values.is_hashtag) {
                delete values.is_hashtag;
            }
            if (values.has_star !== 'false' && !values.has_star) {
                delete values.has_star;
            }
            if (values.status !== 'false' && !values.status) {
                delete values.status;
            }
            if (!values.target) {
                delete values.target;
            }
            if (!values.title) {
                delete values.title;
            }
            if (!values.description) {
                delete values.description;
            }
            if (!values.origin_url) {
                delete values.origin_url;
            }
            if (!values.source_id) {
                delete values.source_id;
            }

            if (values?.factions?.length > 0) {
                values.factions = JSON.stringify(values.factions)
            }

            if (values?.projects?.length > 0) {
                values.projects = JSON.stringify(values.projects)
            }

            if (values?.location?.length > 0) {
                values.location = JSON.stringify(values.location)
            }

            setFilters(values);
            onClose();
        } catch (err) {
            let msg = err?.response?.data?.detail || [];
            if (msg && !Array.isArray(msg)) {
                msg = [{msg: msg}]
            }
            if (!msg.length) {
                msg = [{
                    msg:
                        intl.formatMessage({
                            id: 'responses.unknownError',
                            defaultMessage: 'Something went wrong. Please try again'
                        })
                }]
            }
            msg.map(err =>
                toast({
                    title: err.msg,
                    status: "error",
                    isClosable: true,
                    diration: 1500,
                })
            );
        }
    }

    useEffect(() => {
        setShowRssPlatform(["RSS", "COMMONINJA"].includes((filters || {})?.source));
        setNitterSelected((filters || {})?.source === "NITTER");
        setShowCommoninjaFields((filters || {})?.source === 'COMMONINJA');
    }, []);

    const bgColor = useColorModeValue("gray.300", "gray.600");

    const theLocations = [
        ...configs.locations.map(loc => ({
            ...loc,
            label: intl.formatMessage({
                id: `resources.${loc.value !== 'all' ? loc.value : loc.label}`,
                defaultMessage: loc.label
            })
        })),
        {
            label: intl.formatMessage({
                id: 'resources.allLocations',
                defaultMessage: 'All Locations'
            }),
            value: "all",
            geo: {lat: 0, lon: 0},
            zoom: 3.053770845386799
        }];

    return (<Box {...rest}>
        <ButtonGroup size='sm' w={'100%'} isAttached variant="outline">
            <Tooltip label={intl.formatMessage({
                id: 'resources.changeSearchFilters',
                defaultMessage: "Change Search Filters"
            })}>
                <Button colorScheme="blue" w={'100%'} onClick={onOpen}>
                    <FormattedMessage
                        id="resources.search"
                        defaultMessage="SEARCH"
                    />
                </Button>
            </Tooltip>
            {Object.keys(filters || {}).length > 0 && <Tooltip
                label={intl.formatMessage({id: 'resources.removeAllFilters', defaultMessage: 'Remove All Filters'})}>
                <IconButton colorScheme='red' icon={<CloseIcon/>} onClick={() => setFilters({})}/>
            </Tooltip>}
        </ButtonGroup>
        <Modal
            closeOnOverlayClick={false}
            size={{base:"full", md:"xl"}}
            onClose={onClose}
            isOpen={isOpen}
            // isCentered
        >
            <ModalOverlay/>
            <form onSubmit={handleSubmit(onSubmit)}>
                <ModalContent>
                    <ModalHeader dir={context.isRTL ? 'rtl' : 'ltr'}>
                        <Heading fontSize='2xl' textAlign={'start'}>
                            <FormattedMessage
                                id="resources.searchResource"
                                defaultMessage="SEARCH RESOURCE"
                            />
                        </Heading>
                    </ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        <FormControl isInvalid={errors.title}>
                            <Input
                                placeholder={intl.formatMessage({id: 'resources.title', defaultMessage: 'Title'})}
                                background={bgColor}
                                type="text"
                                variant="filled"
                                size="lg"
                                mt={6}
                                {...register("title", {
                                    minLength: {
                                        value: 1,
                                        message: intl.formatMessage(
                                            {
                                                id: "validation.fieldMinLen",
                                                defaultMessage: "{field} must be at least {length} characters"
                                            },
                                            {
                                                field: intl.formatMessage({
                                                    id: "resources.title",
                                                    defaultMessage: "Title"
                                                }),
                                                length: 1
                                            }
                                        )
                                    },
                                    maxLength: {
                                        value: 55,
                                        message: intl.formatMessage(
                                            {
                                                id: "validation.fieldMaxLen",
                                                defaultMessage: "{field} must be at most {length} characters"
                                            },
                                            {
                                                field: intl.formatMessage({
                                                    id: "resources.title",
                                                    defaultMessage: "Title"
                                                }),
                                                length: 55
                                            }
                                        )
                                    }
                                })}
                            />
                            <FormErrorMessage>
                                {errors.title && errors.title.message}
                            </FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={errors.target}>
                            <Input
                                placeholder={
                                    intl.formatMessage({id: 'resources.target', defaultMessage: 'Target'})
                                }
                                background={bgColor}
                                type="text"
                                variant="filled"
                                size="lg"
                                mt={6}
                                {...register("target", {
                                    minLength: {
                                        value: 1,
                                        message: intl.formatMessage(
                                            {
                                                id: "validation.fieldMinLen",
                                                defaultMessage: "{field} must be at least {length} characters"
                                            },
                                            {
                                                field: intl.formatMessage({
                                                    id: "resources.target",
                                                    defaultMessage: "Target"
                                                }),
                                                length: 1
                                            }
                                        )
                                    },
                                    maxLength: {
                                        value: 512,
                                        message: intl.formatMessage(
                                            {
                                                id: "validation.fieldMaxLen",
                                                defaultMessage: "{field} must be at most {length} characters"
                                            },
                                            {
                                                field: intl.formatMessage({
                                                    id: "resources.target",
                                                    defaultMessage: "Target"
                                                }),
                                                length: 512
                                            }
                                        )
                                    }
                                })}
                            />
                            <FormErrorMessage>
                                {errors.target && errors.target.message}
                            </FormErrorMessage>
                        </FormControl>

                        {!nitterSelected &&
                            <span dir={context.isRTL ? 'rtl' : 'ltr'}>
                                <FormControl isInvalid={errors.timezone}>
                                    <Controller
                                        name="timezone"
                                        control={control}
                                        render={({field: {onChange, value}}) => (
                                            <Select
                                                variant="filled"
                                                size="lg"
                                                mt={6}
                                                value={value}
                                                onChange={onChange}
                                            >
                                                {[...moment.tz.names(), intl.formatMessage({
                                                    id: 'resources.allTimezones',
                                                    defaultMessage: 'All Timezones'
                                                })].map((zone) => (
                                                    <option key={"tz:" + zone} value={zone}>{zone}</option>
                                                ))}
                                            </Select>
                                        )}
                                    />
                                    <FormErrorMessage>
                                        {errors.timezone && errors.timezone.message}
                                    </FormErrorMessage>
                                </FormControl>
                            </span>
                        }

                        <span dir={context.isRTL ? 'rtl' : 'ltr'}>
                            <FormControl isInvalid={errors.source}>
                                <Controller
                                    name="source"
                                    control={control}
                                    render={({field: {onChange, value}}) => (
                                        <Select
                                            variant="filled"
                                            size="lg"
                                            mt={6}
                                            value={value}
                                            onChange={(e) => {
                                                setShowRssPlatform(["RSS", "COMMONINJA"].includes(e.target.value));
                                                setShowCommoninjaFields(e.target.value === 'COMMONINJA');
                                                setNitterSelected(e.target.value === 'NITTER');
                                                return onChange(e)
                                            }}
                                        >
                                            {[...configs.sources, {
                                                value: 'all',
                                                label: intl.formatMessage({
                                                    id: 'resources.allSources',
                                                    defaultMessage: 'All Sources'
                                                })
                                            }].map((source) => (
                                                <option key={"source:" + source.value}
                                                        value={source.value}>{source.label}</option>
                                            ))}
                                        </Select>
                                    )}
                                />
                                <FormErrorMessage>
                                    {errors.source && errors.source.message}
                                </FormErrorMessage>
                            </FormControl>
                        </span>

                        {showRssPlatform &&
                            <span dir={context.isRTL ? 'rtl' : 'ltr'}>
                                <FormControl isInvalid={errors.rss_platform}>
                                    <Controller
                                        name="rss_platform"
                                        control={control}
                                        render={({field: {onChange, value}}) => {
                                            return <Select
                                                variant="filled"
                                                size="lg"
                                                mt={6}
                                                value={value || 'all'}
                                                onChange={onChange}
                                            >
                                                {[...configs.rss_platforms, {
                                                    value: 'all',
                                                    label: intl.formatMessage({
                                                        id: 'resources.all',
                                                        defaultMessage: 'All'
                                                    })
                                                }].map((rss_platform) => (
                                                    <option key={"rss_platform:" + rss_platform.value}
                                                            value={rss_platform.value}>{rss_platform.label}</option>
                                                ))}
                                            </Select>
                                        }}
                                    />
                                    <FormErrorMessage>
                                        {errors.rss_platform && errors.rss_platform.message}
                                    </FormErrorMessage>
                                </FormControl>
                            </span>
                        }

                        {showCommoninjaFields &&
                            <FormControl isInvalid={errors.source_id}>
                                <Input
                                    placeholder={intl.formatMessage({
                                        id: 'resources.sourceId',
                                        defaultMessage: 'Source ID'
                                    })}
                                    background={bgColor}
                                    type="text"
                                    variant="filled"
                                    size="lg"
                                    mt={6}
                                    name="source_id"
                                    {...register('source_id')}
                                />
                                <FormErrorMessage>
                                    {errors.source_id && errors.source_id.message}
                                </FormErrorMessage>
                            </FormControl>}

                        {(showCommoninjaFields || showRssPlatform) &&
                            <FormControl isInvalid={errors.origin_url}>
                                <Input
                                    placeholder={intl.formatMessage({
                                        id: 'resources.originUrl',
                                        defaultMessage: 'Origin URL'
                                    })}
                                    background={bgColor}
                                    type="text"
                                    variant="filled"
                                    size="lg"
                                    mt={6}
                                    name="origin_url"
                                    {...register('origin_url')}
                                />
                                <FormErrorMessage>
                                    {errors.origin_url && errors.origin_url.message}
                                </FormErrorMessage>
                            </FormControl>}

                        <FormControl isInvalid={errors.projects}>
                            <Controller
                                name="projects"
                                control={control}
                                render={({field: {onChange, value}}) => (
                                    <ReactSelect
                                        value={value}
                                        onChange={onChange}
                                        placeholder={intl.formatMessage({
                                            id: 'resources.projects',
                                            defaultMessage: 'Projects'
                                        })}
                                        isMulti
                                        // options={['TRENDS24', 'GOOGLE_TRENDS'].includes(watch('source')) ? configs.projects.filter((prj) => prj.value === 'RAQIB1'): configs.projects}
                                        options={showRssPlatform || nitterSelected ? configs.projects : configs.projects.filter((prj) => prj.value === 'RAQIB1')}
                                        closeMenuOnSelect={false}
                                        classNames={
                                            {
                                                control: (state) => state.isFocused ? 'border-red-600' : 'border-grey-300'
                                            }
                                        }
                                        styles={{
                                            control: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelect}),
                                            menu: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelectMenu}),
                                            option: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelectOptions})
                                        }}
                                    />
                                )}
                            />
                            <FormErrorMessage>
                                {errors.projects && errors.projects.message}
                            </FormErrorMessage>
                        </FormControl>

                        {(watch('projects') || []).map(prj => prj.value).includes('RAQIB2') &&
                            <FormControl isInvalid={errors.factions}>
                                <Controller
                                    name="factions"
                                    control={control}
                                    render={({field: {onChange, value}}) => (
                                        <ReactSelect
                                            value={value}
                                            onChange={onChange}
                                            placeholder={intl.formatMessage({
                                                id: 'resources.factions',
                                                defaultMessage: 'Topic Tags, Factions'
                                            })}
                                            isMulti
                                            options={factions}
                                            closeMenuOnSelect={false}
                                            classNames={
                                                {
                                                    control: (state) => state.isFocused ? 'border-red-600' : 'border-grey-300'
                                                }
                                            }
                                            styles={{
                                                control: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelect}),
                                                menu: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelectMenu}),
                                                option: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelectOptions})
                                            }}
                                        />
                                    )}
                                />
                                <FormErrorMessage>
                                    {errors.factions && errors.factions.message}
                                </FormErrorMessage>
                            </FormControl>}

                        <FormControl isInvalid={errors.location}>
                            <Controller
                                name="location"
                                control={control}
                                render={({field: {onChange, value}}) => {
                                    const vlu = value?.map(loc => theLocations.find(lc => [loc?.state, loc?.value].includes(lc.value)));
                                    return <ReactSelect
                                        value={vlu}
                                        onChange={onChange}
                                        placeholder={intl.formatMessage({
                                            id: 'resources.location',
                                            defaultMessage: 'Location'
                                        })}
                                        isMulti
                                        options={theLocations}
                                        closeMenuOnSelect={false}
                                        classNames={
                                            {
                                                control: (state) => state.isFocused ? 'border-red-600' : 'border-grey-300'
                                            }
                                        }
                                        styles={{
                                            control: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelect}),
                                            menu: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelectMenu}),
                                            option: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelectOptions})
                                        }}
                                    />
                                }
                                }
                            />
                            <FormErrorMessage>
                                {errors.location && errors.location.message}
                            </FormErrorMessage>
                        </FormControl>

                        <FormControl isInvalid={errors.description}>
                            <Textarea
                                rows={5}
                                placeholder={intl.formatMessage({
                                    id: 'resources.description',
                                    defaultMessage: 'description'
                                })}
                                background={bgColor}
                                type="text"
                                variant="filled"
                                size="lg"
                                mt={6}
                                {...register("description", {
                                    maxLength: {
                                        value: 200,
                                        message: intl.formatMessage(
                                            {
                                                id: "validation.fieldMaxLen",
                                                defaultMessage: "{field} must be at most {length} characters"
                                            },
                                            {
                                                field: intl.formatMessage({
                                                    id: "resources.description",
                                                    defaultMessage: "Description"
                                                }),
                                                length: 200
                                            }
                                        )
                                    }
                                })}
                            />
                            <FormErrorMessage>
                                {errors.description && errors.description.message}
                            </FormErrorMessage>
                        </FormControl>

                        <span dir={context.isRTL ? 'rtl' : 'ltr'}>
                            {showRssPlatform && <Controller
                                control={control}
                                name="is_hashtag"
                                render={({field}) => (<FormControl mt={6} display="flex" alignItems="center">
                                    <FormLabel htmlFor="is-hashtag">
                                        <FormattedMessage
                                            id="resources.isHashtag"
                                            defaultMessage="Is Hashtag?"
                                        />
                                    </FormLabel>
                                    <RadioGroup id="is-hashtag" value={field.value} onChange={(e) => field.onChange(e)}>
                                        <Stack direction='row'>
                                            <Radio name="is_hashtag" value='' colorScheme="green">
                                                <FormattedMessage
                                                    id="resources.all"
                                                    defaultMessage="All"
                                                />
                                            </Radio>
                                            <Radio name="is_hashtag" value='true' colorScheme="green">
                                                <FormattedMessage
                                                    id="resources.yes"
                                                    defaultMessage="Yes"
                                                />
                                            </Radio>
                                            <Radio name="is_hashtag" value='false' colorScheme="green">
                                                <FormattedMessage
                                                    id="resources.no"
                                                    defaultMessage="No"
                                                />
                                            </Radio>
                                        </Stack>
                                    </RadioGroup>
                                </FormControl>)}
                            />}
                            <Controller
                                control={control}
                                name="has_star"
                                render={({field}) => (<FormControl mt={6} display="flex" alignItems="center">
                                    <FormLabel htmlFor="has-star">
                                        <FormattedMessage
                                            id="resources.hasStar"
                                            defaultMessage="Has Star?"
                                        />
                                    </FormLabel>
                                    <RadioGroup id="has-star" value={field.value} onChange={(e) => field.onChange(e)}>
                                        <Stack direction='row'>
                                            <Radio name="has_star" value='' colorScheme="green">
                                                <FormattedMessage
                                                    id="resources.all"
                                                    defaultMessage="All"
                                                />
                                            </Radio>
                                            <Radio name="has_star" value='true' colorScheme="green">
                                                <FormattedMessage
                                                    id="resources.yes"
                                                    defaultMessage="Yes"
                                                />
                                            </Radio>
                                            <Radio name="has_star" value='false' colorScheme="green">
                                                <FormattedMessage
                                                    id="resources.no"
                                                    defaultMessage="No"
                                                />
                                            </Radio>
                                        </Stack>
                                    </RadioGroup>
                                </FormControl>)}
                            />
                            <Controller
                                control={control}
                                name="status"
                                render={({field}) => (<FormControl mt={6} display="flex" alignItems="center">
                                    <FormLabel htmlFor="is-done">
                                        <FormattedMessage
                                            id="resources.status"
                                            defaultMessage="Status"
                                        />
                                    </FormLabel>
                                    <RadioGroup id="status" value={field.value} onChange={(e) => field.onChange(e)}>
                                        <Stack direction='row'>
                                            <Radio name="status" value='' colorScheme="green">
                                                <FormattedMessage
                                                    id="resources.all"
                                                    defaultMessage="All"
                                                />
                                            </Radio>
                                            <Radio name="status" value='true' colorScheme="green">
                                                <FormattedMessage
                                                    id="resources.enabled"
                                                    defaultMessage="Enabled"
                                                />
                                            </Radio>
                                            <Radio name="status" value='false' colorScheme="green">
                                                <FormattedMessage
                                                    id="resources.disabled"
                                                    defaultMessage="Disabled"
                                                />
                                            </Radio>
                                        </Stack>
                                    </RadioGroup>
                                </FormControl>)}
                            />
                        </span>
                    </ModalBody>
                    <ModalFooter>
                        <Stack direction="row" spacing={4}>
                            <Button onClick={onClose} disabled={isSubmitting}>
                                <FormattedMessage
                                    id="resources.close"
                                    defaultMessage="Close"
                                />
                            </Button>
                            <Button colorScheme={'red'} onClick={() => {
                                setFilters({});
                                reset();
                                return onClose();
                            }} disabled={isSubmitting}>
                                <FormattedMessage
                                    id="resources.clear"
                                    defaultMessage="Clear"
                                />
                            </Button>
                            <Button
                                colorScheme="blue"
                                type="submit"
                                isLoading={isSubmitting}
                                loadingText={intl.formatMessage({
                                    id: 'resources.searching',
                                    defaultValues: 'Searching...'
                                })}
                            >
                                <FormattedMessage
                                    id="resources.search"
                                    defaultMessage="SEARCH"
                                />
                            </Button>
                        </Stack>
                    </ModalFooter>
                </ModalContent>
            </form>
        </Modal>
    </Box>);
};
