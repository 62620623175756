import {
    Box,
    Button,
    FormControl,
    FormErrorMessage,
    Heading,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Stack,
    Textarea,
    useColorModeValue,
    useDisclosure,
    useToast,
} from "@chakra-ui/react";
import {Controller, useForm} from "react-hook-form";
import {useParams} from "react-router-dom";
import useAxios from "../../hooks/useAxois";
import {FormattedMessage, useIntl} from 'react-intl';
import {useContext, useState} from "react";
import {LocaleContext} from '../Theme/LocaleProvider';
import ReactSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';
import {configs} from '../../Configs';

export const AddUpdateTagsInfoModal = ({
                                           editable = false,
                                           defaultValues = {},
                                           onSuccess = () => {
                                           },
                                           ...rest
                                       }) => {
    const context = useContext(LocaleContext);
    const intl = useIntl();
    const axiosInstance = useAxios();
    const {isOpen, onOpen, onClose} = useDisclosure();
    const toast = useToast();
    const {tagsInfoId} = useParams();
    const {
        handleSubmit,
        register,
        setValue,
        watch,
        control,
        formState: {errors, isSubmitting}
    } = useForm({defaultValues: {tags: [], ...defaultValues}});
    const [tagsTemp, setTagsTemp] = useState(watch('tags'));
    const cstmMutliSelect = {
        width: '100%',
        minWidth: '0px',
        outline: '2px solid transparent',
        outlineOffset: '2px',
        position: 'relative',
        appearance: 'none',
        transitionProperty: 'var(--chakra-transition-property-common)',
        transitionDuration: 'var(--chakra-transition-duration-normal)',
        fontSize: 'var(--chakra-fontSizes-lg)',
        paddingInlineStart: 'var(--chakra-space-4)',
        paddingInlineEnd: 'var(--chakra-space-4)',
        minHeight: 'var(--chakra-sizes-12)',
        borderRadius: 'var(--chakra-radii-md)',
        border: '0px solid',
        borderColor: 'var(--chakra-colors-transparent)',
        background: useColorModeValue("var(--chakra-colors-gray-300)", "var(--chakra-colors-gray-600)"),
        marginTop: 'var(--chakra-space-6)',
        "&:hover": {
            background: useColorModeValue('var(--chakra-colors-gray-200)', 'var(--chakra-colors-whiteAlpha-100)'),
        },
        "&:focus-visible": {
            background: 'var(--chakra-colors-transparent)',
            borderColor: '#63b3ed'
        }
    }

    const cstmMutliSelectMenu = {
        background: useColorModeValue("var(--chakra-colors-gray-300)", "var(--chakra-colors-gray-600)"),
        color: useColorModeValue("var(--chakra-colors-gray-800)", "var(--chakra-colors-gray-300)"),
    }

    const cstmMutliSelectOptions = {
        background: useColorModeValue("var(--chakra-colors-gray-300)", "var(--chakra-colors-gray-600)"),

        "&:hover": {
            background: useColorModeValue('var(--chakra-colors-gray-200)', 'var(--chakra-colors-whiteAlpha-100)'),
        },
        "&:focus-visible": {
            background: 'var(--chakra-colors-transparent)',
            borderColor: '#63b3ed'
        }
    }
    const bgColor = useColorModeValue("gray.300", "gray.600");

    const onSubmit = async (values) => {

        try {
            values.platforms = values.platforms?.map((platform) => platform.value);
            values.tags = values.tags?.map((tag) => tag.value);

            if (editable) {
                await axiosInstance.put(`/trend_tags_info/${tagsInfoId}`, values);
            } else {
                await axiosInstance.post(`/trend_tags_info/create/`, values);
            }
            toast({
                title: editable ? intl.formatMessage({
                    id: 'trend_tags_info.updated'
                }) : intl.formatMessage({id: 'trend_tags_info.created'}),
                status: "success",
                isClosable: true,
                diration: 1500,
            });
            onSuccess();
            onClose();
        } catch (err) {
            let msg = err?.response?.data?.detail || [];
            if (msg && !Array.isArray(msg)) {
                msg = [{msg: msg}]
            }
            if (!msg.length) {
                msg = [{
                    msg:
                        intl.formatMessage({
                            id: 'responses.unknownError',
                            defaultMessage: 'Something went wrong. Please try again'
                        })
                }]
            }
            msg.map(err =>
                toast({
                    title: err.msg,
                    status: "error",
                    isClosable: true,
                    diration: 1500,
                })
            );
        }
    };

    return (<Box {...rest}>
        <Button w="100%" colorScheme="green" onClick={onOpen}>
            {editable ?
                intl.formatMessage({id: 'trend_tags_info.updateTagsInfo'})
                : intl.formatMessage({id: 'trend_tags_info.addTagsInfo'})}
        </Button>
        <Modal
            closeOnOverlayClick={false}
            size={{base: "full", md: "xl"}}
            onClose={onClose}
            isOpen={isOpen}
            // isCentered
        >
            <ModalOverlay/>
            <form onSubmit={handleSubmit(onSubmit)}>
                <ModalContent>
                    <ModalHeader dir={context.isRTL ? 'rtl' : 'ltr'}>
                        <Heading fontSize='2xl' textAlign={'start'}>
                            {editable ?
                                intl.formatMessage({id: 'trend_tags_info.updateTagsInfo'})
                                : intl.formatMessage({id: 'trend_tags_info.addTagsInfo'})}
                        </Heading>
                    </ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        <FormControl isInvalid={errors.title}>
                            <Input
                                placeholder={
                                    intl.formatMessage({id: 'trend_tags_info.title'})
                                }
                                background={bgColor}
                                type="text"
                                variant="filled"
                                size="lg"
                                mt={6}

                                {...register("title", {
                                    required: intl.formatMessage(
                                        {
                                            id: "validation.fieldRequired",
                                            defaultMessage: "{field} is required"
                                        },
                                        {
                                            field: intl.formatMessage({id: "trend_tags_info.title"})
                                        }
                                    ),
                                    minLength: {
                                        value: 1,
                                        message: intl.formatMessage(
                                            {
                                                id: "validation.fieldMinLen",
                                                defaultMessage: "{field} must be at least {length} characters"
                                            },
                                            {
                                                field: intl.formatMessage({id: "trend_tags_info.title"}),
                                                length: 1
                                            }
                                        )
                                    },
                                    maxLength: {
                                        value: 55,
                                        message: intl.formatMessage(
                                            {
                                                id: "validation.fieldMaxLen",
                                                defaultMessage: "{field} must be at most {length} characters"
                                            },
                                            {
                                                field: intl.formatMessage({id: "trend_tags_info.title"}),
                                                length: 55
                                            }
                                        )
                                    },
                                })}
                            />
                            <FormErrorMessage>
                                {errors.title && errors.title.message}
                            </FormErrorMessage>
                        </FormControl>

                        <span dir={context.isRTL ? 'rtl' : 'ltr'}>
                            <FormControl isInvalid={errors.platforms}>
                                <Controller
                                    rules={{
                                        required: intl.formatMessage(
                                            {
                                                id: "validation.fieldRequired",
                                                defaultMessage: "{field} is required"
                                            },
                                            {
                                                field: intl.formatMessage({id: "trend_tags_info.platforms"})
                                            }
                                        )
                                    }}
                                    name="platforms"
                                    control={control}
                                    render={({field: {onChange, value}}) => (<ReactSelect
                                        value={value}
                                        onChange={onChange}
                                        placeholder={intl.formatMessage({id: 'trend_tags_info.platforms',})}
                                        isMulti
                                        options={configs.trends_platforms}
                                        closeMenuOnSelect={false}
                                        classNames={
                                            {
                                                control: (state) => state.isFocused ? 'border-red-600' : 'border-grey-300'
                                            }
                                        }
                                        styles={{
                                            control: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelect}),
                                            menu: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelectMenu}),
                                            option: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelectOptions})
                                        }}
                                    />)
                                    }
                                />
                                <FormErrorMessage>{errors.platforms && errors.platforms.message}</FormErrorMessage>
                            </FormControl>
                        </span>

                        <FormControl isInvalid={errors.tags}>
                            <Controller
                                rules={{
                                    required: intl.formatMessage(
                                        {
                                            id: "validation.fieldRequired",
                                            defaultMessage: "{field} is required"
                                        },
                                        {
                                            field: intl.formatMessage({id: "trend_tags_info.matchingTags"})
                                        }
                                    )
                                }}
                                name="tags"
                                control={control}
                                render={({field: {onChange, value}}) => (
                                    <CreatableSelect
                                        isMulti
                                        isClearable
                                        value={value}
                                        onChange={onChange}
                                        onCreateOption={
                                            (inputValue) => {
                                                const newOption = {label: inputValue, value: inputValue};
                                                const newTags = [...watch('tags'), newOption];
                                                setTagsTemp(newTags)
                                                setValue('tags', newTags, {shouldValidate: true});
                                            }
                                        }
                                        placeholder={intl.formatMessage({id: 'trend_tags_info.matchingTags'})}
                                        options={tagsTemp}
                                        closeMenuOnSelect={false}
                                        styles={{
                                            control: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelect}),
                                            menu: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelectMenu}),
                                            option: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelectOptions})
                                        }}
                                        classNames={
                                            {
                                                control: (state) => state.isFocused ? 'border-red-600' : 'border-grey-300'
                                            }
                                        }
                                    />)
                                }
                            />
                            <FormErrorMessage>{errors.tags && errors.tags.message}</FormErrorMessage>
                        </FormControl>

                        <FormControl isInvalid={errors.description}>
                            <Textarea
                                rows={5}
                                placeholder={intl.formatMessage({id: 'trend_tags_info.description'})}
                                background={bgColor}
                                type="text"
                                variant="filled"
                                size="lg"
                                mt={6}
                                {...register("description", {
                                    required: intl.formatMessage(
                                        {
                                            id: "validation.fieldRequired",
                                            defaultMessage: "{field} is required"
                                        },
                                        {
                                            field: intl.formatMessage({id: "trend_tags_info.description"})
                                        }
                                    ),
                                    maxLength: {
                                        value: 1024,
                                        message: intl.formatMessage(
                                            {
                                                id: "validation.fieldMaxLen",
                                                defaultMessage: "{field} must be at most {length} characters"
                                            },
                                            {
                                                field: intl.formatMessage({id: "resources.description"}),
                                                length: 1024
                                            }
                                        )
                                    }
                                })}
                            />
                            <FormErrorMessage>
                                {errors.description && errors.description.message}
                            </FormErrorMessage>
                        </FormControl>

                    </ModalBody>
                    <ModalFooter>
                        <Stack direction="row" spacing={4}>
                            <Button onClick={onClose} disabled={isSubmitting}>
                                <FormattedMessage id="trend_tags_info.close"/>
                            </Button>
                            <Button
                                colorScheme="green"
                                type="submit"
                                isLoading={isSubmitting}
                                loadingText={editable ?
                                    intl.formatMessage({id: 'trend_tags_info.updating'})
                                    : intl.formatMessage({id: 'trend_tags_info.creating'})}
                            >
                                {editable ?
                                    intl.formatMessage({id: 'trend_tags_info.update'})
                                    : intl.formatMessage({id: 'trend_tags_info.create'})}
                            </Button>
                        </Stack>
                    </ModalFooter>
                </ModalContent>
            </form>
        </Modal>
    </Box>);
};
