import {
    Box,
    Checkbox,
    Flex,
    Heading,
    IconButton,
    Input,
    InputGroup,
    InputRightElement,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Radio,
    RadioGroup,
    Stack
} from "@chakra-ui/react";
import React, {useContext, useEffect, useState} from "react";
import {AddIcon, MinusIcon} from '@chakra-ui/icons';
import {FormattedMessage, useIntl} from 'react-intl';
import {LocaleContext} from '../Theme/LocaleProvider';

const Paranthesis = ({idx, terms, setTerms}) => {
    const setLogic = (val) => setTerms((prevTerms) => {
        const updatedItems = [...prevTerms];
        updatedItems[idx]['logic'] = val
        return updatedItems;
    });

    return <Box border={'1px solid #c5c5c5'} borderRadius={'4px'} p={2} mb={2}
                width={'100%'}>
        <Flex
            align="center"
            justify="center"
            wrap="wrap"
            padding="1rem"
            direction={{base: 'column', md: 'row'}}>
            <RadioGroup onChange={setLogic} value={terms[idx]['logic']}>
                <Stack direction='row'>
                    <Radio value='OR'>
                        <FormattedMessage
                            id="raqib6.or"
                            defaultMessage="OR"
                        />
                    </Radio>
                    <Radio value='AND'>
                        <FormattedMessage
                            id="raqib6.and"
                            defaultMessage="AND"
                        />
                    </Radio>
                </Stack>
            </RadioGroup>
            <IconButton
                ms={1}
                h='1.75rem'
                colorScheme={idx > 0 ? 'red' : 'green'}
                icon={idx > 0 ? <MinusIcon/> : <AddIcon/>}
                size='sm'
                onClick={() => {
                    if (idx > 0) {
                        return setTerms(prevTerms => prevTerms.filter((_, index) => index !== idx));
                    }
                    setTerms(prevTerms => {
                        return [...prevTerms, {
                            logic: 'OR',
                            terms: [{exclude: false, phrase: ''}]
                        }];
                    });
                }
                }
            />
        </Flex>
        <Box>
            {terms[idx]['terms'].map((term, tidx) => <LogicalInput key={'LogicalInput-' + idx + '-' + tidx} pidx={idx}
                                                                   idx={tidx} setTerms={setTerms}
                                                                   terms={terms}/>)}
        </Box>

    </Box>
}

const LogicalInput = ({
                          terms,
                          setTerms,
                          pidx,
                          idx,
                      }) => {
    const intl = useIntl();
    const [exclude, setExclude] = useState(terms[pidx]['terms'][idx]['exclude']);
    const [curTerm, setCurTerm] = useState(terms[pidx]['terms'][idx]['phrase']);

    useEffect(() => {
        setTerms(prevTerms => {
            const updatedItems = [...prevTerms];
            updatedItems[pidx]['terms'][idx] = {'exclude': exclude, 'phrase': curTerm};
            return updatedItems;
        });
    }, [curTerm, exclude]);

    return <InputGroup size='md' mb={2}>
        <Input
            pe='4.5rem'
            type="text"
            placeholder={(exclude ? intl.formatMessage({
                id: 'raqib6.exclude',
                defaultMessage: 'Exclude'
            }) : intl.formatMessage({id: 'raqib6.include', defaultMessage: 'Include'})) + '...'}
            name={'terms'}
            value={curTerm}
            onChange={(e) => setCurTerm(e.target.value)}
        />
        <InputRightElement width='8rem'>
            <Checkbox onChange={() => setExclude(!exclude)} defaultChecked={exclude}>
                <span style={{fontSize: 'small'}}>
                    <FormattedMessage
                        id="raqib6.exclude"
                        defaultMessage="Exclude"
                    />
                </span>
            </Checkbox>
            <IconButton
                ms={1}
                h='1.75rem'
                colorScheme={idx > 0 ? 'red' : 'green'}
                icon={idx > 0 ? <MinusIcon/> : <AddIcon/>}
                size='sm'
                onClick={() => {
                    if (idx > 0) {
                        return setTerms(prevTerms => {
                            const newTerms = [...prevTerms];
                            newTerms[pidx] = {
                                ...newTerms[pidx],
                                terms: [
                                    ...newTerms[pidx].terms.slice(0, idx),
                                    ...newTerms[pidx].terms.slice(idx + 1)
                                ]
                            };
                            return newTerms;
                        });
                    }
                    setTerms(prevTerms => {
                        const newTerms = [...prevTerms];
                        newTerms[pidx] = {
                            ...newTerms[pidx],
                            terms: [
                                ...newTerms[pidx].terms.slice(0, idx),
                                ...newTerms[pidx].terms.slice(idx),
                                {
                                    exclude: false,
                                    phrase: ''
                                },
                            ]
                        };

                        return newTerms;
                    });
                }
                }
            />
        </InputRightElement>
    </InputGroup>
}

export default function TermsModal({
                                       terms,
                                       setTerms,
                                       isOpen,
                                       onOpen,
                                       onClose,
                                       ...rest
                                   }) {
    const context = useContext(LocaleContext);
    return (<Box {...rest}>
        <Modal
            closeOnOverlayClick={false}
            size={{base:"full", md:"xl"}}
            onClose={onClose}
            isOpen={isOpen}
            // isCentered
        >
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader dir={context.isRTL ? 'rtl' : 'ltr'}>
                    <Heading fontSize='2xl' textAlign={'start'}>
                        <FormattedMessage
                            id="raqib6.searchTerms"
                            defaultMessage="Search Terms"
                        />
                    </Heading>
                </ModalHeader>
                <ModalCloseButton/>
                <ModalBody dir={context.isRTL ? 'rtl' : 'ltr'}>
                    {terms.map((term, idx) => <Paranthesis key={'paranthesis-' + idx} idx={idx} terms={terms}
                                                           setTerms={setTerms}/>)}
                </ModalBody>
            </ModalContent>
        </Modal>
    </Box>);
};
