import {Box, Td, Text, Tr} from '@chakra-ui/react';
import {FormattedMessage} from "react-intl";

export const MostUsedWordsRow = ({
                                     row_id,
                                     word,
                                     count,
                                     source,
                                     stripped = false,
                                     ...rest
                                 }) => {
    return (
        <Tr fontSize={'sm'} bgColor={stripped ? '#f2f2f2' : 'white'} {...rest}>
            <Td p={3}>
                <Box maxW="sm" isTruncated>{row_id}</Box>
            </Td>
            <Td p={3}>{word}</Td>
            <Td p={3}>
                <Text>{count}</Text>
            </Td>
            <Td p={3}>
                <Text><FormattedMessage id={`charts.posts.postsTopWords.${source}`}/></Text>
            </Td>
        </Tr>
    );
};