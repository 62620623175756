import {Box, Spinner, Text} from "@chakra-ui/react";
import {useContext, useEffect, useLayoutEffect, useState} from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map";
import russiaLow from "@amcharts/amcharts5-geodata/russiaLow";
import iraqLow from "@amcharts/amcharts5-geodata/iraqLow";
import syriaLow from "@amcharts/amcharts5-geodata/syriaLow";
import lebanonLow from "@amcharts/amcharts5-geodata/lebanonLow";
import azerbaijanLow from "@amcharts/amcharts5-geodata/azerbaijanLow";
import afghanistanLow from "@amcharts/amcharts5-geodata/afghanistanLow";
import turkeyLow from "@amcharts/amcharts5-geodata/turkeyLow";
import worldLow from "@amcharts/amcharts5-geodata/worldLow";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {FormattedMessage, useIntl} from "react-intl";
import {LocaleContext} from "../Theme/LocaleProvider";
import useAxios from "../../hooks/useAxois";
import {configs} from "../../Configs";
import {useAuth} from "../../hooks/useAuth";
import {parseDashboardChartsFilters} from "../../utils/helpers";

export const PostsByStatesMapChart = ({
                                          parentIsLoading,
                                          since,
                                          until,
                                          resource_ids,
                                          tag_ids,
                                          faction_ids,
                                          ...rest
                                      }) => {
    const axiosInstance = useAxios();
    const context = useContext(LocaleContext);
    const intl = useIntl();
    const {hasPermission} = useAuth();
    const hasPermit = hasPermission('charts:posts:states-map', 'fe_states_map');
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(hasPermit);
    const [error, setError] = useState(false);

    useEffect(() => {
        if (!hasPermit || parentIsLoading) return;
        setIsLoading(hasPermit);
        const url = parseDashboardChartsFilters("/charts/posts/states-map?", tag_ids, faction_ids, resource_ids);
        axiosInstance.get(url).then((res) => {
            setData(res.data.map(item => ({
                ...item,
                name: intl.formatMessage({id: `charts.resources.${item.name}`}),
                // radius: 10
            })));
            setError(false);
        }).catch((error) => {
            if (error.response && error.response.status === 404) return;
            setError(true);
            console.error(error);
        }).finally(() => setIsLoading(false));
    }, [since, until, resource_ids, tag_ids, faction_ids]);

    useLayoutEffect(() => {
        if (isLoading || !hasPermit || parentIsLoading) return;
        // define root chart with custom theme based on rtl/ltr
        let root = am5.Root.new("posts-by-states-map");
        const customTheme = am5.Theme.new(root);
        if (context.isRTL) {
            customTheme.rule("Label").setAll({
                fontFamily: "IRANSans",
                direction: 'rtl'
            });
        }
        root.setThemes([am5themes_Animated.new(root), customTheme]);

        // Create the map chart
        let chart = root.container.children.push(am5map.MapChart.new(root, {
                panX: "rotateX",
                projection: am5map.geoMercator(),
                layout: root.horizontalLayout,
            }
        ));

        // select map by location country_code
        let geodata = worldLow;
        if (configs.country_code.toLowerCase() === 'ea') {
            chart.setAll({
                rotationX: -80,
                rotationY: -40,
                rotationZ: 10,
                zoomLevel: 6
            });
        } else if (configs.country_code === 'iq') {
            geodata = iraqLow;
        } else if (configs.country_code === 'sy') {
            geodata = syriaLow;
        } else if (configs.country_code === 'af') {
            geodata = afghanistanLow;
        } else if (configs.country_code === 'az') {
            geodata = azerbaijanLow;
        } else if (configs.country_code === 'lb') {
            geodata = lebanonLow;
        } else if (configs.country_code === 'ru') {
            geodata = russiaLow;
        } else if (configs.country_code === 'tr') {
            geodata = turkeyLow;
        }

        // Create polygon series for continents
        let polygonSeries = chart.series.push(am5map.MapPolygonSeries.new(root, {
            geoJSON: geodata,
            calculateAggregates: true,
            valueField: "value",
            polygonIdField: "id"
        }));

        polygonSeries.data.setAll(data);

        polygonSeries.mapPolygons.template.setAll({
            tooltipText: "{name}: {value}",
            interactive: true
        });

        polygonSeries.set("heatRules", [{
            target: polygonSeries.mapPolygons.template,
            dataField: "value",
            min: am5.color(0xff621f),
            max: am5.color(0x661f00),
            key: "fill"
        }]);

        polygonSeries.mapPolygons.template.states.create("hover", {
            fill: am5.color(0x677935)
        });

        polygonSeries.mapPolygons.template.events.on("pointerover", function (ev) {
            heatLegend.showValue(ev.target.dataItem.get("value"));
        });

        let heatLegend = chart.children.push(am5.HeatLegend.new(root, {
            orientation: "vertical",
            startColor: am5.color(0xff621f),
            endColor: am5.color(0x661f00),
            startText: intl.formatMessage({id: 'charts.posts.postsByStatesMapLowest'}),
            endText: intl.formatMessage({id: 'charts.posts.postsByStatesMapHighest'}),
            stepCount: 5
        }));

        heatLegend.startLabel.setAll({
            fontSize: 12,
            fill: heatLegend.get("startColor")
        });

        heatLegend.endLabel.setAll({
            fontSize: 12,
            fill: heatLegend.get("endColor")
        });

        // change this to template when possible
        polygonSeries.events.on("datavalidated", function () {
            heatLegend.set("startValue", polygonSeries.getPrivate("valueLow"));
            heatLegend.set("endValue", polygonSeries.getPrivate("valueHigh"));
        });

        return () => {
            root.dispose();
        };
    }, [isLoading, context.isRTL]);

    if (!hasPermit) return <></>

    return (<Box
        bg={"gray.100"}
        minHeight="3rem"
        my={2}
        p={3}
        py={5}
        rounded="lg"
        dir='ltr'
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        {...rest}
    >
        <Text mb={5} fontSize={25} fontWeight={500} dir={context.isRTL ? 'rtl' : 'ltr'}>
            <FormattedMessage id="charts.posts.postsByStatesMapTitle"/>
        </Text>

        {(isLoading || parentIsLoading) && <Spinner color="green"/>}

        {/*{!data.length && !isLoading && !parentIsLoading &&*/}
        {/*    <Text><FormattedMessage id="responses.noData"/></Text>}*/}

        {/*{!data.length && !isLoading && !parentIsLoading && error &&*/}
        {/*    <Text><FormattedMessage id="responses.connectionFailed"/></Text>}*/}

        {!isLoading && !parentIsLoading && <Box id="posts-by-states-map" w="100%" h="500px"></Box>}
    </Box>);
};
