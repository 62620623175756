import {
    Box,
    Button,
    FormControl,
    FormErrorMessage,
    Heading,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Stack,
    useColorModeValue,
    useDisclosure,
    useToast
} from "@chakra-ui/react";
import {LocaleContext} from '../Theme/LocaleProvider';
import {useContext, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import 'react-datepicker/dist/react-datepicker.css';
import useAxios from "../../hooks/useAxois";

import {FormattedMessage, useIntl} from 'react-intl';

export const AddBlacklistModal = ({
                                      onSuccess = () => {
                                      },
                                      ...rest
                                  }) => {
    const context = useContext(LocaleContext);
    const intl = useIntl();
    const axiosInstance = useAxios();
    const {isOpen, onOpen, onClose} = useDisclosure();
    const toast = useToast();

    const {
        handleSubmit,
        register,
        setError,
        control,
        formState: {errors, isSubmitting}
    } = useForm();

    const onSubmit = async (values) => {
        if (!values?.file?.length) {
            setError('file', {
                type: 'manual',
                message: intl.formatMessage(
                    {
                        id: "validation.fieldRequired",
                        defaultMessage: "{field} is required"
                    },
                    {
                        field: intl.formatMessage({id: "blacklists.file"})
                    }
                )
            });
            return
        }

        const payload = new FormData();
        payload.append('file', values.file[0]);
        payload.append('title', values.title);

        try {
            await axiosInstance.post(`/blacklists/create/`, payload, {headers: {'Content-Type': 'multipart/form-data'}});
            toast({
                title: intl.formatMessage({id: 'blacklists.submitted'}),
                status: "success",
                isClosable: true,
                diration: 1500,
            });
            onSuccess();
            onClose();
        } catch (err) {
            let msg = err?.response?.data?.detail || [];
            if (msg && !Array.isArray(msg)) {
                msg = [{msg: msg}]
            }
            if (!msg.length) {
                msg = [{
                    msg:
                        intl.formatMessage({
                            id: 'responses.unknownError',
                            defaultMessage: 'Something went wrong. Please try again'
                        })
                }]
            }
            msg.map(err =>
                toast({
                    title: err.msg,
                    status: "error",
                    isClosable: true,
                    diration: 1500,
                })
            );
        }
    };

    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    return (<Box {...rest}>
        <Button mt={2} size={'sm'} onClick={onOpen} colorScheme={'green'}>
            <FormattedMessage id="blacklists.addBlacklist"/>
        </Button>
        <Modal
            closeOnOverlayClick={false}
            size={{base: "full", md: "xl"}}
            onClose={onClose}
            isOpen={isOpen}
            // isCentered
        >
            <ModalOverlay/>
            <form onSubmit={handleSubmit(onSubmit)}>
                <ModalContent>
                    <ModalHeader dir={context.isRTL ? 'rtl' : 'ltr'}>
                        <Heading fontSize='2xl' textAlign={'start'}>
                            <FormattedMessage id="blacklists.addBlacklist"/>
                        </Heading>
                    </ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        <FormControl isInvalid={errors.title}>
                            <Input
                                placeholder={
                                    intl.formatMessage({id: 'blacklists.blacklistTitle'})
                                }
                                background={useColorModeValue("gray.300", "gray.600")}
                                type="text"
                                variant="filled"
                                size="lg"
                                mt={6}

                                {...register("title", {
                                    required: intl.formatMessage(
                                        {id: "validation.fieldRequired"},
                                        {field: intl.formatMessage({id: "blacklists.blacklistTitle"})}
                                    ),
                                    minLength: {
                                        value: 1,
                                        message: intl.formatMessage(
                                            {id: "validation.fieldMinLen"},
                                            {
                                                field: intl.formatMessage({id: "blacklists.blacklistTitle"}),
                                                length: 1
                                            }
                                        )
                                    },
                                    maxLength: {
                                        value: 128,
                                        message: intl.formatMessage(
                                            {id: "validation.fieldMaxLen"},
                                            {
                                                field: intl.formatMessage({
                                                    id: "blacklists.blacklistTitle",
                                                    defaultMessage: "Title"
                                                }),
                                                length: 128
                                            }
                                        )
                                    },
                                })}
                            />
                            <FormErrorMessage>
                                {errors.title && errors.title.message}
                            </FormErrorMessage>
                        </FormControl>
                        <Box bgColor={'#f8f8f8'} p={5} my={4} fontFamily={"Cairo, sans-serif"}>
                            <label htmlFor={'text-file'} style={{fontWeight: 800}}>
                                <FormattedMessage id="blacklists.file"/>
                            </label>
                            <FormControl isInvalid={errors.file} mt={2}>
                                <Controller
                                    name="file"
                                    control={control}
                                    rules={{
                                        required: intl.formatMessage(
                                            {
                                                id: "validation.fieldRequired",
                                                defaultMessage: "{field} is required"
                                            },
                                            {field: intl.formatMessage({id: "blacklists.file"})}
                                        )
                                    }}
                                    render={({field: {onChange, value}}) => (
                                        <input
                                            id={'text-file'}
                                            style={{backgroundColor: 'white', padding: '4px'}}
                                            accept=".txt"
                                            type="file"
                                            onChange={(e) => {
                                                onChange(e.target.files);
                                                handleFileChange(e);
                                            }}
                                        />
                                    )}
                                />
                                <FormErrorMessage>
                                    {errors.file && errors.file.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>

                    </ModalBody>
                    <ModalFooter>
                        <Stack direction="row" spacing={4}>
                            <Button onClick={onClose} disabled={isSubmitting}>
                                <FormattedMessage id="blacklists.close"/>
                            </Button>
                            <Button
                                colorScheme="green"
                                type="submit"
                                isLoading={isSubmitting}
                                loadingText={intl.formatMessage({id: 'blacklists.creating'})}
                            >
                                <FormattedMessage id="blacklists.create"/>
                            </Button>
                        </Stack>
                    </ModalFooter>
                </ModalContent>
            </form>
        </Modal>
    </Box>);
};
