import {Box, Center, Container, Spinner} from "@chakra-ui/react";
import {useEffect, useRef, useState} from "react";
import {AddUpdateTagModal} from "./AddUpdateTagModal";
import {TagCard} from "./TagCard";
import {useAuth} from "../../hooks/useAuth";
import useAxios from "../../hooks/useAxois";

export const TagList = () => {
    const axiosInstance = useAxios();
    const {hasPermission} = useAuth();
    const [tags, setTags] = useState([]);
    const [loading, setLoading] = useState(true);
    const isMounted = useRef(false);

    useEffect(() => {
        if (isMounted.current) return;
        fetchTags();
        isMounted.current = true;
    }, []);

    const fetchTags = () => {
        setLoading(true);
        axiosInstance
            .get("/post_tag/")
            .then((res) => {
                setTags(res.data);
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Container mt={9}>
            {hasPermission('post_tag', 'fe_create') && <AddUpdateTagModal onSuccess={fetchTags}/>}
            {loading ? (
                <Center mt={6}>
                    <Spinner
                        thickness="4px"
                        speed="0.65s"
                        emptyColor="green.200"
                        color="green.500"
                        size="xl"
                    />
                </Center>
            ) : (
                <Box mt={6}>
                    {tags?.map((tag) => (<TagCard tag={tag} key={tag.tag_id}/>))}
                </Box>
            )}
        </Container>
    );
};
