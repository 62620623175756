import {Box, Flex, Spinner, Text} from "@chakra-ui/react";
import {NewsItems} from './NewsItems';
import {FormattedMessage} from "react-intl";

export const NewsTags = ({tag_id, title, news, isLoading, error, ...rest}) => {
    const unique_news = news.reduce((accumulator, currentObject) => {
        const newsId = currentObject.id;

        // Check if the news_id is not already in the accumulator
        if (!accumulator.some((obj) => obj.id === newsId)) {
            accumulator.push(currentObject);
        }

        return accumulator;
    }, []);

    return (
        <Box>
            <Flex
                justify="center"
                wrap="wrap"
                mb={2}
                p={3}
                borderRadius={'8px'}
                borderColor={'f4f4f4'}
                borderWidth={'2px'}
            >
                <Box width={["100%", "calc( 15% - 6px)"]}
                     bgColor={'#f4f4f4'}
                     borderRadius={'8px'} p={2}
                     me={{base: 0, md: '6px'}}
                     mb={{base: '6px', md: 0}}
                     display="flex"
                     justifyContent="center"
                     alignItems="center"
                     _hover={{bgColor: '#e2e2e2', cursor: "pointer"}}
                     onClick={() => window.open(`/articles/tag/${tag_id}`, '_blank')}>
                    <Text fontFamily={"Cairo, sans-serif"}>{title}</Text>
                </Box>
                <Box
                    width={["100%", "85%"]}
                    textAlign={'center'}
                    display={unique_news.length > 0 ? 'block' : 'flex'}
                    alignItems="center"
                    justifyContent="center"
                >
                    {unique_news.length > 0 && unique_news.map(newsItem => <NewsItems
                        key={tag_id + ':' + newsItem.id} {...newsItem}/>)}

                    {!unique_news.length && !isLoading > 0 &&
                        <Text >
                            <FormattedMessage
                                id="responses.noData"
                                defaultMessage="No Data"
                            />
                        </Text>}
                    {!isLoading > 0 && error &&
                        <Text >
                            <FormattedMessage
                                id="responses.connectionFailed"
                                defaultMessage="Connection Failed"
                            />
                        </Text>}
                    {isLoading > 0 && <Spinner mt={5} color="green"/>}
                </Box>
            </Flex>
        </Box>
    );
};
