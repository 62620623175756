export default {
    "type": "FeatureCollection",
    "features": [
        {
            "type": "Feature",
            "properties": {},
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [-180, -90],
                        [-180, 90],
                        [180, 90],
                        [180, -90],
                        [-180, -90]
                    ],
                    [
                        [35.75759, 32.744347],
                        [35.784203, 32.777949],
                        [35.834226, 32.827946],
                        [35.841874, 32.853577],
                        [35.83805, 32.866031],
                        [35.849729, 32.895823],
                        [35.866885, 32.920782],
                        [35.874017, 32.922333],
                        [35.888073, 32.944941],
                        [35.864611, 32.97773],
                        [35.85903, 32.99021],
                        [35.845801, 33.085423],
                        [35.848902, 33.098678],
                        [35.811488, 33.111908],
                        [35.811488, 33.126765],
                        [35.822443, 33.14157],
                        [35.833399, 33.161129],
                        [35.830195, 33.189991],
                        [35.807664, 33.201721],
                        [35.803633, 33.248463],
                        [35.775625, 33.264896],
                        [35.768597, 33.272699],
                        [35.802083, 33.31249],
                        [35.763842, 33.334401],
                        [35.785753, 33.342875],
                        [35.793505, 33.349929],
                        [35.809938, 33.360032],
                        [35.812315, 33.373365],
                        [35.815415, 33.378868],
                        [35.816966, 33.395198],
                        [35.822443, 33.401373],
                        [35.8211, 33.406722],
                        [35.845116, 33.418742],
                        [35.870089, 33.431242],
                        [35.888486, 33.440415],
                        [35.919492, 33.462351],
                        [35.935408, 33.494262],
                        [35.921869, 33.51457],
                        [35.933238, 33.527825],
                        [35.956802, 33.534207],
                        [35.980263, 33.533949],
                        [35.99928, 33.541261],
                        [36.019847, 33.55263],
                        [36.035247, 33.567797],
                        [36.038451, 33.586349],
                        [36.029666, 33.59764],
                        [36.012923, 33.608027],
                        [35.994526, 33.615701],
                        [35.980263, 33.619008],
                        [35.956906, 33.629938],
                        [35.934788, 33.6334],
                        [35.921559, 33.640299],
                        [35.925383, 33.661667],
                        [35.929414, 33.665078],
                        [35.942436, 33.669212],
                        [35.94719, 33.673088],
                        [35.949878, 33.6827],
                        [35.946984, 33.700838],
                        [35.948431, 33.70921],
                        [35.955872, 33.718124],
                        [35.974165, 33.732154],
                        [35.980263, 33.743549],
                        [35.994009, 33.760705],
                        [36.027082, 33.784296],
                        [36.040518, 33.805612],
                        [36.05044, 33.816438],
                        [36.06646, 33.821994],
                        [36.085063, 33.82605],
                        [36.102426, 33.832277],
                        [36.13829, 33.850622],
                        [36.144904, 33.847418],
                        [36.157617, 33.83406],
                        [36.165678, 33.829797],
                        [36.186556, 33.83021],
                        [36.202679, 33.838297],
                        [36.216631, 33.847754],
                        [36.231824, 33.852534],
                        [36.249187, 33.849744],
                        [36.300554, 33.828686],
                        [36.338278, 33.821193],
                        [36.357501, 33.823776],
                        [36.369594, 33.837006],
                        [36.36763, 33.857547],
                        [36.35037, 33.866409],
                        [36.311096, 33.87199],
                        [36.28164, 33.891111],
                        [36.275336, 33.897209],
                        [36.268928, 33.906769],
                        [36.267171, 33.910386],
                        [36.288565, 33.946508],
                        [36.302311, 33.964388],
                        [36.31802, 33.980511],
                        [36.357811, 34.009553],
                        [36.391091, 34.044719],
                        [36.412175, 34.053633],
                        [36.423647, 34.052806],
                        [36.445144, 34.04614],
                        [36.45889, 34.046708],
                        [36.47522, 34.053504],
                        [36.480698, 34.062754],
                        [36.482662, 34.07451],
                        [36.488449, 34.088644],
                        [36.507983, 34.109185],
                        [36.552735, 34.140992],
                        [36.575886, 34.173264],
                        [36.604101, 34.199102],
                        [36.603554, 34.200101],
                        [36.59821, 34.209851],
                        [36.574026, 34.229746],
                        [36.567514, 34.244138],
                        [36.56276, 34.262949],
                        [36.570305, 34.275868],
                        [36.579917, 34.286901],
                        [36.581984, 34.299897],
                        [36.576816, 34.307674],
                        [36.570305, 34.308966],
                        [36.562967, 34.308501],
                        [36.555835, 34.311085],
                        [36.54519, 34.320723],
                        [36.523589, 34.345889],
                        [36.518628, 34.353641],
                        [36.515941, 34.36165],
                        [36.516768, 34.369867],
                        [36.519972, 34.375061],
                        [36.523589, 34.379789],
                        [36.526276, 34.386688],
                        [36.52979, 34.403095],
                        [36.530617, 34.41418],
                        [36.52514, 34.422939],
                        [36.509223, 34.432421],
                        [36.501885, 34.430303],
                        [36.494651, 34.434256],
                        [36.480181, 34.449139],
                        [36.463231, 34.455237],
                        [36.449795, 34.464538],
                        [36.439873, 34.477457],
                        [36.433466, 34.494175],
                        [36.433466, 34.49433],
                        [36.41972, 34.498335],
                        [36.392331, 34.500143],
                        [36.363082, 34.499032],
                        [36.343652, 34.494175],
                        [36.340965, 34.493477],
                        [36.338174, 34.493296],
                        [36.335384, 34.493477],
                        [36.332697, 34.494175],
                        [36.329851, 34.498603],
                        [36.319881, 34.514122],
                        [36.336624, 34.52885],
                        [36.364012, 34.541097],
                        [36.383546, 34.553809],
                        [36.388094, 34.569519],
                        [36.386853, 34.584247],
                        [36.388921, 34.596236],
                        [36.4037, 34.603832],
                        [36.418479, 34.600318],
                        [36.429125, 34.593187],
                        [36.436256, 34.597838],
                        [36.440184, 34.62936],
                        [36.415999, 34.622901],
                        [36.391194, 34.622487],
                        [36.367526, 34.629205],
                        [36.347373, 34.64414],
                        [36.323808, 34.679486],
                        [36.308925, 34.687548],
                        [36.288151, 34.675559],
                        [36.284844, 34.667911],
                        [36.284431, 34.648326],
                        [36.28071, 34.639127],
                        [36.271512, 34.630962],
                        [36.26159, 34.627242],
                        [36.201232, 34.624451],
                        [36.195134, 34.626208],
                        [36.183352, 34.633494],
                        [36.178287, 34.635303],
                        [36.17219, 34.634063],
                        [36.160201, 34.628068],
                        [36.15586, 34.626931],
                        [36.11214, 34.629532],
                        [36.087234, 34.631014],
                        [36.060775, 34.627913],
                        [36.037934, 34.62843],
                        [36.01623, 34.633649],
                        [35.998763, 34.64538],
                        [35.991425, 34.648222],
                        [35.98264, 34.650238],
                        [35.9699, 34.649849],
                        [35.965668, 34.668443],
                        [35.947439, 34.69953],
                        [35.940929, 34.726223],
                        [35.931163, 34.740953],
                        [35.928966, 34.749823],
                        [35.928966, 34.790717],
                        [35.926036, 34.796942],
                        [35.908458, 34.821763],
                        [35.904552, 34.831773],
                        [35.899099, 34.852769],
                        [35.866873, 34.924221],
                        [35.872895, 34.926907],
                        [35.873546, 34.931627],
                        [35.879568, 34.938056],
                        [35.881358, 34.944973],
                        [35.879568, 34.95189],
                        [35.873546, 34.958319],
                        [35.873546, 34.965155],
                        [35.878591, 34.96955],
                        [35.878917, 34.97016],
                        [35.876638, 34.971666],
                        [35.873546, 34.978827],
                        [35.881602, 34.992865],
                        [35.88738, 35.00019],
                        [35.895356, 35.006781],
                        [35.900564, 35.021918],
                        [35.897309, 35.036566],
                        [35.891449, 35.049954],
                        [35.887869, 35.061347],
                        [35.887869, 35.109198],
                        [35.894216, 35.12108],
                        [35.941254, 35.180569],
                        [35.962413, 35.197943],
                        [35.953298, 35.224799],
                        [35.915294, 35.287909],
                        [35.928966, 35.321479],
                        [35.924083, 35.338528],
                        [35.916677, 35.403306],
                        [35.918956, 35.41767],
                        [35.908865, 35.425727],
                        [35.860606, 35.479071],
                        [35.840099, 35.487372],
                        [35.830089, 35.493476],
                        [35.825857, 35.503323],
                        [35.81837, 35.508938],
                        [35.802257, 35.506903],
                        [35.786794, 35.502183],
                        [35.781261, 35.499579],
                        [35.770518, 35.504218],
                        [35.771983, 35.515448],
                        [35.77711, 35.52912],
                        [35.778087, 35.541164],
                        [35.771983, 35.546332],
                        [35.737071, 35.561672],
                        [35.73878, 35.566596],
                        [35.740896, 35.576483],
                        [35.743337, 35.582099],
                        [35.737071, 35.582099],
                        [35.723399, 35.575914],
                        [35.723399, 35.582099],
                        [35.743419, 35.587958],
                        [35.763357, 35.598293],
                        [35.778494, 35.612738],
                        [35.784353, 35.630561],
                        [35.782074, 35.64175],
                        [35.773204, 35.655463],
                        [35.771169, 35.667792],
                        [35.776622, 35.675116],
                        [35.800059, 35.681464],
                        [35.838552, 35.742092],
                        [35.840343, 35.747463],
                        [35.840099, 35.770819],
                        [35.835948, 35.789984],
                        [35.825369, 35.813463],
                        [35.81186, 35.835395],
                        [35.798595, 35.849677],
                        [35.846853, 35.847235],
                        [35.87086, 35.851793],
                        [35.881033, 35.866685],
                        [35.885265, 35.885972],
                        [35.911305, 35.91775],
                        [35.920835, 35.915122],
                        [35.940576, 35.918377],
                        [35.96073, 35.924372],
                        [35.980263, 35.926852],
                        [35.992769, 35.915277],
                        [35.993079, 35.902357],
                        [35.990082, 35.889283],
                        [35.992356, 35.877346],
                        [36.003931, 35.869181],
                        [36.019434, 35.866184],
                        [36.05075, 35.865409],
                        [36.080309, 35.854195],
                        [36.138497, 35.819779],
                        [36.155308, 35.822232],
                        [36.157617, 35.822569],
                        [36.168779, 35.852076],
                        [36.174877, 35.922666],
                        [36.196891, 35.951708],
                        [36.229034, 35.961165],
                        [36.253115, 35.955636],
                        [36.269031, 35.958736],
                        [36.277403, 35.994031],
                        [36.277506, 35.994238],
                        [36.29735, 36.000749],
                        [36.336727, 35.988244],
                        [36.358018, 35.99398],
                        [36.355538, 36.022402],
                        [36.358638, 36.166992],
                        [36.360498, 36.181669],
                        [36.366286, 36.189213],
                        [36.374451, 36.19588],
                        [36.375795, 36.20451],
                        [36.361429, 36.218152],
                        [36.373004, 36.227609],
                        [36.385613, 36.215465],
                        [36.398119, 36.20823],
                        [36.411865, 36.204458],
                        [36.439253, 36.201409],
                        [36.445248, 36.19991],
                        [36.450726, 36.19991],
                        [36.4592, 36.203114],
                        [36.463955, 36.208799],
                        [36.465298, 36.21593],
                        [36.468916, 36.22239],
                        [36.480181, 36.225542],
                        [36.498578, 36.227867],
                        [36.574439, 36.218359],
                        [36.593456, 36.2181],
                        [36.664253, 36.229004],
                        [36.67004, 36.237324],
                        [36.66911, 36.259855],
                        [36.665493, 36.274014],
                        [36.658465, 36.290499],
                        [36.648761, 36.305719],
                        [36.648646, 36.305899],
                        [36.637071, 36.316802],
                        [36.620741, 36.322022],
                        [36.587771, 36.324812],
                        [36.577643, 36.332874],
                        [36.58219, 36.333081],
                        [36.587771, 36.342124],
                        [36.594283, 36.35711],
                        [36.594489, 36.365947],
                        [36.593352, 36.373492],
                        [36.590562, 36.380364],
                        [36.585704, 36.387031],
                        [36.580847, 36.389873],
                        [36.565034, 36.395609],
                        [36.558006, 36.400622],
                        [36.552735, 36.408166],
                        [36.533821, 36.462478],
                        [36.531237, 36.479015],
                        [36.535475, 36.494001],
                        [36.565344, 36.5325],
                        [36.569995, 36.575701],
                        [36.569271, 36.620143],
                        [36.582811, 36.662414],
                        [36.594696, 36.685824],
                        [36.598107, 36.705461],
                        [36.598934, 36.724788],
                        [36.603274, 36.746957],
                        [36.615677, 36.766594],
                        [36.632006, 36.784319],
                        [36.643272, 36.803646],
                        [36.639861, 36.828089],
                        [36.649163, 36.828554],
                        [36.658568, 36.827521],
                        [36.659563, 36.827243],
                        [36.843673, 36.775844],
                        [36.906822, 36.772589],
                        [36.925942, 36.768971],
                        [36.945889, 36.762667],
                        [36.964699, 36.75383],
                        [36.980099, 36.742255],
                        [37.010588, 36.719517],
                        [37.018443, 36.706649],
                        [37.019476, 36.685824],
                        [37.010795, 36.653733],
                        [37.014515, 36.642261],
                        [37.033326, 36.628721],
                        [37.053066, 36.619936],
                        [37.062368, 36.622107],
                        [37.070119, 36.631512],
                        [37.085622, 36.644173],
                        [37.104432, 36.650994],
                        [37.120969, 36.649909],
                        [37.137402, 36.645878],
                        [37.156109, 36.643914],
                        [37.219671, 36.657143],
                        [37.241892, 36.65859],
                        [37.404466, 36.634458],
                        [37.446117, 36.634199],
                        [37.593395, 36.710525],
                        [37.654063, 36.732126],
                        [37.819738, 36.760548],
                        [37.980038, 36.819666],
                        [38.007943, 36.825764],
                        [38.026005, 36.835006],
                        [38.122975, 36.884623],
                        [38.190051, 36.905526],
                        [38.224261, 36.908394],
                        [38.28989, 36.901702],
                        [38.479956, 36.855736],
                        [38.529358, 36.833722],
                        [38.635192, 36.744039],
                        [38.66413, 36.719517],
                        [38.725005, 36.693937],
                        [38.795389, 36.686651],
                        [38.957963, 36.692904],
                        [38.979977, 36.698175],
                        [39.03217, 36.701172],
                        [39.185856, 36.659521],
                        [39.239599, 36.661278],
                        [39.441708, 36.692373],
                        [39.765252, 36.742151],
                        [39.979812, 36.806953],
                        [40.079238, 36.854857],
                        [40.115205, 36.864728],
                        [40.152722, 36.87036],
                        [40.190136, 36.884416],
                        [40.261409, 36.922785],
                        [40.393741, 36.994022],
                        [40.413894, 37.004022],
                        [40.435185, 37.01061],
                        [40.456993, 37.014124],
                        [40.479834, 37.015158],
                        [40.525722, 37.025881],
                        [40.659461, 37.085257],
                        [40.708967, 37.100476],
                        [40.896449, 37.122696],
                        [41.135091, 37.084249],
                        [41.179739, 37.069625],
                        [41.20134, 37.064974],
                        [41.479773, 37.075568],
                        [41.896365, 37.154283],
                        [42.009249, 37.175613],
                        [42.052968, 37.196645],
                        [42.128312, 37.253283],
                        [42.178541, 37.306303],
                        [42.193011, 37.313227],
                        [42.211201, 37.324906],
                        [42.22257, 37.313951],
                        [42.22381, 37.302375],
                        [42.222156, 37.292402],
                        [42.22319, 37.288139],
                        [42.236832, 37.286304],
                        [42.267218, 37.274522],
                        [42.272902, 37.276744],
                        [42.279207, 37.282273],
                        [42.285822, 37.286562],
                        [42.292023, 37.285064],
                        [42.296364, 37.279689],
                        [42.301428, 37.269923],
                        [42.305769, 37.264548],
                        [42.339875, 37.242637],
                        [42.346696, 37.239769],
                        [42.353208, 37.227031],
                        [42.335328, 37.171169],
                        [42.346696, 37.158431],
                        [42.354551, 37.15241],
                        [42.356515, 37.138277],
                        [42.357238, 37.109984],
                        [42.363646, 37.09815],
                        [42.371191, 37.087944],
                        [42.376875, 37.076756],
                        [42.377186, 37.062235],
                        [42.376806, 37.062001],
                        [42.34587, 37.042908],
                        [42.281894, 36.994125],
                        [42.281894, 36.994022],
                        [42.281584, 36.99397],
                        [42.178438, 36.90532],
                        [41.978554, 36.733625],
                        [41.843781, 36.617869],
                        [41.817323, 36.599731],
                        [41.789935, 36.589292],
                        [41.479773, 36.536117],
                        [41.414867, 36.527384],
                        [41.385411, 36.516377],
                        [41.365361, 36.494156],
                        [41.365361, 36.494053],
                        [41.365258, 36.494001],
                        [41.365258, 36.493898],
                        [41.276994, 36.354785],
                        [41.268829, 36.327965],
                        [41.236583, 36.077024],
                        [41.236687, 36.060332],
                        [41.240614, 36.043021],
                        [41.266246, 35.99429],
                        [41.266349, 35.994342],
                        [41.266349, 35.994238],
                        [41.343657, 35.857657],
                        [41.354509, 35.825566],
                        [41.359263, 35.792752],
                        [41.363501, 35.655241],
                        [41.358023, 35.623925],
                        [41.34221, 35.593694],
                        [41.308458, 35.552248],
                        [41.261285, 35.49432],
                        [41.261285, 35.494165],
                        [41.261181, 35.494165],
                        [41.261078, 35.494165],
                        [41.25188, 35.46409],
                        [41.243095, 35.366525],
                        [41.20134, 35.243018],
                        [41.191521, 35.182143],
                        [41.192326, 35.158904],
                        [41.198033, 34.994041],
                        [41.206508, 34.819323],
                        [41.204234, 34.793123],
                        [41.195656, 34.768473],
                        [41.023986, 34.49433],
                        [41.023986, 34.494175],
                        [40.98802, 34.42852],
                        [40.965282, 34.401855],
                        [40.936033, 34.386068],
                        [40.690467, 34.331497],
                        [40.543809, 34.257988],
                        [40.433221, 34.202539],
                        [40.322634, 34.147167],
                        [40.212046, 34.091796],
                        [40.173111, 34.072283],
                        [40.101459, 34.036373],
                        [39.990871, 33.980976],
                        [39.880387, 33.925605],
                        [39.769696, 33.870233],
                        [39.659212, 33.81481],
                        [39.548624, 33.759491],
                        [39.438037, 33.704094],
                        [39.327449, 33.648722],
                        [39.216862, 33.593325],
                        [39.106274, 33.537928],
                        [38.995686, 33.482479],
                        [38.885099, 33.427108],
                        [38.774511, 33.371685],
                        [38.529565, 33.244251],
                        [38.315742, 33.13118],
                        [38.230875, 33.086302],
                        [38.056726, 32.994292],
                        [37.929395, 32.92533],
                        [37.758036, 32.832519],
                        [37.586677, 32.739837],
                        [37.494606, 32.690056],
                        [37.415214, 32.64713],
                        [37.244062, 32.554396],
                        [37.133371, 32.494529],
                        [37.133165, 32.494529],
                        [36.980099, 32.410038],
                        [36.819385, 32.316788],
                        [36.806569, 32.313042],
                        [36.792513, 32.313533],
                        [36.728641, 32.327795],
                        [36.706937, 32.328338],
                        [36.689574, 32.319656],
                        [36.653504, 32.342859],
                        [36.516684, 32.357014],
                        [36.480181, 32.360791],
                        [36.463955, 32.369395],
                        [36.407627, 32.374227],
                        [36.387887, 32.379317],
                        [36.373108, 32.386422],
                        [36.285258, 32.456935],
                        [36.220765, 32.494581],
                        [36.188209, 32.52228],
                        [36.177357, 32.527318],
                        [36.17219, 32.525923],
                        [36.160821, 32.517215],
                        [36.15586, 32.5152],
                        [36.149865, 32.51613],
                        [36.13953, 32.519541],
                        [36.133226, 32.520109],
                        [36.096225, 32.515872],
                        [36.081906, 32.516265],
                        [36.06987, 32.516595],
                        [36.066253, 32.517319],
                        [36.066046, 32.521608],
                        [36.060465, 32.533261],
                        [36.015403, 32.591164],
                        [36.005998, 32.607907],
                        [36.005275, 32.626692],
                        [36.008272, 32.643719],
                        [36.003621, 32.655088],
                        [35.980263, 32.656612],
                        [35.965794, 32.654365],
                        [35.955355, 32.657439],
                        [35.94657, 32.664441],
                        [35.937475, 32.674002],
                        [35.941196, 32.673536],
                        [35.9444, 32.677619],
                        [35.945743, 32.684104],
                        [35.944193, 32.690771],
                        [35.940369, 32.692502],
                        [35.92745, 32.692373],
                        [35.922489, 32.693768],
                        [35.905229, 32.708573],
                        [35.895721, 32.713276],
                        [35.788234, 32.734411],
                        [35.779035, 32.744359],
                        [35.779139, 32.744514],
                        [35.774901, 32.747279],
                        [35.769734, 32.748054],
                        [35.763842, 32.746969],
                        [35.75759, 32.744347]
                    ]
                ]
            }
        }
    ]
}