import {Box, IconButton, Link, Td, Text, Tooltip, Tr} from '@chakra-ui/react';
import {DeleteIcon, DownloadIcon, CheckCircleIcon} from "@chakra-ui/icons";

import {dtToLocaleString, gregorianJalaliDTConversion, setTextDirection} from '../../utils/helpers';
import {useEffect, useRef} from "react";
import {useAuth} from "../../hooks/useAuth";
import {FormattedMessage} from 'react-intl';

const BlacklistTableRow = ({
                               id,
                               words_list_id,
                               filename,
                               title,
                               created_at,
                               status,
                               onChangeState,
                               onDelete,
                               stripped = false
                           }) => {
    const {hasPermission} = useAuth()
    const titleRef = useRef(null);

    useEffect(() => {
        setTextDirection(titleRef.current, title);
    }, [title]);

    return (
        <Tr fontSize={'sm'} bgColor={stripped ? '#f2f2f2' : 'white'}>
            <Td w={'60px'} p={3}>
                <Box maxW="sm" isTruncated>{id}</Box>
            </Td>
            <Td ref={titleRef} p={3}><Box noOfLines={3} lineHeight={2}>{title}</Box></Td>
            <Td p={3}>
                <Box noOfLines={3} lineHeight={2}>
                    <Tooltip label={gregorianJalaliDTConversion(created_at)} aria-label='datetime-conversion'>
                        <Text fontSize='sm'>{dtToLocaleString(created_at)}</Text>
                    </Tooltip>
                </Box>
            </Td>
            <Td p={3}>
                <Box noOfLines={3} lineHeight={2}>
                    <Text>
                        <FormattedMessage id={status ? 'blacklists.ENABLED' : 'blacklists.DISABLED'}/>
                    </Text>
                </Box>
            </Td>
            <Td p={3} w={'120px'}>
                {hasPermission('blacklists', 'fe_change_status') && <IconButton
                    ms={1}
                    size="xs"
                    variant={status ? 'outline' : 'solid'}
                    colorScheme={'blue'}
                    borderRadius={0}
                    onClick={() => onChangeState(words_list_id)}
                    icon={<CheckCircleIcon/>}
                />}
                {hasPermission('blacklists', 'fe_download') && <IconButton
                    ms={1}
                    size="xs"
                    as={Link}
                    variant={'outline'}
                    colorScheme={'blue'}
                    borderRadius={0}
                    href={`/words/${filename}`}
                    icon={<DownloadIcon/>}
                />}
                {hasPermission('blacklists', 'fe_delete') && <IconButton
                    ms={1}
                    size="xs"
                    variant={'outline'}
                    colorScheme={'red'}
                    borderRadius={0}
                    onClick={() => onDelete(words_list_id)}
                    icon={<DeleteIcon/>}
                />}
            </Td>
        </Tr>
    );
};

export default BlacklistTableRow;
