import {Box, Grid, Spinner, Text} from '@chakra-ui/react';
import {useCallback, useEffect, useRef, useState} from "react";
import {PostRow} from './PostRow';
import {PostGrid} from './PostGrid';
import {PostGridWithTagForm} from './PostGridWithTagForm';
import {PostRowWithTagForm} from './PostRowWithTagForm';
import {useAuth} from "../../hooks/useAuth";
import axios from "axios";
import Masonry from 'react-masonry-css';
import {FormattedMessage} from "react-intl";
import {NavBar} from "./NavBar";
import useAxios from "../../hooks/useAxois";
import {configs} from "../../Configs";

export const Raqib7 = () => {
    const axiosInstance = useAxios();
    const [gridView, setGridView] = useState(true);
    const [search, setSearch] = useState('');
    const [selPostCats, setSelPostCats] = useState([]);
    const [since, setSince] = useState(new Date(new Date().setMonth(new Date().getMonth() - 1)));
    const [until, setUntil] = useState(new Date());
    const {hasPermission} = useAuth();


    // pagination related stuff
    const [allPostTags, setAllPostTags] = useState([]);
    const [postTagsLoading, setPostTagsLoading] = useState(true);
    const [resultsCount, setResultsCount] = useState(0);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(false);
    const [nextPageId, setNextPageId] = useState(null);
    const [lastElmId, setLastElmId] = useState(null);
    const [pageId, setPageId] = useState(null);

    useEffect(() => {
        axiosInstance.get("/raqib7_post_tag").then((data) => {
            setAllPostTags(data.data);
            setPostTagsLoading(false);
            setError(false);
        }).catch((error) => {
            setPostTagsLoading(false);
            if (error.response && error.response.status === 404) return;
            setError(true);
            console.error(error);
        });
    }, []);

    const observer = useRef();

    const lastPostElmRef = useCallback(node => {
        if (isLoading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && nextPageId) {
                setPageId(nextPageId)
            }
        })
        if (node) observer.current.observe(node);
    }, [isLoading, nextPageId])

    const onSubmit = (paginate = false) => {
        if (postTagsLoading) return
        setLastElmId(null);
        setIsLoading(true);
        setError(false);
        let cancel;

        if (!paginate) {
            setNextPageId(null);
            setPageId(null);
            setData([]);
            setResultsCount(0);
        }

        const payload = {
            headers: {'Client-Timezone': configs.time_locale},
            params: paginate ? {after: pageId} : {},
            cancelToken: new axios.CancelToken(c => cancel = c)
        };

        if (search && search.replace(' ', '').length > 0) {
            payload.params.search = search
        }

        if (since) {
            payload.params.since = since.toISOString();
        }

        if (until) {
            payload.params.until = until.toISOString();
        }

        // change since/until if they are entered in reverse
        if (since && until && until < since) {
            payload.since = until.toISOString();
            payload.params.until = since.toISOString();
        }

        axiosInstance.get("/raqib7/", payload).then((res) => {
            setResultsCount(res?.data?.count || 0);
            setData(prev => {
                // discard previous results if its not paginating
                const theData = paginate ? [...prev, ...(res?.data?.posts || [])] : [...(res?.data?.posts || [])];
                // uniqify data combination
                let itteratedKeys = [];
                let newData = [];
                theData.map(pst => {
                    if (!itteratedKeys.includes(pst.id)) {
                        itteratedKeys.push(pst.id);
                        newData.push(pst);
                    }
                });

                // the nextPageId is determined by least sort (desc)
                let sortedData = theData;
                sortedData.sort((a, b) => parseFloat(a.sort) - parseFloat(b.sort));
                setLastElmId(sortedData.length ? sortedData[0].id : null);
                setNextPageId(sortedData.length ? sortedData[0].sort : null)
                setIsLoading(false);
                setError(false);

                return newData;
            });
        }).catch((error) => {
            if (axios.isCancel(error)) return;
            setIsLoading(false);
            if (error.response && error.response.status === 404) return;
            setError(true);
            console.error(error);
        });
        return () => cancel();
    }

    useEffect(() => onSubmit(true), [pageId, postTagsLoading]);

    return <>
        <NavBar
            resultsCount={resultsCount}
            search={search}
            setSearch={setSearch}
            since={since}
            setSince={setSince}
            until={until}
            setUntil={setUntil}
            setSelPostCats={setSelPostCats}
            selPostCats={selPostCats}
            gridView={gridView}
            setGridView={setGridView}
            onSubmit={onSubmit}
        />

        <Box
            p={5}
            align="center"
            justify="center"
            direction={{base: 'column', md: 'row'}}
        >
            {gridView && <Masonry
                breakpointCols={{
                    default: 5,
                    1100: 3,
                    700: 2,
                    500: 1
                }}
                className="masonry-grid"
                columnClassName="masonry-grid_column"
            >
                {/*<Grid templateColumns={{base: '1fr', md: 'repeat(3, 1fr)', lg: 'repeat(5, 1fr)'}} gap={6}>*/}
                {data && data.map(pst => {
                    if (pst.id === lastElmId) {
                        pst['refr'] = lastPostElmRef
                    }
                    if (hasPermission('raqib7', 'fe_post_tagging')) {
                        return <PostGridWithTagForm key={'post:' + pst.id} allPostTags={allPostTags} {...pst}/>
                    }
                    return <PostGrid key={'post:' + pst.id} {...pst}/>
                })}
                {/*</Grid>*/}
            </Masonry>}

            {!gridView && <Grid templateColumns={'1fr'}
                                width={{base: '100%', md: '3xl'}}
                                gap={2}
            >
                {data && data.map(pst => {
                    if (pst.id === lastElmId) {
                        pst['refr'] = lastPostElmRef
                    }
                    if (hasPermission('raqib7', 'fe_post_tagging')) {
                        return <PostRowWithTagForm key={'post:' + pst.id} allPostTags={allPostTags} {...pst}/>
                    }
                    return <PostRow key={'post:' + pst.id} {...pst}/>;
                })}
            </Grid>}

            <Box w='100%' textAlign='center'>
                {!data.length && !isLoading && <Text>
                    <FormattedMessage
                        id="responses.noData"
                        defaultMessage="No Date"
                    />
                </Text>}
                {!isLoading && error && <Text>
                    <FormattedMessage
                        id="responses.connectionFailed"
                        defaultMessage="Connection Failed"
                    />
                </Text>}
                {isLoading && <Spinner mt={5} color="green"/>}
            </Box>
        </Box>
    </>;
}
