import {Box, Center, Container, Spinner} from "@chakra-ui/react";
import {useEffect, useRef, useState} from "react";
import {AddUpdateUserModal} from "./AddUpdateUserModal";
import {UsersStackedNegativeChart} from "../Charts/UsersStackedNegativeChart";
import {MostActiveUsersCircleChart} from "../Charts/MostActiveUsersCircleChart";
import {UserCard} from "./UserCard";
import {useAuth} from "../../hooks/useAuth";
import useAxios from "../../hooks/useAxois";
import {UsersActivityIpMapChart} from "../Charts/UsersActivityIpMapChart";
import {UserLatestActivityTable} from "../Charts/UserLatestActivityTable";

export const UserList = () => {
    const axiosInstance = useAxios();
    const {hasPermission} = useAuth();
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const isMounted = useRef(false);

    useEffect(() => {
        if (isMounted.current) return;
        fetchUsers();
        isMounted.current = true;
    }, []);

    const fetchUsers = () => {
        setLoading(true);
        axiosInstance
            .get("/users/")
            .then((res) => {
                setUsers(res.data);
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Container mt={9}>
            <UsersStackedNegativeChart/>
            <MostActiveUsersCircleChart/>
            <UsersActivityIpMapChart/>
            <UserLatestActivityTable/>
            {hasPermission('users', 'fe_create') && <AddUpdateUserModal onSuccess={fetchUsers}/>}
            {loading ? (
                <Center mt={6}>
                    <Spinner
                        thickness="4px"
                        speed="0.65s"
                        emptyColor="green.200"
                        color="green.500"
                        size="xl"
                    />
                </Center>
            ) : (
                <Box mt={6}>
                    {users?.map((user) => (<UserCard user={user} key={user.user_id}/>))}
                </Box>
            )}
        </Container>
    );
};
