import {Button, Flex, FormControl, FormErrorMessage, Heading, useColorModeValue} from "@chakra-ui/react";
import {Controller, useForm} from "react-hook-form";
import ReactSelect from 'react-select';
import {createSearchParams, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import useAxios from "../../hooks/useAxois";
import {FormattedMessage, useIntl} from "react-intl";

export const Raqib2 = () => {
    const intl = useIntl();
    const axiosInstance = useAxios();
    const [selectedOptions, setSelectedOptions] = useState([]);

    const cstmMutliSelect = {
        width: '100%',
        minWidth: '0px',
        outline: '2px solid transparent',
        outlineOffset: '2px',
        position: 'relative',
        appearance: 'none',
        transitionProperty: 'var(--chakra-transition-property-common)',
        transitionDuration: 'var(--chakra-transition-duration-normal)',
        fontSize: 'var(--chakra-fontSizes-lg)',
        paddingInlineStart: 'var(--chakra-space-4)',
        paddingInlineEnd: 'var(--chakra-space-4)',
        minHeight: 'var(--chakra-sizes-12)',
        borderRadius: 'var(--chakra-radii-md)',
        border: '0px solid',
        borderColor: 'var(--chakra-colors-transparent)',
        background: useColorModeValue("var(--chakra-colors-gray-300)", "var(--chakra-colors-gray-600)"),
        marginTop: 'var(--chakra-space-6)',
        "&:hover": {
            background: useColorModeValue('var(--chakra-colors-gray-200)', 'var(--chakra-colors-whiteAlpha-100)'),
        },
        "&:focus-visible": {
            background: 'var(--chakra-colors-transparent)',
            borderColor: '#63b3ed'
        }
    }

    const cstmMutliSelectMenu = {
        background: useColorModeValue("var(--chakra-colors-gray-300)", "var(--chakra-colors-gray-600)"),
        color: useColorModeValue("var(--chakra-colors-gray-800)", "var(--chakra-colors-gray-300)"),
    }

    const cstmMutliSelectOptions = {
        background: useColorModeValue("var(--chakra-colors-gray-300)", "var(--chakra-colors-gray-600)"),

        "&:hover": {
            background: useColorModeValue('var(--chakra-colors-gray-200)', 'var(--chakra-colors-whiteAlpha-100)'),
        },
        "&:focus-visible": {
            background: 'var(--chakra-colors-transparent)',
            borderColor: '#63b3ed'
        }
    }

    const navigate = useNavigate();

    const {
        handleSubmit,
        register,
        watch,
        control,
        formState: {errors, isSubmitting}
    } = useForm();

    const [factions, setFactions] = useState([]);

    useEffect(() => {
        axiosInstance.get('/raqib2_tag').then((data) => setFactions(data.data.map(item => (
            {
                value: item.tag_id,
                label: item.title
            }
        ))));
    }, []);

    const onSubmit = (values) => {
        const params = values.factions.map(item => item.value);
        navigate({
            pathname: "/raqib2/posts",
            search: '?' + createSearchParams({factions: params})
        });
    };

    return (
        <Flex h='80vh' alignItems="center" justifyContent="center" alignContent={'center'}>
            <Flex
                direction="column"
                alignItems="center"
                background={"gray.100"} p={{base: 5, md: 10}} rounded={6}
                w={{base: '90%', xl: '80%'}}
            >
                <Heading mb={12}>
                    <FormattedMessage
                        id="raqib2.title"
                        defaultMessage="Topics"
                    />
                </Heading>
                <form onSubmit={handleSubmit(onSubmit)} style={{width: '100%'}}>

                    <FormControl isInvalid={errors.factions}>
                        <Controller
                            rules={{
                                required: intl.formatMessage(
                                    {
                                        id: "validation.atleastRequired",
                                        defaultMessage: "At least {length} items required for {field}"
                                    },
                                    {
                                        field: intl.formatMessage({
                                            id: "raqib2.factions",
                                            defaultMessage: "Topic Tags, Factions, ..."
                                        }),
                                        itemsCnt: 1
                                    }
                                )
                            }}
                            name="factions"
                            control={control}
                            render={({field: {onChange, value}}) => (
                                <ReactSelect
                                    value={value}
                                    onChange={o => {
                                        setSelectedOptions(o);
                                        return onChange(o);
                                    }
                                    }
                                    placeholder={intl.formatMessage({
                                        id: 'raqib2.factions',
                                        defaultMessage: 'Topic Tags, Factions, ...'
                                    })}
                                    isMulti
                                    options={factions}
                                    closeMenuOnSelect={false}
                                    isOptionDisabled={() => selectedOptions.length >= 5}
                                    classNames={
                                        {
                                            control: (state) => state.isFocused ? 'border-red-600' : 'border-grey-300'
                                        }
                                    }
                                    styles={{
                                        control: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelect}),
                                        menu: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelectMenu}),
                                        option: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelectOptions})
                                    }}
                                />
                            )}
                        />
                        <FormErrorMessage>
                            {errors.factions && errors.factions.message}
                        </FormErrorMessage>
                    </FormControl>
                    <Button
                        width="100%"
                        variant="outline"
                        mt={6}
                        mb={6} colorScheme="green" type="submit" isLoading={isSubmitting}
                    >
                        <FormattedMessage
                            id="raqib2.go"
                            defaultMessage="GO"
                        />
                    </Button>
                </form>
            </Flex>
        </Flex>
    );
};
