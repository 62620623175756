import {Box, Spinner, Text} from "@chakra-ui/react";

import {useContext, useEffect, useLayoutEffect, useState} from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5hierarchy from "@amcharts/amcharts5/hierarchy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {FormattedMessage, useIntl} from "react-intl";
import {LocaleContext} from "../Theme/LocaleProvider";
import useAxios from "../../hooks/useAxois";
import {useAuth} from "../../hooks/useAuth";

export const MostActiveUsersCircleChart = ({...rest}) => {
    const axiosInstance = useAxios();
    const context = useContext(LocaleContext);
    const intl = useIntl();
    const {hasPermission} = useAuth();
    const hasPermit = hasPermission('charts:users:active-circle', 'fe_active_circle');
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(hasPermit);
    const [error, setError] = useState(false);

    useEffect(() => {
        if (!hasPermit) return;
        axiosInstance.get("/charts/users/active-circle").then((res) => {
            setData(res.data);
            setError(false);
        }).catch((error) => {
            if (error.response && error.response.status === 404) return;
            setError(true);
            console.error(error);
        }).finally(() => setIsLoading(false));
    }, []);

    useLayoutEffect(() => {
        if (isLoading || !hasPermit) return;

        // define root chart with custom theme based on rtl/ltr
        let root = am5.Root.new("most-active-users-circle");
        const customTheme = am5.Theme.new(root);
        if (context.isRTL) {
            customTheme.rule("Label").setAll({
                fontFamily: "IRANSans",
                direction: 'rtl'
            });
        }
        root.setThemes([am5themes_Animated.new(root), customTheme]);

        let zoomableContainer = root.container.children.push(
            am5.ZoomableContainer.new(root, {
                width: am5.p100,
                height: am5.p100,
                wheelable: true,
                pinchZoom: true,
                // paddingTop: 50
            })
        );

        // zoomableContainer.children.unshift(am5.Label.new(root, {
        //     text: intl.formatMessage({id: 'charts.users.mostActiveUsersCircleTitle'}),
        //     fontSize: 25,
        //     fontWeight: "500",
        //     textAlign: "center",
        //     x: am5.percent(50),
        //     centerX: am5.percent(50),
        //     paddingTop: -50,
        //     paddingBottom: 0
        // }));

        let zoomTools = zoomableContainer.children.push(am5.ZoomTools.new(root, {target: zoomableContainer}));

        // Create series
        let series = zoomableContainer.contents.children.push(am5hierarchy.Pack.new(root, {
            maskContent: false,
            topDepth: 1,
            valueField: "value",
            categoryField: "category",
            childDataField: "children"
        }));

        series.nodes.template.set("tooltipText", "{category}: {value}");
        series.data.setAll([{category: "", children: data}]);

        // Make stuff animate on load
        series.appear(1000, 100);

        return () => {
            root.dispose();
        };
    }, [isLoading, context.isRTL]);

    if (!hasPermit) return <></>

    return (<Box
        bg={"gray.100"}
        minHeight="3rem"
        my={2}
        p={3}
        py={5}
        rounded="lg"
        dir='ltr'
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        {...rest}
    >
        {isLoading && <Spinner color="green"/>}
        {!isLoading && <Text
            style={{fontSize: 25, fontFamily: "IRANSans", textAlign: 'center', fontWeight: 500}}
            dir={context.isRTL ? 'rtl' : 'ltr'}
        >
            <FormattedMessage id="charts.users.mostActiveUsersCircleTitle"/>
        </Text>}
        {!isLoading && <div id="most-active-users-circle" style={{
            width: "100%",
            height: "400px",
            fontFamily: 'IRANSans, sans-serif'
        }}></div>}
    </Box>);
};
