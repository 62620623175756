import {Box, Spinner, Text} from "@chakra-ui/react";

import {useContext, useEffect, useLayoutEffect, useState} from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map";
import worldLow from "@amcharts/amcharts5-geodata/worldLow";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {FormattedMessage, useIntl} from "react-intl";
import {LocaleContext} from "../Theme/LocaleProvider";
import useAxios from "../../hooks/useAxois";
import {useAuth} from "../../hooks/useAuth";

export const UsersActivityIpMapChart = ({...rest}) => {
    const axiosInstance = useAxios();
    const context = useContext(LocaleContext);
    const intl = useIntl();
    const {hasPermission} = useAuth();
    const hasPermit = hasPermission('charts:users:activity-ip-map', 'fe_activity_ip_map');
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(hasPermit);
    const [error, setError] = useState(false);

    useEffect(() => {
        if (!hasPermit) return;
        axiosInstance.get("/charts/users/activity-ip-map").then((res) => {
            setData(res.data.map(item => ({
                ...item,
                name: intl.formatMessage({id: `charts.users.countries.${item.name}`}),
                // radius: 10
            })));
            setError(false);
        }).catch((error) => {
            if (error.response && error.response.status === 404) return;
            setError(true);
            console.error(error);
        }).finally(() => setIsLoading(false));
    }, []);

    useLayoutEffect(() => {
        if (isLoading || !hasPermit) return;

        // define root chart with custom theme based on rtl/ltr
        let root = am5.Root.new("users-activity-ip-map");
        const customTheme = am5.Theme.new(root);
        if (context.isRTL) {
            customTheme.rule("Label").setAll({
                fontFamily: "IRANSans",
                direction: 'rtl'
            });
        }
        root.setThemes([am5themes_Animated.new(root), customTheme]);

        let chart = root.container.children.push(am5map.MapChart.new(root, {
            panX: "rotateX",
            panY: "rotateY",
            projection: am5map.geoOrthographic(),
            zoomLevel: 1,
            maxZoomLevel: 1,
            // paddingTop: 50,
            // marginBottom: 50
        }));

        // chart.children.unshift(am5.Label.new(root, {
        //     text: intl.formatMessage({id: 'charts.posts.usersActivityIpMapTitle'}),
        //     fontSize: 25,
        //     fontWeight: "500",
        //     textAlign: "center",
        //     x: am5.percent(50),
        //     centerX: am5.percent(50),
        //     paddingTop: -50
        // }));

        let polygonSeries = chart.series.push(
            am5map.MapPolygonSeries.new(root, {
                geoJSON: worldLow,
                exclude: ["AQ"]
            })
        );

        // Create series for background fill
        let backgroundSeries = chart.series.push(am5map.MapPolygonSeries.new(root, {}));
        backgroundSeries.mapPolygons.template.setAll({
            fill: root.interfaceColors.get("alternativeBackground"),
            fillOpacity: 0.1,
            strokeOpacity: 0
        });
        backgroundSeries.data.push({
            geometry: am5map.getGeoRectangle(90, 180, -90, -180)
        });

        let graticuleSeries = chart.series.unshift(
            am5map.GraticuleSeries.new(root, {
                step: 10
            })
        );

        graticuleSeries.mapLines.template.set("strokeOpacity", 0.1)

        let bubbleSeries = chart.series.push(
            am5map.MapPointSeries.new(root, {
                valueField: "value",
                // calculateAggregates: true,
                polygonIdField: "id"
            })
        );

        let circleTemplate = am5.Template.new({});
        bubbleSeries.bullets.push(function (root, series, dataItem) {
            let container = am5.Container.new(root, {});
            let circle = container.children.push(
                am5.Circle.new(root, {
                    radius: 20,
                    fillOpacity: 0.7,
                    fill: am5.color(0xff0000),
                    cursorOverStyle: "pointer",
                    tooltipText: '{name}: {value}'
                }, circleTemplate)
            );

            let countryLabel = container.children.push(
                am5.Label.new(root, {
                    text: "{name}",
                    centerX: am5.p100,
                    centerY: am5.p100,
                    textAlign: "center",
                    // paddingTop: 30,
                    populateText: true,
                    fontWeight: "bold",
                    fontSize: 13
                })
            );

            circle.on("radius", function (radius) {
                countryLabel.set("x", radius);
            })

            return am5.Bullet.new(root, {
                sprite: container,
                dynamic: true
            });
        });

        bubbleSeries.bullets.push(function (root, series, dataItem) {
            return am5.Bullet.new(root, {
                sprite: am5.Label.new(root, {
                    text: "{value.formatNumber('#.')}",
                    fill: am5.color(0xffffff),
                    populateText: true,
                    centerX: am5.p50,
                    centerY: am5.p50,
                    textAlign: "center"
                }),
                dynamic: true
            });
        });


        // minValue and maxValue must be set for the animations to work
        bubbleSeries.set("heatRules", [
            {
                target: circleTemplate,
                dataField: "radius",
                min: 10,
                max: 50,
                minValue: 0,
                maxValue: 100,
                key: "radius"
            }
        ]);

        bubbleSeries.data.setAll(data);

        // updateData();
        // setInterval(function () {
        //     updateData();
        // }, 2000)
        // function updateData() {
        //     for (let i = 0; i < bubbleSeries.dataItems.length; i++) {
        //         bubbleSeries.data.setIndex(i, {
        //             value: Math.round(Math.random() * 100),
        //             id: data[i].id,
        //             name: data[i].name
        //         })
        //     }
        // }

        return () => {
            root.dispose();
        };
    }, [isLoading, context.isRTL]);

    if (!hasPermit) return <></>

    return (<Box
        bg={"gray.100"}
        minHeight="3rem"
        my={2}
        p={3}
        py={5}
        rounded="lg"
        dir='ltr'
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        {...rest}
    >
        {isLoading && <Spinner color="green"/>}
        {!isLoading && <Text
            style={{fontSize: 25, fontFamily: "IRANSans", textAlign: 'center', fontWeight: 500}}
            dir={context.isRTL ? 'rtl' : 'ltr'}
        >
            <FormattedMessage id="charts.users.usersActivityIpMapTitle"/>
        </Text>}
        {!isLoading && <div id="users-activity-ip-map" style={{
            width: "100%",
            height: "400px",
            fontFamily: 'IRANSans, sans-serif'
        }}></div>}
    </Box>);
};
