const now = new Date();
const midnight = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
const today = now.getTime() - midnight.getTime() > 0 ? ((now.getTime() - midnight.getTime()) / 1000).toFixed() : 0;

const configs = {
    country_code: 'lb',
    ui_font_family: 'BlinkMacSystemFont, Cairo, IRANSans, sans-serif',
    posts_heading_font_family: 'Cairo',
    posts_body_font_family: 'Cairo, sans-serif',
    base_back_url: 'http://lb-raqib.ai:8931/api/v1/',
    // base_back_url: 'http://127.0.0.1:8000/api/v1/',
    // raqib_support_bot_url: 'tg://resolve?domain=lb_raqib_support_bot',
    raqib_support_bot_url: 'https://web.telegram.org/k/#@lb_raqib_support_bot',
    // time_locale: 'utc',
    // time_locale: 'Asia/Tehran',
    time_locale: Intl.DateTimeFormat().resolvedOptions().timeZone,   //Detect browser timezone and show as that zone
    target_location: {
        country: 'Lebanon',
        google_trends_country_code: 'LB',
    },
    roles: [
        {label: 'Superadmin', value: 'super_admin'},
        {label: 'Admin', value: 'admin'},
        {label: 'Viewer', value: 'viewer'},
        {label: 'Disabled', value: 'disabled'}
    ],
    projects: [
        {label: 'Map', value: 'RAQIB1'},
        {label: 'Monitoring', value: 'RAQIB6'},
        {label: 'News', value: 'RAQIB5'},
        {label: 'Enemy View', value: 'RAQIB7'},
        {label: 'Topics', value: 'RAQIB2'},
        {label: 'Image Trends', value: 'RAQIB3'},
        {label: 'Contextual Trends', value: 'RAQIB4'},
    ],
    locations: [
        {label: "International", value: "international", geo: {lat: 36.2, lon: 37.5}, zoom: 4.5},
        {label: 'Akkar', value: 'akkar', geo: {lat: 34.56173194030454, lon: 36.170391507255836}, zoom: 11.7},
        {
            label: 'Baalbak - Hermel',
            value: 'baalbak - hermel',
            geo: {lat: 34.18443978105663, lon: 36.30651446746094},
            zoom: 11
        },
        {label: 'Beirut', value: 'beirut', geo: {lat: 33.88872026357247, lon: 35.50129943374898}, zoom: 14},
        {label: 'Bekaa', value: 'bekaa', geo: {lat: 33.56314461154913, lon: 35.80260642179886}, zoom: 11.7},
        {
            label: 'Mount Lebanon',
            value: 'mount lebanon',
            geo: {lat: 34.08307550408834, lon: 35.80266178854958},
            zoom: 11.7
        },
        {label: 'Nabatiyeh', value: 'nabatiyeh', geo: {lat: 33.16029072162728, lon: 35.427473153878076}, zoom: 12.5},
        {label: 'North', value: 'north', geo: {lat: 34.31903450653157, lon: 35.92654011262274}, zoom: 11.7},
        {label: 'South', value: 'south', geo: {lat: 33.2567150550706, lon: 35.302152351303235}, zoom: 12.7},
    ],
    exports: {
        templates: [
            {label: "Simple", value: "SIMPLE"},
            {label: "Observatory", value: "OBSERVATORY"}
        ],
        fields: [
            {label: "Title", value: 'title'},
            {label: "Translate link", value: 'translate_link'},
            {label: "Link", value: 'link'},
            {label: "Hashtags", value: 'hashtags'},
            {label: "Mentions", value: 'mentions'},
            {label: "Pub date", value: 'pubDate'},
            {label: "Has star", value: 'raqib_metadata.has_star'},
            {label: "Origin url", value: 'raqib_metadata.target.origin_url'},
            {label: "Target", value: 'raqib_metadata.target.title'},
            {label: "Platform", value: 'raqib_metadata.target.rss_platform'},
            {label: "Tags", value: 'raqib_metadata.tags.title'},
            {label: "Fetched at", value: 'raqib_metadata.fetched_at'}
        ]
    },
    raqib1: {
        mapsOsmProvider: {
            token: "pk.eyJ1IjoibWZyYXNvdWxuZWphZCIsImEiOiJjbGM0cTR6aHkwcW8xM3ZwZ3hoYTNqNjY5In0.A7GD0TuHDQuzblYcGyh25g",
            attribution: {
                show: false,
                text: ""
            },
            colors: {
                'TRENDS24': [113, 128, 150],
                'TRENDSMAP': [113, 128, 150],
                'GOOGLE_TRENDS': [56, 161, 105],
                'TWITTER': [26, 32, 44],
                'RSS_INSTAGRAM': [229, 62, 62],
                'RSS_FACEBOOK': [43, 108, 176],
                'RSS_YOUTUBE': [128, 90, 213],
                'RSS_TIKTOK': [213, 63, 140],
                'RSS_TELEGRAM': [0, 136, 204],
                'TELEGRAM': [0, 136, 204],
                'RSS_OTHER': [214, 158, 46],
                'RSS_VK': [49, 151, 149],
                'RSS_OK': [0, 181, 216]
            },
            deckGl: {
                showCoordinates: false,
                controller: {
                    doubleClickZoom: false,
                    keyboard: false,
                    scrollZoom: false,
                    touchZoom: false,
                    dragPan: false
                },
                // lightStyle: 'https://basemaps.cartocdn.com/gl/positron-nolabels-gl-style/style.json',
                lightStyle: 'mapbox://styles/mapbox/satellite-v9',
                // darkStyle: 'mapbox://styles/mapbox/dark-v9',
                darkStyle: 'mapbox://styles/mapbox/satellite-v9',
                terrain: {source: 'mapbox-dem', exaggeration: 1.5},
                scrollZoom: false,
                touchZoom: false,
                keyboard: false,
                doubleClickZoom: false,
                initial_states: {
                    longitude: 36.11489378692081,
                    latitude: 34.17413796671719,
                    zoom: 10,
                    pitch: 0,
                    bearing: 0
                }
            }
        },
        // refresh page after this amount of time
        refresh_interval_default: 360000,
        // if true, enable auto refresh after refresh_interval_default seconds passed by default
        should_refresh_default: true,
        sources: [
            {label: 'Trends24', value: 'TRENDS24'},
            {label: 'Trendsmap', value: 'TRENDSMAP'},
            {label: 'Google', value: 'GOOGLE_TRENDS'},
            {label: 'X', value: 'TWITTER'},
        ],
        rss_platforms: [
            {label: 'Instagram', value: 'RSS_INSTAGRAM'},
            {label: 'Facebook', value: 'RSS_FACEBOOK'},
            {label: 'X', value: 'RSS_TWITTER'},
            {label: 'Telegram', value: 'RSS_TELEGRAM'},
            {label: 'YouTube', value: 'RSS_YOUTUBE'},
            {label: 'TikTok', value: 'RSS_TIKTOK'},
            {label: 'Web', value: 'RSS_OTHER'},
            {label: 'Vk', value: 'RSS_VK'},
            {label: 'Ok', value: 'RSS_OK'}
        ],
        mapMinFontSize: 15,
        mapMaxFontSize: 30,
        mapsSourcesVals: {
            // TRENDSMAP: 'TRENDSMAP',
            TRENDS24: 'TRENDS24',
            GOOGLE_TRENDS: 'GOOGLE_TRENDS',
            TWITTER: 'TWITTER',
            RSS_FACEBOOK: 'RSS_FACEBOOK',
            RSS_INSTAGRAM: 'RSS_INSTAGRAM',
            RSS_TIKTOK: 'RSS_TIKTOK',
            RSS_YOUTUBE: 'RSS_YOUTUBE',
            TELEGRAM: 'TELEGRAM',
            RSS_OTHER: 'RSS_OTHER',
            RSS_VK: 'RSS_VK',
            RSS_OK: 'RSS_OK'
        },
        mapsFilters: {
            timeranges: [
                {label: 'Last Hour', value: 3600},
                {label: 'Last 24 Hours', value: 86400},
                {label: 'Last 7 Days', value: 604800},
                {label: 'Last 30 Days', value: 2592000}
            ],
        }
    },
    raqib2: {
        // refresh page after this amount of time
        refresh_interval_default: 360000,
        // if true, enable auto refresh after refresh_interval_default seconds passed by default
        should_refresh_default: true,
        rss_platforms: [
            {label: 'Instagram', value: 'RSS_INSTAGRAM'},
            {label: 'Facebook', value: 'RSS_FACEBOOK'},
            {label: 'X', value: 'RSS_TWITTER'},
            {label: 'TikTok', value: 'RSS_TIKTOK'},
            {label: 'YouTube', value: 'RSS_YOUTUBE'},
            {label: 'Telegram', value: 'RSS_TELEGRAM'},
            {label: 'Web', value: 'RSS_OTHER'},
            {label: 'Vk', value: 'RSS_VK'},
            {label: 'Ok', value: 'RSS_OK'},
            {label: 'News', value: 'NEWS'},
        ],
        colors: {
            'RSS_INSTAGRAM': [229, 62, 62],
            'RSS_TWITTER': [0, 0, 45],
            'RSS_FACEBOOK': [56, 88, 152],
            'RSS_TELEGRAM': [0, 136, 204],
            'TELEGRAM': [0, 136, 204],
            'RSS_YOUTUBE': [128, 90, 213],
            'RSS_TIKTOK': [213, 63, 140],
            'RSS_OTHER': [214, 158, 46],
            'RSS_VK': [49, 151, 149],
            'RSS_OK': [0, 181, 216],
            'NEWS': [153, 0, 76]
        }
    },
    raqib3: {
        // refresh page after this amount of time
        refresh_interval_default: 360000,
        // if true, enable auto refresh after refresh_interval_default seconds passed by default
        should_refresh_default: true,
        periods: [
            {label: '1 Hour', value: 3600},
            {label: '2 Hours', value: 7200},
            {label: '3 Hours', value: 10800},
            {label: '6 Hours', value: 21600},
            {label: 'Today', value: today},
            {label: '1 Day Ago', value: 86400},
            {label: '2 Days Ago', value: 172800},
            {label: '3 Days Ago', value: 259200},
            {label: '4 Days Ago', value: 345600},
            {label: '5 Days Ago', value: 432000},
            {label: '6 Days Ago', value: 518400},
            {label: '7 Days Ago', value: 604800}
        ],
        rss_platforms: [
            {label: 'Instagram', value: 'RSS_INSTAGRAM'},
            {label: 'Facebook', value: 'RSS_FACEBOOK'},
            {label: 'X', value: 'RSS_TWITTER'},
            {label: 'TikTok', value: 'RSS_TIKTOK'},
            {label: 'YouTube', value: 'RSS_YOUTUBE'},
            {label: 'Telegram', value: 'RSS_TELEGRAM'},
            {label: 'Web', value: 'RSS_OTHER'},
            {label: 'Vk', value: 'RSS_VK'},
            {label: 'Ok', value: 'RSS_OK'},
            {label: 'News', value: 'NEWS'},
        ],
        colors: {
            'RSS_INSTAGRAM': [229, 62, 62],
            'RSS_TWITTER': [0, 0, 45],
            'RSS_FACEBOOK': [56, 88, 152],
            'RSS_TELEGRAM': [0, 136, 204],
            'TELEGRAM': [0, 136, 204],
            'RSS_YOUTUBE': [128, 90, 213],
            'RSS_TIKTOK': [213, 63, 140],
            'RSS_OTHER': [214, 158, 46],
            'RSS_VK': [49, 151, 149],
            'RSS_OK': [0, 181, 216],
            'NEWS': [153, 0, 76]
        }
    },
    raqib4: {
        // refresh page after this amount of time
        refresh_interval_default: 360000,
        // if true, enable auto refresh after refresh_interval_default seconds passed by default
        should_refresh_default: true,
        periods: [
            {label: '1 Hour', value: 3600},
            {label: '2 Hours', value: 7200},
            {label: '3 Hours', value: 10800},
            {label: '6 Hours', value: 21600},
            {label: 'Today', value: today},
            {label: '1 Day Ago', value: 86400},
            {label: '2 Days Ago', value: 172800},
            {label: '3 Days Ago', value: 259200},
            {label: '4 Days Ago', value: 345600},
            {label: '5 Days Ago', value: 432000},
            {label: '6 Days Ago', value: 518400},
            {label: '7 Days Ago', value: 604800}
        ],
        rss_platforms: [
            {label: 'Instagram', value: 'RSS_INSTAGRAM'},
            {label: 'Facebook', value: 'RSS_FACEBOOK'},
            {label: 'X', value: 'RSS_TWITTER'},
            {label: 'TikTok', value: 'RSS_TIKTOK'},
            {label: 'YouTube', value: 'RSS_YOUTUBE'},
            {label: 'Telegram', value: 'RSS_TELEGRAM'},
            {label: 'Web', value: 'RSS_OTHER'},
            {label: 'Vk', value: 'RSS_VK'},
            {label: 'Ok', value: 'RSS_OK'},
            {label: 'News', value: 'NEWS'},
        ],
        colors: {
            'RSS_INSTAGRAM': [229, 62, 62],
            'RSS_TWITTER': [0, 0, 45],
            'RSS_FACEBOOK': [56, 88, 152],
            'RSS_TELEGRAM': [0, 136, 204],
            'TELEGRAM': [0, 136, 204],
            'RSS_YOUTUBE': [128, 90, 213],
            'RSS_TIKTOK': [213, 63, 140],
            'RSS_OTHER': [214, 158, 46],
            'RSS_VK': [49, 151, 149],
            'RSS_OK': [0, 181, 216],
            'NEWS': [153, 0, 76]
        }
    },
    raqib5: {
        // refresh page after this amount of time
        refresh_interval_default: 360000,
        // if true, enable auto refresh after refresh_interval_default seconds passed by default
        should_refresh_default: true,
        rss_platforms: [
            {label: 'Instagram', value: 'RSS_INSTAGRAM'},
            {label: 'Facebook', value: 'RSS_FACEBOOK'},
            {label: 'X', value: 'RSS_TWITTER'},
            {label: 'TikTok', value: 'RSS_TIKTOK'},
            {label: 'YouTube', value: 'RSS_YOUTUBE'},
            {label: 'Telegram', value: 'RSS_TELEGRAM'},
            {label: 'Web', value: 'RSS_OTHER'},
            {label: 'Vk', value: 'RSS_VK'},
            {label: 'Ok', value: 'RSS_OK'},
            {label: 'News', value: 'NEWS'},
        ],
        colors: {
            'RSS_INSTAGRAM': [229, 62, 62],
            'RSS_TWITTER': [0, 0, 45],
            'RSS_FACEBOOK': [56, 88, 152],
            'RSS_TELEGRAM': [0, 136, 204],
            'TELEGRAM': [0, 136, 204],
            'RSS_YOUTUBE': [128, 90, 213],
            'RSS_TIKTOK': [213, 63, 140],
            'RSS_OTHER': [214, 158, 46],
            'RSS_VK': [49, 151, 149],
            'RSS_OK': [0, 181, 216],
            'NEWS': [153, 0, 76]
        }
    },
    raqib6: {
        // refresh page after this amount of time
        refresh_interval_default: 360000,
        // if true, enable auto refresh after refresh_interval_default seconds passed by default
        should_refresh_default: true,
        timeranges: [
            {label: '1 Hour', value: 3600},
            {label: '2 Hours', value: 7200},
            {label: '3 Hours', value: 10800},
            {label: '6 Hours', value: 21600},
            {label: 'Today', value: today},
            {label: '1 Day Ago', value: 86400},
            {label: '2 Days Ago', value: 172800},
            {label: '3 Days Ago', value: 259200},
            {label: '4 Days Ago', value: 345600},
            {label: '5 Days Ago', value: 432000},
            {label: '6 Days Ago', value: 518400},
            {label: '7 Days Ago', value: 604800}
        ],
        social_platforms: [
            {label: 'Instagram', value: 'RSS_INSTAGRAM'},
            {label: 'Facebook', value: 'RSS_FACEBOOK'},
            {label: 'X', value: 'RSS_TWITTER'},
            {label: 'TikTok', value: 'RSS_TIKTOK'},
            {label: 'YouTube', value: 'RSS_YOUTUBE'},
            {label: 'Telegram', value: 'RSS_TELEGRAM'},
            {label: 'Web', value: 'RSS_OTHER'},
            {label: 'Vk', value: 'RSS_VK'},
            {label: 'Ok', value: 'RSS_OK'},
            {label: 'News', value: 'NEWS'},
        ],
        colors: {
            'RSS_INSTAGRAM': [229, 62, 62],
            'RSS_TWITTER': [0, 0, 45],
            'RSS_FACEBOOK': [56, 88, 152],
            'RSS_TELEGRAM': [0, 136, 204],
            'TELEGRAM': [0, 136, 204],
            'RSS_YOUTUBE': [128, 90, 213],
            'RSS_TIKTOK': [213, 63, 140],
            'RSS_OTHER': [214, 158, 46],
            'RSS_VK': [49, 151, 149],
            'RSS_OK': [0, 181, 216],
            'NEWS': [153, 0, 76]
        }
    },
    raqib7: {
        // refresh page after this amount of time
        refresh_interval_default: 360000,
        // if true, enable auto refresh after refresh_interval_default seconds passed by default
        should_refresh_default: true,
        rss_platforms: [
            {label: 'Instagram', value: 'RSS_INSTAGRAM'},
            {label: 'Facebook', value: 'RSS_FACEBOOK'},
            {label: 'X', value: 'RSS_TWITTER'},
            {label: 'TikTok', value: 'RSS_TIKTOK'},
            {label: 'YouTube', value: 'RSS_YOUTUBE'},
            {label: 'Telegram', value: 'RSS_TELEGRAM'},
            {label: 'Web', value: 'RSS_OTHER'},
            {label: 'Vk', value: 'RSS_VK'},
            {label: 'Ok', value: 'RSS_OK'},
            {label: 'News', value: 'NEWS'},
        ],
        colors: {
            'RSS_INSTAGRAM': [229, 62, 62],
            'RSS_TWITTER': [0, 0, 45],
            'RSS_FACEBOOK': [56, 88, 152],
            'RSS_TELEGRAM': [0, 136, 204],
            'TELEGRAM': [0, 136, 204],
            'RSS_YOUTUBE': [128, 90, 213],
            'RSS_TIKTOK': [213, 63, 140],
            'RSS_OTHER': [214, 158, 46],
            'RSS_VK': [49, 151, 149],
            'RSS_OK': [0, 181, 216],
            'NEWS': [153, 0, 76]
        }
    },
    articles: {
        // refresh page after this amount of time
        refresh_interval_default: 360000,
        // if true, enable auto refresh after refresh_interval_default seconds passed by default
        should_refresh_default: true,
        periods: [
            {label: 'Today', value: today},
            {label: '1 Day Ago', value: 86400},
            {label: '2 Days Ago', value: 172800},
            {label: '3 Days Ago', value: 259200},
            {label: '4 Days Ago', value: 345600},
            {label: '5 Days Ago', value: 432000},
            {label: '6 Days Ago', value: 518400},
            {label: '7 Days Ago', value: 604800}
        ],
        colors: {
            'GOOGLE_TRENDS': [56, 161, 105],
            'RSS_INSTAGRAM': [229, 62, 62],
            'RSS_TWITTER': [0, 0, 45],
            'RSS_FACEBOOK': [56, 88, 152],
            'RSS_TELEGRAM': [0, 136, 204],
            'TELEGRAM': [0, 136, 204],
            'RSS_YOUTUBE': [128, 90, 213],
            'RSS_TIKTOK': [213, 63, 140],
            'RSS_OTHER': [214, 158, 46]
        }
    },
    sources: [
        // {label: 'Trendsmap', value: 'TRENDSMAP'},
        {label: 'Trends24', value: 'TRENDS24'},
        {label: 'Google Trends', value: 'GOOGLE_TRENDS'},
        // {label: 'X Trends', value: 'TWITTER_TRENDS'},
        {label: 'Telegram', value: 'TELEGRAM'},
        {label: 'Commoninja.com', value: 'COMMONINJA'},
        {label: 'Observer', value: 'NITTER'},
        {label: 'RSS', value: 'RSS'}
    ],
    rss_platforms: [
        {label: 'Instagram', value: 'RSS_INSTAGRAM'},
        {label: 'Facebook', value: 'RSS_FACEBOOK'},
        {label: 'X', value: 'RSS_TWITTER'},
        {label: 'Telegram', value: 'RSS_TELEGRAM'},
        {label: 'TikTok', value: 'RSS_TIKTOK'},
        {label: 'YouTube', value: 'RSS_YOUTUBE'},
        {label: 'Web', value: 'RSS_OTHER'},
        {label: 'Vk', value: 'RSS_VK'},
        {label: 'Ok', value: 'RSS_OK'}
    ],
    trends_platforms: [
        {label: 'Instagram', value: 'INSTAGRAM'},
        {label: 'Facebook', value: 'FACEBOOK'},
        {label: 'X', value: 'TWITTER'},
        {label: 'Telegram', value: 'TELEGRAM'},
        {label: 'TikTok', value: 'TIKTOK'},
        {label: 'YouTube', value: 'YOUTUBE'},
        {label: 'Web', value: 'OTHER'},
        {label: 'Vk', value: 'VK'},
        {label: 'Ok', value: 'OK'}
    ]
}
export default configs;