import {createContext, useEffect, useReducer, useRef} from "react";
// import axios from "../services/axios";
import {getSession, resetSession, setSession} from "../utils/session";
import useAxios from "../hooks/useAxois";

const initialState = {
    isAuthenticated: false,
    isInitialized: false,
    user: null,
    token: null
};

export const AuthContext = createContext({
    ...initialState,
    login: () => Promise.resolve(),
    logout: () => Promise.resolve(),
    hasPermission: () => false
});

const handlers = {
    INITIALIZE: (state, action) => {
        const {isAuthenticated, user, token} = action.payload;

        return {
            ...state,
            isAuthenticated,
            isInitialized: true,
            user,
            token
        };
    },
    LOGIN: (state, action) => {
        const {user, token} = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            user,
            token
        };
    },
    LOGOUT: (state) => {
        return {
            ...state,
            isAuthenticated: false,
            user: null,
            token: null,
        };
    }
};

const reducer = (state, action) => handlers[action.type] ? handlers[action.type](state, action) : state;

export const AuthProvider = (props) => {
    const axiosInstance = useAxios();
    const {children} = props;
    const [state, dispatch] = useReducer(reducer, initialState);
    const isMounted = useRef(false);

    useEffect(() => {
        if (isMounted.current) return;
        const initialize = async () => {
            try {
                const accessToken = getSession();
                if (accessToken) {
                    setSession(accessToken);

                    const response = await axiosInstance.get("/users/me");
                    const {data: user} = response;
                    dispatch({
                        type: "INITIALIZE",
                        payload: {
                            isAuthenticated: true,
                            user,
                            accessToken
                        },
                    });
                } else {
                    dispatch({
                        type: "INITIALIZE",
                        payload: {
                            isAuthenticated: false,
                            user: null,
                            token: null
                        },
                    });
                }
            } catch (error) {
                console.error(error);
                dispatch({
                    type: "INITIALIZE",
                    payload: {
                        isAuthenticated: false,
                        user: null,
                        token: null
                    },
                });
            }
        };
        initialize();
        isMounted.current = true;
    }, []);

    const getTokens = async (email, password) => {
        const formData = new FormData();
        formData.append("username", email);
        formData.append("password", password);
        try {
            const response = await axiosInstance.post("/auth/login", formData);
            setSession(response.data.access_token, response.data.refresh_token);
            return response.data.access_token
        } catch (error) {
            throw error;
        }
    };

    const login = async (email, password) => {
        try {
            const token = await getTokens(email, password);
            const response = await axiosInstance.get("/users/me");
            const {data: user} = response;

            dispatch({
                type: "LOGIN",
                payload: {
                    user,
                    token
                },
            });
        } catch (err) {
            return Promise.reject(err);
        }
    };

    const logout = () => {
        resetSession();
        dispatch({type: "LOGOUT"});
    };

    const hasPermission = (resource, action) => {
        const permission = `${resource.toLowerCase()}:${action.replace(/[./`:~\\|\-\s@]/g, '').toLowerCase()}`;
        return state.isAuthenticated && state.user && state.user?.permissions && state.user?.permissions.includes(permission);
    };

    return (
        <AuthContext.Provider
            value={{
                ...state,
                login,
                logout,
                hasPermission
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const AuthConsumer = AuthContext.Consumer;
