import {Box, Container, Spinner, Text} from "@chakra-ui/react";
import {useCallback, useEffect, useRef, useState} from "react";
import {AddUpdateResourceModal} from "./AddUpdateResourceModal";
import {ChangeResourceTimezoneModal} from "./ChangeResourceTimezoneModal";
import {SearchResourceModal} from "./SearchResourceModal";
import {ResourceCard} from "./ResourceCard";
import {useAuth} from "../../hooks/useAuth";
import useAxios from "../../hooks/useAxois";
import axios from "axios";
import {FormattedMessage} from "react-intl";
import {ResourcesCountPieChart} from "../Charts/ResourcesCountPieChart";
import {ResourcesByStatesMapChart} from "../Charts/ResourcesByStatesMapChart";
import {MostActiveResourceBarChart} from "../Charts/MostActiveResourceBarChart";
import {ResourcesLocationsStatisticsPieChart} from "../Charts/ResourcesLocationsStatisticsPieChart";

export const ResourceList = () => {
    const axiosInstance = useAxios();
    const {hasPermission} = useAuth();
    const [resources, setResources] = useState([]);
    const [loading, setLoading] = useState(true);
    const isMounted = useRef(false);
    const [filters, setFilters] = useState(null);
    const [selectedResources, setSelectedResources] = useState([]);

    const [error, setError] = useState(false);
    const [lastElmId, setLastElmId] = useState(null);
    const [pageId, setPageId] = useState(null);
    const [nextPageId, setNextPageId] = useState(null);
    const observer = useRef();

    // useEffect(() => {
    //     if (isMounted.current) return;
    //     onSubmit();
    //     isMounted.current = true;
    // }, []);

    useEffect(() => {
        onSubmit(true);
    }, [pageId]);

    useEffect(() => {
        if (filters === null) return;
        onSubmit();
    }, [filters]);

    const onSubmit = (paginate = false) => {
        setLastElmId(null);
        setLoading(true);
        setError(false);
        let cancel;

        // handle pagination
        let payload = {
            cancelToken: new axios.CancelToken(c => cancel = c),
            params: paginate ? {...filters, after: pageId} : filters
        }

        if (!paginate) {
            setNextPageId(null)
            setPageId(null)
            setResources([])
        }

        axiosInstance.get("/resource/", payload).then((res) => setResources(prev => {
            // discard previous results if it's not paginating
            const theData = paginate ? [...prev, ...res.data] : [...res.data];

            // uniqify data combination
            let itteratedKeys = [];
            let newData = [];
            theData.map(resourceItem => {
                if (!itteratedKeys.includes(resourceItem.resource_id)) {
                    itteratedKeys.push(resourceItem.resource_id);
                    newData.push(resourceItem);
                }
            });

            // the nextPageId is determined by least sort (desc)
            setLastElmId(theData.length ? theData[theData.length - 1].resource_id : null);
            setNextPageId(theData.length ? theData[theData.length - 1].resource_id : null)
            setLoading(false);
            setError(false);

            return newData;
        })).catch((error) => {
            if (axios.isCancel(error)) return;
            setLoading(false);
            if (error.response && error.response.status === 404) return;
            setError(true);
            console.error(error);
        });
        return () => cancel();
    }

    const lastResourceElmRef = useCallback(node => {
        if (loading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && nextPageId) {
                setPageId(nextPageId)
            }
        })
        if (node) observer.current.observe(node);
    }, [loading, nextPageId]);

    return (
        <Container mt={9}>
            <ResourcesCountPieChart/>
            <ResourcesByStatesMapChart/>
            <MostActiveResourceBarChart/>
            <ResourcesLocationsStatisticsPieChart/>
            {hasPermission('resource', 'fe_create') && <AddUpdateResourceModal mb={1} onSuccess={onSubmit}/>}
            {hasPermission('resource', 'fe_manage') &&
                <SearchResourceModal mt={1} setFilters={setFilters} filters={filters}/>}
            {hasPermission('resource', 'fe_edit') &&
                <ChangeResourceTimezoneModal mt={1} onSuccess={onSubmit} resources={resources}
                                             selectedResources={selectedResources}
                                             setSelectedResources={setSelectedResources}/>}
            <Box mt={6}>
                {resources?.map((resource) => {
                    return <ResourceCard
                        resource={resource}
                        key={resource.resource_id}
                        refr={resource.resource_id === lastElmId ? lastResourceElmRef : undefined}
                        selectedResources={selectedResources}
                        setSelectedResources={setSelectedResources}/>
                })}
                <Box w='100%' textAlign='center'>
                    {!resources.length && !loading &&
                        <Text>
                            <FormattedMessage
                                id="responses.noData"
                                defaultMessage="No Data"
                            />
                        </Text>}
                    {!loading && error &&
                        <Text>
                            <FormattedMessage
                                id="responses.connectionFailed"
                                defaultMessage="Connection Failed"
                            />
                        </Text>}
                    {loading && <Spinner mt={5} color="green"/>}
                </Box>
            </Box>
        </Container>
    );
};
